import {useMemo, useState} from 'react'
import {saveAs} from 'file-saver'
import {createEvent} from 'ics'
import {arrayOf, shape, string} from 'prop-types'
import {
  Root as PopoverRoot,
  Trigger as PopoverTrigger,
} from '@radix-ui/react-popover'
import {CloseIcon, AddToCalendarIcon} from 'assets'
import {Button, Flex, Icon, Link, Text} from 'components'
import {formatLocation, formatCountryToString} from 'utils'
import {PopoverArrow, PopoverContent, PopoverClose} from './styles'
import {
  formatCalendarDate,
  formatGoogleDate,
  getAttendees,
  getNextDay,
} from './utils'

const AddToCalendar = ({
  allUsers,
  city,
  countryCode,
  description,
  endsOn,
  listType,
  startsOn,
  title,
  usersAttending,
  url,
}) => {
  const [open, setOpen] = useState(false)
  const isEventsTable = listType === 'events'
  const location = useMemo(
    () =>
      city &&
      countryCode &&
      formatLocation(city, formatCountryToString(countryCode)),
    [city, countryCode],
  )
  const start = formatCalendarDate(startsOn) || ''
  const endsOnPlusOne = endsOn && getNextDay(endsOn)
  const end = endsOnPlusOne ? formatCalendarDate(endsOnPlusOne) : ''
  const endDateGoogle = endsOnPlusOne
    ? formatGoogleDate(endsOnPlusOne)
    : formatGoogleDate(getNextDay(startsOn))
  const attendees = useMemo(
    () =>
      allUsers?.length > 0 &&
      usersAttending &&
      getAttendees(allUsers, usersAttending),
    [allUsers, usersAttending],
  )
  const event = {
    start,
    end,
    title,
    description,
    location,
    url,
    attendees,
  }
  const encodedName = useMemo(() => title && encodeURIComponent(title), [title])
  const encodedLocation = useMemo(
    () => location && encodeURIComponent(location),
    [location],
  )
  const encodedDescription = useMemo(
    () => description && encodeURIComponent(description),
    [description],
  )
  const usersAttendingEmails =
    attendees?.length > 0 && attendees.map(({email}) => email).join(',')
  const appList = [
    {
      name: 'Outlook',
    },
    {
      name: 'Apple Calendar',
    },
    {
      name: 'Google',
      url: `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodedName}&dates=${formatGoogleDate(
        startsOn,
      )}/${endDateGoogle}${encodedLocation && `&location=${encodedLocation}`}${
        encodedDescription ? `&details=${encodedDescription}` : ''
      }${url ? `&sprop=website:${url}` : ''}${
        usersAttendingEmails ? `&add=${usersAttendingEmails}` : ''
      }`,
    },
  ]
  const handleSaveCalendarFile = () => {
    createEvent(event, (error, value) => {
      const blob = new Blob([value], {type: 'text/plain;charset=utf-8'})
      saveAs(blob, 'event.ics')
    })
  }
  const AppListLinks = () => (
    <ul>
      {appList.map(app => (
        <li key={app.name}>
          {app.name === 'Google' ? (
            <Link css={{fontSize: '$13'}} href={app.url} external>
              {app.name}
            </Link>
          ) : (
            <Button
              css={{color: '$black', lh: '$title', '&:hover': {txtd: 'none'}}}
              color="secondary"
              type="button"
              onClick={handleSaveCalendarFile}
              variant="ghost-icon"
              size="ghost-small"
            >
              {app.name}
            </Button>
          )}
        </li>
      ))}
    </ul>
  )
  return (
    (startsOn || endsOn) && (
      <PopoverRoot open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            color={isEventsTable && 'grey'}
            css={!isEventsTable && {whiteSpace: 'nowrap'}}
            size={isEventsTable ? 'ghost-small' : 'small'}
            title="Add to calendar"
            type="button"
            variant={isEventsTable ? 'ghost' : 'secondary-icon'}
          >
            <Icon
              css={!isEventsTable && {mr: '$4'}}
              data-testid="edit"
              size="16"
            >
              <AddToCalendarIcon />
            </Icon>
            {!isEventsTable && <Text>Add to calendar</Text>}
          </Button>
        </PopoverTrigger>
        <PopoverContent sideOffset={8}>
          <PopoverArrow />
          <Flex css={{mb: '$16'}}>
            <Text variant="card-subtitle" css={{fg: '1', mb: 0}}>
              Add to calendar
            </Text>
            <PopoverClose>
              <Button
                as="span"
                size="ghost-small"
                title="Close"
                type="button"
                variant="ghost-icon"
              >
                <Icon color="grey200" size="20">
                  <CloseIcon />
                </Icon>
              </Button>
            </PopoverClose>
          </Flex>
          <AppListLinks />
        </PopoverContent>
      </PopoverRoot>
    )
  )
}

AddToCalendar.propTypes = {
  allUsers: arrayOf(shape({})).isRequired,
  city: string.isRequired,
  countryCode: string.isRequired,
  description: string.isRequired,
  endsOn: string.isRequired,
  listType: string.isRequired,
  startsOn: string.isRequired,
  title: string.isRequired,
  url: string.isRequired,
  usersAttending: string.isRequired,
}

export default AddToCalendar
