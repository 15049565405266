import {memo} from 'react'
import {bool, func} from 'prop-types'
import {Box} from 'components'
import {useDeviceDetect} from 'hooks'
import MenuList from './components'
import Flex from './styles'

const sectionNavItems = [
  {
    id: 'nav-01',
    label: 'Dashboard',
    path: '/dashboard',
    title: 'Dashboard - Ctrl + Alt + 1',
  },
  {
    id: 'nav-02',
    label: 'Companies',
    path: '/companies',
    title: 'Companies - Ctrl + Alt + 2',
  },
  {
    id: 'nav-03',
    label: 'People',
    path: '/people',
    title: 'People - Ctrl + Alt + 3',
  },
  {
    id: 'nav-04',
    label: 'Investors',
    path: '/investors',
    title: 'Investors - Ctrl + Alt + 4',
  },
  {
    id: 'nav-06',
    label: 'Events',
    path: '/events',
    title: 'Events - Ctrl + Alt + 6',
  },
]

const mobileNavItems = [
  {
    id: 'mobile-nav-01',
    label: 'Search',
    path: '/search',
    title: 'Search',
  },
  {
    id: 'mobile-nav-02',
    label: 'Add Company',
    path: '/add-company',
    title: 'Add Company',
  },
  {
    id: 'mobile-nav-03',
    label: 'Upload List',
    path: '/upload-list',
    title: 'Upload List',
  },
  {
    id: 'mobile-nav-04',
    label: 'Create List',
    path: '/create-list',
    title: 'Create List',
  },
]

const userNavItems = [
  {
    id: 'user-nav-01',
    label: 'My Lists',
    path: '/my-lists',
    title: 'My Lists - Ctrl + Alt + 7',
  },
  {
    id: 'user-nav-03',
    label: 'Shortcuts',
    path: '/shortcuts',
    title: 'Shortcuts - Ctrl + Alt + 8',
  },
  {
    id: 'user-nav-04',
    label: 'Settings',
    path: '/settings',
    title: 'Settings - Ctrl + Alt + 9',
  },
  {
    id: 'user-nav-05',
    label: 'Logout',
    path: '/logout',
    title: 'Logout - Ctrl + Alt + 0',
  },
]

const userNavMobileItems = userNavItems.filter(i => i.label !== 'Shortcuts')

const MenuNav = ({extendMenu, setShowMobileMenu, showMobileMenu}) => {
  const {isMobile} = useDeviceDetect()
  return (
    <Flex
      as="nav"
      data-testid="menu-nav"
      variant="menu-nav"
      css={{
        d: showMobileMenu ? 'flex' : 'none',
        '@bp3': {d: 'flex'},
      }}
    >
      <MenuList
        extendMenu={extendMenu}
        navList={sectionNavItems}
        setShowMobileMenu={setShowMobileMenu}
        showMobileMenu={showMobileMenu}
      />
      <Flex direction="column">
        <Box
          css={{
            d: 'block',
            mb: '-$16',
            '@bp3': {d: 'none'},
          }}
        >
          <MenuList
            extendMenu={extendMenu}
            navList={mobileNavItems}
            setShowMobileMenu={setShowMobileMenu}
            showMobileMenu={showMobileMenu}
          />
        </Box>
        <Box>
          <MenuList
            extendMenu={extendMenu}
            navList={isMobile ? userNavMobileItems : userNavItems}
            setShowMobileMenu={setShowMobileMenu}
            showMobileMenu={showMobileMenu}
          />
        </Box>
      </Flex>
    </Flex>
  )
}

MenuNav.propTypes = {
  extendMenu: bool.isRequired,
  setShowMobileMenu: func.isRequired,
  showMobileMenu: bool.isRequired,
}

export default memo(MenuNav)
