import {Text as BaseText} from 'components/primitives'
import {styled} from 'styles'

const Text = styled(BaseText, {
  variants: {
    variant: {
      'date-cell': {
        lh: '$body',
        whiteSpace: 'nowrap',
      },
    },
  },
})

export default Text
