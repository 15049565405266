import BaseText from 'components/primitives/Text'
import {styled} from 'styles'

const Text = styled(BaseText, {
  variants: {
    variant: {
      'people-results-description': {
        d: '-webkit-box',
        lh: '$body',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 5,
        maxWidth: '200px',
      },
    },
  },
})

export default Text
