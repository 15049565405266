import {useState} from 'react'
import {bool, func, string} from 'prop-types'
import {CloseModalButton, Modal, Text, UserFeedback} from 'components'
import EditListForm from './components'

const EditListModal = ({
  creatorId,
  creatorOffice,
  description,
  modal,
  name,
  setModal,
  uuid,
}) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  if (isError)
    return (
      <UserFeedback isError={isError} setIsError={setIsError}>
        Error editing list information. Please, try again or report this bug.
      </UserFeedback>
    )
  if (isSuccess)
    return (
      <UserFeedback isSuccess={isSuccess} setIsSuccess={setIsSuccess}>
        List information successfully updated
      </UserFeedback>
    )
  return (
    <Modal modal={modal} setModal={setModal}>
      <CloseModalButton setModal={setModal} />
      <Text variant="modal-title">Edit {name}</Text>
      <EditListForm
        creatorId={creatorId}
        creatorOffice={creatorOffice}
        description={description}
        name={name}
        setIsError={setIsError}
        setIsSuccess={setIsSuccess}
        setModal={setModal}
        uuid={uuid}
      />
    </Modal>
  )
}

EditListModal.propTypes = {
  creatorId: string.isRequired,
  creatorOffice: string.isRequired,
  description: string.isRequired,
  modal: bool.isRequired,
  name: string.isRequired,
  setModal: func.isRequired,
  uuid: string.isRequired,
}

export default EditListModal
