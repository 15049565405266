import {useEffect, useRef, useState} from 'react'
import {useMutation} from '@apollo/client'
import {useForm} from 'react-hook-form'
import {useLocation} from 'react-router-dom'
import {CREATE_NEW_LIST, SAVE_COMPANY_TO_LIST} from 'constants/mutations'
import {GET_MY_LISTS, GET_COMPANY_IN_LISTS} from 'constants/queries'

const useCreateNewListForm = ({
  commentText,
  companiesToSave,
  orgUuid,
  setCompaniesSavedOrRemoved,
  setIsCreateError,
  setIsCreateSuccess,
  setIsSaveError,
  setIsSaveSuccess,
  setListName,
  setModal,
}) => {
  const [listUuid, setListUuid] = useState('')
  const [checkedUsersIds, setCheckedUsersIds] = useState([])
  const listNameRef = useRef(null)
  const {pathname} = useLocation()
  const {register, handleSubmit, errors} = useForm({mode: 'onBlur'})
  const [createNewList, {loading: isCreateNewListLoading}] = useMutation(
    CREATE_NEW_LIST,
    {
      onCompleted: ({
        createCustomList: {
          customList: {uuid},
        },
      }) => {
        if ((orgUuid || companiesToSave) && uuid) {
          setListUuid(uuid)
        }
        if (setModal) setModal(false)
        setIsCreateSuccess(true)
      },
      onError: error => {
        if (setModal) setModal(false)
        setIsCreateError(true)
        console.error(error)
      },
    },
  )
  const [saveToList, {loading: isSaveToListLoading}] = useMutation(
    SAVE_COMPANY_TO_LIST,
    {
      onError: err => {
        if (setModal) setModal(false)
        setIsSaveError(true)
        console.error(err)
      },
      onCompleted: () => {
        setModal(false)
        setIsSaveSuccess(true)
      },
    },
  )
  useEffect(() => listNameRef?.current && listNameRef.current.focus(), [])
  const handleCreateNewList = ({description, name}) => {
    setListName(name)
    createNewList({
      variables: {
        collaboratorIds: checkedUsersIds.join(),
        description,
        name,
      },
      refetchQueries: (pathname === '/my-lists' ||
        pathname === '/dashboard') && [{query: GET_MY_LISTS}],
    })
  }
  useEffect(() => {
    if (listUuid && companiesToSave?.length > 0) {
      setCompaniesSavedOrRemoved(companiesToSave)
      companiesToSave.map(company =>
        saveToList({
          variables: {commentText, listUuid, orgUuid: company.orgUuid},
          refetchQueries: [
            {query: GET_COMPANY_IN_LISTS, variables: {uuid: company.orgUuid}},
          ],
        }),
      )
    }
    if (orgUuid && listUuid) {
      saveToList({
        variables: {commentText, listUuid, orgUuid},
        refetchQueries: [
          {query: GET_COMPANY_IN_LISTS, variables: {uuid: orgUuid}},
        ],
      })
    }
  }, [
    commentText,
    companiesToSave,
    listUuid,
    orgUuid,
    saveToList,
    setCompaniesSavedOrRemoved,
  ])
  const isSaveLoading = isCreateNewListLoading || isSaveToListLoading
  return {
    checkedUsersIds,
    errors,
    handleCreateNewList,
    handleSubmit,
    isSaveLoading,
    listNameRef,
    pathname,
    register,
    setCheckedUsersIds,
  }
}

export default useCreateNewListForm
