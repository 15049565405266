import {memo} from 'react'
import {shape, string} from 'prop-types'
import {Link, Text} from 'components'

const CompanyCell = ({
  row: {
    original: {orgName, orgPermalink, orgUuid},
  },
}) =>
  orgPermalink && orgUuid ? (
    <Link
      css={{
        d: 'block',
        maxWidth: '200px',
        minWidth: '144px',
        overflowWrap: 'break-word',
      }}
      external
      href={`/companies/${orgPermalink}?uuid=${orgUuid}`}
      title={orgName}
    >
      {orgName}
    </Link>
  ) : (
    <Text css={{lh: '$body'}}>{orgName}</Text>
  )

CompanyCell.propTypes = {
  row: shape({
    original: shape({
      orgName: string,
      orgPermalink: string,
      orgUuid: string,
    }),
  }).isRequired,
}

export default memo(CompanyCell)
