import {CloseIcon} from 'assets'
import {Icon} from 'components'

const ClearSelectedIcon = () => (
  <Icon color="grey300" size="19">
    <CloseIcon data-testid="icon" />
  </Icon>
)

export default ClearSelectedIcon
