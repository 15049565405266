import {memo} from 'react'
import {arrayOf, func, shape} from 'prop-types'
import {
  Box,
  Button,
  CancelButton,
  Checkbox,
  CheckboxList,
  ErrorMessage,
  Flex,
  Label,
  Loader,
  MentionInput,
  SelectAllCheckbox,
  Text,
} from 'components'
import useShareCompanyForm from './hooks'

const ShareCompanyForm = ({companiesToShare, setIsSuccess, setModal}) => {
  const {
    checkedUsersIds,
    checkedUsersIdsOtherOffices,
    checkedUsersIdsSameOffice,
    comment,
    commentTextRef,
    errorMsg,
    formattedOffice,
    handleSaveToMyActionList,
    handleShareCompany,
    handleSubmit,
    isAddToActionListError,
    isAddToActionListLoading,
    isCheckedMyself,
    isError,
    isUsersQueryError,
    isUsersQueryLoading,
    myId,
    myOffice,
    setCheckedUsersIds,
    setCheckedUsersIdsOtherOffices,
    setCheckedUsersIdsSameOffice,
    setComment,
    setUsers,
    users,
  } = useShareCompanyForm({companiesToShare, setIsSuccess, setModal})
  if (isUsersQueryError)
    return (
      <ErrorMessage>
        An error occurred getting the users data. Please try to refresh or
        report this bug.
      </ErrorMessage>
    )
  return (
    <Flex
      as="form"
      direction="column"
      onSubmit={handleSubmit(handleShareCompany)}
    >
      {isUsersQueryLoading ? (
        <Loader size="20" css={{mb: '$16'}} />
      ) : (
        <Flex css={{mb: '$16', jc: 'space-between'}}>
          <Box css={{mr: '$24'}}>
            <Text variant="office">{formattedOffice} office:</Text>
            <SelectAllCheckbox
              checkedUsersIdsOtherOffices={checkedUsersIdsOtherOffices}
              checkedUsersIdsSameOffice={checkedUsersIdsSameOffice}
              isCheckedMyself={isCheckedMyself}
              myId={myId}
              office={myOffice}
              setCheckedUsersIds={setCheckedUsersIds}
              setCheckedUsersIdsSameOffice={setCheckedUsersIdsSameOffice}
              setUsers={setUsers}
              users={users[myOffice]}
            />
            <CheckboxList
              checkedUsersIds={checkedUsersIds}
              checkedUsersIdsOtherOffices={checkedUsersIdsOtherOffices}
              checkedUsersIdsSameOffice={checkedUsersIdsSameOffice}
              myOffice={myOffice}
              office={myOffice}
              setCheckedUsersIds={setCheckedUsersIds}
              setCheckedUsersIdsOtherOffices={setCheckedUsersIdsOtherOffices}
              setCheckedUsersIdsSameOffice={setCheckedUsersIdsSameOffice}
              setUsers={setUsers}
              users={users[myOffice]}
            />
          </Box>
          <Box css={{fg: '0.5'}}>
            <Text variant="office">Other offices:</Text>
            <CheckboxList
              checkedUsersIds={checkedUsersIds}
              checkedUsersIdsOtherOffices={checkedUsersIdsOtherOffices}
              checkedUsersIdsSameOffice={checkedUsersIdsSameOffice}
              myOffice={myOffice}
              office="otherOffices"
              setCheckedUsersIds={setCheckedUsersIds}
              setCheckedUsersIdsOtherOffices={setCheckedUsersIdsOtherOffices}
              setCheckedUsersIdsSameOffice={setCheckedUsersIdsSameOffice}
              setUsers={setUsers}
              users={users.otherOffices}
            />
          </Box>
        </Flex>
      )}
      <Box
        css={{
          mb: '$24',
          '@initial': {w: '311px'},
          '@bp0': {w: '350px'},
          '@bp1': {w: '638px'},
          '@bp2': {w: '704px'},
          '@bp4': {w: '768px'},
          '@landscape678': {w: '603px'},
          '@landscape678plus': {w: '672px'},
          '@landscapeX': {w: '748px'},
        }}
      >
        <Label htmlFor="commentText">Comment</Label>
        <MentionInput
          comment={comment}
          inputRef={commentTextRef}
          name="commentText"
          onChange={e => setComment(e.target.value)}
          onKeyDown={({key}) => key === 'Escape' && setModal(false)}
        />
      </Box>
      <Box>
        <Label htmlFor="me" css={{d: 'inline-block', whiteSpace: 'nowrap'}}>
          <Checkbox
            css={{mr: '$8'}}
            defaultChecked
            id="me"
            name="me"
            onChange={e => handleSaveToMyActionList(e)}
            type="checkbox"
          />
          Also save to my Action List
        </Label>
      </Box>
      {(isError || isAddToActionListError) && (
        <Text variant="form-error" css={{mt: '$16'}}>
          {errorMsg} error
        </Text>
      )}
      <Flex css={{as: 'flex-end', gap: '$16', mt: '$24'}}>
        <CancelButton onClick={() => setModal(false)}>Cancel</CancelButton>
        <Flex as={Button} type="submit">
          {isAddToActionListLoading && (
            <Loader size="14" css={{mr: '$8'}} data-testid="loader" />
          )}
          Share
        </Flex>
      </Flex>
    </Flex>
  )
}

ShareCompanyForm.propTypes = {
  companiesToShare: arrayOf(shape({})).isRequired,
  setIsSuccess: func.isRequired,
  setModal: func.isRequired,
}

export default memo(ShareCompanyForm)
