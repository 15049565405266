import {func, shape} from 'prop-types'
import {CloseIcon} from 'assets'
import {Button, Icon} from 'components'

const ClearSearchButton = ({searchInputRef, setQuery}) => (
  <Button
    css={{
      position: 'relative',
      right: '$8',
      zIndex: '$z400',
    }}
    onClick={() => {
      setQuery('')
      if (searchInputRef?.current) {
        searchInputRef.current.focus()
      }
    }}
    size="ghost-small"
    type="button"
    variant="ghost-icon"
  >
    <Icon color="grey200" size="24" data-testid="icon">
      <CloseIcon />
    </Icon>
  </Button>
)

ClearSearchButton.propTypes = {
  searchInputRef: shape({}).isRequired,
  setQuery: func.isRequired,
}

export default ClearSearchButton
