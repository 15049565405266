import {bool, string} from 'prop-types'
import {
  Flex,
  Button,
  Loader,
  MentionInput,
  Text,
  UserFeedback,
} from 'components'
import useCommentsForm from './hooks'

const CommentsForm = ({isEvent, isPerson, uuid}) => {
  const {
    comment,
    commentRef,
    errors,
    handleAddComment,
    handleSubmit,
    isAddCommentError,
    isAddCommentLoading,
    isError,
    register,
    setComment,
    setIsError,
  } = useCommentsForm({isEvent, isPerson, uuid})
  return (
    <>
      <Flex
        as="form"
        css={{d: 'flex', mt: '$16', fd: 'column', '@bp2': {fd: 'row'}}}
        onSubmit={handleSubmit(handleAddComment)}
        data-testid="comments-form"
      >
        <MentionInput
          onChange={e => setComment(e.target.value)}
          onKeyDown={({key}) => key === 'Escape' && commentRef.current.blur()}
          inputRef={e => {
            register(e, {required: true})
            commentRef.current = e
          }}
          placeholder="Add a new comment"
          name="comment"
          comment={comment}
          setComment={setComment}
          errors={errors}
        />
        <Flex
          as={Button}
          css={{
            as: 'flex-start',
            jc: 'center',
            mt: '$8',
            whiteSpace: 'nowrap',
            '@bp2': {ml: '$16', mt: 0},
          }}
          size="small"
          type="submit"
        >
          Add comment
          {isAddCommentLoading && (
            <Loader size="16" css={{ml: '$8'}} data-testid="loader" />
          )}
        </Flex>
      </Flex>
      {errors.comment && <Text variant="form-error">Required</Text>}
      {isAddCommentError && isError && (
        <UserFeedback isError={isError} setIsError={setIsError}>
          An error occurred. Please try again or report this bug.
        </UserFeedback>
      )}
    </>
  )
}

CommentsForm.propTypes = {
  isEvent: bool.isRequired,
  isPerson: bool.isRequired,
  uuid: string.isRequired,
}

export default CommentsForm
