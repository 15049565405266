import {
  formatExtra,
  formatLastReview,
  formatLocation,
  formatTeam,
  getBetalistUrl,
  getCapterraUrl,
  getEditedOrOriginal,
  getEditedOrOriginalCountry,
  getEditedOrOriginalDiversityFounders,
  getEditedOrOriginalFoundedOnYear,
  getEditedOrOriginalHomepageUrl,
  getEditedOrOriginalTwitterUrl,
  getPipedriveUrl,
  isOnPipedriveCheck,
} from 'utils'

const formatPortfolioCompanies = data =>
  data
    .map(company => {
      const extraData = formatExtra(company.extra)
      const cityData = getEditedOrOriginal('city', extraData, company.city)
      const country = getEditedOrOriginalCountry(extraData, company.countryCode)
      return {
        ...company,
        betalistUrl: getBetalistUrl(extraData),
        capterraUrl: getCapterraUrl(extraData),
        city: cityData,
        country,
        diversityFounders: getEditedOrOriginalDiversityFounders(
          extraData,
          company.diversityFounders,
        ),
        foundedOnYear: getEditedOrOriginalFoundedOnYear(
          extraData,
          company.foundedOn,
        ),
        funding: company.totalFundingUsd,
        homepageUrl: getEditedOrOriginalHomepageUrl(
          extraData,
          company.homepageUrl,
        ),
        isOnPipedrive: isOnPipedriveCheck(company.organizationpipedrive),
        linkedinUrl: getEditedOrOriginal(
          'linkedinUrl',
          extraData,
          company.linkedinUrl,
        ),
        location: formatLocation(cityData, country),
        nautable: formatLastReview(company.lastReview),
        name: getEditedOrOriginal('name', extraData, company.name),
        orgName: getEditedOrOriginal('name', extraData, company.name),
        orgPermalink: company.permalink,
        orgUuid: company.uuid,
        pipedriveUrl: getPipedriveUrl(company.organizationpipedrive),
        shortDescription: getEditedOrOriginal(
          'shortDescription',
          extraData,
          company.shortDescription,
        ),
        team: formatTeam(company.employeeCount),
        twitterUrl: getEditedOrOriginalTwitterUrl(
          extraData,
          company.twitterUrl,
        ),
      }
    })
    .map(
      ({
        __typename,
        countryCode,
        employeeCount,
        organizationpipedrive,
        ...company
      }) => company,
    )

export default formatPortfolioCompanies
