import {memo} from 'react'
import {string} from 'prop-types'
import {LocationIcon} from 'assets'
import {Flex, Icon} from 'components'
import {formatLocation} from 'utils'
import Text from './styles'

const ProfileLocation = ({city, country, location}) => (
  <Flex css={{ai: 'center', mb: '$8'}} data-testid="profile-location">
    <Icon color="grey300" size="14" css={{mb: '6px', mr: '$4'}}>
      <LocationIcon />
    </Icon>
    {location ? (
      <Text variant="location">{location}</Text>
    ) : (
      <Text variant="location">{formatLocation(city, country)}</Text>
    )}
  </Flex>
)

ProfileLocation.propTypes = {
  city: string.isRequired,
  country: string.isRequired,
  location: string.isRequired,
}

export default memo(ProfileLocation)
