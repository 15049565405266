import {useCallback, useState} from 'react'
import {useMutation} from '@apollo/client'
import {ADD_REVIEW} from 'constants/mutations'
import {GET_COMPANY_LAST_REVIEW} from 'constants/queries'
import useKeyShortcut from '../useKeyShortcut'

const useReviewShortcuts = ({
  isInvestorsPage,
  orgUuid,
  setIsReviewLoading,
  setFocusNextItem,
}) => {
  const [otherReasonModal, setOtherReasonModal] = useState(false)
  const [isReviewError, setIsReviewError] = useState(false)
  const [addReview] = useMutation(ADD_REVIEW, {
    onCompleted: () => {
      setIsReviewLoading(false)
      setOtherReasonModal(false)
    },
    onError: error => {
      setIsReviewError(true)
      console.error(error)
    },
  })
  const handleAddReview = useCallback(
    (nautable, notNautableReason, notNautableOther) => {
      setIsReviewLoading(true)
      addReview({
        variables: {
          orgUuid,
          nautable,
          notNautableReason,
          notNautableOther,
        },
        refetchQueries: [
          {
            query: GET_COMPANY_LAST_REVIEW,
            variables: {
              uuid: orgUuid,
            },
          },
        ],
      })
      setFocusNextItem(true)
    },
    [addReview, orgUuid, setIsReviewLoading, setFocusNextItem],
  )
  useKeyShortcut('1', () => !isInvestorsPage && handleAddReview('yes'))
  useKeyShortcut('2', () => !isInvestorsPage && handleAddReview('not_sure'))
  useKeyShortcut(
    '3',
    () => !isInvestorsPage && handleAddReview('no', 'too_late'),
  )
  useKeyShortcut('4', () => !isInvestorsPage && handleAddReview('no', 'team'))
  useKeyShortcut(
    '5',
    () => !isInvestorsPage && handleAddReview('no', 'not_in_nauta_scope'),
  )
  useKeyShortcut(
    '6',
    () => !isInvestorsPage && handleAddReview('no', 'small_market'),
  )
  useKeyShortcut(
    '7',
    () => !isInvestorsPage && handleAddReview('no', 'competition_too_high'),
  )
  useKeyShortcut(
    '8',
    () => !isInvestorsPage && handleAddReview('no', 'portfolio_overlap'),
  )
  useKeyShortcut(
    '9',
    () => !isInvestorsPage && handleAddReview('no', 'not_active'),
  )
  useKeyShortcut(
    '0',
    () => !isInvestorsPage && handleAddReview('no', 'not_interesting'),
  )
  useKeyShortcut(
    'o',
    () => !isInvestorsPage && setOtherReasonModal(!otherReasonModal),
    true,
  )
  return {
    isReviewError,
    handleAddReview,
    otherReasonModal,
    setOtherReasonModal,
    setIsReviewError,
  }
}

export default useReviewShortcuts
