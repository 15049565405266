import {useEffect, useMemo, useState, createRef} from 'react'
import {
  useFilters,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import {DefaultColumnFilter, filterTypes} from 'components'
import {useTableShortcuts, useReviewShortcuts} from 'hooks'
import {SelectedColumnCell, SelectedColumnHeader} from './components'

const useCompaniesListTable = ({
  columns,
  controlledPageCount,
  controlledPageIndex,
  controlledPageSize,
  data,
  listType,
  listUuid,
  scope,
  setFilteredCompaniesLength,
  setIsFiltered,
}) => {
  const [commentText, setCommentText] = useState('')
  const [createNewListModal, setCreateNewListModal] = useState(false)
  const [companiesSavedOrRemoved, setCompaniesSavedOrRemoved] = useState([])
  const [companyToReview, setCompanyToReview] = useState([])
  const [companyToShare, setCompanyToShare] = useState([])
  const [companyToSave, setCompanyToSave] = useState([])
  const [isReviewLoading, setIsReviewLoading] = useState(false)
  const [isColumnsSavedSuccess, setIsColumnsSavedSuccess] = useState(false)
  const defaultColumn = useMemo(() => ({Filter: DefaultColumnFilter}), [])
  const isInvestorsPage = listType === 'investors-page'
  const isCompaniesPageOrInvestorPage =
    listType === 'companies-page' || listType === 'investors-page'
  const {
    canNextPage,
    canPreviousPage,
    getTableBodyProps,
    getTableProps,
    allColumns,
    gotoPage,
    headerGroups,
    nextPage,
    page,
    pageCount,
    prepareRow,
    previousPage,
    rows,
    selectedFlatRows,
    setHiddenColumns,
    setPageSize,
    state: {pageIndex, pageSize, filters},
  } = useTable(
    isCompaniesPageOrInvestorPage
      ? {
          autoResetFilters: false,
          autoResetPage: false,
          autoResetSortBy: false,
          columns,
          data,
          defaultColumn,
          filterTypes,
          initialState: {
            hiddenColumns: [
              'comment',
              'firstName',
              'newEmployeesPerMonthValue',
            ],
            pageIndex: controlledPageIndex,
            pageSize: controlledPageSize,
          },
          manualPagination: true,
          pageCount: controlledPageCount,
          useControlledState: state => {
            if (state.pageIndex !== controlledPageIndex) {
              return {...state, pageIndex: controlledPageIndex}
            }
            if (state.pageSize !== controlledPageSize) {
              return {...state, pageSize: controlledPageSize}
            }
            return state
          },
        }
      : {
          autoResetPage: false,
          autoResetSortBy: false,
          autoResetFilters: false,
          columns,
          data,
          defaultColumn,
          filterTypes,
          initialState: {
            hiddenColumns: [
              'comment',
              'firstName',
              'newEmployeesPerMonthValue',
            ],
            pageIndex: 0,
            pageSize: 25,
          },
          manualPagination: false,
        },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      if (listType !== 'my-lists-page' && listType !== 'action-list-my-lists') {
        hooks.visibleColumns.push(columnsItems => [
          {
            id: 'selection',
            Header: SelectedColumnHeader,
            Cell: SelectedColumnCell,
          },
          ...columnsItems,
        ])
      }
    },
  )
  useEffect(() => {
    if (
      !isCompaniesPageOrInvestorPage &&
      listType !== 'my-lists-page' &&
      listType !== 'action-list-my-lists'
    ) {
      setFilteredCompaniesLength(rows.length)
      if (filters?.length) {
        setIsFiltered(true)
      }
    }
  }, [
    filters.length,
    isCompaniesPageOrInvestorPage,
    listType,
    rows.length,
    setFilteredCompaniesLength,
    setIsFiltered,
  ])

  useEffect(() => {
    const columnsToHide = JSON.parse(localStorage.getItem(`columns_to_hide`))
    switch (listType) {
      case 'action-list-my-lists':
        setHiddenColumns(columnsToHide || [])
        setPageSize(3)
        break
      case 'my-lists-page':
        setHiddenColumns(columnsToHide || [])
        setPageSize(3)
        break
      default:
        setHiddenColumns(columnsToHide || [])
    }
  }, [listType, setHiddenColumns, setPageSize])

  const companiesToReview = useMemo(
    () =>
      selectedFlatRows.map(({original: {uuid, name}}) => ({
        orgUuid: uuid,
        name,
      })),
    [selectedFlatRows],
  )

  const companiesToSave = useMemo(
    () =>
      selectedFlatRows.map(
        ({original: {inMyActionlist, inMyList, logoUrl, name, uuid}}) => ({
          logoUrl,
          inMyActionlist,
          inMyList,
          orgName: name,
          orgUuid: uuid,
        }),
      ),
    [selectedFlatRows],
  )

  const companiesToShare = useMemo(
    () =>
      selectedFlatRows.map(({original: {uuid, name}}) => ({
        uuid,
        name,
      })),
    [selectedFlatRows],
  )

  const companiesToOpenLinks = useMemo(
    () =>
      selectedFlatRows.map(
        ({
          original: {
            cbUrl,
            homepageUrl,
            linkedinUrl,
            name,
            permalink,
            pipedriveUrl,
            twitterUrl,
            type,
            uuid,
          },
        }) => ({
          original: {
            cbUrl,
            homepageUrl,
            linkedinUrl,
            name,
            permalink,
            pipedriveUrl,
            twitterUrl,
            type,
            uuid,
          },
        }),
      ),
    [selectedFlatRows],
  )

  const rowsRefs = useMemo(
    () => [...Array(page.length)].map(() => createRef()),
    [page.length],
  )

  const {
    addToPipedriveModal,
    cursor,
    reviewModal,
    saveRemoveListModal,
    selected,
    setAddToPipedriveModal,
    setCursor,
    setFocusNextItem,
    setReviewModal,
    setSaveRemoveListModal,
    setSelected,
    setShareModal,
    setShowColumnsModal,
    shareModal,
    showColumnsModal,
  } = useTableShortcuts({
    canNextPage,
    canPreviousPage,
    listType,
    listUuid,
    nextPage,
    page,
    pageIndex,
    previousPage,
    rowsRefs,
    scope,
    setCompanyToReview,
    setCompanyToSave,
    setCompanyToShare,
  })
  const {
    isReviewError,
    setIsReviewError,
    handleAddReview,
    otherReasonModal,
    setOtherReasonModal,
  } = useReviewShortcuts({
    isInvestorsPage,
    orgUuid: selected?.original?.uuid,
    setIsReviewLoading,
    setFocusNextItem,
  })
  const hasHiddenColumns = allColumns.some(c => !c.isVisible)
  return {
    addToPipedriveModal,
    allColumns,
    canNextPage,
    canPreviousPage,
    commentText,
    companiesSavedOrRemoved,
    companiesToOpenLinks,
    companiesToReview,
    companiesToSave,
    companiesToShare,
    companyToReview,
    companyToSave,
    companyToShare,
    createNewListModal,
    cursor,
    filters,
    getTableBodyProps,
    getTableProps,
    gotoPage,
    handleAddReview,
    hasHiddenColumns,
    headerGroups,
    isColumnsSavedSuccess,
    isCompaniesPageOrInvestorPage,
    isReviewError,
    isReviewLoading,
    nextPage,
    otherReasonModal,
    page,
    pageCount,
    pageIndex,
    pageSize,
    prepareRow,
    previousPage,
    reviewModal,
    rows,
    rowsRefs,
    saveRemoveListModal,
    selected,
    setAddToPipedriveModal,
    setCommentText,
    setCompaniesSavedOrRemoved,
    setCreateNewListModal,
    setCursor,
    setFocusNextItem,
    setIsColumnsSavedSuccess,
    setIsReviewError,
    setIsReviewLoading,
    setOtherReasonModal,
    setPageSize,
    setReviewModal,
    setSaveRemoveListModal,
    setSelected,
    setShareModal,
    setShowColumnsModal,
    shareModal,
    showColumnsModal,
  }
}

export default useCompaniesListTable
