import {memo} from 'react'
import {bool, func, string} from 'prop-types'
import {ListItem} from 'components'
import MenuItemLink from './components'

const MenuItem = ({
  extendMenu,
  label,
  path,
  setShowMobileMenu,
  showMobileMenu,
  title,
}) => (
  <ListItem variant="menu-item" css={{w: extendMenu ? '$48' : '$full'}}>
    <MenuItemLink
      extendMenu={extendMenu}
      label={label}
      path={path}
      setShowMobileMenu={setShowMobileMenu}
      showMobileMenu={showMobileMenu}
      title={title}
    />
  </ListItem>
)

MenuItem.propTypes = {
  extendMenu: bool.isRequired,
  label: string.isRequired,
  path: string.isRequired,
  setShowMobileMenu: func.isRequired,
  showMobileMenu: bool.isRequired,
  title: string,
}

MenuItem.defaultProps = {
  title: '',
}

export default memo(MenuItem)
