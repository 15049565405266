import {Box} from 'components'
import {arrayOf, shape, string} from 'prop-types'
import {formatBucketsUi, formatBucketsFundingType} from 'utils'
import Text from './styles'

const InvestorsSearchFilters = ({
  fundingRoundTypes,
  portfolioCategories,
  portfolioLocation,
}) => (
  <Text css={{mt: '$8'}}>
    {portfolioCategories?.length > 0 && (
      <Box css={{mb: '$4'}}>
        <Text variant="investors-filter-title">Portfolio categories: </Text>
        <Text variant="investors-filter-selected">
          {formatBucketsUi(portfolioCategories)}
        </Text>
      </Box>
    )}
    {fundingRoundTypes?.length > 0 && (
      <Box css={{mb: '$4'}}>
        <Text variant="investors-filter-title">Funding round types: </Text>
        <Text variant="investors-filter-selected">
          {formatBucketsFundingType(fundingRoundTypes)}
        </Text>
      </Box>
    )}
    {portfolioLocation?.length > 0 && (
      <Box>
        <Text variant="investors-filter-title">Portfolio location: </Text>
        <Text variant="investors-filter-selected">
          {formatBucketsUi(portfolioLocation)}
        </Text>
      </Box>
    )}
  </Text>
)

InvestorsSearchFilters.propTypes = {
  fundingRoundTypes: arrayOf(shape({value: string, label: string})).isRequired,
  portfolioCategories: arrayOf(shape({value: string, label: string}))
    .isRequired,
  portfolioLocation: arrayOf(shape({value: string, label: string})).isRequired,
}

export default InvestorsSearchFilters
