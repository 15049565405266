import {string} from 'prop-types'
import {ListRequestIcon} from 'assets'
import {Icon, Link} from 'components'

const WebScrapingLink = ({webscrapingListUuid, webscrapingName}) => (
  <Link
    ariaLabel={`Open ${webscrapingName} web scraping`}
    css={{
      d: 'block',
      lh: '$body',
      '&:focus': {txtd: 'underline'},
      svg: {color: '$green'},
    }}
    external
    href={`/list/web_scraping?listUuid=${webscrapingListUuid}${
      webscrapingName && `&name=${webscrapingName}`
    }`}
    title="Open web scraping"
  >
    <Icon css={{as: 'center', mt: '$2'}} size="14">
      <ListRequestIcon />
    </Icon>
  </Link>
)

WebScrapingLink.propTypes = {
  webscrapingListUuid: string.isRequired,
  webscrapingName: string.isRequired,
}

export default WebScrapingLink
