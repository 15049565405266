const formatLabelsData = data =>
  data
    .slice()
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    .map(label => ({
      ...label,
      dateToCompare: label.createdAt,
      type: 'label',
    }))

export default formatLabelsData
