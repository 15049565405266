import {formatSentenceCapitalize} from 'utils'

export const formatBuckets = values =>
  values?.length > 0 ? values.map(filter => filter.value).join(',') : ''

export const formatBucketsUi = values =>
  values?.length > 0
    ? values.map(filter => formatSentenceCapitalize(filter.label)).join(', ')
    : ''

export const formatBucketsFundingType = values =>
  values?.length > 0
    ? values
        .map(filter =>
          formatSentenceCapitalize(filter.label.split('_').join(' ')),
        )
        .join(', ')
    : ''
