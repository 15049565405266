import {func} from 'prop-types'
import {useLocation} from 'react-router-dom'
import {EditIcon} from 'assets'
import {Button, Icon, Text} from 'components'

const EditListButton = ({setModal}) => {
  const {pathname} = useLocation()
  const isMyListCompaniesPage =
    pathname !== '/my-lists' && pathname !== '/dashboard'
  return (
    <Button
      color="grey"
      css={
        isMyListCompaniesPage ? {mr: '$8', whiteSpace: 'nowrap'} : {mr: '$4'}
      }
      onClick={() => setModal(true)}
      size={isMyListCompaniesPage ? 'small' : 'ghost-small'}
      title="Edit list"
      type="button"
      variant={isMyListCompaniesPage ? 'secondary-icon' : 'ghost'}
    >
      <Icon
        css={isMyListCompaniesPage ? {mr: '$4'} : {ml: '$4'}}
        data-testid="edit"
        size="16"
      >
        <EditIcon />
      </Icon>
      {isMyListCompaniesPage && <Text>Edit list</Text>}
    </Button>
  )
}

EditListButton.propTypes = {
  setModal: func.isRequired,
}

export default EditListButton
