import {useEffect, useMemo, useRef, useState} from 'react'
import {useMutation} from '@apollo/client'
import {useForm} from 'react-hook-form'
import {useLocation} from 'react-router-dom'
import {GET_EVENT_EDITION, GET_EVENTS} from 'pages/Events/queries'
import EDIT_EVENT from 'pages/Events/mutations'
import SCOPE_OPTIONS from 'pages/Events/components/AddEvent/components/AddEventModal/components/AddEventForm/constants'
import {formatAttendingOptions} from 'pages/Events/utils'
import {formatBuckets, formatCountryToAlpha3} from 'utils'

const useEditEventForm = ({
  allUsers,
  editionUuid,
  scope,
  setIsError,
  setIsSuccess,
  setModal,
  usersAttending,
}) => {
  const {pathname} = useLocation()
  const ATTENDING_OPTIONS = useMemo(
    () => allUsers?.length > 0 && formatAttendingOptions(allUsers),
    [allUsers],
  )
  const defaultUsersAttending = useMemo(
    () =>
      (ATTENDING_OPTIONS?.length > 0 &&
        usersAttending &&
        usersAttending
          .split(',')
          .map(id => ATTENDING_OPTIONS?.find(user => id === user?.value))) ||
      [],
    [ATTENDING_OPTIONS, usersAttending],
  )
  const defaultScope = useMemo(
    () =>
      (SCOPE_OPTIONS?.length > 0 &&
        scope &&
        scope
          .split(', ')
          ?.map(s =>
            SCOPE_OPTIONS.find(option => s.toLowerCase() === option.value),
          )) ||
      [],
    [scope],
  )
  const [finalUsersAttending, setFinalUsersAttending] = useState(
    defaultUsersAttending,
  )
  const [finalScope, setFinalScope] = useState(defaultScope)
  const eventNameRef = useRef(null)
  const {register, handleSubmit, errors} = useForm({mode: 'onBlur'})
  const [
    editEvent,
    {error: isEditEventError, loading: isEditEventLoading},
  ] = useMutation(EDIT_EVENT, {
    onCompleted: () => {
      setIsSuccess(true)
      setModal(false)
    },
    onError: e => {
      setIsError(true)
      console.error(e)
    },
  })
  const handleEditEvent = ({
    city,
    country,
    description,
    editionFrequency,
    endsOn,
    linkedinUrl,
    name,
    onlineEvent,
    recurringEvent,
    startsOn,
    ticketPrice,
    website,
  }) => {
    editEvent({
      variables: {
        bcnScope: formatBuckets(finalScope).includes('bcn'),
        berScope: formatBuckets(finalScope).includes('ber'),
        city,
        countryCode: formatCountryToAlpha3(country),
        description,
        editionFrequency,
        editionUuid,
        endsOn,
        ldnScope: formatBuckets(finalScope).includes('ldn'),
        linkedinUrl,
        name,
        onlineEvent,
        recurringEvent,
        startsOn,
        ticketPrice,
        usersAttending: formatBuckets(finalUsersAttending),
        website,
      },
      refetchQueries: (pathname === '/events' && [
        {
          query: GET_EVENTS,
          variables: {page: 1, pageSize: 100, pastEvents: true},
        },
        {
          query: GET_EVENTS,
          variables: {page: 1, pageSize: 999999, pastEvents: true},
        },
        {
          query: GET_EVENTS,
          variables: {page: 1, pageSize: 999999, futureEvents: true},
        },
      ]) || [{query: GET_EVENT_EDITION, variables: {editionUuid}}],
    })
  }
  useEffect(() => eventNameRef?.current && eventNameRef.current.focus(), [])
  return {
    ATTENDING_OPTIONS,
    errors,
    eventNameRef,
    finalScope,
    finalUsersAttending,
    handleEditEvent,
    handleSubmit,
    isEditEventError,
    isEditEventLoading,
    register,
    SCOPE_OPTIONS,
    setFinalScope,
    setFinalUsersAttending,
  }
}

export default useEditEventForm
