import {styled} from 'styles'

const MentionsTextarea = styled('div', {
  fg: 1,
  fontSize: '$13',
  lh: '$body',
  ox: 'clip',
  oy: 'visible',
  '.mentions__control:': {
    bgc: '#fff',
    fontSize: '$14',
    fontWeight: '$400',
    w: '$full',
  },
  '.mentions__input': {
    w: '$full',
    lh: '$body',
    oy: 'auto !important',
    resize: 'none',
    outline: 'none',
    bgc: '#fff',
    br: '$small',
    b: '1px solid $grey100',
    transition: '0.3s ease-in-out',
    p: '$8',
    maxHeight: '34.25px',
    '&:active': {
      outline: 'none',
      boc: '$black',
    },
    '&::placeholder': {
      color: '$grey200',
    },
    '&:focus': {
      outline: 'none',
      boc: '$black',
    },
  },
  '.mentions__highlighter': {
    p: '$8',
    maxHeight: '34.25px',
  },
  '.mentions__suggestions': {
    br: '$small',
    b: '1px solid $grey100',
  },
  '.mentions__suggestions__list': {
    bgc: 'white',
    maxHeight: '110px',
    overflow: 'auto',
  },
  '.mentions__suggestions__item': {
    padding: '$4 $8',
    borderBottom: '1px solid $grey100',
  },
  '.mentions__suggestions__item--focused': {
    bgc: '$grey100',
  },
  '.mentions__mention': {
    position: 'relative',
    zIndex: '$z100',
    color: '$primary',
    textShadow:
      '1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white',
    pe: 'none',
  },
  variants: {
    borderColor: {
      red: {
        '.mentions__input': {
          boc: '$red',
        },
      },
    },
  },
})

export default MentionsTextarea
