import {func, string} from 'prop-types'
import {useLocation} from 'react-router-dom'
import {ShareIcon} from 'assets'
import {useAuth} from 'context'
import {Button, Icon, Text} from 'components'

const ShareListButton = ({creatorUsername, setModal}) => {
  const {pathname} = useLocation()
  const isMyListCompaniesPage =
    pathname !== '/my-lists' && pathname !== '/dashboard'
  const {authState} = useAuth()
  const loggedUsername = authState.username
  return (
    <Button
      color="grey"
      css={
        isMyListCompaniesPage
          ? {whiteSpace: 'nowrap', mt: '$8', '@bp1': {mt: 0}}
          : {mr: '$4'}
      }
      onClick={() => setModal(true)}
      size={isMyListCompaniesPage ? 'small' : 'ghost-small'}
      title={`${
        loggedUsername === creatorUsername ? `Edit` : `Add`
      } collaborators`}
      type="button"
      variant={isMyListCompaniesPage ? 'secondary-icon' : 'ghost'}
    >
      <Icon
        css={isMyListCompaniesPage ? {mr: '$4'} : {ml: '$4'}}
        data-testid="share"
        size="16"
      >
        <ShareIcon />
      </Icon>
      {isMyListCompaniesPage && (
        <Text>
          {' '}
          {loggedUsername === creatorUsername ? `Edit` : `Add`} Collaborators
        </Text>
      )}
    </Button>
  )
}

ShareListButton.propTypes = {
  creatorUsername: string.isRequired,
  setModal: func.isRequired,
}

export default ShareListButton
