import {memo, useMemo} from 'react'
import {arrayOf, bool, func, number, shape, string} from 'prop-types'
import {
  actionListColumns,
  aggregatedColumns,
  employeeGrowthColumns,
  myListsCompaniesListColumns,
  standardColumns,
} from 'components/table'
import CompaniesListTable from './components'

const CompaniesListPreTable = ({
  allDataLoading,
  listType,
  listUuid,
  preData,
  reviewed,
  scope,
  setFilteredCompaniesLength,
  setIsFiltered,
  setShowShortcuts,
  showShortcuts,
  totalCount,
  week,
  year,
}) => {
  const columns = useMemo(
    () =>
      (listType === 'action-list' && actionListColumns) ||
      (listType === 'aggregated' && aggregatedColumns) ||
      (listType === 'li_radar' && employeeGrowthColumns) ||
      (listType === 'my-lists-companies-list' && myListsCompaniesListColumns) ||
      standardColumns,
    [listType],
  )
  return (
    <div data-testid="pre-table" id="pre-table">
      <CompaniesListTable
        allDataLoading={allDataLoading}
        columns={columns}
        data={preData}
        listType={listType}
        listUuid={listUuid}
        reviewed={reviewed}
        scope={scope}
        setFilteredCompaniesLength={setFilteredCompaniesLength}
        setIsFiltered={setIsFiltered}
        setShowShortcuts={setShowShortcuts}
        showShortcuts={showShortcuts}
        totalCount={totalCount}
        week={week}
        year={year}
      />
    </div>
  )
}

CompaniesListPreTable.propTypes = {
  allDataLoading: bool.isRequired,
  listType: string.isRequired,
  listUuid: string.isRequired,
  preData: arrayOf(shape({})).isRequired,
  reviewed: bool.isRequired,
  scope: string.isRequired,
  setFilteredCompaniesLength: func.isRequired,
  setIsFiltered: func.isRequired,
  setShowShortcuts: func.isRequired,
  showShortcuts: bool.isRequired,
  totalCount: number.isRequired,
  week: string.isRequired,
  year: string.isRequired,
}

export default memo(CompaniesListPreTable)
