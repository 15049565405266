import {func, string} from 'prop-types'
import {
  Box,
  Checkbox,
  ErrorMessage,
  Label,
  Loader,
  PortfolioTable,
} from 'components'
import useFundingRounds from './hooks'

const FundingRounds = ({investorUuid, selectedTab, setHasFundingRounds}) => {
  const {
    columns,
    data,
    isAllPortfolioFundingRoundsQueryError,
    isAllPortfolioFundingRoundsQueryLoading,
    isFirst100PortfolioFundingRoundsQueryError,
    isFirst100PortfolioFundingRoundsQueryLoading,
    setIsFiltered,
  } = useFundingRounds({investorUuid, setHasFundingRounds})
  if (
    isFirst100PortfolioFundingRoundsQueryLoading &&
    selectedTab === 'funding-rounds'
  )
    return <Loader size="20" data-testid="loader" />
  if (isFirst100PortfolioFundingRoundsQueryError)
    return (
      <ErrorMessage css={{mb: '$16'}}>
        An error occurred getting the portfolio data. Please try to refresh or
        report this bug.
      </ErrorMessage>
    )
  return (
    selectedTab === 'funding-rounds' &&
    data?.length > 0 && (
      <>
        <Box css={{mt: '$24'}}>
          <Label htmlFor="filter-first-funding-rounds">
            <Checkbox
              css={{mr: '$8'}}
              id="filter-first-funding-rounds"
              name="filter-first-funding-rounds"
              onChange={() => setIsFiltered(prevIsFiltered => !prevIsFiltered)}
              type="checkbox"
            />
            Show only first investment
          </Label>
        </Box>
        <PortfolioTable
          allDataLoading={isAllPortfolioFundingRoundsQueryLoading}
          columns={columns}
          data={data}
          listType="portfolio-funding-rounds"
          totalCount={data.length}
        />
        {isAllPortfolioFundingRoundsQueryError && (
          <ErrorMessage css={{mt: '$16'}}>
            An error occurred getting the portfolio data. Please try to refresh
            or report this bug.
          </ErrorMessage>
        )}
      </>
    )
  )
}

FundingRounds.propTypes = {
  investorUuid: string.isRequired,
  selectedTab: string.isRequired,
  setHasFundingRounds: func.isRequired,
}

export default FundingRounds
