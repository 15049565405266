import {gql} from '@apollo/client'

export const GET_COMPANY_PROFILE_PIPEDRIVE = gql`
  query organization($uuid: String, $permalink: String) {
    organization(uuid: $uuid, permalink: $permalink) {
      uuid
      organizationpipedrive {
        uuid {
          uuid
        }
        lostReason
        owner
        pipedriveUrl
        status
        updateTime
      }
    }
  }
`

export const GET_COMPANY_PIPEDRIVE = gql`
  query organization($uuid: String) {
    organization(uuid: $uuid) {
      organizationpipedrive {
        pipedriveUrl
        uuid {
          uuid
        }
      }
      uuid
    }
  }
`
