import {memo} from 'react'
import {string} from 'prop-types'
import {formatDate} from 'utils'
import Text from './styles'

const DateCell = ({value}) =>
  value ? <Text variant="date-cell">{formatDate(value)}</Text> : '-'

DateCell.propTypes = {
  value: string.isRequired,
}

export default memo(DateCell)
