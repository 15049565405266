import {Helmet} from 'react-helmet'
import {bool, node} from 'prop-types'
import formatHelmetDescription from './utils'

const TabTitle = ({children, isRoot}) =>
  isRoot ? (
    <Helmet>
      <title>NautaDB</title>
      <link rel="canonical" href="http://www.nautadb.com" />
      <meta name="description" content="NautaDB app" />
    </Helmet>
  ) : (
    <Helmet>
      <title>{children}</title>
      <meta
        name="description"
        content={
          (typeof children === 'object' && formatHelmetDescription(children)) ||
          children
        }
      />
    </Helmet>
  )

TabTitle.propTypes = {
  children: node.isRequired,
  isRoot: bool.isRequired,
}

export default TabTitle
