import {memo} from 'react'
import {bool, func, node, string} from 'prop-types'
import {Box, Input, Label, Text} from 'components'

const InputField = ({
  autoComplete,
  children,
  defaultValue,
  errors,
  name,
  onBlur,
  onKeyDown,
  register,
  required,
  type,
}) => {
  return (
    <Box>
      <Label color={errors && 'red'} htmlFor={name}>
        {children}
        {required && <Text variant="required">*</Text>}
      </Label>
      <Input
        autoComplete={autoComplete}
        borderColor={errors && 'red'}
        css={{'@bp3': {minWidth: '311px'}}}
        defaultValue={defaultValue}
        id={name}
        name={name}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        ref={register}
        type={type}
      />
      {errors && errors.type === 'pattern' && name === 'email' && (
        <Text variant="form-error">Should be a valid email address</Text>
      )}
      {errors &&
        errors.type === 'pattern' &&
        (name === 'organizationLinkedinUrl' ||
          name === 'input_0' ||
          name === 'input_1' ||
          name === 'input_2' ||
          name === 'input_3' ||
          name === 'input_4') && (
          <Text variant="form-error">Please enter a valid Linkedin url</Text>
        )}
      {errors && errors.type === 'required' && (
        <Text variant="form-error">Required</Text>
      )}
    </Box>
  )
}

InputField.propTypes = {
  autoComplete: string.isRequired,
  children: node.isRequired,
  defaultValue: string.isRequired,
  errors: func.isRequired,
  name: string.isRequired,
  onBlur: func.isRequired,
  onKeyDown: func.isRequired,
  register: func.isRequired,
  required: bool.isRequired,
  type: string.isRequired,
}

export default memo(InputField)
