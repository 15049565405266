import {gql} from '@apollo/client'

export const DELETE_COMPANY_LABEL = gql`
  mutation deleteOrganizationLabel($uuid: String!) {
    deleteOrganizationLabel(uuid: $uuid) {
      organizationLabel {
        uuid
      }
    }
  }
`

export const DELETE_PERSON_LABEL = gql`
  mutation deletePersonLabel($uuid: String!) {
    deletePersonLabel(uuid: $uuid) {
      personLabel {
        uuid
      }
    }
  }
`
