import {useMemo, useEffect} from 'react'
import {useQuery} from '@apollo/client'
import categoriesColumns from './columns'
import GET_INVESTOR_PORTFOLIO_CATEGORIES from './queries'

const useCategories = ({investorUuid, setHasCategories}) => {
  const {
    data: portfolioCategoriesData,
    error: isPortfolioCategoriesQueryError,
    loading: isPortfolioCategoriesQueryLoading,
  } = useQuery(GET_INVESTOR_PORTFOLIO_CATEGORIES, {
    variables: {investorUuid},
    onError: error => console.error(error),
  })
  const columns = useMemo(() => categoriesColumns, [])
  const data = useMemo(
    () => portfolioCategoriesData?.investorPortfolioCategories?.categories,
    [portfolioCategoriesData],
  )
  useEffect(() => {
    if (data?.length === 0) {
      setHasCategories(false)
    }
  }, [data, setHasCategories])
  return {
    columns,
    data,
    isPortfolioCategoriesQueryError,
    isPortfolioCategoriesQueryLoading,
  }
}

export default useCategories
