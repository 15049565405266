export const CURRENCY_OPTIONS = [
  {id: 'currency-01', value: 'usd', label: 'USD'},
  {id: 'currency-02', value: 'eur', label: 'EUR'},
  {id: 'currency-03', value: 'gbp', label: 'GBP'},
]

export const FUNDING_STAGE_OPTIONS = [
  {id: 'funding-00', value: '', label: 'Select a stage'},
  {id: 'funding-01', value: 'pre_seed', label: 'Pre-Seed'},
  {id: 'funding-02', value: 'seed', label: 'Seed'},
  {id: 'funding-03', value: 'seed_plus', label: 'Seed+'},
  {id: 'funding-04', value: 'series_a', label: 'Series A'},
  {id: 'funding-05', value: 'series_b', label: 'Series B'},
  {id: 'funding-06', value: 'series_c', label: 'Series C'},
]

export const SECTOR_OPTIONS = [
  {id: 'sector-00', value: '', label: 'Select a sector'},
  {id: 'sector-01', value: 'adtech', label: 'AdTech'},
  {id: 'sector-02', value: 'ai_ml', label: 'AI / ML'},
  {id: 'sector-03', value: 'ar_vr', label: 'AR / VR'},
  {
    id: 'sector-04',
    value: 'business_performance',
    label: 'Business Performance',
  },
  {id: 'sector-05', value: 'cloud', label: 'Cloud management, Serverless'},
  {
    id: 'sector-06',
    value: 'consumer_performance',
    label: 'Consumer Performance, Events and Ticketing',
  },
  {id: 'sector-07', value: 'cybersecurity', label: 'Cybersecurity'},
  {id: 'sector-08', value: 'data_analytics', label: 'Data & Analytics'},
  {id: 'sector-09', value: 'devops', label: 'DevOps and Developer Tools'},
  {id: 'sector-10', value: 'digital_marketing', label: 'Digital Marketing'},
  {id: 'sector-11', value: 'ecommerce', label: 'eCommerce & Marketplace'},
  {id: 'sector-12', value: 'edtech', label: 'EdTech & Recruitment'},
  {id: 'sector-13', value: 'fintech', label: 'FinTech & Payments'},
  {id: 'sector-14', value: 'hardware', label: 'Hardware'},
  {id: 'sector-15', value: 'healthtech', label: 'HealthTech'},
  {id: 'sector-16', value: 'ict', label: 'ICT'},
  {id: 'sector-17', value: 'insurtech', label: 'Insurtech'},
  {id: 'sector-18', value: 'iot', label: 'Internet of Things'},
  {id: 'sector-19', value: 'legaltech', label: 'LegalTech / RegTech'},
  {id: 'sector-20', value: 'media', label: 'Media PDC'},
  {id: 'sector-21', value: 'mobile', label: 'Mobile & Internet Technology'},
  {id: 'sector-22', value: 'os', label: 'Open Source'},
  {id: 'sector-23', value: 'other', label: 'Other Technologies (plug)'},
  {id: 'sector-24', value: 'quantum', label: 'Quantum Technology'},
  {id: 'sector-25', value: 'proptech', label: 'Real Estate Tech'},
]

export const SOURCE_OPTIONS = [
  {id: 'source-00', value: '', label: 'Select a source'},
  {id: 'source-01', value: 'inbound', label: 'Inbound'},
  {id: 'source-02', value: 'outbound', label: 'Outbound'},
]

export const CHANNEL_OPTIONS = [
  {id: 'channel-00', value: '', label: 'Select a channel'},
  {id: 'channel-01', value: 'nautadb_lists', label: 'Nauta DB lists'},
  {id: 'channel-02', value: 'thesis_research', label: 'Thesis research'},
  {id: 'channel-03', value: 'event/conference', label: 'Event/conference'},
  {
    id: 'channel-04',
    value: 'feeder_fund_and_angel_portfolios',
    label: 'Feeder fund & angel portfolios',
  },
  {
    id: 'channel-05',
    value: 'co-investors_and_follow-on_funds',
    label: 'Co-investors & follow-on funds',
  },
  {
    id: 'channel-06',
    value: 'commutities_and_network_referrals',
    label: 'Communities & network referrals',
  },
  {
    id: 'channel-07',
    value: 'cold_inbound_message',
    label: 'Cold inbound message',
  },
  {
    id: 'channel-08',
    value: 'fundraising_advisors',
    label: 'Fundraising advisors',
  },
  {id: 'channel-09', value: 'website_inbound', label: 'Website inbound'},
  {id: 'channel-10', value: 'other', label: 'Other'},
]
