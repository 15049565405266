import {memo} from 'react'
import {arrayOf, bool, func, shape, string} from 'prop-types'
import {Checkbox, Label} from 'components'
import {formatOffice} from 'utils'
import useSelectAllCheckbox from './hooks'

const SelectAllCheckbox = ({
  checkedUsersIdsOtherOffices,
  checkedUsersIdsSameOffice,
  isCheckedMyself,
  myId,
  office,
  setCheckedUsersIds,
  setCheckedUsersIdsSameOffice,
  setUsers,
  users,
}) => {
  const {handleSelectAll} = useSelectAllCheckbox({
    checkedUsersIdsOtherOffices,
    checkedUsersIdsSameOffice,
    isCheckedMyself,
    myId,
    office,
    setCheckedUsersIds,
    setCheckedUsersIdsSameOffice,
    setUsers,
    users,
  })
  return (
    <Label htmlFor="checkAll" css={{w: 'fit-content'}}>
      <Checkbox
        aria-label="Select all"
        css={{mr: '$8'}}
        checked={(users && users.every(({isChecked}) => isChecked)) || false}
        name="checkAll"
        onChange={e => handleSelectAll(e.target.checked)}
        type="checkbox"
      />
      All {formatOffice(office)} office
    </Label>
  )
}

SelectAllCheckbox.propTypes = {
  checkedUsersIdsOtherOffices: arrayOf(string).isRequired,
  checkedUsersIdsSameOffice: arrayOf(string).isRequired,
  isCheckedMyself: bool.isRequired,
  myId: string.isRequired,
  office: string.isRequired,
  setCheckedUsersIds: func.isRequired,
  setCheckedUsersIdsSameOffice: func.isRequired,
  setUsers: func.isRequired,
  users: arrayOf(shape({})).isRequired,
}

export default memo(SelectAllCheckbox)
