const getNextDay = date => {
  const currentDate = date && new Date(date)
  return currentDate
    ? new Date(currentDate.setDate(currentDate.getDate() + 1))
        .toISOString()
        .split('T')[0]
    : ''
}

export default getNextDay
