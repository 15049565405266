import {keyframes, styled} from 'styles'

const spin = keyframes({
  from: {
    transform: 'rotate(0deg)',
  },
  to: {
    transform: 'rotate(360deg)',
  },
})

const Loader = styled('div', {
  b: '2px solid transparent',
  borderTop: '2px solid $grey100',
  br: '$round',
  animation: `${spin} 1.1s linear infinite`,
  variants: {
    size: {
      14: {
        wh: '$14',
      },
      16: {
        wh: '$16',
      },
      20: {
        wh: '$20',
      },
    },
  },
})

export default Loader
