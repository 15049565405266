import {memo} from 'react'
import {bool, func} from 'prop-types'
import {ShortcutsIcon} from 'assets'
import {Button, Icon} from 'components'
import {useKeyShortcut} from 'hooks'

const ShortcutsButton = ({setShowShortcuts, showShortcuts}) => {
  useKeyShortcut('S', () => setShowShortcuts(!showShortcuts))
  return (
    <Button
      css={{
        d: 'none',
        '@bp4': {d: 'flex', mt: 0, whiteSpace: 'nowrap'},
      }}
      onClick={() => setShowShortcuts(!showShortcuts)}
      size="small"
      title={`${showShortcuts ? 'Hide' : 'Show'} shortcuts - S`}
      type="button"
      variant="secondary-icon"
    >
      <Icon size="16" css={{mr: '$4'}}>
        <ShortcutsIcon />
      </Icon>
      {showShortcuts ? 'Hide' : 'Show'} shortcuts
    </Button>
  )
}

ShortcutsButton.propTypes = {
  setShowShortcuts: func.isRequired,
  showShortcuts: bool.isRequired,
}

export default memo(ShortcutsButton)
