import {gql} from '@apollo/client'

const DELETE_EVENT = gql`
  mutation deleteCustomEvent($editionUuid: String!) {
    deleteCustomEvent(editionUuid: $editionUuid) {
      customEvent {
        editionUuid
      }
    }
  }
`

export default DELETE_EVENT
