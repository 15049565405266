import {memo} from 'react'
import {func} from 'prop-types'
import {Redirect} from 'react-router-dom'
import {
  Box,
  Button,
  CancelButton,
  Checkbox,
  CheckboxList,
  ErrorMessage,
  Flex,
  Grid,
  InputField,
  Label,
  Link,
  Loader,
  MentionInput,
  SelectAllCheckbox,
  SelectField,
  Text,
  TextareaField,
} from 'components'
import DIVERSE_TEAM_OPTIONS from 'constants/options'
import useAddCompanyForm from './hooks'
import YEAR_OPTIONS from './options'

const AddCompanyForm = ({setModal}) => {
  const {
    checkedUsersIds,
    checkedUsersIdsOtherOffices,
    checkedUsersIdsSameOffice,
    comment,
    companyNameRef,
    errorMsg,
    errors,
    formattedMyOffice,
    handleAddNewCompany,
    handleSaveToMyActionList,
    handleSubmit,
    isAddNewCompanyError,
    isAddNewCompanyLoading,
    isCheckedMyself,
    isSuccess,
    isUsersQueryError,
    isUsersQueryLoading,
    myId,
    myOffice,
    permalink,
    register,
    setCheckedUsersIds,
    setCheckedUsersIdsOtherOffices,
    setCheckedUsersIdsSameOffice,
    setComment,
    setUsers,
    url,
    users,
    usersData,
    uuid,
  } = useAddCompanyForm({setModal})
  if (isSuccess) return <Redirect to={`/companies/${permalink}?uuid=${uuid}`} />
  return (
    <Flex
      as="form"
      direction="column"
      onSubmit={handleSubmit(handleAddNewCompany)}
      data-testid="add-company-form"
    >
      <Grid variant="modal">
        <InputField
          errors={errors.name}
          name="name"
          register={e => {
            register(e, {required: true})
            companyNameRef.current = e
          }}
          onKeyDown={({key}) => key === 'Escape' && setModal(false)}
          required
          type="text"
        >
          Company name
        </InputField>
        <InputField
          errors={errors.homepageUrl}
          name="homepageUrl"
          register={register({required: true})}
          required
          type="text"
        >
          Website
        </InputField>
        <TextareaField
          maxLength={1500}
          name="shortDescription"
          register={register}
          rows={1}
        >
          Short description
        </TextareaField>
        <InputField name="city" register={register} type="text">
          City
        </InputField>
        <InputField name="countryCode" register={register} type="text">
          Country
        </InputField>
        <SelectField
          name="foundedOnYear"
          options={YEAR_OPTIONS}
          register={register}
        >
          Founded on year
        </SelectField>
        <InputField name="linkedinUrl" register={register} type="text">
          Linkedin Url
        </InputField>
        <SelectField
          name="diversityFounders"
          options={DIVERSE_TEAM_OPTIONS}
          register={register({required: true})}
          required
          errors={errors.diversityFounders}
        >
          Diverse founding team?
        </SelectField>
        <Box css={{mt: '$8'}} variant="span-column">
          <Text variant="modal-subtitle">Share company with (optional)</Text>
        </Box>
        <Box variant="span-column">
          {isUsersQueryError && (
            <ErrorMessage css={{mb: '$16'}}>
              An error occurred getting the users data. Please try to refresh or
              report this bug.
            </ErrorMessage>
          )}
          {isUsersQueryLoading && <Loader size="20" css={{mb: '$8'}} />}
          {usersData && (
            <Flex css={{jc: 'space-between'}}>
              <Box css={{mr: '$24'}}>
                <Text variant="office">{formattedMyOffice} office:</Text>
                <SelectAllCheckbox
                  checkedUsersIdsOtherOffices={checkedUsersIdsOtherOffices}
                  checkedUsersIdsSameOffice={checkedUsersIdsSameOffice}
                  isCheckedMyself={isCheckedMyself}
                  myId={myId}
                  office={myOffice}
                  setCheckedUsersIds={setCheckedUsersIds}
                  setCheckedUsersIdsSameOffice={setCheckedUsersIdsSameOffice}
                  setUsers={setUsers}
                  users={users[myOffice]}
                />
                <CheckboxList
                  checkedUsersIds={checkedUsersIds}
                  checkedUsersIdsOtherOffices={checkedUsersIdsOtherOffices}
                  checkedUsersIdsSameOffice={checkedUsersIdsSameOffice}
                  myOffice={myOffice}
                  office={myOffice}
                  setCheckedUsersIds={setCheckedUsersIds}
                  setCheckedUsersIdsOtherOffices={
                    setCheckedUsersIdsOtherOffices
                  }
                  setCheckedUsersIdsSameOffice={setCheckedUsersIdsSameOffice}
                  setUsers={setUsers}
                  users={users[myOffice]}
                />
              </Box>
              <Box css={{fg: '0.5'}}>
                <Text variant="office">Other offices:</Text>
                <CheckboxList
                  checkedUsersIds={checkedUsersIds}
                  checkedUsersIdsOtherOffices={checkedUsersIdsOtherOffices}
                  checkedUsersIdsSameOffice={checkedUsersIdsSameOffice}
                  myOffice={myOffice}
                  office="otherOffices"
                  setCheckedUsersIds={setCheckedUsersIds}
                  setCheckedUsersIdsSameOffice={setCheckedUsersIdsSameOffice}
                  setCheckedUsersIdsOtherOffices={
                    setCheckedUsersIdsOtherOffices
                  }
                  setUsers={setUsers}
                  users={users.otherOffices}
                />
              </Box>
            </Flex>
          )}
        </Box>
        <Box
          variant="span-column"
          css={{
            mt: '-$8',
            '@initial': {w: '327px'},
            '@bp0': {w: '366px'},
            '@bp1': {w: '638px'},
            '@bp2': {w: '720px'},
            '@bp3': {w: '768px'},
            '@landscape678': {w: '619px'},
            '@landscape678plus': {w: '688px'},
            '@landscapeX': {w: '764px'},
          }}
        >
          <Label htmlFor="commentText">Comment</Label>
          <MentionInput
            name="commentText"
            comment={comment}
            onChange={e => setComment(e.target.value)}
            inputRef={register}
          />
        </Box>
        <Box>
          <Label htmlFor="me" css={{d: 'inline-block', whiteSpace: 'nowrap'}}>
            <Checkbox
              css={{mr: '$8'}}
              defaultChecked
              id="me"
              name="me"
              onChange={e => handleSaveToMyActionList(e)}
              type="checkbox"
            />
            Also save to my Action List
          </Label>
        </Box>
      </Grid>
      <Flex
        direction={{'@initial': 'column', '@bp3': 'row'}}
        css={{
          mt: '$24',
          '@bp3': {
            ai: 'center',
            jc: 'space-between',
          },
        }}
      >
        <Box css={{mb: '$16', '@bp3': {mb: 0, w: '400px'}}}>
          {isAddNewCompanyError && errorMsg && (
            <>
              <Text variant="form-error">{errorMsg}: </Text>
              <Link
                to={url}
                css={{
                  mr: '$16',
                  fontSize: '$14',
                  txtd: 'underline',
                }}
                onClick={() => setModal(false)}
              >
                Open company profile
              </Link>
            </>
          )}
          {isAddNewCompanyError && !errorMsg && (
            <Text variant="form-error">
              An error occurred. Please try again or report this bug.
            </Text>
          )}
        </Box>
        <Flex css={{as: 'flex-end', gap: '$16', mt: '$24'}}>
          <CancelButton onClick={() => setModal(false)}>Cancel</CancelButton>
          <Flex as={Button} type="submit">
            Add company
            {isAddNewCompanyLoading && <Loader size="14" css={{ml: '$8'}} />}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

AddCompanyForm.propTypes = {
  setModal: func.isRequired,
}

export default memo(AddCompanyForm)
