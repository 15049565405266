import {useState} from 'react'
import {arrayOf, bool, func, number, shape, string} from 'prop-types'
import {FallbackImgCompanySm} from 'assets'
import {
  CloseModalButton,
  Flex,
  Image,
  Modal,
  Text,
  UserFeedback,
} from 'components'
import SaveToListForm from './components'

const SaveToListModal = ({
  commentText,
  companiesSavedOrRemoved,
  companiesToSave,
  inMyActionlist,
  logoUrl,
  modal,
  orgName,
  orgUuid,
  setCommentText,
  setCompaniesSavedOrRemoved,
  setCreateNewListModal,
  setModal,
  totalCount,
}) => {
  const [isSaveSuccess, setIsSaveSuccess] = useState(false)
  const [isSaveError, setIsSaveError] = useState(false)
  const [isRemoveSuccess, setIsRemoveSuccess] = useState(false)
  const [isRemoveError, setIsRemoveError] = useState(false)
  const [selectedListsNamesToSave, setSelectedListsNamesToSave] = useState([])
  const [selectedListsNamesToRemove, setSelectedListsNamesToRemove] = useState(
    [],
  )
  const [companySavedOrRemoved, setCompanySavedOrRemoved] = useState('')
  const [
    companySavedOrRemovedLogoUrl,
    setCompanySavedOrRemovedLogoUrl,
  ] = useState('')
  if (isSaveSuccess || isRemoveSuccess) {
    return (
      <UserFeedback
        hasImage
        isSuccess={isSaveSuccess || isRemoveSuccess}
        setIsSuccess={isSaveSuccess ? setIsSaveSuccess : setIsRemoveSuccess}
        setSelectedListsNamesToRemove={setSelectedListsNamesToRemove}
        setSelectedListsNamesToSave={setSelectedListsNamesToSave}
      >
        {companiesSavedOrRemoved?.length > 0 ? (
          <Flex css={{ai: 'center'}}>
            <Flex as="ul" css={{ai: 'center', fw: 'wrap'}}>
              {companiesSavedOrRemoved.map(company => (
                <Flex
                  as="li"
                  key={company.orgUuid}
                  css={{ai: 'center', mr: '$8', p: '$4'}}
                >
                  <Image
                    alt={`${company.orgName} logo`}
                    css={{mr: '$8'}}
                    size="32"
                    src={company.logoUrl || FallbackImgCompanySm}
                    variant="company"
                  />
                  {company.orgName}
                  {companiesSavedOrRemoved.length > 1 && ' '}
                </Flex>
              ))}
            </Flex>
            <Text css={{whiteSpace: 'nowrap'}}>
              {isSaveSuccess
                ? `saved to ${selectedListsNamesToSave.join(', ')}`
                : `removed from ${selectedListsNamesToRemove.join(', ')}`}
            </Text>
          </Flex>
        ) : (
          <Flex css={{ai: 'center'}}>
            <Image
              alt={`${companySavedOrRemoved} logo`}
              css={{mr: '$8'}}
              size="32"
              src={companySavedOrRemovedLogoUrl || FallbackImgCompanySm}
              variant="company"
            />
            {companySavedOrRemoved}{' '}
            {isSaveSuccess
              ? `saved to ${selectedListsNamesToSave.join(', ')}`
              : `removed from ${selectedListsNamesToRemove.join(', ')}`}
          </Flex>
        )}
      </UserFeedback>
    )
  }
  if (isSaveError || isRemoveError)
    return (
      <UserFeedback
        isError={isSaveError || isRemoveError}
        setIsError={isSaveError ? setIsSaveError : setIsRemoveError}
        setSelectedListsNamesToRemove={setSelectedListsNamesToRemove}
        setSelectedListsNamesToSave={setSelectedListsNamesToSave}
      >
        Error {(isRemoveError && 'removing') || (isSaveError && 'saving')}{' '}
        {companySavedOrRemoved || companiesSavedOrRemoved.join(',')}{' '}
        {(isRemoveError && `from ${selectedListsNamesToRemove.join(', ')}`) ||
          (isSaveError && `to ${selectedListsNamesToSave.join(', ')}`)}
        . Please, try again or report this bug.
      </UserFeedback>
    )
  return (
    <Modal isSaveToList modal={modal} setModal={setModal}>
      <CloseModalButton setModal={setModal} />
      <Text variant="modal-title">
        Save/Remove{' '}
        {companiesToSave?.length > 1 ? 'selected companies' : orgName} from List
      </Text>
      <SaveToListForm
        commentText={commentText}
        companiesToSave={companiesToSave}
        inMyActionlist={inMyActionlist}
        logoUrl={logoUrl}
        orgName={orgName}
        orgUuid={orgUuid}
        setCommentText={setCommentText}
        setCompaniesSavedOrRemoved={setCompaniesSavedOrRemoved}
        setCompanySavedOrRemoved={setCompanySavedOrRemoved}
        setCompanySavedOrRemovedLogoUrl={setCompanySavedOrRemovedLogoUrl}
        setCreateNewListModal={setCreateNewListModal}
        setIsRemoveError={setIsRemoveError}
        setIsRemoveSuccess={setIsRemoveSuccess}
        setIsSaveError={setIsSaveError}
        setIsSaveSuccess={setIsSaveSuccess}
        setSaveToListModal={setModal}
        setSelectedListsNamesToRemove={setSelectedListsNamesToRemove}
        setSelectedListsNamesToSave={setSelectedListsNamesToSave}
        totalCount={totalCount}
      />
    </Modal>
  )
}

SaveToListModal.propTypes = {
  commentText: string.isRequired,
  companiesSavedOrRemoved: arrayOf(shape({})).isRequired,
  companiesToSave: arrayOf(shape({})).isRequired,
  logoUrl: string.isRequired,
  inMyActionlist: bool.isRequired,
  modal: bool.isRequired,
  orgName: string.isRequired,
  orgUuid: string.isRequired,
  setCommentText: func.isRequired,
  setCompaniesSavedOrRemoved: func.isRequired,
  setCreateNewListModal: func.isRequired,
  setModal: func.isRequired,
  totalCount: number.isRequired,
}

export default SaveToListModal
