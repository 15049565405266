import {bool, string} from 'prop-types'
import {
  Box,
  Button,
  Flex,
  ListItem,
  Loader,
  MentionInput,
  Text,
  UserFeedback,
} from 'components'
import useCommentItem from './hooks'

const CommentItem = ({
  commentText,
  id,
  isEvent,
  isPerson,
  updatedAt,
  userName,
  uuid,
}) => {
  const {
    authState,
    comment,
    commentTextareaRef,
    cursorPosition,
    formattedComment,
    formattedDate,
    formattedUsername,
    handleCancelEditComment,
    handleDeleteComment,
    handleEditComment,
    handleSaveComment,
    isDeleteCommentError,
    isDeleteCommentLoading,
    isEditCommentError,
    isEditCommentLoading,
    isEditing,
    isError,
    setComment,
    setIsEditing,
    setIsError,
  } = useCommentItem({
    commentText,
    id,
    isEvent,
    isPerson,
    updatedAt,
    userName,
    uuid,
  })
  return userName !== authState.username ? (
    <ListItem variant="comments" data-testid="comment-item">
      <Box css={{'@initial': {d: 'block'}, '@bp1': {d: 'inline'}}}>
        <Text variant="username">{formattedUsername}</Text>
        <Text variant="date">{formattedDate}</Text>
      </Box>
      <Text variant="comment">{formattedComment}</Text>
    </ListItem>
  ) : (
    <>
      <ListItem
        css={{
          '@initial': {d: 'flex', fd: 'column', ai: 'baseline'},
          '@bp1': {fd: isEditing ? 'column' : 'row'},
        }}
        variant="comments"
      >
        {!isEditing ? (
          <>
            <Box>
              <Text variant="username">{formattedUsername}</Text>
              <Text variant="date">{formattedDate}</Text>
              <Text variant="comment">{formattedComment}</Text>
              <Button
                color="grey"
                css={{mr: '$8', d: 'inline'}}
                onClick={handleEditComment}
                size="ghost-small"
                title="Edit"
                type="button"
                variant="ghost"
              >
                Edit
                {isEditCommentLoading && <Loader size="14" css={{ml: '$8'}} />}
              </Button>
              <Button
                color="grey"
                css={{d: 'inline-flex'}}
                onClick={handleDeleteComment}
                size="ghost-small"
                title="Delete"
                type="button"
                variant="ghost"
              >
                Delete
                {isDeleteCommentLoading && (
                  <Loader size="14" css={{ml: '$8'}} />
                )}
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Box css={{'@initial': {mb: '$4'}, '@bp1': {mb: 0}}}>
              <Text variant="username">{formattedUsername}</Text>
            </Box>
            <Flex
              css={{ai: 'center', jc: 'space-between', mt: '$8', w: '$full'}}
            >
              <MentionInput
                comment={comment}
                inputRef={commentTextareaRef}
                onChange={e => setComment(e.target.value)}
                onFocus={() => {
                  setIsEditing(true)
                  commentTextareaRef.current.setSelectionRange(
                    cursorPosition,
                    cursorPosition,
                  )
                }}
                onKeyDown={({key}) =>
                  key === 'Escape' && handleCancelEditComment()
                }
              />
              <Button
                color="grey"
                css={{mx: '$8'}}
                onClick={handleSaveComment}
                size="ghost-small"
                title="Save"
                type="button"
                variant="ghost"
              >
                Save
              </Button>
              <Button
                color="grey"
                onClick={handleCancelEditComment}
                size="ghost-small"
                title="Cancel"
                type="button"
                variant="ghost"
              >
                Cancel
              </Button>
            </Flex>
          </>
        )}
      </ListItem>
      {isError && (isEditCommentError || isDeleteCommentError) && (
        <UserFeedback isError={isError} setIsError={setIsError}>
          An error occurred. Please try again or report this bug.
        </UserFeedback>
      )}
    </>
  )
}

CommentItem.propTypes = {
  commentText: string.isRequired,
  id: string.isRequired,
  isEvent: bool.isRequired,
  isPerson: bool.isRequired,
  updatedAt: string.isRequired,
  userName: string.isRequired,
  uuid: string.isRequired,
}

export default CommentItem
