import {memo} from 'react'
import {arrayOf, func, number, shape, string} from 'prop-types'
import {Input} from 'components'
import {useDispatch} from 'context'

const DefaultColumnFilter = ({
  column: {filterValue, setFilter},
  gotoPage,
  state: {pageIndex},
}) => {
  const dispatch = useDispatch()
  const setIsInputFocused = isInputFocused =>
    dispatch({type: 'setIsInputFocused', isInputFocused})
  return (
    <Input
      aria-label="Search"
      onBlur={() => setIsInputFocused(false)}
      onChange={e => {
        if (pageIndex > 0) {
          gotoPage(0)
        }
        setFilter(e.target.value || undefined)
      }}
      onFocus={() => setIsInputFocused(true)}
      placeholder="Search..."
      size="small"
      value={filterValue || ''}
    />
  )
}

DefaultColumnFilter.propTypes = {
  column: shape({
    filterValue: string,
    preFilteredRows: arrayOf(shape({})),
    setFilter: func,
  }).isRequired,
  gotoPage: func.isRequired,
  state: shape({
    pageIndex: number,
  }).isRequired,
}

export default memo(DefaultColumnFilter)
