import {memo} from 'react'
import {func, string} from 'prop-types'
import {Button, Text} from 'components'
import {getTextColor} from 'utils'
import ListItem from './styles'

const LabelChoiceItem = ({color, handleAddLabel, name, uuid}) => {
  const labelTextColor = getTextColor(color)
  return (
    <ListItem>
      <Button
        css={{
          '&:hover': {
            color: labelTextColor,
          },
        }}
        onClick={() => handleAddLabel(uuid)}
        type="button"
        title={name}
        variant="ghost-icon"
        size="ghost-small"
      >
        <Text
          css={{
            br: '$small',
            d: 'inline-block',
            fontSize: '$13',
            lh: '$body',
            px: '6px',
            py: '2px',
            whiteSpace: 'nowrap',
            color: labelTextColor,
            bgc: color || '$black',
          }}
        >
          {name}
        </Text>
      </Button>
    </ListItem>
  )
}

LabelChoiceItem.propTypes = {
  color: string.isRequired,
  handleAddLabel: func.isRequired,
  name: string.isRequired,
  uuid: string.isRequired,
}

export default memo(LabelChoiceItem)
