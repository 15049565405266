import {gql} from '@apollo/client'

export const GET_ACTION_LIST_COMPANIES_MY_LISTS_REMOVED = gql`
  query actionList($reviewed: Boolean) {
    actionList(reviewed: $reviewed) {
      id
      uuid {
        uuid
      }
    }
  }
`

export const GET_MY_LISTS_REMOVED = gql`
  query customList($uuid: String) {
    customList(uuid: $uuid) {
      customlistorganizationSet {
        id
        orgUuid {
          inMyActionlist
          inMyList
          uuid
        }
      }
      uuid
    }
  }
`
