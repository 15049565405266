import {bool, func} from 'prop-types'
import {FilterIcon} from 'assets'
import {Button, Icon} from 'components'

const MobileFiltersButton = ({openFilters, setOpenFilters}) => (
  <Button
    css={{
      as: 'flex-end',
      mb: '$16',
      w: 'min-content',
      '@bp3': {d: 'none'},
    }}
    type="button"
    onClick={() => setOpenFilters(!openFilters)}
    variant="secondary-icon"
  >
    <Icon size="16" css={{mr: '$8'}}>
      <FilterIcon />
    </Icon>
    Filters
  </Button>
)

MobileFiltersButton.propTypes = {
  openFilters: bool.isRequired,
  setOpenFilters: func.isRequired,
}

export default MobileFiltersButton
