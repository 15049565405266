import {Text} from 'components'
import NoCompanyInMyListsContainer from './styles'

const NoCompanyInMyLists = () => (
  <NoCompanyInMyListsContainer>
    <Text variant="empty">
      You don&apos;t have any company saved in this list yet.
    </Text>
  </NoCompanyInMyListsContainer>
)

export default NoCompanyInMyLists
