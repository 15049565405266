import {memo} from 'react'
import {MentionsInput, Mention} from 'react-mentions'
import {func, shape, string} from 'prop-types'
import useMentionInput from './hooks'
import MentionsTextarea from './styles'

const MentionInput = ({
  comment,
  errors,
  inputRef,
  name,
  onChange,
  onFocus,
  onKeyDown,
  placeholder,
}) => {
  const {users} = useMentionInput()
  return (
    <MentionsTextarea borderColor={errors?.comment && 'red'}>
      <MentionsInput
        autoComplete="off"
        className="mentions"
        id={name}
        inputRef={inputRef}
        maxLength={1500}
        name={name}
        onChange={onChange}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        value={comment}
      >
        <Mention
          className="mentions__mention"
          data={users}
          displayTransform={(id, display) => `@${display}`}
          markup="@[__display__](__id__)"
          trigger="@"
          type="user"
        />
      </MentionsInput>
    </MentionsTextarea>
  )
}

MentionInput.propTypes = {
  comment: string.isRequired,
  errors: shape({}).isRequired,
  inputRef: func.isRequired,
  name: string.isRequired,
  onChange: func.isRequired,
  onFocus: func.isRequired,
  onKeyDown: func.isRequired,
  placeholder: string.isRequired,
}

export default memo(MentionInput)
