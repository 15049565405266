import {memo} from 'react'
import {string} from 'prop-types'
import {
  BetalistIcon,
  CapterraIcon,
  CrunchbaseIcon,
  FacebookIcon,
  GlobeIcon,
  LinkedinIcon,
  PipedriveIcon,
  TwitterIcon,
} from 'assets'
import {Flex, SocialLink} from 'components'

const SocialLinksList = ({
  betalistUrl,
  capterraUrl,
  cbUrl,
  facebookUrl,
  homepageUrl,
  linkedinUrl,
  name,
  pipedriveUrl,
  twitterUrl,
}) => (
  <Flex data-testid="social-links">
    {homepageUrl && (
      <SocialLink
        ariaLabel={`${name} homepage`}
        href={homepageUrl}
        icon={<GlobeIcon />}
        title="Homepage - h"
        variant="name-cell"
      />
    )}
    {cbUrl && (
      <SocialLink
        ariaLabel={`${name} crunchbase`}
        href={cbUrl}
        icon={<CrunchbaseIcon />}
        title="Crunchbase - c"
        variant="name-cell"
      />
    )}
    {linkedinUrl && (
      <SocialLink
        ariaLabel={`${name} linkedin`}
        href={linkedinUrl}
        icon={<LinkedinIcon />}
        title="LinkedIn - l"
        variant="name-cell"
      />
    )}
    {twitterUrl && (
      <SocialLink
        ariaLabel={`${name} twitter`}
        href={twitterUrl}
        icon={<TwitterIcon />}
        title="Twitter - t"
        variant="name-cell"
      />
    )}
    {betalistUrl && (
      <SocialLink
        ariaLabel={`${name} betalist`}
        href={betalistUrl}
        icon={<BetalistIcon />}
        title="Betalist - b"
        variant="name-cell"
      />
    )}
    {capterraUrl && (
      <SocialLink
        ariaLabel={`${name} capterra`}
        href={capterraUrl}
        icon={<CapterraIcon />}
        title="Capterra - C"
        variant="name-cell"
      />
    )}
    {facebookUrl && (
      <SocialLink
        ariaLabel={`${name} facebook`}
        href={facebookUrl}
        icon={<FacebookIcon />}
        title="Facebook - f"
        variant="name-cell"
      />
    )}
    {pipedriveUrl && (
      <SocialLink
        ariaLabel={`${name} pipedrive`}
        href={pipedriveUrl}
        icon={<PipedriveIcon />}
        title="Pipedrive - p"
        variant="name-cell"
      />
    )}
  </Flex>
)

SocialLinksList.propTypes = {
  betalistUrl: string.isRequired,
  capterraUrl: string.isRequired,
  cbUrl: string.isRequired,
  facebookUrl: string.isRequired,
  homepageUrl: string.isRequired,
  linkedinUrl: string.isRequired,
  name: string.isRequired,
  pipedriveUrl: string.isRequired,
  twitterUrl: string.isRequired,
}

export default memo(SocialLinksList)
