import {useEffect, useRef} from 'react'
import {arrayOf, shape, string} from 'prop-types'
import {CompaniesIcon, InvestorsIcon} from 'assets'
import {Box, Icon, Link} from 'components'
import SearchResultsItem from './components'
import Text from './styles'

const SearchResultsList = ({data, searchType}) => {
  const resultsListRef = useRef(null)
  useEffect(() => resultsListRef?.current && resultsListRef.current.focus(), [])
  return (
    <Box data-testid="search-results-list" ref={resultsListRef}>
      <Link
        title={
          (searchType === 'Companies' && 'companies') ||
          (searchType === 'Investors' && 'investors')
        }
        to={
          (searchType === 'Companies' && {
            pathname: '/companies',
          }) ||
          (searchType === 'Investors' && {
            pathname: '/investors',
          })
        }
        variant="search-results-type"
      >
        <Icon size="20" css={{mr: '$8'}}>
          {(searchType === 'Companies' && <CompaniesIcon />) ||
            (searchType === 'Investors' && <InvestorsIcon />)}
        </Icon>
        <Text variant="search-results-type">{searchType}</Text>
      </Link>
      <Box as="ul">
        {data.map(
          ({
            fallbackLogoUrl,
            logoUrl,
            name,
            permalink,
            shortDescription,
            type,
            uuid,
          }) => (
            <SearchResultsItem
              permalink={permalink}
              fallbackLogoUrl={fallbackLogoUrl}
              key={uuid}
              logoUrl={logoUrl}
              name={name}
              shortDescription={shortDescription}
              type={type}
              uuid={uuid}
            />
          ),
        )}
      </Box>
    </Box>
  )
}

SearchResultsList.propTypes = {
  data: arrayOf(shape({})).isRequired,
  searchType: string.isRequired,
}

export default SearchResultsList
