import {Text as BaseText} from 'components/primitives'
import {styled} from 'styles'

const Text = styled(BaseText, {
  variants: {
    variant: {
      'shared-by-cell-shared-with': {
        color: '$grey500',
        d: 'inline',
        fontSize: '$11',
        lh: '$body',
        mt: '$4',
      },
      'shared-by-cell-time': {
        color: '$grey300',
        d: 'inline',
        fontSize: '$11',
        lh: '$body',
        mt: '$2',
        whiteSpace: 'nowrap',
      },
    },
  },
})

export default Text
