/* eslint-disable no-underscore-dangle */
import reactStringReplace from 'react-string-replace'
import {Link, Text} from 'components'

export const formatComment = comment => {
  let replacedText
  replacedText = reactStringReplace(
    comment,
    /@\[(.*?)]\(\d*?\)/g,
    (match, i) => (
      <Text variant="mentioned-name" key={match + i}>
        @{match}
      </Text>
    ),
  )
  replacedText = reactStringReplace(
    replacedText,
    /(https?:\/\/\S+)/g,
    (match, i) => (
      <Link external key={match + i} href={match}>
        {match}
      </Link>
    ),
  )
  return replacedText
}

export const formatCommentsData = data =>
  data
    .slice()
    .sort(
      (a, b) =>
        new Date(b.createdAt) - new Date(a.createdAt) &&
        new Date(b.updatedAt) - new Date(a.updatedAt),
    )
    .map(comment =>
      comment.__typename === 'OrganizationCommentType' ||
      comment.__typename === 'PersonCommentType' ||
      comment.__typename === 'EventCommentType'
        ? {
            ...comment,
            type: 'comment',
            dateToCompare: comment.updatedAt,
          }
        : {...comment},
    )
