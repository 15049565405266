const getAttendees = (allUsers, usersAttending) =>
  usersAttending
    ?.split(',')
    .map(id => allUsers?.find(user => user?.id === id))
    .map(user => ({
      name: `${user?.firstName} ${user?.lastName}`,
      email: user?.username,
      partstat: 'ACCEPTED',
    }))

export default getAttendees
