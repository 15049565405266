import {bool} from 'prop-types'
import {CreateNewListModal, SearchBar} from 'components'
import {
  AddCompanyModal,
  HeaderButtons,
  UploadListModal,
  UserMenuList,
} from './components'
import useHeader from './hooks'
import {Box, Flex} from './styles'

const Header = ({extendMenu}) => {
  const {
    addCompanyModal,
    createNewListModal,
    dropdownOpen,
    setAddCompanyModal,
    setCreateNewListModal,
    setDropdownOpen,
    setUploadListModal,
    uploadListModal,
  } = useHeader()
  return (
    <>
      <Flex variant="header">
        <Box
          variant="search-bar-header"
          css={{w: extendMenu ? '600px' : 'auto'}}
        >
          <SearchBar extendMenu={extendMenu} />
        </Box>
        <HeaderButtons
          dropdownOpen={dropdownOpen}
          setAddCompanyModal={setAddCompanyModal}
          setCreateNewListModal={setCreateNewListModal}
          setDropdownOpen={setDropdownOpen}
          setUploadListModal={setUploadListModal}
        />
      </Flex>
      <AddCompanyModal modal={addCompanyModal} setModal={setAddCompanyModal} />
      <UploadListModal modal={uploadListModal} setModal={setUploadListModal} />
      <CreateNewListModal
        modal={createNewListModal}
        setModal={setCreateNewListModal}
      />
      {dropdownOpen && <UserMenuList setDropdownOpen={setDropdownOpen} />}
    </>
  )
}

Header.propTypes = {
  extendMenu: bool.isRequired,
}

export default Header
