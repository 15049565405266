import {gql} from '@apollo/client'

export const CHANGE_OWNER_OF_LIST = gql`
  mutation changeOwnerOfCustomList($newOwnerId: String!, $uuid: String!) {
    changeOwnerOfCustomList(newOwnerId: $newOwnerId, uuid: $uuid) {
      customList {
        uuid
      }
    }
  }
`

export const EDIT_LIST = gql`
  mutation editCustomList($description: String, $name: String, $uuid: String!) {
    editCustomList(description: $description, name: $name, uuid: $uuid) {
      customList {
        description
        name
        uuid
      }
    }
  }
`
