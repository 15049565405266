import {memo} from 'react'
import {arrayOf, bool, func, shape, string} from 'prop-types'
import {HeartIcon, HeartSemiIcon} from 'assets'
import {Box, Button, Flex, Icon, ListItem, Text} from 'components'
import useMyListsListItem from './hooks'

const MyListsListItem = ({
  companiesToSave,
  initialSelectedListNames,
  initialSelectedListUuid,
  isEveryOrgInList,
  isOrgInList,
  isSomeOrgInList,
  listName,
  listUuid,
  orgUuid,
  setSelectedListsNamesToRemove,
  setSelectedListsNamesToSave,
  setSelectedListsUuidsToRemove,
  setSelectedListsUuidsToSave,
}) => {
  const {
    formattedListName,
    handleSelectListToBeRemoved,
    handleSelectListToBeSaved,
    isSave,
    isSomeActive,
  } = useMyListsListItem({
    companiesToSave,
    initialSelectedListNames,
    initialSelectedListUuid,
    isEveryOrgInList,
    isOrgInList,
    isSomeOrgInList,
    listName,
    listUuid,
    orgUuid,
    setSelectedListsNamesToRemove,
    setSelectedListsNamesToSave,
    setSelectedListsUuidsToRemove,
    setSelectedListsUuidsToSave,
  })
  return (
    <>
      <ListItem as="li" css={{mb: '0'}}>
        <Button
          color="grey"
          css={{
            d: 'flex',
            ai: 'center',
            p: '$8',
            w: '$full',
            '&:focus': {
              txtd: 'none',
            },
            color: isSave ? '$grey300' : '$primary',
            '&:hover': {
              bgc: '$grey75',
              color: isSave ? '$grey300' : '$primary',
            },
          }}
          onClick={
            isSave ? handleSelectListToBeSaved : handleSelectListToBeRemoved
          }
          size="ghost-small"
          title={isSave ? `Save to ${listName}` : `Remove from ${listName}`}
          type="button"
          variant="ghost"
        >
          <Flex
            css={{
              color: '$grey200',
              wh: '$32',
              bgc: '$grey100',
              br: '$small',
              fontSize: '$11',
              jc: 'center',
              ai: 'center',
            }}
          >
            <Box>{formattedListName}</Box>
          </Flex>
          <Text css={{ml: '$8', color: '$grey400'}}>{listName}</Text>
          <Icon
            css={{
              ml: 'auto',
              mr: '$16',
            }}
            data-testid="icon"
            size="16"
          >
            {companiesToSave?.length > 0 &&
              isSomeOrgInList &&
              !isEveryOrgInList &&
              isSomeActive && <HeartSemiIcon />}
            {((companiesToSave?.length > 0 && isEveryOrgInList) ||
              !isSomeActive) && <HeartIcon />}
          </Icon>
        </Button>
      </ListItem>
    </>
  )
}

MyListsListItem.propTypes = {
  companiesToSave: arrayOf(shape({})).isRequired,
  initialSelectedListNames: arrayOf(string).isRequired,
  initialSelectedListUuid: arrayOf(string).isRequired,
  isEveryOrgInList: bool.isRequired,
  isOrgInList: bool.isRequired,
  isSomeOrgInList: bool.isRequired,
  listName: string.isRequired,
  listUuid: string.isRequired,
  orgUuid: string.isRequired,
  setSelectedListsNamesToRemove: func.isRequired,
  setSelectedListsNamesToSave: func.isRequired,
  setSelectedListsUuidsToRemove: func.isRequired,
  setSelectedListsUuidsToSave: func.isRequired,
}

export default memo(MyListsListItem)
