import {gql} from '@apollo/client'

export const ADD_COLLABORATORS = gql`
  mutation addCollaboratorsToCustomList(
    $collaboratorIds: String
    $uuid: String!
  ) {
    addCollaboratorsToCustomList(
      collaboratorIds: $collaboratorIds
      uuid: $uuid
    ) {
      customList {
        uuid
        customlistcollaboratorSet {
          id
          isOwner
          collaborator {
            firstName
            lastName
          }
        }
      }
    }
  }
`

export const UPDATE_COLLABORATORS = gql`
  mutation updateCollaboratorsFromCustomList(
    $collaboratorIds: String
    $uuid: String!
  ) {
    updateCollaboratorsFromCustomList(
      collaboratorIds: $collaboratorIds
      uuid: $uuid
    ) {
      customList {
        uuid
        customlistcollaboratorSet {
          id
          isOwner
          collaborator {
            firstName
            lastName
          }
        }
      }
    }
  }
`
