import {useDispatch} from 'context'

const useNav = ({
  gotoPage,
  listType,
  nextPage,
  pageCount,
  pageInView,
  previousPage,
}) => {
  const isPortfolioCompanies = listType === 'portfolio-companies'
  const isPortfolioFundingRounds = listType === 'portfolio-funding-rounds'
  const isPortfolioCategories = listType === 'portfolio-categories'
  const isEventPage = listType === 'web-scraping-event'
  const isPastEventsTable = listType === 'past-events'
  const isUpcomingEventsTable = listType === 'upcoming-events'
  const dispatch = useDispatch()
  const setCompaniesSearchPage = companiesSearchPage =>
    dispatch({type: 'setCompaniesSearchPage', companiesSearchPage})
  const setInvestorsSearchPage = investorsSearchPage =>
    dispatch({type: 'setInvestorsSearchPage', investorsSearchPage})
  const setPeopleSearchPage = peopleSearchPage =>
    dispatch({type: 'setPeopleSearchPage', peopleSearchPage})
  const setIsPastEvents = isPastEvents =>
    dispatch({type: 'setIsPastEvents', isPastEvents})
  const setIsUpcomingEvents = isUpcomingEvents =>
    dispatch({type: 'setIsUpcomingEvents', isUpcomingEvents})
  const setIsPageChanged = isPageChanged =>
    dispatch({type: 'setIsPageChanged', isPageChanged})
  const handleSetCompaniesSearchPage = pageNumber =>
    listType === 'companies-page' && setCompaniesSearchPage(pageNumber)
  const handleSetInvestorsSearchPage = pageNumber =>
    listType === 'investors-page' && setInvestorsSearchPage(pageNumber)
  const handleSetPeopleSearchPage = pageNumber =>
    listType === 'people' && setPeopleSearchPage(pageNumber)
  const handleSetIsPageChanged = () =>
    (isPortfolioCompanies ||
      isPortfolioFundingRounds ||
      isPortfolioCategories ||
      isEventPage ||
      isPastEventsTable ||
      isUpcomingEventsTable) &&
    setIsPageChanged(true)
  const handleGoToFirstPage = () => {
    handleSetCompaniesSearchPage(1)
    handleSetInvestorsSearchPage(1)
    handleSetPeopleSearchPage(1)
    handleSetIsPageChanged()
    if (isPastEventsTable) setIsPastEvents(true)
    if (isUpcomingEventsTable) setIsUpcomingEvents(true)
    gotoPage(0)
  }
  const handleGoToPreviousPage = () => {
    handleSetCompaniesSearchPage(pageInView - 1)
    handleSetInvestorsSearchPage(pageInView - 1)
    handleSetPeopleSearchPage(pageInView - 1)
    handleSetIsPageChanged()
    if (isPastEventsTable) setIsPastEvents(true)
    if (isUpcomingEventsTable) setIsUpcomingEvents(true)
    previousPage()
  }
  const handleGoToNextPage = () => {
    handleSetCompaniesSearchPage(pageInView + 1)
    handleSetInvestorsSearchPage(pageInView + 1)
    handleSetPeopleSearchPage(pageInView + 1)
    handleSetIsPageChanged()
    if (isPastEventsTable) setIsPastEvents(true)
    if (isUpcomingEventsTable) setIsUpcomingEvents(true)
    nextPage()
  }
  const handleGoToLastPage = () => {
    handleSetCompaniesSearchPage(pageCount)
    handleSetInvestorsSearchPage(pageCount)
    handleSetPeopleSearchPage(pageCount)
    handleSetIsPageChanged()
    if (isPastEventsTable) setIsPastEvents(true)
    if (isUpcomingEventsTable) setIsUpcomingEvents(true)
    gotoPage(pageCount - 1)
  }
  return {
    handleGoToFirstPage,
    handleGoToLastPage,
    handleGoToNextPage,
    handleGoToPreviousPage,
  }
}

export default useNav
