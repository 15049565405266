import {Text as BaseText} from 'components/primitives'
import {styled} from 'styles'

const Text = styled(BaseText, {
  variants: {
    variant: {
      'search-results-description': {
        color: '$grey300',
        d: '-webkit-box',
        fontSize: '$11',
        lh: '$body',
        ml: '$8',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
      },
      'search-results-name': {
        fontSize: '$13',
        mb: '$4',
        ml: '$8',
      },
    },
  },
})

export default Text
