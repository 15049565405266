import {memo} from 'react'
import {func, number} from 'prop-types'
import {ShareIcon} from 'assets'
import {Button, Icon} from 'components'

const BulkShareButton = ({companiesToShareLength, setShareModal}) => (
  <Button
    css={{
      mt: '$16',
      mr: '$16',
      whiteSpace: 'nowrap',
      '@bp1': {mt: 0},
      '@landscape678': {mt: '$8'},
    }}
    disabled={companiesToShareLength === 0}
    onClick={() => setShareModal(true)}
    size="small"
    title="Bulk share"
    type="button"
    variant="secondary-icon"
  >
    <Icon size="16" css={{mr: '$4'}}>
      <ShareIcon />
    </Icon>
    Bulk share
  </Button>
)

BulkShareButton.propTypes = {
  companiesToShareLength: number.isRequired,
  setShareModal: func.isRequired,
}

export default memo(BulkShareButton)
