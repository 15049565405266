import BaseBox from 'components/layout/Box'
import BaseFlex from 'components/layout/Flex'
import {styled} from 'styles'

export const Box = styled(BaseBox, {
  variants: {
    variant: {
      menu: {
        '@initial': {
          bgc: '$white',
          h: '100vh',
          position: 'absolute',
          transition: 'height 0.25s ease-in-out',
          w: '100vw',
          zIndex: '$zMax',
        },
        '@bp1': {
          h: 'auto',
        },
        '@landscapeX': {
          h: 'auto',
        },
        '@bp3': {
          br: '0rem 1rem 1rem 0rem',
          bs: '$2',
          h: '100vh',
          position: 'fixed',
          transition: 'width 0.25s ease-in-out',
          zIndex: '$z200',
        },
      },
    },
  },
})

export const Flex = styled(BaseFlex, {
  variants: {
    variant: {
      menu: {
        ai: 'center',
        bgc: '$white',
        position: 'relative',
        px: '$24',
        py: '$16',
        zIndex: '$zMax',
        '@bp3': {
          bgc: 'transparent',
          mb: '2.25rem',
        },
      },
    },
  },
})
