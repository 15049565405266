import {useEffect, useMemo, useState} from 'react'
import {useLazyQuery, useQuery} from '@apollo/client'
import portfolioCompaniesColumns from './columns'
import GET_INVESTOR_PORTFOLIO_COMPANIES from './queries'
import formatPortfolioCompanies from './utils'

const useCompanies = ({investorUuid, setHasCompanies}) => {
  const [data, setData] = useState([])
  const {
    data: first100PortfolioCompaniesData,
    error: isFirst100PortfolioCompaniesQueryError,
    loading: isFirst100PortfolioCompaniesQueryLoading,
  } = useQuery(GET_INVESTOR_PORTFOLIO_COMPANIES, {
    variables: {
      investorUuid,
      page: 1,
      pageSize: 100,
    },
    onError: error => console.error(error),
  })
  const [
    getAllPortfolioCompanies,
    {
      data: allPortfolioCompaniesData,
      error: isAllPortfolioCompaniesQueryError,
      loading: isAllPortfolioCompaniesQueryLoading,
    },
  ] = useLazyQuery(GET_INVESTOR_PORTFOLIO_COMPANIES, {
    variables: {
      investorUuid,
      page: 1,
      pageSize: 999999,
    },
    onError: error => console.error(error),
  })
  const columns = useMemo(() => portfolioCompaniesColumns, [])
  const totalCount = useMemo(
    () =>
      first100PortfolioCompaniesData?.investorPortfolioOrganizations?.totalCount
        ? first100PortfolioCompaniesData.investorPortfolioOrganizations
            .totalCount
        : 0,
    [first100PortfolioCompaniesData],
  )
  useEffect(() => {
    if (first100PortfolioCompaniesData?.investorPortfolioOrganizations) {
      const first100Results = formatPortfolioCompanies(
        first100PortfolioCompaniesData.investorPortfolioOrganizations.objects,
      )
      setData(first100Results)
      if (first100Results.length === 0) setHasCompanies(false)
      if (totalCount > 100) getAllPortfolioCompanies()
    }
    if (allPortfolioCompaniesData?.investorPortfolioOrganizations) {
      const allResults = formatPortfolioCompanies(
        allPortfolioCompaniesData.investorPortfolioOrganizations.objects,
      )
      setData(allResults)
    }
  }, [
    allPortfolioCompaniesData,
    first100PortfolioCompaniesData,
    getAllPortfolioCompanies,
    setHasCompanies,
    totalCount,
  ])
  return {
    columns,
    data,
    isAllPortfolioCompaniesQueryError,
    isAllPortfolioCompaniesQueryLoading,
    isFirst100PortfolioCompaniesQueryError,
    isFirst100PortfolioCompaniesQueryLoading,
    totalCount,
  }
}

export default useCompanies
