import {memo} from 'react'
import {shape, string} from 'prop-types'
import Text from './styles'

const ShortDescriptionCell = ({
  row: {
    original: {shortDescription},
  },
}) => <Text variant="people-results-description">{shortDescription}</Text>

ShortDescriptionCell.propTypes = {
  row: shape({
    original: shape({
      shortDescription: string,
    }),
  }).isRequired,
}

export default memo(ShortDescriptionCell)
