import {memo} from 'react'
import {func, node, number, string} from 'prop-types'
import {Box, Label, Textarea} from 'components'

const TextareaField = ({
  children,
  css,
  defaultValue,
  maxLength,
  name,
  register,
  rows,
}) => (
  <Box variant="span-column" css={css}>
    <Label htmlFor={name}>{children}</Label>
    <Textarea
      defaultValue={defaultValue}
      id={name}
      maxLength={maxLength}
      name={name}
      ref={register}
      rows={rows}
    />
  </Box>
)

TextareaField.propTypes = {
  children: node.isRequired,
  css: node.isRequired,
  defaultValue: string.isRequired,
  name: string.isRequired,
  maxLength: number.isRequired,
  register: func.isRequired,
  rows: string.isRequired,
}

export default memo(TextareaField)
