import {Text as BaseText} from 'components/primitives'
import {styled} from 'styles'

const Text = styled(BaseText, {
  variants: {
    variant: {
      'shared-with': {
        color: '$secondary',
        d: 'inline',
        fontSize: '$13',
        fontWeight: '$300',
        ml: '-$4',
        mr: '$4',
      },
      'shared-with-people': {
        color: '$grey400',
        d: 'inline',
        fontSize: '$14',
        fontWeight: '$500',
        mr: '$8',
      },
    },
  },
})

export default Text
