const formatLabelOptions = data =>
  data
    .map(({name}) => ({value: name, label: name}))
    .slice()
    .sort((a, b) => a.label.localeCompare(b.label))
    .filter(
      (value, index, self) =>
        index === self.findIndex(t => t.value === value.value),
    )

export default formatLabelOptions
