import {memo} from 'react'
import {func} from 'prop-types'
import {IndeterminateCheckbox} from 'components'

const SelectedColumnHeader = ({getToggleAllPageRowsSelectedProps}) => (
  <div>
    <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
  </div>
)

SelectedColumnHeader.propTypes = {
  getToggleAllPageRowsSelectedProps: func.isRequired,
}

export default memo(SelectedColumnHeader)
