import {formatLocation, formatCountryToString} from 'utils'
import getEventScope from '../getEventScope'

const formatEventsData = ({data, allUsers, isFutureEvent}) => {
  const formattedData = data.map(event => {
    const country = formatCountryToString(event.countryCode)
    const permalink = event.name.replace(/\s/g, '')
    const formattedPermalink = permalink.includes('/')
      ? permalink.replace('/', '-')
      : permalink
    return {
      ...event,
      allUsers,
      country,
      location: formatLocation(event.city, country),
      permalink: formattedPermalink,
      scope: getEventScope({
        bcnScope: event.bcnScope,
        berScope: event.berScope,
        ldnScope: event.ldnScope,
      }),
    }
  })
  return isFutureEvent ? formattedData.reverse() : formattedData
}

export default formatEventsData
