import {memo} from 'react'
import {func, shape} from 'prop-types'
import {IndeterminateCheckbox} from 'components'

const SelectedColumnCell = ({row}) => (
  <div>
    <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
  </div>
)

SelectedColumnCell.propTypes = {
  row: shape({
    getToggleRowSelectedProps: func,
  }).isRequired,
}

export default memo(SelectedColumnCell)
