import {useReducer} from 'react'
import {createContainer} from 'react-tracked'

const initialState = {
  companiesSearchPage: 1,
  companiesSearchPageSize: 25,
  category: [],
  country: [],
  employeeCount: [],
  founded: [],
  lastFundingRound: [],
  review: [],
  primaryRole: [],
  totalFunding: [],
  searchLoading: false,
  isInputFocused: false,
  isModalOpen: false,
  peopleSearchPage: 1,
  peopleSearchPageSize: 25,
  personCountry: [],
  jobType: [],
  jobArea: [],
  degreeType: [],
  subject: [],
  gender: [],
  isPageChanged: false,
  isUpcomingEvents: false,
  isPastEvents: false,
  investorsSearchPage: 1,
  investorsSearchPageSize: 25,
  fundingRoundTypes: [],
  investmentCount: [],
  investorLocation: [],
  investorTypes: [],
  investorNature: [],
  portfolioCategories: [],
  portfolioLocation: [],
  label: [],
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'setIsPageChanged':
      return {...state, isPageChanged: action.isPageChanged}
    case 'setIsUpcomingEvents':
      return {...state, isUpcomingEvents: action.isUpcomingEvents}
    case 'setIsPastEvents':
      return {...state, isPastEvents: action.isPastEvents}
    case 'setSearchLoading':
      return {...state, searchLoading: action.searchLoading}
    case 'setIsInputFocused':
      return {...state, isInputFocused: action.isInputFocused}
    case 'setIsModalOpen':
      return {...state, isModalOpen: action.isModalOpen}
    case 'setCompaniesSearchPage':
      return {...state, companiesSearchPage: action.companiesSearchPage}
    case 'setCompaniesSearchPageSize':
      return {...state, companiesSearchPageSize: action.companiesSearchPageSize}
    case 'setCategory':
      return {...state, category: action.category, companiesSearchPage: 1}
    case 'setCountry':
      return {...state, country: action.country, companiesSearchPage: 1}
    case 'setEmployeeCount':
      return {
        ...state,
        employeeCount: action.employeeCount,
        companiesSearchPage: 1,
      }
    case 'setFounded':
      return {...state, founded: action.founded, companiesSearchPage: 1}
    case 'setLastFundingRound':
      return {
        ...state,
        lastFundingRound: action.lastFundingRound,
        companiesSearchPage: 1,
      }
    case 'setReview':
      return {...state, review: action.review, companiesSearchPage: 1}
    case 'setPrimaryRole':
      return {...state, primaryRole: action.primaryRole, companiesSearchPage: 1}
    case 'setTotalFunding':
      return {
        ...state,
        totalFunding: action.totalFunding,
        companiesSearchPage: 1,
      }
    case 'setPeopleSearchPage':
      return {...state, peopleSearchPage: action.peopleSearchPage}
    case 'setPeopleSearchPageSize':
      return {...state, peopleSearchPageSize: action.peopleSearchPageSize}
    case 'setPersonCountry':
      return {
        ...state,
        personCountry: action.personCountry,
        peopleSearchPage: 1,
      }
    case 'setJobType':
      return {...state, jobType: action.jobType, peopleSearchPage: 1}
    case 'setJobArea':
      return {...state, jobArea: action.jobArea, peopleSearchPage: 1}
    case 'setDegreeType':
      return {...state, degreeType: action.degreeType, peopleSearchPage: 1}
    case 'setSubject':
      return {...state, subject: action.subject, peopleSearchPage: 1}
    case 'setGender':
      return {...state, gender: action.gender, peopleSearchPage: 1}
    case 'setInvestorsSearchPage':
      return {...state, investorsSearchPage: action.investorsSearchPage}
    case 'setInvestorsSearchPageSize':
      return {...state, investorsSearchPageSize: action.investorsSearchPageSize}
    case 'setPortfolioLocation':
      return {
        ...state,
        portfolioLocation: action.portfolioLocation,
        investorsSearchPage: 1,
      }
    case 'setInvestmentCount':
      return {
        ...state,
        investmentCount: action.investmentCount,
        investorsSearchPage: 1,
      }
    case 'setInvestorLocation':
      return {
        ...state,
        investorLocation: action.investorLocation,
        investorsSearchPage: 1,
      }
    case 'setInvestorTypes':
      return {
        ...state,
        investorTypes: action.investorTypes,
        investorsSearchPage: 1,
      }
    case 'setInvestorNature':
      return {
        ...state,
        investorNature: action.investorNature,
        investorsSearchPage: 1,
      }
    case 'setPortfolioCategories':
      return {
        ...state,
        portfolioCategories: action.portfolioCategories,
        investorsSearchPage: 1,
      }
    case 'setFundingRoundTypes':
      return {
        ...state,
        fundingRoundTypes: action.fundingRoundTypes,
        investorsSearchPage: 1,
      }
    case 'setLabel':
      return {
        ...state,
        label: action.label,
        companiesSearchPage: 1,
        investorsSearchPage: 1,
        peopleSearchPage: 1,
      }
    default:
      return state
  }
}

const useValue = () => useReducer(reducer, initialState)

export const {
  Provider: SearchProvider,
  useTrackedState: useSearch,
  useUpdate: useDispatch,
} = createContainer(useValue)
