import {memo, useEffect, useRef, useState} from 'react'
import {func} from 'prop-types'
import {Button, Flex, Input} from 'components'

const OtherReasonForm = ({handleAddReview, setModal}) => {
  const [otherReason, setOtherReason] = useState('')
  const otherReasonRef = useRef(null)
  useEffect(() => otherReasonRef?.current && otherReasonRef.current.focus(), [])
  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        handleAddReview('no', 'other', otherReason)
      }}
    >
      <Flex css={{ai: 'flex-start'}}>
        <Input
          onChange={e => setOtherReason(e.target.value)}
          onKeyDown={({key}) => key === 'Escape' && setModal(false)}
          placeholder="No, other reason"
          type="text"
          ref={otherReasonRef}
          value={otherReason}
        />
        <Button
          color="red"
          css={{ml: '$8', mb: '$8', whiteSpace: 'nowrap'}}
          type="submit"
          size="small"
        >
          Add other reason
        </Button>
      </Flex>
    </form>
  )
}

OtherReasonForm.propTypes = {
  handleAddReview: func.isRequired,
  setModal: func.isRequired,
}

export default memo(OtherReasonForm)
