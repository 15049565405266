import {memo} from 'react'
import {bool, func, number} from 'prop-types'
import {Button, Flex, Loader} from 'components'

const BulkReviewButton = ({
  companiesToReviewLength,
  isReviewLoading,
  setReviewModal,
}) => (
  <Button
    css={{
      mt: '$16',
      mr: '$16',
      whiteSpace: 'nowrap',
      '@bp1': {mt: 0},
    }}
    disabled={companiesToReviewLength === 0}
    onClick={() => setReviewModal(true)}
    size="small"
    title="Bulk review"
    type="button"
    variant="secondary"
  >
    <Flex>
      {isReviewLoading && companiesToReviewLength ? (
        <Loader size="16" css={{mr: '$8'}} data-testid="loader" />
      ) : null}
      Bulk review
    </Flex>
  </Button>
)

BulkReviewButton.propTypes = {
  companiesToReviewLength: number.isRequired,
  isReviewLoading: bool.isRequired,
  setReviewModal: func.isRequired,
}

export default memo(BulkReviewButton)
