import {memo} from 'react'
import {bool, func} from 'prop-types'
import {
  AddFilledIcon,
  AddIcon,
  HeartEmpty2Icon,
  HeartIcon,
  UploadIcon,
  UserFilledIcon,
  UserIcon,
} from 'assets'
import {useAuth} from 'context'
import {Flex} from 'components'
import HeaderButton from './components'

const HeaderButtons = ({
  dropdownOpen,
  setAddCompanyModal,
  setCreateNewListModal,
  setDropdownOpen,
  setUploadListModal,
}) => {
  const {
    authState: {firstName, lastName},
  } = useAuth()
  return (
    <Flex data-testid="header-buttons">
      <HeaderButton
        css={{whiteSpace: 'nowrap'}}
        setAddCompanyModal={setAddCompanyModal}
        icon={<AddIcon />}
        iconFilled={<AddFilledIcon />}
        label="Add Company"
        title="Add new company to NautaDB - a"
      />
      <HeaderButton
        css={{ml: '$24', whiteSpace: 'nowrap'}}
        setUploadListModal={setUploadListModal}
        icon={<UploadIcon />}
        iconFilled={<UploadIcon />}
        label="Upload List"
        title="Upload new list to NautaDB - u"
      />
      <HeaderButton
        css={{ml: '$24', whiteSpace: 'nowrap'}}
        setCreateNewListModal={setCreateNewListModal}
        icon={<HeartEmpty2Icon />}
        iconFilled={<HeartIcon />}
        label="Create List"
        title="Create New List - n"
      />
      <HeaderButton
        css={{ml: '$24', whiteSpace: 'nowrap'}}
        setDropdownOpen={setDropdownOpen}
        dropdownOpen={dropdownOpen}
        icon={<UserIcon />}
        iconFilled={<UserFilledIcon />}
        label={`${firstName} ${lastName}`}
      />
    </Flex>
  )
}

HeaderButtons.propTypes = {
  dropdownOpen: bool.isRequired,
  setAddCompanyModal: func.isRequired,
  setCreateNewListModal: func.isRequired,
  setDropdownOpen: func.isRequired,
  setUploadListModal: func.isRequired,
}

export default memo(HeaderButtons)
