import {styled} from 'styles'

const ListItem = styled('li', {
  mb: '$8',
  '&:last-of-type': {
    mb: 0,
  },
})

export default ListItem
