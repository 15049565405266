import {gql} from '@apollo/client'

export const GET_COMPANY_SUMMARY_LABELS = gql`
  query organization($uuid: String!) {
    summary: organization(uuid: $uuid) {
      organizationlabelSet {
        uuid
        addedByName
        commentText
        createdAt
        labelUuid {
          uuid
          name
          color
        }
        organizationUuid {
          uuid
        }
      }
      uuid
    }
  }
`

export const GET_PERSON_SUMMARY_LABELS = gql`
  query person($uuid: String!) {
    summary: person(uuid: $uuid) {
      personlabelSet {
        uuid
        labelUuid {
          uuid
          name
          color
        }
        addedBy {
          firstName
          lastName
        }
        addedByName
        createdAt
      }
      uuid
    }
  }
`
