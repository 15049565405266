import {useDispatch} from 'context'

const useGoToPage = ({listType, pageCount}) => {
  const isPortfolioCompanies = listType === 'portfolio-companies'
  const isPortfolioFundingRounds = listType === 'portfolio-funding-rounds'
  const isPortfolioCategories = listType === 'portfolio-categories'
  const dispatch = useDispatch()
  const setCompaniesSearchPage = companiesSearchPage =>
    dispatch({type: 'setCompaniesSearchPage', companiesSearchPage})
  const setInvestorsSearchPage = investorsSearchPage =>
    dispatch({type: 'setInvestorsSearchPage', investorsSearchPage})
  const setPeopleSearchPage = peopleSearchPage =>
    dispatch({type: 'setPeopleSearchPage', peopleSearchPage})
  const setIsPageChanged = isPageChanged =>
    dispatch({type: 'setIsPageChanged', isPageChanged})
  const gotoPageOptions = Array.from({length: pageCount}, (_, i) => i + 1)
  return {
    gotoPageOptions,
    isPortfolioCategories,
    isPortfolioCompanies,
    isPortfolioFundingRounds,
    setCompaniesSearchPage,
    setInvestorsSearchPage,
    setIsPageChanged,
    setPeopleSearchPage,
  }
}

export default useGoToPage
