import {useMemo} from 'react'
import {useQuery} from '@apollo/client'
import {GET_USERS} from 'constants/queries'
import {useAuth} from 'context'
import {
  getAllUsersExcludingTestUsers,
  getUsersToMention,
  isTechUserCheck,
} from './utils'

const useMentionInput = () => {
  const {authState} = useAuth()
  const loggedUser = authState.username
  const {data: usersData} = useQuery(GET_USERS, {
    onError: error => console.error(error),
  })
  const users = useMemo(() => {
    if (usersData?.users) {
      const usersToMention = getUsersToMention(usersData.users)
      const allUsersExcludingTestUsers = getAllUsersExcludingTestUsers(
        usersToMention,
      )
      const isTechUser = isTechUserCheck(usersData.users, loggedUser)
      if (isTechUser) {
        return usersToMention
      }
      return allUsersExcludingTestUsers
    }
    return []
  }, [loggedUser, usersData])
  return {
    users,
  }
}

export default useMentionInput
