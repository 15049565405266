import camelcaseKeys from 'camelcase-keys'

const isJsonString = str => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

const checkStr = str => str.replace(/\n/g, '\\n')

const parseJSONData = data =>
  (data !== null && isJsonString(data) && camelcaseKeys(JSON.parse(data))) ||
  (isJsonString(checkStr(data)) && camelcaseKeys(JSON.parse(checkStr(data)))) ||
  ''

export default parseJSONData
