import {lazy, Suspense} from 'react'
import {Route, Switch} from 'react-router-dom'
import {PrivateRoute} from 'components'
import * as routes from 'constants/routes'

const ActionList = lazy(() => import('../../../pages/ActionList'))
const AddCompany = lazy(() => import('../../../pages/AddCompany'))
const Companies = lazy(() => import('../../../pages/Companies'))
const CompaniesList = lazy(() => import('../../../pages/CompaniesList'))
const CompanyProfile = lazy(() => import('../../../pages/CompanyProfile'))
const CreateList = lazy(() => import('../../../pages/CreateList'))
const Dashboard = lazy(() => import('../../../pages/Dashboard'))
const EventProfile = lazy(() => import('../../../pages/EventProfile'))
const Events = lazy(() => import('../../../pages/Events'))
const Investors = lazy(() => import('../../../pages/Investors'))
const Login = lazy(() => import('../../../pages/Login'))
const Logout = lazy(() => import('../../../pages/Logout'))
const Maintenance = lazy(() => import('../../../pages/Maintenance'))
const MyLists = lazy(() => import('../../../pages/MyLists'))
const MyListsCompaniesList = lazy(() =>
  import('../../../pages/MyListsCompaniesList'),
)
const NotFound = lazy(() => import('../../../pages/NotFound'))
const OldCompanyProfile = lazy(() => import('../../../pages/OldCompanyProfile'))
const People = lazy(() => import('../../../pages/People'))
const PeopleList = lazy(() => import('../../../pages/PeopleList'))
const PersonProfile = lazy(() => import('../../../pages/PersonProfile'))
const Search = lazy(() => import('../../../pages/Search'))
const Settings = lazy(() => import('../../../pages/Settings'))
const Shortcuts = lazy(() => import('../../../pages/Shortcuts'))
const UploadList = lazy(() => import('../../../pages/UploadList'))

const App = () => (
  <Suspense fallback={null}>
    <Switch>
      <Route exact path={routes.LOGIN}>
        <Login />
      </Route>
      <PrivateRoute exact path={routes.DASHBOARD}>
        <Dashboard />
      </PrivateRoute>
      <PrivateRoute path={routes.COMPANIES_LIST}>
        <CompaniesList />
      </PrivateRoute>
      <PrivateRoute path={routes.CREATE_LIST}>
        <CreateList />
      </PrivateRoute>
      <PrivateRoute exact path={routes.ACTION_LIST}>
        <ActionList />
      </PrivateRoute>
      <PrivateRoute exact path={routes.MY_LISTS}>
        <MyLists />
      </PrivateRoute>
      <PrivateRoute path={routes.MY_LISTS_COMPANIES_LIST}>
        <MyListsCompaniesList />
      </PrivateRoute>
      <PrivateRoute exact path={routes.ADD_COMPANY}>
        <AddCompany />
      </PrivateRoute>
      <PrivateRoute exact path={routes.COMPANIES}>
        <Companies />
      </PrivateRoute>
      <PrivateRoute path={routes.COMPANY_PROFILE}>
        <CompanyProfile />
      </PrivateRoute>
      <PrivateRoute path={routes.OLD_COMPANY_PROFILE}>
        <OldCompanyProfile />
      </PrivateRoute>
      <PrivateRoute exact path={routes.PEOPLE}>
        <People />
      </PrivateRoute>
      <PrivateRoute exact path={routes.PEOPLE_LIST}>
        <PeopleList />
      </PrivateRoute>
      <PrivateRoute path={routes.PERSON_PROFILE}>
        <PersonProfile />
      </PrivateRoute>
      <PrivateRoute path={routes.INVESTORS}>
        <Investors />
      </PrivateRoute>
      <PrivateRoute exact path={routes.EVENT_PPROFILE}>
        <EventProfile />
      </PrivateRoute>
      <PrivateRoute exact path={routes.EVENTS}>
        <Events />
      </PrivateRoute>
      <PrivateRoute path={routes.SEARCH}>
        <Search />
      </PrivateRoute>
      <PrivateRoute path={routes.SETTINGS}>
        <Settings />
      </PrivateRoute>
      <PrivateRoute path={routes.SHORTCUTS}>
        <Shortcuts />
      </PrivateRoute>
      <PrivateRoute path={routes.UPLOAD_LIST}>
        <UploadList />
      </PrivateRoute>
      <PrivateRoute path={routes.LOGOUT}>
        <Logout />
      </PrivateRoute>
      <Route path={routes.MAINTENANCE}>
        <Maintenance />
      </Route>
      <Route exact path={routes.NOT_FOUND}>
        <NotFound />
      </Route>
    </Switch>
  </Suspense>
)

export default App
