export const ACTION_LIST = '/action-list'
export const ADD_COMPANY = '/add-company'
export const COMPANIES = '/companies'
export const COMPANIES_LIST = '/list/:listType'
export const COMPANY_PROFILE = '/companies/:companyId'
export const CREATE_LIST = '/create-list'
export const DASHBOARD = '/dashboard'
export const EVENT_PPROFILE = '/events/:eventId'
export const EVENTS = '/events'
export const INVESTORS = '/investors'
export const LOGIN = '/'
export const LOGOUT = '/logout'
export const MAINTENANCE = '/maintenance'
export const MY_LISTS = '/my-lists'
export const MY_LISTS_COMPANIES_LIST = '/my-lists/:listName'
export const NOT_FOUND = '*'
export const OLD_COMPANY_PROFILE = '/dfe/search_companies/:oldUuid'
export const PEOPLE = '/people'
export const PEOPLE_LIST = '/people-list/:listType'
export const PERSON_PROFILE = '/people/:personId'
export const SEARCH = '/search'
export const SETTINGS = '/settings'
export const SHORTCUTS = '/shortcuts'
export const UPLOAD_LIST = '/upload-list'
