import {useState} from 'react'
import {bool, func} from 'prop-types'
import {
  CloseModalButton,
  Modal,
  Text,
  UploadListForm,
  UserFeedback,
} from 'components'

const UploadListModal = ({modal, setModal}) => {
  const [isSuccess, setIsSuccess] = useState(false)
  if (isSuccess)
    return (
      <UserFeedback isSuccess={isSuccess} setIsSuccess={setIsSuccess}>
        You&apos;ll receive an email confirmation when the upload is complete
      </UserFeedback>
    )
  return (
    <Modal modal={modal} setModal={setModal}>
      <CloseModalButton setModal={setModal} />
      <Text variant="modal-title">Upload List to NautaDB</Text>
      <UploadListForm setModal={setModal} setIsSuccess={setIsSuccess} />
    </Modal>
  )
}

UploadListModal.propTypes = {
  modal: bool.isRequired,
  setModal: func.isRequired,
}

export default UploadListModal
