import {func, shape} from 'prop-types'
import {Button} from 'components'

const CancelButton = ({css, onClick}) => (
  <Button
    css={css}
    data-testid="cancel"
    onClick={onClick}
    type="button"
    variant="secondary"
  >
    Cancel
  </Button>
)

CancelButton.propTypes = {
  css: shape({}).isRequired,
  onClick: func.isRequired,
}

export default CancelButton
