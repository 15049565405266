import {styled} from 'styles'

const Text = styled('span', {
  d: 'block',
  fontVariantNumeric: 'tabular-nums',
  fontWeight: '$400',
  lh: '$solid',
  margin: 0,
  variants: {
    color: {
      green: {
        color: '$green',
      },
      red: {
        color: '$red',
      },
      yellow: {
        color: '$yellow200',
      },
    },
    variant: {
      'card-subtitle': {
        color: '$primary',
        fontSize: '$18',
        mb: '$16',
        pt: '$8',
      },
      'card-title': {
        color: '$primary',
        fontSize: '$20',
        lh: '$body',
        mb: '$24',
      },
      'card-subtitle-my-lists': {
        color: '$secondary',
        fontSize: '$16',
        fontWeight: '$300',
      },
      cell: {
        lh: '$body',
      },
      comment: {
        color: '$grey600',
        fontSize: '$14',
        lh: '$body',
        mr: '$8',
        whiteSpace: 'pre-wrap',
      },
      date: {
        color: '$grey200',
        d: 'inline',
        fontSize: '$11',
        mr: '$8',
        whiteSpace: 'break-spaces',
      },
      empty: {
        color: '$grey300',
        fontSize: '$14',
        fontWeight: '$500',
        lh: '$body',
        my: '$8',
      },
      error: {
        color: '$red',
        fontSize: '$14',
        lh: '$body',
      },
      'form-error': {
        color: '$red',
        fontSize: '$11',
        lh: '$body',
        mt: '$8',
      },
      'grid-title': {
        color: '$grey300',
        fontSize: '$14',
        lh: '$body',
        mb: '$4',
        '@bp1': {
          mb: 0,
        },
      },
      'grid-value': {
        color: '$grey500',
        fontSize: '$14',
        lh: '$body',
        mb: '$16',
        '@bp1': {
          mb: 0,
        },
      },
      'list-counter': {
        color: '$grey300',
        d: 'inline',
        fontSize: '$16',
        fontWeight: '$300',
        lh: '$body',
      },
      'list-date': {
        color: '$grey300',
        d: 'inline',
        fontSize: '$16',
        fontWeight: '$300',
        lh: '$body',
      },
      'list-scope': {
        color: '$secondary',
        fontSize: '$18',
        fontWeight: '$300',
      },
      'location-cell': {
        color: '$grey500',
        lh: '$body',
        whiteSpace: 'pre-wrap',
      },
      'location-cell-city': {
        color: '$grey500',
        lh: '$body',
        mb: '$4',
      },
      'location-cell-country': {
        color: '$grey300',
        fontSize: '$11',
        lh: '$body',
        whiteSpace: 'pre-wrap',
      },
      'mentioned-name': {
        br: '$small',
        color: '$primary',
        d: 'inline',
        p: '$2',
      },
      'menu-item': {
        d: 'inline-block',
        fontSize: '$14',
        ml: '$16',
        whiteSpace: 'nowrap',
      },
      'modal-subtitle': {
        color: '$primary',
        fontSize: '$18',
        lh: '$body',
      },
      'modal-title': {
        color: '$primary',
        fontSize: '$24',
        lh: '$body',
        mb: '$24',
      },
      'name-cell': {
        d: 'inline',
        lh: '$body',
      },
      'nautable-cell-by': {
        color: '$grey300',
        fontSize: '$11',
        mt: '$4',
        whiteSpace: 'nowrap',
      },
      'nautable-cell-date': {
        mt: '$4',
      },
      'nautable-cell-name': {
        color: '$grey500',
        d: 'inline',
      },
      'nautable-cell-review': {
        fontSize: '$13',
        lh: '$body',
        mr: '$8',
      },
      'new-tag': {
        bgc: '$secondary',
        br: '$large',
        color: '$white',
        fontSize: '$11',
        h: 'fit-content',
        lh: '$body',
        ml: '$8',
        px: '6px',
        py: '2px',
        whiteSpace: 'nowrap',
      },
      office: {
        color: '$secondary',
        fontSize: '$14',
        mb: '$16',
      },
      pagination: {
        color: '$grey300',
        fontSize: '$13',
      },
      'pagination-strong': {
        color: '$grey300',
        d: 'inline-block',
        fontSize: '$13',
        fontWeight: '$600',
      },
      percentile: {
        color: '$secondary',
        d: 'inline',
        fontSize: '$16',
        lh: '$body',
        mt: '$8',
      },
      'profile-name': {
        color: '$primary',
        d: 'inline',
        fontSize: '$26',
      },
      required: {
        color: '$red',
        d: 'inline-block',
        fontSize: '$11',
        ml: '$4',
      },
      'score-description': {
        color: '$grey500',
        fontSize: '$14',
        lh: '$body',
      },
      'section-subtitle': {
        color: '$secondary',
        fontSize: '$18',
        fontWeight: '$300',
        mt: '-$8',
      },
      'section-title': {
        color: '$primary',
        fontSize: '$24',
        lh: '$body',
        mb: '$24',
        whiteSpace: 'pre-wrap',
        '@bp3': {
          fontSize: '$24',
        },
      },
      'selected-companies': {
        color: '$grey500',
        fontSize: '$14',
      },
      'shared-by-cell-date': {
        color: '$grey300',
        fontSize: '$11',
        lh: '$body',
        mt: '$4',
        whiteSpace: 'nowrap',
      },
      'shared-by-cell-name': {
        br: '$large',
        d: 'inline-block',
        fontSize: '$11',
        lh: '$body',
        px: '6px',
        py: '2px',
        whiteSpace: 'nowrap',
      },
      warning: {
        color: '$grey500',
        d: 'inline',
        fontSize: '$14',
        ml: '$8',
      },
      username: {
        color: '$grey400',
        d: 'inline',
        fontSize: '$14',
        fontWeight: '$500',
        mr: '$8',
        whiteSpace: 'nowrap',
      },
    },
  },
})

export default Text
