import {useCallback, useEffect, useRef, useLayoutEffect} from 'react'
import {useDispatch} from 'context'
import {useClickOutside, useKeyDown} from 'hooks'

let modalRoot = document.getElementById('modal')
if (!modalRoot) {
  modalRoot = document.createElement('div')
  modalRoot.setAttribute('id', 'modal')
  document.body.appendChild(modalRoot)
}

const useModal = ({modal, setModal}) => {
  const el = useRef(document.createElement('div'))
  const modalRef = useRef()
  const dispatch = useDispatch()
  const setIsModalOpen = useCallback(
    isModalOpen => dispatch({type: 'setIsModalOpen', isModalOpen}),
    [dispatch],
  )
  useKeyDown('Escape', () => modal && setModal(false))
  useClickOutside(modalRef, () => modal && setModal(false))
  useLayoutEffect(() => {
    const currentEl = el.current
    modalRoot.appendChild(currentEl)
    return () => modalRoot.removeChild(currentEl)
  }, [])
  useEffect(() => {
    if (modal) setIsModalOpen(true)
    return () => modal && setIsModalOpen(false)
  }, [modal, setIsModalOpen])
  return {
    el,
    modalRef,
  }
}

export default useModal
