import {useState} from 'react'

const useBulkOpenLinks = ({companiesToOpenLinks, listType}) => {
  const [openLinkSelectedOption, setOpenLinkSelectedOption] = useState('')
  const openLinksFrom = [
    'NautaDB',
    'Website',
    'NautaDB + Website',
    'Linkedin',
    'Crunchbase',
    'Twitter',
    'Pipedrive',
    'All links',
  ]
  const investorsOpenLinksFrom = [
    'NautaDB',
    'Website',
    'NautaDB + Linkedin',
    'Linkedin',
    'Crunchbase',
    'Twitter',
    'All links',
  ]
  const linksOptions =
    listType !== 'investors-page' ? openLinksFrom : investorsOpenLinksFrom
  const handleOpenLinks = () => {
    const getLinks = name =>
      companiesToOpenLinks.map(({original}) => original[name])
    const nautadbLinks = companiesToOpenLinks.map(
      ({original: {permalink, type, uuid}}) =>
        `${window.location.href.split(/\b\/\b/)[0]}/${
          (type === 'person' && 'people') || 'companies'
        }/${permalink}?uuid=${uuid}`,
    )
    const filterEmptyLinks = links =>
      links.filter(link => link && link.length !== 0)
    const websiteLinks = filterEmptyLinks(getLinks('homepageUrl'))
    const linkedinLinks = filterEmptyLinks(getLinks('linkedinUrl'))
    const cbLinks = filterEmptyLinks(getLinks('cbUrl'))
    const twitterLinks = filterEmptyLinks(getLinks('twitterUrl'))
    const pipedriveLinks = filterEmptyLinks(getLinks('pipedriveUrl'))
    const allLinks = [
      ...nautadbLinks,
      ...websiteLinks,
      ...linkedinLinks,
      ...cbLinks,
      ...twitterLinks,
      ...pipedriveLinks,
    ]
    const openLinksInNewTab = links =>
      links.forEach(url => window.open(url, '_blank', 'noopener,noreferrer'))
    if (openLinkSelectedOption === 'NautaDB') openLinksInNewTab(nautadbLinks)
    if (openLinkSelectedOption === 'Website') openLinksInNewTab(websiteLinks)
    if (openLinkSelectedOption === 'NautaDB + Website') {
      openLinksInNewTab(nautadbLinks)
      openLinksInNewTab(websiteLinks)
    }
    if (openLinkSelectedOption === 'NautaDB + Linkedin') {
      openLinksInNewTab(nautadbLinks)
      openLinksInNewTab(linkedinLinks)
    }
    if (openLinkSelectedOption === 'Linkedin') openLinksInNewTab(linkedinLinks)
    if (openLinkSelectedOption === 'Crunchbase') openLinksInNewTab(cbLinks)
    if (openLinkSelectedOption === 'Twitter') openLinksInNewTab(twitterLinks)
    if (openLinkSelectedOption === 'Pipedrive')
      openLinksInNewTab(pipedriveLinks)
    if (openLinkSelectedOption === 'All links') openLinksInNewTab(allLinks)
  }
  return {
    handleOpenLinks,
    linksOptions,
    openLinkSelectedOption,
    setOpenLinkSelectedOption,
  }
}

export default useBulkOpenLinks
