import BaseBox from 'components/layout/Box'
import {styled} from 'styles'

export const Box = styled(BaseBox, {
  variants: {
    variant: {
      'app-shell-header': {
        d: 'none',
        '@bp3': {d: 'block'},
      },
      'app-shell-content': {
        pb: '$32',
        '@bp3': {
          px: '$0',
          py: '$24',
        },
      },
    },
  },
})

export const MainContainer = styled('div', {
  boxSizing: 'border-box',
  fs: 0,
  variants: {
    extended: {
      no: {
        margin: '$8 $24 $0 $24',
        '@bp3': {
          margin: '$0 $16 $16 6rem',
        },
        '@bp5': {
          margin: '$0 $24 $16 6.5rem',
        },
      },
      yes: {
        mx: '$24',
        '@bp2': {mx: '$16'},
        '@bp3': {
          mr: '$16',
          ml: '13rem',
        },
        '@bp5': {
          mr: '$24',
          ml: '13.5rem',
        },
      },
    },
  },
})
