import {memo} from 'react'
import {func, number} from 'prop-types'
import {HeartIcon} from 'assets'
import {Button, Icon} from 'components'

const BulkSaveButton = ({companiesToSaveLength, setSaveRemoveListModal}) => (
  <Button
    css={{
      mt: '$8',
      mr: 0,
      whiteSpace: 'nowrap',
      '@bp1': {mr: 0},
      '@bp2': {mt: '$8'},
      '@bp3': {mt: 0, mr: '$16'},
      '@landscape678': {mt: '$8'},
      '@landscapeX': {
        mr: 0,
        mt: '$8',
      },
    }}
    disabled={companiesToSaveLength === 0}
    onClick={() => setSaveRemoveListModal(true)}
    size="small"
    title="Bulk save/remove"
    type="button"
    variant="secondary-icon"
  >
    <Icon size="16" css={{mr: '$4'}}>
      <HeartIcon />
    </Icon>
    Bulk save
  </Button>
)

BulkSaveButton.propTypes = {
  companiesToSaveLength: number.isRequired,
  setSaveRemoveListModal: func.isRequired,
}

export default memo(BulkSaveButton)
