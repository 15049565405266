import {styled} from 'styles'

const Input = styled('input', {
  appearance: 'none',
  b: '1px solid $grey100',
  backgroundImage: 'none',
  br: '$small',
  flex: 1,
  fontSize: '$13',
  h: 'auto',
  lh: '$body',
  outline: 'none',
  p: '$8',
  textOverflow: 'ellipsis',
  w: '$full',
  '&::placeholder': {
    color: '$grey200',
  },
  '&:focus': {
    boc: '$grey300',
  },
  '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active': {
    '-webkit-box-shadow': '0 0 0 30px $white inset !important',
    '-webkit-text-fill-color': '$white !important',
    transition: 'background-color 5000s',
  },
  variants: {
    borderColor: {
      red: {
        boc: '$red',
      },
    },
    size: {
      small: {
        p: '7px',
      },
      large: {
        fontSize: '$14',
        p: '$12',
      },
    },
    variant: {
      search: {
        b: 0,
        bgc: '$grey75',
        fontSize: '$16',
        '@bp3': {bgc: '$white'},
      },
    },
  },
})

export default Input
