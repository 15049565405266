import {bool, arrayOf, shape, string} from 'prop-types'
import {Flex} from 'components'
import {
  AddToCalendar,
  EditEvent,
  RemoveEvent,
  RequestWebScraping,
  UserAttending,
} from './components'

const EventActions = ({
  allUsers,
  city,
  countryCode,
  description,
  editionUuid,
  eventUuid,
  endsOn,
  linkedinUrl,
  listType,
  name,
  onlineEvent,
  recurringEvent,
  scope,
  startsOn,
  ticketPrice,
  usersAttending,
  webscrapingAvailability,
  webscrapingListUuid,
  webscrapingRequest,
  webscrapingRequestBy,
  website,
}) => {
  const isEventsTable = listType === 'events'
  return (
    <Flex
      direction={isEventsTable ? 'column' : 'row'}
      css={!isEventsTable && {gap: '$8', fw: 'wrap'}}
    >
      <Flex css={{mb: isEventsTable && '$8', gap: '$8'}}>
        <RequestWebScraping
          editionUuid={editionUuid}
          hasRequestedWebScraping={webscrapingRequest}
          isWebScrapingAvailable={webscrapingAvailability}
          listType={listType}
          webscrapingListUuid={webscrapingListUuid}
          webscrapingName={name}
          webscrapingRequestByUsername={webscrapingRequestBy?.username}
        />
        <UserAttending
          allUsers={allUsers}
          editionUuid={editionUuid}
          listType={listType}
          usersAttending={usersAttending}
        />
        <AddToCalendar
          allUsers={allUsers}
          city={city}
          countryCode={countryCode}
          description={description}
          endsOn={endsOn}
          listType={listType}
          startsOn={startsOn}
          title={name}
          url={website}
          usersAttending={usersAttending}
        />
      </Flex>
      <Flex css={{gap: '$8'}}>
        <EditEvent
          allUsers={allUsers}
          city={city}
          countryCode={countryCode}
          description={description}
          editionUuid={editionUuid}
          endsOn={endsOn}
          linkedinUrl={linkedinUrl}
          listType={listType}
          name={name}
          onlineEvent={onlineEvent}
          recurringEvent={recurringEvent}
          scope={scope}
          startsOn={startsOn}
          ticketPrice={ticketPrice}
          usersAttending={usersAttending}
          website={website}
        />
        <RemoveEvent
          editionUuid={editionUuid}
          eventUuid={eventUuid}
          listType={listType}
          name={name}
        />
      </Flex>
    </Flex>
  )
}

EventActions.propTypes = {
  allUsers: arrayOf(shape({})).isRequired,
  city: string.isRequired,
  countryCode: string.isRequired,
  description: string.isRequired,
  editionUuid: string.isRequired,
  endsOn: string.isRequired,
  eventUuid: string.isRequired,
  linkedinUrl: string.isRequired,
  listType: string.isRequired,
  name: string.isRequired,
  onlineEvent: bool.isRequired,
  recurringEvent: bool.isRequired,
  scope: string.isRequired,
  startsOn: string.isRequired,
  ticketPrice: string.isRequired,
  usersAttending: string.isRequired,
  webscrapingAvailability: bool.isRequired,
  webscrapingListUuid: string.isRequired,
  webscrapingRequest: bool.isRequired,
  webscrapingRequestBy: arrayOf(shape({})).isRequired,
  website: string.isRequired,
}

export default EventActions
