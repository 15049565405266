import {useEffect, useMemo, useState} from 'react'
import {useQuery} from '@apollo/client'
import {GET_IS_COMPANY_INVESTOR, GET_IS_PERSON_INVESTOR} from './queries'

const usePortfolio = ({isCompany, setIsInvestor, uuid}) => {
  const [hasCompanies, setHasCompanies] = useState(true)
  const [hasFundingRounds, setHasFundingRounds] = useState(true)
  const [hasCategories, setHasCategories] = useState(true)
  const {
    data: isInvestorData,
    error: isInvestorQueryError,
    loading: isInvestorQueryLoading,
  } = useQuery(isCompany ? GET_IS_COMPANY_INVESTOR : GET_IS_PERSON_INVESTOR, {
    variables: {uuid},
    onError: error => console.error(error),
  })
  const isInvestor = useMemo(
    () =>
      isCompany
        ? isInvestorData?.organization.isInvestor
        : isInvestorData?.person.isInvestor,
    [isCompany, isInvestorData],
  )
  useEffect(() => {
    if (setIsInvestor) setIsInvestor(isInvestor)
  }, [isInvestor, setIsInvestor])
  return {
    hasCategories,
    hasCompanies,
    hasFundingRounds,
    isInvestor,
    isInvestorQueryError,
    isInvestorQueryLoading,
    setHasCategories,
    setHasCompanies,
    setHasFundingRounds,
  }
}

export default usePortfolio
