import {useMemo, useState, useRef} from 'react'
import {useMutation} from '@apollo/client'
import {useAuth} from 'context'
import {
  GET_COMPANY_COMMENTS,
  GET_EVENT_COMMENTS,
  GET_PERSON_COMMENTS,
} from 'constants/queries'
import {
  formatComment,
  formatDateTime,
  formatFirstAndLastName,
  getMentionedUserIds,
} from 'utils'
import {
  DELETE_COMPANY_COMMENT,
  DELETE_EVENT_COMMENT,
  DELETE_PERSON_COMMENT,
  EDIT_COMPANY_COMMENT,
  EDIT_EVENT_COMMENT,
  EDIT_PERSON_COMMENT,
} from './mutations'

const useCommentItem = ({
  commentText,
  id,
  isEvent,
  isPerson,
  updatedAt,
  userName,
  uuid,
}) => {
  const [comment, setComment] = useState(commentText)
  const [isEditing, setIsEditing] = useState(false)
  const [isError, setIsError] = useState(false)
  const [
    editComment,
    {error: isEditCommentError, loading: isEditCommentLoading},
  ] = useMutation(
    (isPerson && EDIT_PERSON_COMMENT) ||
      (isEvent && EDIT_EVENT_COMMENT) ||
      EDIT_COMPANY_COMMENT,
    {
      onError: error => {
        setIsError(true)
        console.error(error)
      },
    },
  )
  const [
    deleteComment,
    {error: isDeleteCommentError, loading: isDeleteCommentLoading},
  ] = useMutation(
    (isPerson && DELETE_PERSON_COMMENT) ||
      (isEvent && DELETE_EVENT_COMMENT) ||
      DELETE_COMPANY_COMMENT,
    {
      onError: error => {
        setIsError(true)
        console.error(error)
      },
    },
  )
  const {authState} = useAuth()
  const commentTextareaRef = useRef(null)
  const cursorPosition = comment.length
  const handleEditComment = () => {
    setIsEditing(true)
    setTimeout(
      () => commentTextareaRef?.current && commentTextareaRef.current.focus(),
      200,
    )
  }
  const handleCancelEditComment = () => {
    commentTextareaRef.current.blur()
    setComment(commentText)
    setIsEditing(false)
  }
  const handleSaveComment = () => {
    setIsEditing(false)
    commentTextareaRef.current.blur()
    editComment({
      variables: {
        commentText: comment,
        mentionedUserIds: getMentionedUserIds(comment),
        uuid,
      },
      refetchQueries: (isPerson && [
        {query: GET_PERSON_COMMENTS, variables: {personUuid: id}},
      ]) ||
        (isEvent && [
          {query: GET_EVENT_COMMENTS, variables: {eventUuid: id}},
        ]) || [{query: GET_COMPANY_COMMENTS, variables: {orgUuid: id}}],
    })
  }
  const handleDeleteComment = () => {
    deleteComment({
      variables: {uuid},
      refetchQueries: (isPerson && [
        {query: GET_PERSON_COMMENTS, variables: {personUuid: id}},
      ]) ||
        (isEvent && [
          {query: GET_EVENT_COMMENTS, variables: {eventUuid: id}},
        ]) || [{query: GET_COMPANY_COMMENTS, variables: {orgUuid: id}}],
    })
  }
  const formattedUsername = useMemo(
    () => userName && formatFirstAndLastName(userName),
    [userName],
  )
  const formattedDate = useMemo(() => updatedAt && formatDateTime(updatedAt), [
    updatedAt,
  ])
  const formattedComment = useMemo(() => comment && formatComment(comment), [
    comment,
  ])
  return {
    authState,
    comment,
    commentTextareaRef,
    cursorPosition,
    formattedComment,
    formattedDate,
    formattedUsername,
    handleCancelEditComment,
    handleDeleteComment,
    handleEditComment,
    handleSaveComment,
    isDeleteCommentError,
    isDeleteCommentLoading,
    isEditCommentError,
    isEditCommentLoading,
    isEditing,
    isError,
    setComment,
    setIsEditing,
    setIsError,
  }
}

export default useCommentItem
