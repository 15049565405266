import {useState} from 'react'
import {func, string} from 'prop-types'
import {Box} from 'components'
import {Categories, Companies, FundingRounds, TabNavBar} from './components'

const Content = ({
  investorUuid,
  setHasCompanies,
  setHasFundingRounds,
  setHasCategories,
}) => {
  const [selectedTab, setSelectedTab] = useState('companies')
  return (
    <Box data-testid="content" id="table">
      <TabNavBar selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      <Companies
        investorUuid={investorUuid}
        selectedTab={selectedTab}
        setHasCompanies={setHasCompanies}
      />
      <FundingRounds
        investorUuid={investorUuid}
        selectedTab={selectedTab}
        setHasFundingRounds={setHasFundingRounds}
      />
      <Categories
        investorUuid={investorUuid}
        selectedTab={selectedTab}
        setHasCategories={setHasCategories}
      />
    </Box>
  )
}

Content.propTypes = {
  investorUuid: string.isRequired,
  setHasCompanies: func.isRequired,
  setHasFundingRounds: func.isRequired,
  setHasCategories: func.isRequired,
}

export default Content
