const getSharedToOthers = data =>
  data
    .map(share => ({
      createdAt: share.createdAi,
      sharedWith: share.sharedWith.filter(
        person => person.username !== share.whoShares.username,
      ),
      whoShares: share.whoShares,
    }))
    .filter(person => person.sharedWith.length !== 0)

export default getSharedToOthers
