import {shape, string} from 'prop-types'
import {Text} from 'components'
import {formatAmountUSD} from 'utils'

const AmountRaisedCell = ({
  row: {
    original: {raisedAmountUsd},
  },
}) =>
  raisedAmountUsd !== '0' ? (
    <Text css={{whiteSpace: 'nowrap'}}>{formatAmountUSD(raisedAmountUsd)}</Text>
  ) : (
    '-'
  )

AmountRaisedCell.propTypes = {
  row: shape({
    original: shape({
      raisedAmountUsd: string,
    }),
  }).isRequired,
}

export default AmountRaisedCell
