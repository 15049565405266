import {useEffect, useMemo, useState} from 'react'
import {useLazyQuery, useQuery} from '@apollo/client'
import portfolioFundingRoundsColumns from './columns'
import GET_INVESTOR_PORTFOLIO_FUNDING_ROUNDS from './queries'
import {
  formatPortfolioFundingRounds,
  sortByAnnouncedDateAndRemoveDuplicatesByOrgName,
} from './utils'

const useFundingRounds = ({investorUuid, setHasFundingRounds}) => {
  const [preData, setPreData] = useState([])
  const [data, setData] = useState([])
  const [isFiltered, setIsFiltered] = useState(false)
  const {
    data: first100PortfolioFundingRoundsData,
    error: isFirst100PortfolioFundingRoundsQueryError,
    loading: isFirst100PortfolioFundingRoundsQueryLoading,
  } = useQuery(GET_INVESTOR_PORTFOLIO_FUNDING_ROUNDS, {
    variables: {
      investorUuid,
      page: 1,
      pageSize: 100,
    },
    onError: error => console.error(error),
  })
  const [
    getAllFundingRoundsCompaniesData,
    {
      data: allPortfolioFundingRoundsData,
      error: isAllPortfolioFundingRoundsQueryError,
      loading: isAllPortfolioFundingRoundsQueryLoading,
    },
  ] = useLazyQuery(GET_INVESTOR_PORTFOLIO_FUNDING_ROUNDS, {
    variables: {
      investorUuid,
      page: 1,
      pageSize: 999999,
    },
    onError: error => console.error(error),
  })
  const columns = useMemo(() => portfolioFundingRoundsColumns, [])
  const fundingTotalCount = useMemo(
    () =>
      first100PortfolioFundingRoundsData?.investorPortfolioFundingRounds
        ?.totalCount
        ? first100PortfolioFundingRoundsData.investorPortfolioFundingRounds
            .totalCount
        : 0,
    [first100PortfolioFundingRoundsData],
  )
  useEffect(() => {
    if (first100PortfolioFundingRoundsData?.investorPortfolioFundingRounds) {
      const first100Results = formatPortfolioFundingRounds(
        first100PortfolioFundingRoundsData.investorPortfolioFundingRounds
          .objects,
      )
      setPreData(first100Results)
      if (first100Results.length === 0) setHasFundingRounds(false)
      if (fundingTotalCount > 100) getAllFundingRoundsCompaniesData()
    }
    if (allPortfolioFundingRoundsData?.investorPortfolioFundingRounds) {
      const allResults = formatPortfolioFundingRounds(
        allPortfolioFundingRoundsData.investorPortfolioFundingRounds.objects,
      )
      setPreData(allResults)
    }
  }, [
    allPortfolioFundingRoundsData,
    first100PortfolioFundingRoundsData,
    fundingTotalCount,
    getAllFundingRoundsCompaniesData,
    setHasFundingRounds,
  ])
  useEffect(() => {
    if (isFiltered) {
      const filteredData = sortByAnnouncedDateAndRemoveDuplicatesByOrgName(
        preData,
      )
      setData(filteredData)
    } else {
      setData(preData)
    }
  }, [isFiltered, preData, setData])
  return {
    columns,
    data,
    isAllPortfolioFundingRoundsQueryError,
    isAllPortfolioFundingRoundsQueryLoading,
    isFirst100PortfolioFundingRoundsQueryError,
    isFirst100PortfolioFundingRoundsQueryLoading,
    setIsFiltered,
  }
}

export default useFundingRounds
