import {useCallback, useMemo, useState} from 'react'
import {useQuery, useMutation} from '@apollo/client'
import {useLocation} from 'react-router-dom'
import {
  GET_COMPANY_SUMMARY_LABELS,
  GET_PERSON_SUMMARY_LABELS,
} from 'components/patterns/ActivityList/components/LabelItem/queries'
import {GET_COMPANY_LABELS} from 'constants/queries'
import GET_PERSON_LABELS from 'pages/PersonProfile/components/CommentsAndActivityHistory/queries'
import {getMentionedUserIds} from 'utils'
import {ADD_COMPANY_LABEL, ADD_PERSON_LABEL} from './mutations'
import {GET_COMPANY_LABEL_CHOICES, GET_PERSON_LABEL_CHOICES} from './queries'

const useAddLabels = ({uuid}) => {
  const [commentText, setCommentText] = useState('')
  const [isError, setIsError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [open, setOpen] = useState(false)
  const {pathname} = useLocation()
  const isPersonProfile = pathname.startsWith('/people/')
  const {
    data: labelChoicesData,
    error: isLabelChoicesQueryError,
    loading: isLabelChoicesQueryLoading,
  } = useQuery(
    isPersonProfile ? GET_PERSON_LABEL_CHOICES : GET_COMPANY_LABEL_CHOICES,
    {
      onError: error => console.error(error),
    },
  )
  const [
    addLabel,
    {error: isAddLabelError, loading: isAddLabelLoading},
  ] = useMutation(isPersonProfile ? ADD_PERSON_LABEL : ADD_COMPANY_LABEL, {
    onCompleted: () => {
      setOpen(false)
      setCommentText('')
    },
    onError: error => {
      const duplicateLabelErrorRegex = /This (person|organization) already has this label from you/i
      if (duplicateLabelErrorRegex.test(error.toString())) {
        setErrorMsg('Sorry, you cannot add the same label twice.')
      }
      setIsError(true)
      console.error(error)
    },
    refetchQueries: isPersonProfile
      ? [
          {query: GET_PERSON_LABELS, variables: {uuid}},
          {query: GET_PERSON_SUMMARY_LABELS, variables: {uuid}},
        ]
      : [
          {query: GET_COMPANY_SUMMARY_LABELS, variables: {uuid}},
          {query: GET_COMPANY_LABELS, variables: {uuid}},
        ],
  })
  const sortByName = data =>
    data.slice().sort((a, b) => a.name.localeCompare(b.name))
  const labelChoices = useMemo(
    () =>
      (labelChoicesData &&
        isPersonProfile &&
        sortByName(labelChoicesData.personLabelChoices)) ||
      (labelChoicesData &&
        sortByName(labelChoicesData.organizationLabelChoices)),
    [labelChoicesData, isPersonProfile],
  )
  const handleAddLabel = useCallback(
    labelUuid => {
      const id = isPersonProfile ? {personUuid: uuid} : {organizationUuid: uuid}
      addLabel({
        variables: {
          commentText,
          labelUuid,
          mentionedUserIds: getMentionedUserIds(commentText),
          ...id,
        },
      })
    },
    [addLabel, commentText, isPersonProfile, uuid],
  )
  return {
    commentText,
    errorMsg,
    handleAddLabel,
    isAddLabelError,
    isAddLabelLoading,
    isError,
    isLabelChoicesQueryError,
    isLabelChoicesQueryLoading,
    labelChoices,
    open,
    setCommentText,
    setIsError,
    setOpen,
  }
}

export default useAddLabels
