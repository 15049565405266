import {memo} from 'react'
import {bool, func} from 'prop-types'
import {ShareIcon} from 'assets'
import {Button, Icon, Text} from 'components'

const ShareCompanyButton = ({isTable, setModal}) => (
  <Button
    color="grey"
    css={!isTable ? undefined : {mt: '$4'}}
    onClick={() => setModal(true)}
    size={!isTable ? 'small' : 'ghost-small'}
    title="Share company - s"
    type="button"
    variant={!isTable ? 'secondary-icon' : 'ghost'}
  >
    <Icon size="16" css={!isTable ? {mr: '$4'} : {ml: '$4'}}>
      <ShareIcon />
    </Icon>
    {!isTable && <Text>Share</Text>}
  </Button>
)

ShareCompanyButton.propTypes = {
  isTable: bool.isRequired,
  setModal: func.isRequired,
}

export default memo(ShareCompanyButton)
