import BaseFlex from 'components/layout/Flex'
import {keyframes, styled} from 'styles'

const showMenu = keyframes({
  from: {
    transform: 'translateY(-100%)',
  },
  to: {
    transform: 'translateY(0%)',
  },
})

const Flex = styled(BaseFlex, {
  variants: {
    variant: {
      'menu-nav': {
        bgc: '$white',
        jc: 'space-between',
        fd: 'column',
        h: 'calc(100% - 64px)',
        animation: `${showMenu} 0.5s ease-in-out`,
        padding: '$16 $16',
        zIndex: '$zMax',
        '@bp3': {
          padding: '0rem $16',
          h: '86%',
          animation: 'none',
          animationName: 'none',
        },
      },
    },
  },
})

export default Flex
