import {arrayOf, bool, func, shape, string} from 'prop-types'
import {
  Box,
  Button,
  CancelButton,
  Checkbox,
  Filter,
  Flex,
  Grid,
  InputField,
  Label,
  Loader,
  Text,
  TextareaField,
} from 'components'
import {formatCountryToString} from 'utils'
import useEditEventForm from './hooks'

const EditEventForm = ({
  allUsers,
  city,
  countryCode,
  description,
  editionUuid,
  endsOn,
  linkedinUrl,
  name,
  onlineEvent,
  recurringEvent,
  scope,
  setIsError,
  setIsSuccess,
  setModal,
  startsOn,
  ticketPrice,
  usersAttending,
  website,
}) => {
  const {
    ATTENDING_OPTIONS,
    errors,
    eventNameRef,
    finalScope,
    finalUsersAttending,
    handleEditEvent,
    handleSubmit,
    isEditEventError,
    isEditEventLoading,
    register,
    SCOPE_OPTIONS,
    setFinalScope,
    setFinalUsersAttending,
  } = useEditEventForm({
    allUsers,
    editionUuid,
    scope,
    setIsError,
    setIsSuccess,
    setModal,
    usersAttending,
  })
  return (
    <Flex as="form" direction="column" onSubmit={handleSubmit(handleEditEvent)}>
      <Grid variant="modal">
        <InputField
          defaultValue={name}
          errors={errors.name}
          name="name"
          register={e => {
            register(e, {required: true})
            eventNameRef.current = e
          }}
          onKeyDown={({key}) => key === 'Escape' && setModal(false)}
          required
          type="text"
        >
          Name
        </InputField>
        <InputField
          defaultValue={website}
          errors={errors.website}
          name="website"
          register={register({required: true})}
          required
          type="text"
        >
          Website
        </InputField>
        <InputField
          defaultValue={city}
          name="city"
          register={register}
          type="text"
        >
          City
        </InputField>
        <InputField
          defaultValue={formatCountryToString(countryCode)}
          name="country"
          register={register}
          type="text"
        >
          Country
        </InputField>
        <InputField
          defaultValue={linkedinUrl}
          name="linkedinUrl"
          register={register}
          type="text"
        >
          Linkedin url
        </InputField>
        <Box>
          <Filter
            disableSearch
            onChange={setFinalScope}
            options={SCOPE_OPTIONS}
            value={finalScope}
          >
            Scope
          </Filter>
        </Box>
        <InputField
          defaultValue={startsOn}
          name="startsOn"
          register={register}
          type="date"
        >
          Start date
        </InputField>
        <InputField
          defaultValue={endsOn}
          name="endsOn"
          register={register}
          type="date"
        >
          End date
        </InputField>
        <TextareaField
          defaultValue={description}
          maxLength={1500}
          name="description"
          register={register}
          rows={2}
        >
          Description
        </TextareaField>
        <Box>
          <Filter
            onChange={setFinalUsersAttending}
            options={ATTENDING_OPTIONS}
            value={finalUsersAttending}
          >
            Users attending
          </Filter>
        </Box>
        <InputField
          defaultValue={ticketPrice}
          name="ticketPrice"
          register={register}
        >
          Ticket price
        </InputField>
      </Grid>
      <Flex direction="column" css={{mt: '$16'}}>
        <Label htmlFor="onlineEvent">
          <Checkbox
            css={{mr: '$8'}}
            defaultChecked={onlineEvent}
            id="onlineEvent"
            name="onlineEvent"
            ref={register}
            type="checkbox"
          />
          Online event
        </Label>
        <Label htmlFor="recurringEvent">
          <Checkbox
            css={{mr: '$8'}}
            defaultChecked={recurringEvent}
            id="recurringEvent"
            name="recurringEvent"
            ref={register}
            type="checkbox"
          />
          Recurring event
        </Label>
      </Flex>
      <Flex
        direction={{'@initial': 'column', '@bp3': 'row'}}
        css={{
          mt: '$24',
          '@bp3': {
            ai: 'center',
            jc: 'space-between',
          },
        }}
      >
        <Box css={{mb: '$16', '@bp3': {mb: 0, w: '400px'}}}>
          {isEditEventError && (
            <Text variant="form-error">
              An error occurred. Please try again or report this bug.
            </Text>
          )}
        </Box>
        <Flex css={{as: 'flex-end', gap: '$16', mb: '$16'}}>
          <CancelButton onClick={() => setModal(false)}>Cancel</CancelButton>
          <Flex as={Button} type="submit">
            Save
            {isEditEventLoading && <Loader size="14" css={{ml: '$8'}} />}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

EditEventForm.propTypes = {
  allUsers: arrayOf(shape({})).isRequired,
  city: string.isRequired,
  countryCode: string.isRequired,
  description: string.isRequired,
  editionUuid: string.isRequired,
  endsOn: string.isRequired,
  linkedinUrl: string.isRequired,
  name: string.isRequired,
  onlineEvent: bool.isRequired,
  recurringEvent: bool.isRequired,
  scope: string.isRequired,
  setIsError: func.isRequired,
  setIsSuccess: func.isRequired,
  setModal: func.isRequired,
  startsOn: string.isRequired,
  ticketPrice: string.isRequired,
  usersAttending: string.isRequired,
  website: string.isRequired,
}

export default EditEventForm
