import {styled} from 'styles'

const Grid = styled('div', {
  boxSizing: 'border-box',
  d: 'grid',
  variants: {
    variant: {
      modal: {
        gridGap: '$16',
        '@bp2': {
          gtc: '1fr 1fr',
        },
      },
    },
  },
})

export default Grid
