import {arrayOf, bool, shape} from 'prop-types'
import {InvestorsSearchFilters, Sort, TableFilter} from './components'

const TableHead = ({
  canFilter,
  canSort,
  fundingRoundTypes,
  headerGroups,
  portfolioCategories,
  portfolioLocation,
}) => (
  <thead data-testid="thead">
    {headerGroups.map(headerGroup => (
      <tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map(column => (
          <th {...column.getHeaderProps()}>
            <div>
              {canSort ? (
                <span {...column.getSortByToggleProps()}>
                  {column.render('Header')}{' '}
                  <Sort
                    isSorted={column.isSorted}
                    isSortedDesc={column.isSortedDesc}
                    canSort={column.canSort}
                  />
                </span>
              ) : (
                column.render('Header')
              )}
              {canFilter && column.canFilter && (
                <TableFilter render={column.render} />
              )}
              {column.id === 'Portfolio companies matching with:' && (
                <InvestorsSearchFilters
                  fundingRoundTypes={fundingRoundTypes}
                  portfolioCategories={portfolioCategories}
                  portfolioLocation={portfolioLocation}
                />
              )}
            </div>
          </th>
        ))}
      </tr>
    ))}
  </thead>
)

TableHead.propTypes = {
  canFilter: bool.isRequired,
  canSort: bool.isRequired,
  fundingRoundTypes: arrayOf(shape({})),
  headerGroups: shape({}).isRequired,
  portfolioCategories: arrayOf(shape({})),
  portfolioLocation: arrayOf(shape({})),
}

TableHead.defaultProps = {
  fundingRoundTypes: undefined,
  portfolioCategories: undefined,
  portfolioLocation: undefined,
}

export default TableHead
