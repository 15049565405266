import {bool, func} from 'prop-types'
import {CloseIcon} from 'assets'
import {Button, Card, Icon, Flex} from 'components'
import ShortcutItem from './components'
import Text from './styles'

const ListShortcuts = ({isInvestorsList, isPeopleList, setShowShortcuts}) => {
  const subject =
    (isInvestorsList && 'investor') || (isPeopleList && 'person') || 'company'
  return (
    <Card
      data-testid="list-shortcuts"
      css={{mb: '$16', position: 'sticky', top: 0, py: '$16', zIndex: '$z400'}}
    >
      <Flex css={{ai: 'center', mb: '$4', jc: 'space-between'}}>
        <Text css={{fontSize: '$11', color: '$primary'}}>Shortcuts</Text>
        <Button
          onClick={() => setShowShortcuts(false)}
          size="ghost-small"
          title="Close Shortcuts"
          type="button"
          variant="ghost-icon"
        >
          <Icon color="grey200" size="19">
            <CloseIcon />
          </Icon>
        </Button>
      </Flex>
      <Flex css={{ai: 'baseline', fw: 'wrap'}}>
        <Text variant="shortcut-section-title">Navigation</Text>
        <ShortcutItem shortcutKey="&#9660;" color="$grey500">
          Highlight next {subject}
        </ShortcutItem>
        <ShortcutItem shortcutKey="&#9650;" color="$grey500">
          Highlight previous {subject}
        </ShortcutItem>
        <ShortcutItem shortcutKey="&#9658;" color="$grey500">
          Go to next page
        </ShortcutItem>
        <ShortcutItem shortcutKey="&#9668;" color="$grey500">
          Go to previous page
        </ShortcutItem>
      </Flex>
      {!isPeopleList && !isInvestorsList && (
        <Flex css={{ai: 'baseline', fw: 'wrap'}}>
          <Text variant="shortcut-section-title">Review</Text>
          <ShortcutItem shortcutKey="1" color="$green" isReview>
            Yes
          </ShortcutItem>
          <ShortcutItem shortcutKey="2" color="$yellow200" isReview>
            Not sure
          </ShortcutItem>
          <ShortcutItem shortcutKey="3" color="$red" isReview>
            Too late
          </ShortcutItem>
          <ShortcutItem shortcutKey="4" color="$red" isReview>
            Team
          </ShortcutItem>
          <ShortcutItem shortcutKey="5" color="$red" isReview>
            Not Nauta scope
          </ShortcutItem>
          <ShortcutItem shortcutKey="6" color="$red" isReview>
            Small market
          </ShortcutItem>
          <ShortcutItem shortcutKey="7" color="$red" isReview>
            Competition too high
          </ShortcutItem>
          <ShortcutItem shortcutKey="8" color="$red" isReview>
            Portfolio overlap
          </ShortcutItem>
          <ShortcutItem shortcutKey="9" color="$red" isReview>
            Not active
          </ShortcutItem>
          <ShortcutItem shortcutKey="0" color="$red" isReview>
            Not interesting
          </ShortcutItem>
          <ShortcutItem shortcutKey="o" color="$red" isReview>
            Other reason
          </ShortcutItem>
          <ShortcutItem shortcutKey="r" color="$grey500">
            Review and comment
          </ShortcutItem>
        </Flex>
      )}
      <Flex css={{ai: 'baseline', fw: 'wrap'}}>
        <Text variant="shortcut-section-title">Other</Text>
        <ShortcutItem shortcutKey="Enter" color="$grey500">
          Open profile in new tab
        </ShortcutItem>
        <ShortcutItem shortcutKey="Ctrl + Enter" color="$grey500">
          Open profile in same tab
        </ShortcutItem>
        {!isPeopleList && !isInvestorsList && (
          <>
            <ShortcutItem shortcutKey="L" color="$grey500">
              Save/Remove List
            </ShortcutItem>
            <ShortcutItem shortcutKey="s" color="$grey500">
              Share
            </ShortcutItem>
            <ShortcutItem shortcutKey="h" color="$grey500">
              Homepage
            </ShortcutItem>
          </>
        )}
        <ShortcutItem shortcutKey="c" color="$grey500">
          Crunchbase
        </ShortcutItem>
        <ShortcutItem shortcutKey="l" color="$grey500">
          Linkedin
        </ShortcutItem>
        <ShortcutItem shortcutKey="t" color="$grey500">
          Twitter
        </ShortcutItem>
        {(isPeopleList || isInvestorsList) && (
          <ShortcutItem shortcutKey="f" color="$grey500">
            Facebook
          </ShortcutItem>
        )}
        {!isPeopleList && !isInvestorsList && (
          <>
            <ShortcutItem shortcutKey="b" color="$grey500">
              Betalist
            </ShortcutItem>
            <ShortcutItem shortcutKey="C" color="$grey500">
              Capterra
            </ShortcutItem>
            <ShortcutItem shortcutKey="p" color="$grey500">
              Pipedrive/Add to Pipedrive
            </ShortcutItem>
          </>
        )}
      </Flex>
    </Card>
  )
}

ListShortcuts.propTypes = {
  isInvestorsList: bool.isRequired,
  isPeopleList: bool.isRequired,
  setShowShortcuts: func.isRequired,
}

export default ListShortcuts
