import {gql} from '@apollo/client'

const TOGGLE_USER_ATTENDING_EVENT = gql`
  mutation toggleUserAttendingCustomEvent($editionUuid: String!) {
    toggleUserAttendingCustomEvent(editionUuid: $editionUuid) {
      customEvent {
        eventUuid
        editionUuid
        name
        usersAttending
      }
    }
  }
`

export default TOGGLE_USER_ATTENDING_EVENT
