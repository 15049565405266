import {formatCountryToString, formatHomepageUrl} from 'utils'

export const checkTwitterUrl = url =>
  /\bhttps:\/\/www.twitter.com\/https:\/\/twitter.com\/\b/g.test(url)
    ? url.replace(/\bhttps:\/\/www.twitter.com\/\b/g, '')
    : url

export const getEditedOrOriginal = (type, extra, original) =>
  (extra && extra[type]) || original

export const getEditedOrOriginalCountry = (extra, countryCode) =>
  (extra?.countryCode && formatCountryToString(extra.countryCode)) ||
  formatCountryToString(countryCode)

export const getEditedOrOriginalDiversityFounders = (extra, original) =>
  extra?.diversityFounders !== undefined ? extra.diversityFounders : original

export const getEditedOrOriginalFoundedOnYear = (extra, foundedOn) =>
  (extra?.foundedOn && extra.foundedOn.split('-')[0]) ||
  (foundedOn && foundedOn !== '0' && foundedOn.split('-')[0]) ||
  'unk'

export const getEditedOrOriginalHomepageUrl = (extra, url) =>
  (extra?.homepageUrl && formatHomepageUrl(extra.homepageUrl)) ||
  formatHomepageUrl(url)

export const getEditedOrOriginalTwitterUrl = (extra, url) =>
  extra?.twitterUrl || checkTwitterUrl(url)
