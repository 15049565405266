import {memo} from 'react'
import {bool, func} from 'prop-types'
import {ColumnsIcon, ColumnsHiddenIcon} from 'assets'
import {Button, Icon} from 'components'

const ShowColumnsButton = ({hasHiddenColumns, setShowColumnsModal}) => (
  <Button
    css={{
      ml: '$16',
      mt: '$8',
      whiteSpace: 'nowrap',
      '@bp3': {ml: 0},
      '@bp4': {ml: '$16', mt: 0},
      '@landscape678': {ml: 0},
    }}
    onClick={() => setShowColumnsModal(true)}
    size="small"
    title="Show columns - |"
    type="button"
    variant="secondary-icon"
  >
    <Icon size="16" css={{mr: '$4'}}>
      {!hasHiddenColumns ? <ColumnsIcon /> : <ColumnsHiddenIcon />}
    </Icon>
    Show columns
  </Button>
)

ShowColumnsButton.propTypes = {
  hasHiddenColumns: bool.isRequired,
  setShowColumnsModal: func.isRequired,
}

export default memo(ShowColumnsButton)
