import {gql} from '@apollo/client'

export const DELETE_COMPANY_COMMENT = gql`
  mutation deleteOrganizationComment($uuid: String!) {
    deleteOrganizationComment(uuid: $uuid) {
      comment {
        uuid
      }
    }
  }
`
export const DELETE_EVENT_COMMENT = gql`
  mutation deleteEventComment($uuid: String!) {
    deleteEventComment(uuid: $uuid) {
      comment {
        uuid
      }
    }
  }
`

export const DELETE_PERSON_COMMENT = gql`
  mutation deletePersonComment($uuid: String!) {
    deletePersonComment(uuid: $uuid) {
      comment {
        uuid
      }
    }
  }
`

export const EDIT_COMPANY_COMMENT = gql`
  mutation updateOrganizationComment(
    $commentText: String!
    $mentionedUserIds: String
    $uuid: ID!
  ) {
    updateOrganizationComment(
      commentText: $commentText
      mentionedUserIds: $mentionedUserIds
      uuid: $uuid
    ) {
      comment {
        commentText
        updatedAt
        userName
      }
    }
  }
`

export const EDIT_EVENT_COMMENT = gql`
  mutation updateEventComment(
    $commentText: String!
    $mentionedUserIds: String
    $uuid: ID!
  ) {
    updateEventComment(
      commentText: $commentText
      mentionedUserIds: $mentionedUserIds
      uuid: $uuid
    ) {
      comment {
        commentText
        updatedAt
        userName
      }
    }
  }
`

export const EDIT_PERSON_COMMENT = gql`
  mutation updatePersonComment(
    $commentText: String!
    $mentionedUserIds: String
    $uuid: ID!
  ) {
    updatePersonComment(
      commentText: $commentText
      mentionedUserIds: $mentionedUserIds
      uuid: $uuid
    ) {
      comment {
        commentText
        updatedAt
        userName
      }
    }
  }
`
