import {memo} from 'react'
import {bool, func} from 'prop-types'
import {HeartIcon} from 'assets'
import {Button, Icon, Loader} from 'components'

const RemoveFromListButton = ({isLoading, setModal}) => (
  <Button
    color="primary"
    onClick={() => setModal(true)}
    size="ghost-small"
    title="Remove from List"
    type="button"
    variant="ghost"
  >
    {isLoading ? (
      <Loader size="14" data-testid="loader" />
    ) : (
      <Icon data-testid="icon" size="16">
        <HeartIcon />
      </Icon>
    )}
  </Button>
)

RemoveFromListButton.propTypes = {
  isLoading: bool.isRequired,
  setModal: func.isRequired,
}

export default memo(RemoveFromListButton)
