import {styled} from 'styles'

export const BackgroundLine = styled('hr', {
  bgc: '$grey100',
  b: 0,
  h: '2px',
  left: 0,
  margin: 0,
  position: 'absolute',
  top: '$24',
  w: '100%',
  zIndex: '$z100',
})

export const TabButton = styled('button', {
  color: '$primary',
  border: 'none',
  backgroundColor: 'transparent',
  borderBottom: '2px solid $secondary',
  zIndex: '$z200',
  fontSize: '$16',
  textAlign: 'left',
  cursor: 'pointer',
  paddingBottom: '5px',
  transition: '0.3s ease-in-out',
  '@bp1': {paddingRight: '50px'},
  '&:nth-child(2)': {
    marginLeft: '$16',
  },
  '&:nth-child(3)': {
    marginLeft: '$16',
  },
  variants: {
    hoveredTab: {
      true: {
        borderBottom: '2px solid $secondary',
      },
      false: {
        borderBottom: '2px solid transparent',
      },
    },
  },
})
