import {useState} from 'react'
import {bool, func, number, string} from 'prop-types'
import {RemoveFromListButton, RemoveFromListModal} from './components'

const RemoveFromList = ({
  isLastItem,
  itemIndex,
  listName,
  listUuid,
  logoUrl,
  orgName,
  orgUuid,
  pageIndex,
  previousPage,
}) => {
  const [modal, setModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  return (
    <>
      <RemoveFromListButton isLoading={isLoading} setModal={setModal} />
      <RemoveFromListModal
        isLastItem={isLastItem}
        itemIndex={itemIndex}
        listName={listName}
        listUuid={listUuid}
        logoUrl={logoUrl}
        modal={modal}
        orgName={orgName}
        orgUuid={orgUuid}
        pageIndex={pageIndex}
        previousPage={previousPage}
        setIsLoading={setIsLoading}
        setModal={setModal}
      />
    </>
  )
}

RemoveFromList.propTypes = {
  isLastItem: bool.isRequired,
  itemIndex: number.isRequired,
  listName: string.isRequired,
  listUuid: string.isRequired,
  logoUrl: string.isRequired,
  orgName: string.isRequired,
  orgUuid: string.isRequired,
  pageIndex: number.isRequired,
  previousPage: func.isRequired,
}

export default RemoveFromList
