import {useCallback, useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {useDispatch} from 'context'

const useResultsPerPageSelect = ({listType, totalCount}) => {
  const [pageSizesList, setPageSizesList] = useState([])
  const {pathname} = useLocation()
  const dispatch = useDispatch()
  const isPeople = listType === 'people'
  const isInvestors = listType === 'investors'
  const isPortfolioFundingRounds = listType === 'portfolio-funding-rounds'
  const isPortfolioCategories = listType === 'portfolio-categories'
  const isEvents = listType === 'past-events' || listType === 'upcoming-events'
  const setCompaniesSearchPage = companiesSearchPage =>
    dispatch({type: 'setCompaniesSearchPage', companiesSearchPage})
  const setCompaniesSearchPageSize = companiesSearchPageSize =>
    dispatch({type: 'setCompaniesSearchPageSize', companiesSearchPageSize})
  const setInvestorsSearchPage = useCallback(
    investorsSearchPage =>
      dispatch({type: 'setInvestorsSearchPage', investorsSearchPage}),
    [dispatch],
  )
  const setInvestorsSearchPageSize = useCallback(
    investorsSearchPageSize =>
      dispatch({type: 'setInvestorsSearchPageSize', investorsSearchPageSize}),
    [dispatch],
  )
  const setPeopleSearchPage = useCallback(
    peopleSearchPage =>
      dispatch({type: 'setPeopleSearchPage', peopleSearchPage}),
    [dispatch],
  )
  const setPeopleSearchPageSize = useCallback(
    peopleSearchPageSize =>
      dispatch({type: 'setPeopleSearchPageSize', peopleSearchPageSize}),
    [dispatch],
  )
  useEffect(() => {
    if (totalCount > 250) {
      setPageSizesList([25, 50, 100])
    }
    if (totalCount <= 250 && totalCount > 100) {
      setPageSizesList([25, 50, 100, 'all'])
    }
    if (totalCount > 50 && totalCount <= 100) {
      setPageSizesList([25, 50, 'all'])
    }
    if (totalCount > 1 && totalCount <= 50) {
      setPageSizesList([25, 'all'])
    }
    if (
      (pathname === '/vc-tracker' || pathname === '/events') &&
      totalCount > 250
    ) {
      setPageSizesList([10, 25, 50, 100])
    }
    if (
      (pathname === '/vc-tracker' || pathname === '/events') &&
      totalCount <= 250 &&
      totalCount > 100
    ) {
      setPageSizesList([10, 25, 50, 100, 'all'])
    }
    if (
      (pathname === '/vc-tracker' || pathname === '/events') &&
      totalCount > 50 &&
      totalCount <= 100
    ) {
      setPageSizesList([10, 25, 50, 'all'])
    }
    if (
      (pathname === '/vc-tracker' || pathname === '/events') &&
      totalCount > 25 &&
      totalCount <= 50
    ) {
      setPageSizesList([10, 25, 'all'])
    }
    if (
      (pathname === '/vc-tracker' || pathname === '/events') &&
      totalCount > 1 &&
      totalCount <= 25
    ) {
      setPageSizesList([10, 'all'])
    }
  }, [pathname, totalCount])
  const element =
    (isEvents && 'Events') ||
    (isInvestors && 'Investors') ||
    (isPeople && 'People') ||
    (isPortfolioFundingRounds && 'Funding rounds') ||
    (isPortfolioCategories && 'Categories') ||
    'Companies'
  return {
    element,
    pageSizesList,
    pathname,
    setCompaniesSearchPage,
    setCompaniesSearchPageSize,
    setInvestorsSearchPage,
    setInvestorsSearchPageSize,
    setPeopleSearchPage,
    setPeopleSearchPageSize,
  }
}

export default useResultsPerPageSelect
