import {useState} from 'react'
import {arrayOf, bool, shape, string} from 'prop-types'
import {EditEventButton, EditEventModal} from './components'

const EditEvent = ({
  allUsers,
  city,
  countryCode,
  description,
  editionUuid,
  endsOn,
  linkedinUrl,
  listType,
  name,
  onlineEvent,
  recurringEvent,
  scope,
  startsOn,
  ticketPrice,
  usersAttending,
  website,
}) => {
  const [editEventModal, setEditEventModal] = useState(false)
  return (
    <>
      <EditEventButton listType={listType} setModal={setEditEventModal} />
      <EditEventModal
        allUsers={allUsers}
        city={city}
        countryCode={countryCode}
        description={description}
        editionUuid={editionUuid}
        endsOn={endsOn}
        linkedinUrl={linkedinUrl}
        modal={editEventModal}
        name={name}
        onlineEvent={onlineEvent}
        recurringEvent={recurringEvent}
        scope={scope}
        setModal={setEditEventModal}
        startsOn={startsOn}
        ticketPrice={ticketPrice}
        usersAttending={usersAttending}
        website={website}
      />
    </>
  )
}

EditEvent.propTypes = {
  allUsers: arrayOf(shape({})).isRequired,
  city: string.isRequired,
  countryCode: string.isRequired,
  description: string.isRequired,
  editionUuid: string.isRequired,
  endsOn: string.isRequired,
  linkedinUrl: string.isRequired,
  listType: string.isRequired,
  name: string.isRequired,
  onlineEvent: bool.isRequired,
  recurringEvent: bool.isRequired,
  scope: string.isRequired,
  startsOn: string.isRequired,
  ticketPrice: string.isRequired,
  usersAttending: string.isRequired,
  website: string.isRequired,
}

export default EditEvent
