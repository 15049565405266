import {Flex as BaseFlex} from 'components/layout'
import {Text as BaseText} from 'components/primitives'
import {styled} from 'styles'

export const Flex = styled(BaseFlex, {
  variants: {
    variant: {
      'people-item': {
        ai: 'center',
        b: '1px solid $grey100',
        br: '$small',
        bs: '$1',
        h: '100%',
        maxWidth: '128px',
        mb: '$16',
        mr: '$16',
        p: '$16',
        ta: 'center',
        '&:nth-child(even)': {
          mr: 0,
        },
        '@bp1': {
          maxWidth: '152px',
          mb: '$16',
          minWidth: '132px',
          mr: '$16',
          '&:nth-child(even)': {
            mr: '$16',
          },
        },
      },
    },
  },
})

export const Text = styled(BaseText, {
  variants: {
    variant: {
      'person-title': {
        color: '$grey300',
        fontSize: '$13',
        lh: '$body',
        mb: '$16',
        mt: '$4',
      },
    },
  },
})
