import {Text as BaseText} from 'components/primitives'
import {styled} from 'styles'

export const Dropzone = styled('div', {
  ai: 'center',
  bgc: '$grey50',
  boc: '$grey100',
  br: '$small',
  borderStyle: 'dashed',
  borderWidth: 2,
  color: '$grey200',
  cursor: 'pointer',
  d: 'flex',
  fd: 'column',
  flex: 1,
  my: '$16',
  outline: 'none',
  p: '$24',
  transition: 'border .24s ease-in-out',
  '&:focus': {
    boc: '$grey200',
  },
  variants: {
    variant: {
      active: {
        boc: '$primary',
      },
      accept: {
        boc: '$green',
      },
      reject: {
        boc: '$red',
      },
    },
  },
})

export const Text = styled(BaseText, {
  variants: {
    variant: {
      'upload-list-rule': {
        fontSize: '$11',
      },
    },
  },
})
