import {string} from 'prop-types'
import {Text} from 'components'
import {formatDate} from 'utils'

const DateOrEmptyCell = ({value}) => {
  if (!value) return '-'
  return (
    <Text css={{whiteSpace: 'nowrap', lh: '$body'}}>
      {typeof value === 'string' ? formatDate(value) : value}
    </Text>
  )
}

DateOrEmptyCell.propTypes = {
  value: string.isRequired,
}

export default DateOrEmptyCell
