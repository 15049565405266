import {func, string} from 'prop-types'
import {ErrorMessage, Loader, PortfolioTable} from 'components'
import useCompanies from './hooks'

const Companies = ({investorUuid, selectedTab, setHasCompanies}) => {
  const {
    columns,
    data,
    isAllPortfolioCompaniesQueryError,
    isAllPortfolioCompaniesQueryLoading,
    isFirst100PortfolioCompaniesQueryError,
    isFirst100PortfolioCompaniesQueryLoading,
    totalCount,
  } = useCompanies({investorUuid, setHasCompanies})
  if (isFirst100PortfolioCompaniesQueryLoading && selectedTab === 'companies')
    return <Loader size="20" data-testid="loader" />
  if (isFirst100PortfolioCompaniesQueryError)
    return (
      <ErrorMessage css={{mb: '$16'}}>
        An error occurred getting the portfolio data. Please try to refresh or
        report this bug.
      </ErrorMessage>
    )
  return (
    selectedTab === 'companies' &&
    data?.length > 0 && (
      <>
        <PortfolioTable
          allDataLoading={isAllPortfolioCompaniesQueryLoading}
          columns={columns}
          data={data}
          investorUuid={investorUuid}
          listType="portfolio-companies"
          totalCount={totalCount}
        />
        {isAllPortfolioCompaniesQueryError && (
          <ErrorMessage css={{mt: '$16'}}>
            An error occurred getting the portfolio data. Please try to refresh
            or report this bug.
          </ErrorMessage>
        )}
      </>
    )
  )
}

Companies.propTypes = {
  investorUuid: string.isRequired,
  selectedTab: string.isRequired,
  setHasCompanies: func.isRequired,
}

export default Companies
