import {useEffect, useMemo, useRef} from 'react'
import {useMutation, useQuery} from '@apollo/client'
import {useLocation} from 'react-router-dom'
import {GET_MY_LISTS, GET_MY_LISTS_DASHBOARD} from 'constants/queries'
import {useAuth} from 'context'
import {DELETE_LIST, REMOVE_MYSELF_AS_COLLABORATOR} from './mutations'
import GET_MY_USER_ID from './queries'

const useDeleteListForm = ({
  creatorUsername,
  setIsError,
  setIsSuccess,
  setModal,
  uuid,
}) => {
  const deleteRef = useRef(null)
  const {pathname} = useLocation()
  const {authState} = useAuth()
  const loggedUsername = authState.username
  const isListOwner = useMemo(() => loggedUsername === creatorUsername, [
    loggedUsername,
    creatorUsername,
  ])
  const scrollTop = () => window.scrollTo({top: 0, behavior: 'smooth'})
  const {
    data: userData,
    error: isUserQueryError,
    loading: isUserQueryLoading,
  } = useQuery(GET_MY_USER_ID)
  useEffect(
    () =>
      deleteRef?.current && !isUserQueryLoading && deleteRef.current.focus(),
    [isUserQueryLoading],
  )
  const myUserId = useMemo(() => userData?.userCustomField.user.id, [userData])
  const [deleteList, {loading: isDeleteListLoading}] = useMutation(
    DELETE_LIST,
    {
      onCompleted: () => {
        setModal(false)
        setIsSuccess(true)
        if (pathname === '/my-lists') scrollTop()
      },
      refetchQueries:
        (pathname === '/my-lists' && [{query: GET_MY_LISTS}]) ||
        (pathname === '/dashboard' && [{query: GET_MY_LISTS_DASHBOARD}]),
      onError: error => {
        setModal(false)
        setIsError(true)
        console.error(error)
      },
    },
  )
  const [
    removeMyselfFromList,
    {loading: isRemoveMyselfFromListLoading},
  ] = useMutation(REMOVE_MYSELF_AS_COLLABORATOR, {
    onCompleted: () => {
      setModal(false)
      setIsSuccess(true)
      if (pathname === '/my-lists') scrollTop()
    },
    refetchQueries:
      (pathname === '/my-lists' && [{query: GET_MY_LISTS}]) ||
      (pathname === '/dashboard' && [{query: GET_MY_LISTS_DASHBOARD}]),
    onError: error => {
      setModal(false)
      setIsError(true)
      console.error(error)
    },
  })
  const handleRemove = () => {
    if (isListOwner) {
      deleteList({
        variables: {uuid},
      })
    } else {
      removeMyselfFromList({
        variables: {
          collaboratorIds: myUserId,
          uuid,
        },
      })
    }
  }
  const isDeleteLoading = isDeleteListLoading || isRemoveMyselfFromListLoading
  return {
    deleteRef,
    handleRemove,
    isDeleteLoading,
    isListOwner,
    isUserQueryError,
    isUserQueryLoading,
  }
}

export default useDeleteListForm
