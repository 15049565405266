import {gql} from '@apollo/client'

export const ADD_EVENT_COMMENT = gql`
  mutation createEventComment(
    $commentText: String!
    $eventUuid: String!
    $mentionedUserIds: String
  ) {
    createEventComment(
      commentText: $commentText
      eventUuid: $eventUuid
      mentionedUserIds: $mentionedUserIds
    ) {
      comment {
        uuid
        eventUuid
        userName
        createdAt
        updatedAt
        commentText
      }
    }
  }
`

export const ADD_PERSON_COMMENT = gql`
  mutation createPersonComment(
    $commentText: String!
    $mentionedUserIds: String
    $personUuid: String!
  ) {
    createPersonComment(
      commentText: $commentText
      mentionedUserIds: $mentionedUserIds
      personUuid: $personUuid
    ) {
      comment {
        uuid
        personUuid
        userId
        userName
        createdAt
        commentText
      }
    }
  }
`
