import {styled} from 'styles'

const ListsTableStyles = styled('div', {
  ox: 'auto',
  oy: 'hidden',
  mt: '-$8',
  table: {
    borderSpacing: 0,
    tableLayout: 'auto',
    w: '$full',
    'th, td': {
      margin: 0,
      ta: 'left',
      '&:last-child': {
        borderRight: 0,
      },
    },
    th: {
      borderBottom: '1px solid $grey100',
      color: '$grey300',
      fontSize: '$13',
      fontWeight: '$400',
      pr: '$8',
      py: '$8',
      verticalAlign: 'top',
      whiteSpace: 'nowrap',
    },
    'thead > tr:first-of-type > th': {
      color: '$secondary',
      ta: 'center',
    },
    'thead > tr:nth-of-type(2n) > th': {
      fontSize: '$11',
    },
    td: {
      borderBottom: '1px solid $grey100',
      fontSize: '$13',
      py: '$8',
      pr: '$8',
      verticalAlign: 'top',
    },
    'tbody > tr': {
      bgc: '$white',
      transition: 'background-color 0.3s ease',
      '&:hover': {
        bgc: '$grey50',
      },
      '&:nth-child(even)': {
        '&:hover': {
          bgc: '$grey50',
        },
      },
      '&:last-child': {
        td: {
          borderBottom: 0,
        },
      },
    },
  },
  variants: {
    variant: {
      'my-lists': {
        // total heading
        'tr:nth-of-type(1) > th:nth-of-type(4)': {
          pr: '$32',
        },
        // last heading
        'tr:nth-of-type(1) > th:last-of-type': {
          pr: 0,
        },
        // actions heading
        'tr:nth-of-type(2) > th:last-of-type': {
          pr: 0,
        },
        'td:last-of-type': {
          pr: 0,
        },
      },
      weekly: {
        'tr:nth-of-type(1) > th': {
          pr: '$40',
        },
        'tr:nth-of-type(1) > th:nth-of-type(7)': {
          pr: '$8',
        },
        'tr:nth-of-type(2) > th:nth-of-type(2)': {
          pr: '$8',
        },
        'td:nth-of-type(2)': {
          pr: '$8',
        },
        'tr:nth-of-type(2) > th:nth-of-type(even)': {
          pr: '$24',
        },
        'td:nth-of-type(even)': {
          pr: '$24',
        },
        'tr:nth-of-type(2) > th:nth-of-type(odd)': {
          pr: '$2',
        },
        'td:nth-of-type(odd)': {
          pr: '$2',
        },
        'tr:nth-of-type(2) > th:nth-of-type(12)': {
          pr: '0 !important',
        },
        'td:nth-of-type(12)': {
          pr: '0 !important',
        },
      },
      scrapings: {
        'tr:nth-of-type(1) > th:last-of-type > div': {
          pr: '$8',
        },
        'tr:nth-of-type(2) > th:last-of-type': {
          pr: 0,
        },
        'td:last-of-type': {
          pr: 0,
        },
      },
      uploaded: {
        'tr:nth-of-type(1) > th:last-of-type > div': {
          pr: '$8',
        },
        'tr:nth-of-type(2) > th:last-of-type': {
          pr: 0,
        },
        'td:last-of-type': {
          pr: 0,
        },
      },
      'founders-stealth': {
        table: {
          'thead > tr:first-of-type > th': {
            ta: 'right',
          },
          'tr:nth-of-type(2) > th:nth-of-type(n + 2)': {
            ta: 'right',
          },
          'tr:nth-of-type(2) > th:nth-of-type(5)': {
            pr: '$16',
          },
          'tr > td:nth-of-type(5)': {
            pr: '$24',
          },
          'tr > td:nth-of-type(n + 2)': {
            pr: '$16',
            ta: 'right',
          },
        },
      },
    },
  },
})

export default ListsTableStyles
