import {Text as BaseText} from 'components/primitives'
import {styled} from 'styles'

const Text = styled(BaseText, {
  variants: {
    variant: {
      'short-description': {
        color: '$grey500',
        fontSize: '$14',
        lh: '$body',
      },
    },
  },
})

export default Text
