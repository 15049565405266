import {useState} from 'react'
import {arrayOf, bool, func, shape, string} from 'prop-types'
import {FallbackImgCompanySm} from 'assets'
import {
  CloseModalButton,
  Flex,
  Image,
  Modal,
  Text,
  UserFeedback,
} from 'components'
import CreateNewListForm from './components'

const CreateNewListModal = ({
  commentText,
  companiesSavedOrRemoved,
  companiesToSave,
  logoUrl,
  modal,
  orgName,
  orgUuid,
  setCompaniesSavedOrRemoved,
  setModal,
}) => {
  const [isCreateSuccess, setIsCreateSuccess] = useState(false)
  const [isCreateError, setIsCreateError] = useState(false)
  const [isSaveSuccess, setIsSaveSuccess] = useState(false)
  const [isSaveError, setIsSaveError] = useState(false)
  const [listName, setListName] = useState('')
  if (isCreateError)
    return (
      <UserFeedback isError={isCreateError} setIsError={setIsCreateError}>
        Error creating list. Please, try again or report this bug.
      </UserFeedback>
    )
  if (isSaveError)
    return (
      <UserFeedback isError={isSaveError} setIsError={setIsSaveError}>
        Error saving {orgName} to {listName}. Please, try again or report this
        bug.
      </UserFeedback>
    )
  if (isSaveSuccess)
    return (
      <UserFeedback
        hasImage
        isSuccess={isSaveSuccess}
        setIsSuccess={setIsSaveSuccess}
      >
        {companiesSavedOrRemoved?.length > 0 ? (
          <Flex css={{ai: 'center'}}>
            <Flex as="ul" css={{ai: 'center', fw: 'wrap'}}>
              {companiesSavedOrRemoved.map(company => (
                <Flex
                  as="li"
                  key={company.orgUuid}
                  css={{ai: 'center', mr: '$8', p: '$4'}}
                >
                  <Image
                    alt={`${company.orgName} logo`}
                    css={{mr: '$8'}}
                    size="32"
                    src={company.logoUrl || FallbackImgCompanySm}
                    variant="company"
                  />
                  {company.orgName}
                  {companiesSavedOrRemoved.length > 1 && ' '}
                </Flex>
              ))}
            </Flex>
            <Text css={{whiteSpace: 'nowrap'}}>saved to {listName}</Text>
          </Flex>
        ) : (
          <Flex css={{ai: 'center'}}>
            <Image
              alt={`${orgName} logo`}
              css={{mr: '$8'}}
              size="32"
              src={logoUrl || FallbackImgCompanySm}
              variant="company"
            />
            {orgName} saved to {listName}
          </Flex>
        )}
      </UserFeedback>
    )
  if (isCreateSuccess && !orgUuid && !companiesToSave)
    return (
      <UserFeedback
        isSuccess={isCreateSuccess}
        setIsSuccess={setIsCreateSuccess}
      >
        List created successfully
      </UserFeedback>
    )
  return (
    <Modal modal={modal} setModal={setModal}>
      <CloseModalButton setModal={setModal} />
      <Text variant="modal-title">Create New List</Text>
      <CreateNewListForm
        commentText={commentText}
        companiesToSave={companiesToSave}
        orgUuid={orgUuid}
        setCompaniesSavedOrRemoved={setCompaniesSavedOrRemoved}
        setIsCreateError={setIsCreateError}
        setIsCreateSuccess={setIsCreateSuccess}
        setIsSaveError={setIsSaveError}
        setIsSaveSuccess={setIsSaveSuccess}
        setListName={setListName}
        setModal={setModal}
      />
    </Modal>
  )
}

CreateNewListModal.propTypes = {
  commentText: string.isRequired,
  companiesSavedOrRemoved: arrayOf(shape({})).isRequired,
  companiesToSave: arrayOf(shape({})).isRequired,
  logoUrl: string.isRequired,
  modal: bool.isRequired,
  orgName: string.isRequired,
  orgUuid: string.isRequired,
  setCompaniesSavedOrRemoved: func.isRequired,
  setModal: func.isRequired,
}

export default CreateNewListModal
