import {useState} from 'react'
import {arrayOf, string} from 'prop-types'
import {ShareListButton, ShareListModal} from './components'

const ShareList = ({collaboratorsIds, creatorUsername, name, uuid}) => {
  const [modal, setModal] = useState(false)
  return (
    <>
      <ShareListButton creatorUsername={creatorUsername} setModal={setModal} />
      <ShareListModal
        collaboratorsIds={collaboratorsIds}
        creatorUsername={creatorUsername}
        modal={modal}
        name={name}
        setModal={setModal}
        uuid={uuid}
      />
    </>
  )
}

ShareList.propTypes = {
  collaboratorsIds: arrayOf(string).isRequired,
  creatorUsername: string.isRequired,
  name: string.isRequired,
  uuid: string.isRequired,
}

export default ShareList
