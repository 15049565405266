import {gql} from '@apollo/client'

const GET_INVESTOR_PORTFOLIO_COMPANIES = gql`
  query investorPortfolioOrganizations(
    $investorUuid: String
    $page: Int
    $pageSize: Int
  ) {
    investorPortfolioOrganizations(
      investorUuid: $investorUuid
      page: $page
      pageSize: $pageSize
    ) {
      page
      pages
      hasNext
      hasPrev
      count
      totalCount
      objects {
        categoryList
        cbUrl
        city
        countryCode
        diversityFounders
        employeeCount
        extra
        firstInvestmentOn
        foundedOn
        homepageUrl
        inMyActionlist
        inMyList
        lastReview {
          uuid
          nautable
          notNautableOther
          notNautableReason
          createdAt
          userName
        }
        linkedinUrl
        logoUrl
        name
        organizationpipedrive {
          pipedriveUrl
          uuid {
            uuid
          }
        }
        permalink
        shortDescription
        totalFundingUsd
        twitterUrl
        uuid
      }
    }
  }
`

export default GET_INVESTOR_PORTFOLIO_COMPANIES
