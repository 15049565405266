import {memo} from 'react'
import {bool, func, string} from 'prop-types'
import {NavLink} from 'react-router-dom'
import {
  AddIcon,
  CompaniesIcon,
  DashboardIcon,
  EventsIcon,
  HeartEmptyIcon,
  InvestorsIcon,
  LogoutIcon,
  PeopleIcon,
  SearchIcon,
  SettingsIcon,
  ShortcutsMenuIcon,
  UploadIcon,
  // VcTrackerIcon,
} from 'assets'
import {Icon, Text} from 'components'

const MenuItemLink = ({
  extendMenu,
  label,
  path,
  setShowMobileMenu,
  showMobileMenu,
  title,
}) => (
  <NavLink
    activeStyle={{
      backgroundColor: '#f9f2f5',
      color: '#8c003d',
      transition: 'all 0.25s ease-in-out',
    }}
    className="nav-link"
    onClick={() => setShowMobileMenu(false)}
    title={title}
    to={path}
  >
    <Icon size="20">
      {(path === '/my-lists' && <HeartEmptyIcon />) ||
        (path === '/create-list' && <HeartEmptyIcon />) ||
        (path === '/add-company' && <AddIcon />) ||
        (path === '/companies' && <CompaniesIcon />) ||
        (path === '/dashboard' && <DashboardIcon />) ||
        (path === '/events' && <EventsIcon />) ||
        (path === '/investors' && <InvestorsIcon />) ||
        (path === '/logout' && <LogoutIcon />) ||
        (path === '/people' && <PeopleIcon />) ||
        (path === '/search' && <SearchIcon />) ||
        (path === '/settings' && <SettingsIcon />) ||
        (path === '/shortcuts' && <ShortcutsMenuIcon />) ||
        (path === '/upload-list' && <UploadIcon />)}
    </Icon>
    {(extendMenu || showMobileMenu) && <Text variant="menu-item">{label}</Text>}
  </NavLink>
)

MenuItemLink.propTypes = {
  extendMenu: bool.isRequired,
  label: string.isRequired,
  path: string.isRequired,
  setShowMobileMenu: func.isRequired,
  showMobileMenu: bool.isRequired,
  title: string.isRequired,
}

export default memo(MenuItemLink)
