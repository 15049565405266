const getUsersAttendingNames = (usersAttending, allUsers) =>
  allUsers?.length > 0 &&
  usersAttending &&
  usersAttending
    .split(',')
    .flatMap(userId => allUsers.filter(({id}) => id === userId))
    .map(({firstName, lastName}) => `${firstName} ${lastName}`)
    .join(', ')

export default getUsersAttendingNames
