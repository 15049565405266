import {Arrow, Content} from '@radix-ui/react-hover-card'
import {Text as BaseText} from 'components/primitives'
import {keyframes, styled} from 'styles'

export const ListItem = styled('li', {
  mr: '$8',
})

export const LabelDetailItem = styled('li', {
  mb: '$8',
  '&:last-of-type': {
    mb: '$4',
  },
})

export const Text = styled(BaseText, {
  br: '$small',
  bgc: '$black',
  d: 'inline-block',
  lh: '$body',
  px: '6px',
  py: '2px',
  whiteSpace: 'nowrap',
})

const slideUp = keyframes({
  '0%': {opacity: 0, transform: 'translateY(10px)'},
  '100%': {opacity: 1, transform: 'translateY(0)'},
})

const slideDown = keyframes({
  '0%': {opacity: 0, transform: 'translateY(-10px)'},
  '100%': {opacity: 1, transform: 'translateY(0)'},
})

export const HoverCardContent = styled(Content, {
  br: '$small',
  bgc: '$white',
  px: '$12',
  py: '$8',
  bs: '$3',
  '&[data-side="top"]': {animationName: slideUp},
  '&[data-side="bottom"]': {animationName: slideDown},
  animationDuration: '0.6s',
  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
})

export const HoverCardArrow = styled(Arrow, {
  fill: 'white',
})

export const LabelText = styled(BaseText, {
  br: '$small',
  d: 'inline-block',
  fontSize: '$11',
  lh: '$body',
  px: '6px',
  py: '2px',
  whiteSpace: 'nowrap',
})

export const LabelCreator = styled(BaseText, {
  color: '$grey500',
  d: 'inline',
})

export const LabelDate = styled(BaseText, {
  color: '$grey300',
  mt: '$4',
})

export const LabelBy = styled(BaseText, {
  color: '$grey300',
  fontSize: '$11',
  mt: '$4',
  whiteSpace: 'nowrap',
})
