import {createRef, useMemo, useState} from 'react'
import {useFilters, usePagination, useSortBy, useTable} from 'react-table'
import {DefaultColumnFilter, filterTypes} from 'components'
import {useTableShortcuts, useReviewShortcuts} from 'hooks'

const usePortfolioTable = ({columns, data}) => {
  const [commentText, setCommentText] = useState('')
  const [companyToReview, setCompanyToReview] = useState([])
  const [companyToShare, setCompanyToShare] = useState([])
  const [companyToSave, setCompanyToSave] = useState([])
  const [isReviewLoading, setIsReviewLoading] = useState(false)
  const [companiesSavedOrRemoved, setCompaniesSavedOrRemoved] = useState([])
  const [createNewListModal, setCreateNewListModal] = useState(false)
  const defaultColumn = useMemo(() => ({Filter: DefaultColumnFilter}), [])
  const {
    canNextPage,
    canPreviousPage,
    getTableProps,
    getTableBodyProps,
    gotoPage,
    headerGroups,
    nextPage,
    page,
    pageCount,
    prepareRow,
    previousPage,
    rows,
    setPageSize,
    state: {pageIndex, pageSize, filters},
  } = useTable(
    {
      autoResetPage: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: {
        pageIndex: 0,
        pageSize: 25,
      },
      manualPagination: false,
    },
    useFilters,
    useSortBy,
    usePagination,
  )
  const rowsRefs = useMemo(
    () => [...Array(page.length)].map(() => createRef()),
    [page.length],
  )
  const {
    addToPipedriveModal,
    cursor,
    reviewModal,
    saveRemoveListModal,
    selected,
    setAddToPipedriveModal,
    setCursor,
    setFocusNextItem,
    setReviewModal,
    setSaveRemoveListModal,
    setSelected,
    setShareModal,
    shareModal,
  } = useTableShortcuts({
    canNextPage,
    canPreviousPage,
    isPortfolioTable: true,
    nextPage,
    page,
    pageIndex,
    previousPage,
    rowsRefs,
    setCompanyToReview,
    setCompanyToSave,
    setCompanyToShare,
  })
  const {
    isReviewError,
    setIsReviewError,
    handleAddReview,
    otherReasonModal,
    setOtherReasonModal,
  } = useReviewShortcuts({
    orgUuid: selected?.original?.uuid,
    setIsReviewLoading,
    setFocusNextItem,
  })
  return {
    addToPipedriveModal,
    canNextPage,
    canPreviousPage,
    commentText,
    companiesSavedOrRemoved,
    companyToReview,
    companyToSave,
    companyToShare,
    createNewListModal,
    cursor,
    filters,
    getTableBodyProps,
    getTableProps,
    gotoPage,
    handleAddReview,
    headerGroups,
    isReviewError,
    isReviewLoading,
    nextPage,
    otherReasonModal,
    page,
    pageCount,
    pageIndex,
    pageSize,
    prepareRow,
    previousPage,
    reviewModal,
    rows,
    rowsRefs,
    saveRemoveListModal,
    selected,
    setAddToPipedriveModal,
    setCommentText,
    setCompaniesSavedOrRemoved,
    setCreateNewListModal,
    setCursor,
    setFocusNextItem,
    setIsReviewError,
    setIsReviewLoading,
    setOtherReasonModal,
    setPageSize,
    setReviewModal,
    setSaveRemoveListModal,
    setSelected,
    setShareModal,
    shareModal,
  }
}

export default usePortfolioTable
