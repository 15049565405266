import {arrayOf, bool, shape, string} from 'prop-types'
import {Flex} from 'components'
import {CommentItem, LabelItem, ShareItem} from './components'

const ActivityList = ({data, id, isEvent, isPerson}) => (
  <Flex
    as="ul"
    css={{maxHeight: '128px', oy: 'auto'}}
    data-testid="activity-list"
    direction="column"
  >
    {data.map(
      ({
        addedByName,
        commentText,
        createdAt,
        labelUuid,
        sharedWith,
        type,
        updatedAt,
        userName,
        uuid,
        whoShares,
      }) =>
        (type === 'share' && (
          <ShareItem
            key={uuid}
            commentText={commentText}
            createdAt={createdAt}
            sharedWith={sharedWith}
            whoShares={whoShares}
          />
        )) ||
        (type === 'comment' && (
          <CommentItem
            key={uuid}
            commentText={commentText}
            id={id}
            isEvent={isEvent}
            isPerson={isPerson}
            updatedAt={updatedAt}
            userName={userName}
            uuid={uuid}
          />
        )) ||
        (type === 'label' && (
          <LabelItem
            key={uuid}
            color={labelUuid.color}
            commentText={commentText}
            createdAt={createdAt}
            id={id}
            isPerson={isPerson}
            label={labelUuid.name}
            username={addedByName}
            uuid={uuid}
          />
        )),
    )}
  </Flex>
)

ActivityList.propTypes = {
  data: arrayOf(shape({})).isRequired,
  id: string.isRequired,
  isEvent: bool.isRequired,
  isPerson: bool.isRequired,
}

export default ActivityList
