import {memo, useEffect} from 'react'
import {arrayOf, bool, func, shape, string} from 'prop-types'
import {Flex} from 'components'
import {ActionListItem, MyListsListItem} from './components'

const MyListsList = ({
  companiesToSave,
  data,
  initialSelectedListNames,
  initialSelectedListUuid,
  inMyActionlist,
  isActionListSelected,
  isEveryCompanyInMyActionList,
  isSomeOrgInMyActionList,
  orgUuid,
  setInitialSelectedListNames,
  setInitialSelectedListUuid,
  setIsActionListSelected,
  setSelectedListsNamesToRemove,
  setSelectedListsNamesToSave,
  setSelectedListsUuidsToRemove,
  setSelectedListsUuidsToSave,
}) => {
  useEffect(() => {
    const currentSelectedUuids = data
      .filter(({isOrgInList}) => isOrgInList)
      .map(({listUuid}) => listUuid)
    const currentSelectedNames = data
      .filter(({isOrgInList}) => isOrgInList)
      .map(({listName}) => listName)
    setInitialSelectedListUuid(currentSelectedUuids)
    if (orgUuid && inMyActionlist) {
      const initialNames = [...currentSelectedNames, 'Action List']
      setInitialSelectedListNames(initialNames)
    } else {
      setInitialSelectedListNames([...currentSelectedNames])
    }
    if (companiesToSave?.length > 0) {
      const listsNamesInWhichEverySelectedCompanyIsInside = data
        .filter(({isEveryOrgInList}) => isEveryOrgInList)
        .map(({listName}) => listName)
      const listsUuidInWhichEverySelectedCompanyIsInside = data
        .filter(({isEveryOrgInList}) => isEveryOrgInList)
        .map(({listUuid}) => listUuid)
      setInitialSelectedListNames([
        ...currentSelectedNames,
        ...listsNamesInWhichEverySelectedCompanyIsInside,
      ])
      setInitialSelectedListUuid([
        ...currentSelectedUuids,
        ...listsUuidInWhichEverySelectedCompanyIsInside,
      ])
      if (isEveryCompanyInMyActionList || isSomeOrgInMyActionList) {
        setInitialSelectedListNames([
          ...currentSelectedNames,
          ...listsNamesInWhichEverySelectedCompanyIsInside,
          'Action List',
        ])
      }
    }
  }, [
    companiesToSave?.length,
    data,
    inMyActionlist,
    isEveryCompanyInMyActionList,
    isSomeOrgInMyActionList,
    orgUuid,
    setInitialSelectedListNames,
    setInitialSelectedListUuid,
  ])
  return (
    <Flex
      as="ul"
      css={{
        overflow: 'scroll',
        '@bp3': {h: '312px'},
        '@bp5': {h: '360px'},
      }}
      direction="column"
    >
      <ActionListItem
        companiesToSave={companiesToSave}
        initialSelectedListNames={initialSelectedListNames}
        isActionListSelected={isActionListSelected}
        isEveryCompanyInMyActionList={isEveryCompanyInMyActionList}
        isSomeOrgInMyActionList={isSomeOrgInMyActionList}
        orgUuid={orgUuid}
        setIsActionListSelected={setIsActionListSelected}
        setSelectedListsNamesToRemove={setSelectedListsNamesToRemove}
        setSelectedListsNamesToSave={setSelectedListsNamesToSave}
      />
      {data?.length > 0
        ? data.map(
            ({
              listUuid,
              listName,
              isOrgInList,
              isSomeOrgInList,
              isEveryOrgInList,
            }) => (
              <MyListsListItem
                companiesToSave={companiesToSave}
                initialSelectedListNames={initialSelectedListNames}
                initialSelectedListUuid={initialSelectedListUuid}
                isEveryOrgInList={isEveryOrgInList}
                isOrgInList={isOrgInList}
                isSomeOrgInList={isSomeOrgInList}
                key={listUuid}
                listName={listName}
                listUuid={listUuid}
                orgUuid={orgUuid}
                setInitialSelectedListUuid={setInitialSelectedListUuid}
                setSelectedListsNamesToRemove={setSelectedListsNamesToRemove}
                setSelectedListsNamesToSave={setSelectedListsNamesToSave}
                setSelectedListsUuidsToRemove={setSelectedListsUuidsToRemove}
                setSelectedListsUuidsToSave={setSelectedListsUuidsToSave}
              />
            ),
          )
        : null}
    </Flex>
  )
}

MyListsList.propTypes = {
  companiesToSave: arrayOf(shape({})).isRequired,
  data: arrayOf(shape({})).isRequired,
  initialSelectedListNames: arrayOf(string).isRequired,
  initialSelectedListUuid: arrayOf(string).isRequired,
  inMyActionlist: bool.isRequired,
  isActionListSelected: bool.isRequired,
  isEveryCompanyInMyActionList: bool.isRequired,
  isSomeOrgInMyActionList: bool.isRequired,
  orgUuid: string.isRequired,
  setInitialSelectedListNames: func.isRequired,
  setInitialSelectedListUuid: func.isRequired,
  setIsActionListSelected: func.isRequired,
  setSelectedListsNamesToRemove: func.isRequired,
  setSelectedListsNamesToSave: func.isRequired,
  setSelectedListsUuidsToRemove: func.isRequired,
  setSelectedListsUuidsToSave: func.isRequired,
}

export default memo(MyListsList)
