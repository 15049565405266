const formatAttendingOptions = users =>
  users
    .map(({id, firstName, lastName}) => ({
      id: `user-attending-${id}`,
      value: id,
      label: `${firstName} ${lastName}`,
    }))
    .slice()
    .sort((a, b) => a.label.localeCompare(b.label))

export default formatAttendingOptions
