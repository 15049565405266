import {useState, useEffect} from 'react'

const useDeviceDetect = () => {
  const [isMobile, setMobile] = useState(false)
  const [isWebKit, setWebKit] = useState(false)
  const [isMoz, setMoz] = useState(false)
  useEffect(() => {
    const userAgent =
      typeof navigator === 'undefined' ? '' : navigator.userAgent
    const mobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i,
      ),
    )
    const webkit = Boolean(userAgent.match(/AppleWebKit|Safari|Chrome/i))
    const moz = Boolean(userAgent.match(/Firefox/i))
    setMobile(mobile)
    setWebKit(webkit)
    setMoz(moz)
  }, [])
  return {isMobile, isWebKit, isMoz}
}

export default useDeviceDetect
