import {string} from 'prop-types'
import {useState} from 'react'
import {EditListButton, EditListModal} from './components'

const EditList = ({creatorId, creatorOffice, description, name, uuid}) => {
  const [modal, setModal] = useState(false)
  return (
    <>
      <EditListButton setModal={setModal} />
      <EditListModal
        creatorId={creatorId}
        creatorOffice={creatorOffice}
        description={description}
        modal={modal}
        name={name}
        setModal={setModal}
        uuid={uuid}
      />
    </>
  )
}

EditList.propTypes = {
  creatorId: string.isRequired,
  creatorOffice: string.isRequired,
  description: string.isRequired,
  name: string.isRequired,
  uuid: string.isRequired,
}

export default EditList
