import {bool, string} from 'prop-types'
import {UserFeedback} from 'components'
import {
  RequestWebScrapingModal,
  RequestWSEventPageButton,
  RequestWSTableButton,
  WebScrapingLink,
} from './components'
import useRequestWebScraping from './hooks'

const RequestWebScraping = ({
  editionUuid,
  hasRequestedWebScraping,
  isWebScrapingAvailable,
  listType,
  webscrapingListUuid,
  webscrapingName,
  webscrapingRequestByUsername,
}) => {
  const {
    handleCancelRequest,
    isCancel,
    isError,
    isEventsTable,
    isLoggedUserWhoRequested,
    isRequesWebscrapingError,
    isRequesWebscrapingLoading,
    isSuccess,
    requestWebscraping,
    requestWebScrapingModal,
    setIsError,
    setIsSuccess,
    setRequestWebScrapingModal,
  } = useRequestWebScraping({
    editionUuid,
    listType,
    webscrapingRequestByUsername,
  })
  return (
    <>
      {isWebScrapingAvailable && isEventsTable && (
        <WebScrapingLink
          webscrapingListUuid={webscrapingListUuid}
          webscrapingName={webscrapingName}
        />
      )}
      {!isWebScrapingAvailable &&
        isEventsTable &&
        (!hasRequestedWebScraping ||
          (hasRequestedWebScraping && isLoggedUserWhoRequested)) && (
          <RequestWSTableButton
            handleCancelRequest={handleCancelRequest}
            hasRequestedWebScraping={hasRequestedWebScraping}
            isRequesWebscrapingLoading={isRequesWebscrapingLoading}
            setModal={setRequestWebScrapingModal}
          />
        )}
      {!isWebScrapingAvailable &&
        !isEventsTable &&
        (!hasRequestedWebScraping ||
          (hasRequestedWebScraping && isLoggedUserWhoRequested)) && (
          <RequestWSEventPageButton
            handleCancelRequest={handleCancelRequest}
            hasRequestedWebScraping={hasRequestedWebScraping}
            isRequesWebscrapingLoading={isRequesWebscrapingLoading}
            setModal={setRequestWebScrapingModal}
          />
        )}
      <RequestWebScrapingModal
        editionUuid={editionUuid}
        isRequesWebscrapingLoading={isRequesWebscrapingLoading}
        modal={requestWebScrapingModal}
        requestWebscraping={requestWebscraping}
        setModal={setRequestWebScrapingModal}
      />
      {isSuccess && (
        <UserFeedback isSuccess={isSuccess} setIsSuccess={setIsSuccess}>
          Web scraping {isCancel ? 'canceled' : 'requested'} successfully
        </UserFeedback>
      )}
      {isRequesWebscrapingError && isError && (
        <UserFeedback isError={isError} setIsError={setIsError}>
          Error requesting web scraping. Please try again or report this bug.
        </UserFeedback>
      )}
    </>
  )
}

RequestWebScraping.propTypes = {
  editionUuid: string.isRequired,
  hasRequestedWebScraping: bool.isRequired,
  isWebScrapingAvailable: bool.isRequired,
  listType: string.isRequired,
  webscrapingListUuid: string.isRequired,
  webscrapingName: string.isRequired,
  webscrapingRequestByUsername: string.isRequired,
}

export default RequestWebScraping
