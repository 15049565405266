import {gql} from '@apollo/client'

const EDIT_EVENT = gql`
  mutation updateCustomEvent(
    $bcnScope: Boolean
    $berScope: Boolean
    $city: String
    $contact: String
    $countryCode: String
    $description: String
    $editionFrequency: String
    $editionUuid: String!
    $endsOn: String
    $linkedinUrl: String
    $ldnScope: Boolean
    $name: String
    $onlineEvent: Boolean
    $recurringEvent: Boolean
    $startsOn: String
    $ticketPrice: String
    $usersAttending: String
    $webscrapingComment: String
    $webscrapingRequest: Boolean
    $website: String
  ) {
    updateCustomEvent(
      bcnScope: $bcnScope
      berScope: $berScope
      city: $city
      contact: $contact
      countryCode: $countryCode
      description: $description
      editionFrequency: $editionFrequency
      editionUuid: $editionUuid
      endsOn: $endsOn
      ldnScope: $ldnScope
      linkedinUrl: $linkedinUrl
      name: $name
      onlineEvent: $onlineEvent
      recurringEvent: $recurringEvent
      startsOn: $startsOn
      ticketPrice: $ticketPrice
      usersAttending: $usersAttending
      webscrapingComment: $webscrapingComment
      webscrapingRequest: $webscrapingRequest
      website: $website
    ) {
      customEvent {
        eventUuid
        editionUuid
        name
        website
        countryCode
      }
    }
  }
`

export default EDIT_EVENT
