export const formatDateFix = value =>
  value.indexOf(' ') >= 0 ? value.split(' ').join('T') : value

const timestamp = value => Date.parse(value)

export const formatDate = value =>
  !Number.isNaN(timestamp(value))
    ? new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      }).format(new Date(formatDateFix(value)))
    : ''

export const formatDateTime = value =>
  !Number.isNaN(timestamp(value))
    ? new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }).format(new Date(formatDateFix(value)))
    : ''
