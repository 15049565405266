import {shape, string} from 'prop-types'
import {Text} from 'components'
import {formatInvestmentType} from 'utils'

const InvestmentTypeCell = ({
  row: {
    original: {investmentType},
  },
}) =>
  investmentType && (
    <Text css={{lh: '$body'}}>{formatInvestmentType(investmentType)}</Text>
  )

InvestmentTypeCell.propTypes = {
  row: shape({
    original: shape({
      investmentType: string,
    }),
  }).isRequired,
}

export default InvestmentTypeCell
