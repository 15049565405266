import {bool, func, string} from 'prop-types'
import {CloseModalButton, Modal, Text} from 'components'
import RequestWebScrapingForm from './components'

const RequestWebScrapingModal = ({
  editionUuid,
  isRequesWebscrapingLoading,
  modal,
  requestWebscraping,
  setModal,
}) => (
  <Modal modal={modal} setModal={setModal}>
    <CloseModalButton setModal={setModal} />
    <Text variant="modal-title">Request web scraping</Text>
    <RequestWebScrapingForm
      editionUuid={editionUuid}
      isRequesWebscrapingLoading={isRequesWebscrapingLoading}
      requestWebscraping={requestWebscraping}
      setModal={setModal}
    />
  </Modal>
)

RequestWebScrapingModal.propTypes = {
  editionUuid: string.isRequired,
  isRequesWebscrapingLoading: bool.isRequired,
  modal: bool.isRequired,
  requestWebscraping: func.isRequired,
  setModal: func.isRequired,
}

export default RequestWebScrapingModal
