import {memo, useState} from 'react'
import {func, string} from 'prop-types'
import {AddFilledIcon, AddIcon} from 'assets'
import {Button, Icon, Text} from 'components'

const AddToPipedriveButton = ({addFrom, setModal, title, variant}) => {
  const [isHover, setIsHover] = useState(false)
  return (
    <Button
      color="primary"
      onClick={() => setModal(true)}
      onFocus={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onMouseOver={() => setIsHover(true)}
      size={addFrom === 'company-profile' ? 'ghost-regular' : 'ghost-small'}
      title={title}
      type="button"
      variant={variant}
    >
      <Text>{addFrom === 'company-profile' ? 'Add to Pipedrive' : 'Add'}</Text>
      <Icon size="20" css={{ml: '$4'}}>
        {!isHover ? <AddIcon /> : <AddFilledIcon />}
      </Icon>
    </Button>
  )
}

AddToPipedriveButton.propTypes = {
  addFrom: string.isRequired,
  setModal: func.isRequired,
  title: string.isRequired,
  variant: string.isRequired,
}

export default memo(AddToPipedriveButton)
