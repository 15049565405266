import {memo, forwardRef} from 'react'
import {func} from 'prop-types'
import {Input} from 'components'
import useSearchInput from './hooks'

const SearchInput = forwardRef(({setSearchDropdown}, ref) => {
  const {
    handleInputBlur,
    handleInputChange,
    handleInputFocus,
    handleKeyDown,
    query,
  } = useSearchInput({ref, setSearchDropdown})
  return (
    <Input
      aria-label="Search"
      autoComplete="off"
      name="search"
      onBlur={handleInputBlur}
      onChange={handleInputChange}
      onFocus={handleInputFocus}
      onKeyDown={handleKeyDown}
      placeholder="Search"
      ref={ref}
      title="Search - /"
      type="text"
      value={query}
      variant="search"
    />
  )
})

SearchInput.propTypes = {
  setSearchDropdown: func.isRequired,
}

export default memo(SearchInput)
