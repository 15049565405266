import {useEffect, useRef, useState} from 'react'
import {useQuery, useMutation} from '@apollo/client'
import {useForm} from 'react-hook-form'
import {useAuth} from 'context'
import {SAVE_TO_ACTION_LIST} from 'constants/mutations'
import {GET_ACTION_LIST, GET_USERS} from 'constants/queries'
import {
  formatOffice,
  getFormattedUsers,
  getMentionedUserIds,
  getMyselfInAllUsers,
  getUsersOtherOffices,
  getUsersSameOffice,
} from 'utils'

const useShareCompanyForm = ({companiesToShare, setIsSuccess, setModal}) => {
  const [myOffice, setMyOffice] = useState('')
  const [users, setUsers] = useState({})
  const [usersSameOffice, setUsersSameOffice] = useState([])
  const [usersOtherOffices, setUsersOtherOffices] = useState([])
  const [myUser, setMyUser] = useState([])
  const [checkedUsersIds, setCheckedUsersIds] = useState([])
  const [isChecked, setIsChecked] = useState(false)
  const [myId, setMyId] = useState('')
  const [isCheckedMyself, setIsCheckedMyself] = useState(true)
  const [checkedUsersIdsSameOffice, setCheckedUsersIdsSameOffice] = useState([])
  const [
    checkedUsersIdsOtherOffices,
    setCheckedUsersIdsOtherOffices,
  ] = useState([])
  const [isError, setIsError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [comment, setComment] = useState('')
  const commentTextRef = useRef(null)
  const {authState} = useAuth()
  const {handleSubmit} = useForm({mode: 'onBlur'})
  const me = authState.username
  const {
    data: usersData,
    error: isUsersQueryError,
    loading: isUsersQueryLoading,
  } = useQuery(GET_USERS, {onError: error => console.error(error)})
  const [
    addToActionList,
    {error: isAddToActionListError, loading: isAddToActionListLoading},
  ] = useMutation(SAVE_TO_ACTION_LIST, {
    onCompleted: () => {
      setIsSuccess(true)
      setModal(false)
    },
    onError: error => {
      setErrorMsg('An error occurred. Please try again or report this bug.')
      setIsError(true)
      console.error(error)
    },
  })
  useEffect(() => commentTextRef?.current && commentTextRef.current.focus(), [])
  useEffect(() => {
    if (usersSameOffice && usersOtherOffices && myOffice) {
      setUsers({
        [myOffice]: usersSameOffice,
        otherOffices: usersOtherOffices,
        me: myUser,
      })
    }
  }, [usersSameOffice, usersOtherOffices, myUser, myOffice])
  useEffect(() => {
    if (checkedUsersIds.length) {
      setIsChecked(true)
    } else {
      setIsChecked(false)
    }
  }, [checkedUsersIds])
  useEffect(() => {
    if (usersData?.users) {
      const formattedUsers = getFormattedUsers(usersData.users)
      const myself = getMyselfInAllUsers(formattedUsers, me)
      setMyUser({...myself, isChecked: true})
      setMyId(myself.id)
      setCheckedUsersIds([myself.id])
      setMyOffice(myself.office)
      setUsersSameOffice(getUsersSameOffice(formattedUsers, myself))
      setUsersOtherOffices(getUsersOtherOffices(formattedUsers, myself))
    }
  }, [usersData, me])
  const handleShareCompany = () => {
    if (isChecked) {
      companiesToShare.map(({uuid}) =>
        addToActionList({
          variables: {
            commentText: comment,
            mentionedUserIds: getMentionedUserIds(comment),
            userIds: checkedUsersIds.join(),
            uuid,
          },
          refetchQueries: [{query: GET_ACTION_LIST}],
        }),
      )
    } else {
      setIsError(true)
      setErrorMsg('Select at least one checkbox.')
    }
  }
  const handleSaveToMyActionList = e => {
    const {checked} = e.target
    setUsers({...users, me: {...users.me, isChecked: checked}})
    if (checked) {
      setIsCheckedMyself(true)
      setCheckedUsersIds([...checkedUsersIds, myId])
    } else {
      setIsCheckedMyself(false)
      setCheckedUsersIds(checkedUsersIds.filter(id => id !== myId))
    }
  }
  const formattedOffice = formatOffice(myOffice)
  return {
    checkedUsersIds,
    checkedUsersIdsOtherOffices,
    checkedUsersIdsSameOffice,
    comment,
    commentTextRef,
    errorMsg,
    formattedOffice,
    handleSaveToMyActionList,
    handleShareCompany,
    handleSubmit,
    isAddToActionListError,
    isAddToActionListLoading,
    isCheckedMyself,
    isError,
    isUsersQueryError,
    isUsersQueryLoading,
    myId,
    myOffice,
    setCheckedUsersIds,
    setCheckedUsersIdsOtherOffices,
    setCheckedUsersIdsSameOffice,
    setComment,
    setUsers,
    users,
  }
}

export default useShareCompanyForm
