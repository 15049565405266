import {useEffect, useRef, useState} from 'react'

const useFilter = () => {
  const selectRef = useRef(null)
  const [isCloseToBottomCheck, setIsCloseToBottomCheck] = useState(null)
  const [dropdownHeight, setDropdownHeight] = useState(null)
  useEffect(() => {
    if (selectRef?.current) {
      const elementBottom = selectRef.current.getBoundingClientRect().bottom
      setIsCloseToBottomCheck(
        elementBottom + dropdownHeight > window.innerHeight,
      )
      const dropdownContent = selectRef.current.querySelector(
        '.dropdown-content',
      )
      if (dropdownContent) {
        setDropdownHeight(dropdownContent.offsetHeight)
      }
    }
  }, [dropdownHeight])
  return {
    isCloseToBottomCheck,
    selectRef,
    setDropdownHeight,
  }
}

export default useFilter
