import {Trigger as PopoverTrigger} from '@radix-ui/react-popover'
import {bool} from 'prop-types'
import {LabelIcon} from 'assets'
import {Button, Icon, Loader} from 'components'

const LabelPopoverTrigger = ({isAddLabelLoading}) => (
  <PopoverTrigger asChild>
    <Button
      css={{d: 'flex', fd: 'row'}}
      data-testid="button label icon"
      size="ghost-small"
      title="Add label"
      variant="ghost-icon"
    >
      <Icon size="17" color="grey300">
        <LabelIcon />
      </Icon>
      {isAddLabelLoading && (
        <Loader size="16" css={{ml: '$8'}} data-testid="loader" />
      )}
    </Button>
  </PopoverTrigger>
)

LabelPopoverTrigger.propTypes = {
  isAddLabelLoading: bool.isRequired,
}

export default LabelPopoverTrigger
