import fetch from 'cross-fetch'
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client'
import {onError} from '@apollo/client/link/error'

const errorLink = onError(({graphQLErrors, networkError}) => {
  if (graphQLErrors)
    graphQLErrors.map(({message, locations, path}) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    )
  if (networkError) console.error(`[Network error]: ${networkError}`)
})

const httpLink = createHttpLink({
  uri: process.env.NAUTADB_GRAPHQL_URI,
  credentials: 'include',
  fetch,
})

const link = ApolloLink.from([errorLink, httpLink])

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        actionList: {
          merge(existing, incoming) {
            return incoming
          },
        },
        customList: {
          merge(existing, incoming) {
            return incoming
          },
        },
        customListOrganization: {
          merge(existing, incoming) {
            return incoming
          },
        },
        customListOrganizationPaginated: {
          merge(existing = [], incoming) {
            return {...existing, ...incoming}
          },
        },
        customListsRelated: {
          merge(existing, incoming) {
            return incoming
          },
        },
        organization: {
          merge(existing = [], incoming) {
            return {...existing, ...incoming}
          },
        },
        organizationLabel: {
          merge(existing, incoming) {
            return incoming
          },
        },
        organizationListed: {
          merge(existing = [], incoming) {
            return {...existing, ...incoming}
          },
        },
        personComment: {
          merge(existing, incoming) {
            return incoming
          },
        },
        personLabel: {
          merge(existing, incoming) {
            return incoming
          },
        },
        person: {
          merge(existing = {}, incoming) {
            return {...existing, ...incoming}
          },
        },
        review: {
          merge(existing, incoming) {
            return incoming
          },
        },
        customEvent: {
          merge(existing, incoming) {
            return incoming
          },
        },
        eventComment: {
          merge(existing, incoming) {
            return incoming
          },
        },
      },
    },
    CustomListType: {
      keyFields: ['uuid'],
      fields: {
        customlistcollaboratorSet: {
          merge(existing, incoming) {
            return incoming
          },
        },
        customlistorganizationSet: {
          merge(existing, incoming) {
            return incoming
          },
        },
      },
    },
    OrganizationListedType: {
      fields: {
        listUuid: {
          merge(existing = {}, incoming) {
            return {...existing, ...incoming}
          },
        },
      },
    },
    CustomListOrganizationType: {
      keyFields: ['id'],
      fields: {
        listUuid: {
          merge(existing = {}, incoming) {
            return {...existing, ...incoming}
          },
        },
      },
    },
    ListingType: {
      keyFields: ['uuid'],
    },
    OrganizationType: {
      keyFields: ['uuid'],
      fields: {
        organizationlabelSet: {
          merge(existing, incoming) {
            return incoming
          },
        },
      },
    },
    OrganizationScoreType: {
      keyFields: ['uuid'],
    },
    OrganizationPipedriveType: {
      keyFields: ['uuid'],
    },
  },
})

const client = new ApolloClient({
  link,
  cache,
})

export default client
