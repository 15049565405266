import {arrayOf, shape, string} from 'prop-types'
import {Flex} from 'components'
import {AddLabel, LabelList} from './components'

const Labels = ({allLabels, labels, uuid}) => (
  <Flex css={{ml: '$8'}}>
    {labels?.length > 0 ? (
      <LabelList allLabels={allLabels} labels={labels} />
    ) : null}
    <AddLabel uuid={uuid} />
  </Flex>
)

Labels.propTypes = {
  allLabels: arrayOf(shape({})).isRequired,
  labels: arrayOf(shape({})).isRequired,
  uuid: string.isRequired,
}

export default Labels
