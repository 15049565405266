import {useCallback, useEffect, useRef, useState} from 'react'
import {post} from 'axios'
import {useDropzone} from 'react-dropzone'
import {useForm} from 'react-hook-form'

const useUploadListForm = ({setIsSuccess, setModal}) => {
  const [fileToUpload, setFileToUpload] = useState(null)
  const [checkedUsersIds, setCheckedUsersIds] = useState([])
  const dropzoneRef = useRef(null)
  const {register, handleSubmit, errors} = useForm({mode: 'onBlur'})

  const onDrop = useCallback(acceptedFiles => {
    const [file] = acceptedFiles
    setFileToUpload(file)
  }, [])

  const uploadList = (
    file,
    name,
    createCustomList,
    description,
    collaboratorIds,
  ) => {
    const url = process.env.NAUTADB_GRAPHQL_URI
    const formData = new FormData()
    formData.append('file', file)
    formData.append(
      'query',
      `mutation{
        uploadFile(
          input:{clientMutationId:"${name}",
          description:"${description}",
          createCustomList:${createCustomList},
          collaboratorIds:"${collaboratorIds}"}
          ){
            clientMutationId
          }
      }`,
    )
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: '*/*',
      },
      withCredentials: true,
    }
    return post(url, formData, config)
  }

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    isDragAccept,
  } = useDropzone({
    onDrop,
    multiple: false,
    accept:
      '.xls, .xlsx, .xml, .txt, .csv, text/plain, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })

  useEffect(() => dropzoneRef?.current && dropzoneRef.current.focus(), [])

  const handleUploadList = ({name, createCustomList, description}) => {
    uploadList(
      fileToUpload,
      name,
      createCustomList,
      description,
      checkedUsersIds.join(),
    ).then(() => {
      setIsSuccess(true)
      if (setModal) setModal(false)
    })
  }

  return {
    acceptedFiles,
    checkedUsersIds,
    dropzoneRef,
    errors,
    getInputProps,
    getRootProps,
    handleSubmit,
    handleUploadList,
    isDragAccept,
    isDragActive,
    isDragReject,
    register,
    setCheckedUsersIds,
  }
}

export default useUploadListForm
