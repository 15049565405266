import {memo} from 'react'
import {shape, string} from 'prop-types'
import {Flex, Text} from 'components'

const LocationCell = ({
  row: {
    original: {city, country},
  },
}) => (
  <Flex direction="column">
    <Text variant="location-cell-city">{city}</Text>
    <Text variant="location-cell-country">{country}</Text>
  </Flex>
)

LocationCell.propTypes = {
  row: shape({
    original: shape({
      city: string,
      country: string,
    }),
  }).isRequired,
}

export default memo(LocationCell)
