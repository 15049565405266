import {memo} from 'react'
import {bool, string} from 'prop-types'
import {Loader} from 'components'
import Text from './styles'

const SearchResultsCount = ({loading, total}) =>
  loading ? (
    <Loader size="16" css={{ml: '$8'}} data-testid="loader" />
  ) : (
    <Text variant="search-total-hits">{`(${total})`}</Text>
  )

SearchResultsCount.propTypes = {
  loading: bool.isRequired,
  total: string.isRequired,
}

export default memo(SearchResultsCount)
