import {useEffect} from 'react'

const useClickInside = (ref, callback) => {
  useEffect(() => {
    const listener = e =>
      ref.current && ref.current.contains(e.target) && callback()
    document.addEventListener('click', listener)
    return () => document.removeEventListener('click', listener)
  })
}

export default useClickInside
