import {string} from 'prop-types'

const SelectOptions = ({label, value}) => (
  <option data-testid="options" value={value}>
    {label}
  </option>
)

SelectOptions.propTypes = {
  label: string.isRequired,
  value: string.isRequired,
}

export default SelectOptions
