export const formatScope = scope =>
  (scope === 'bcn' && 'Barcelona') ||
  (scope === 'ldn' && 'London') ||
  (scope === 'ber' && 'Berlin') ||
  (scope === 'muc' && 'Berlin') ||
  (scope === 'unk' && 'Other') ||
  (scope === null && 'All')

export const formatTabScope = scope =>
  scope
    ? ` ${
        (scope === 'unk' && 'Other') ||
        (scope === 'muc' && 'BER') ||
        scope.toUpperCase()
      }`
    : ''
