import {memo} from 'react'
import {arrayOf, shape, string} from 'prop-types'
import {Box, Pagination, TableBody, TableHead, TableStyles} from 'components'
import useCategoriesTable from './hooks'

const CategoriesTable = ({columns, data, listType}) => {
  const {
    canNextPage,
    canPreviousPage,
    filters,
    getTableBodyProps,
    getTableProps,
    gotoPage,
    headerGroups,
    nextPage,
    page,
    pageCount,
    pageIndex,
    pageSize,
    prepareRow,
    previousPage,
    rows,
    setPageSize,
  } = useCategoriesTable({columns, data})
  return (
    <Box data-testid="portfolio-categories-table">
      <TableStyles data-testid="categories-table">
        <table {...getTableProps()}>
          <TableHead canSort canFilter headerGroups={headerGroups} />
          <TableBody
            data={page}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
          />
        </table>
      </TableStyles>
      <Pagination
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
        isFiltered={filters.length > 0}
        listType={listType}
        nextPage={nextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        previousPage={previousPage}
        setPageSize={setPageSize}
        totalCount={data.length}
        totalFilteredCount={rows.length}
      />
    </Box>
  )
}

CategoriesTable.propTypes = {
  columns: arrayOf(shape({})).isRequired,
  data: arrayOf(shape({})).isRequired,
  listType: string.isRequired,
}

export default memo(CategoriesTable)
