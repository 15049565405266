import {memo, useState} from 'react'
import {arrayOf, bool, func, number, shape, string} from 'prop-types'
import {useLocation} from 'react-router-dom'
import {Flex, ReviewCompany, SaveToList, ShareCompany} from 'components'
import RemoveFromList from './components'

const ActionsCell = ({
  previousPage,
  row: {
    original: {
      customListUuid,
      inMyActionlist,
      inMyList,
      listName,
      logoUrl,
      name,
      uuid,
    },
    index,
  },
  rows: {length},
  selectedUuid,
  setFocusNextItem,
  state: {pageIndex},
  isTableReviewLoading,
}) => {
  const [reviewModal, setReviewModal] = useState(false)
  const [shareModal, setShareModal] = useState(false)
  const {pathname} = useLocation()
  const lastItemIndex = length - 1
  return (
    <>
      {pathname !== '/dashboard' && pathname !== '/my-lists' && (
        <ReviewCompany
          buttonSize="ghost-small"
          companyToReview={[{orgUuid: uuid, name}]}
          isTableReviewLoading={
            selectedUuid === uuid ? isTableReviewLoading : false
          }
          modal={reviewModal}
          reviewFrom="companies-list-right"
          setFocusNextItem={setFocusNextItem}
          setModal={setReviewModal}
          variant="ghost"
        />
      )}
      <Flex css={{ai: 'baseline'}}>
        {pathname === '/my-lists' ? (
          <RemoveFromList
            isLastItem={index === lastItemIndex}
            itemIndex={index}
            listName={listName}
            listUuid={customListUuid}
            logoUrl={logoUrl}
            orgName={name}
            orgUuid={uuid}
            pageIndex={pageIndex}
            previousPage={previousPage}
          />
        ) : (
          <SaveToList
            inMyActionlist={inMyActionlist}
            inMyList={inMyList}
            isTable
            logoUrl={logoUrl}
            orgName={name}
            orgUuid={uuid}
            totalCount={length}
          />
        )}
        <ShareCompany
          companiesToShare={[{uuid, name}]}
          isTable
          modal={shareModal}
          setModal={setShareModal}
        />
      </Flex>
    </>
  )
}

ActionsCell.propTypes = {
  isTableReviewLoading: bool.isRequired,
  previousPage: func.isRequired,
  row: shape({
    index: number,
    original: shape({
      customListUuid: string,
      inMyActionlist: bool,
      inMyList: bool,
      listName: string,
      logoUrl: string,
      name: string,
      uuid: string,
    }),
  }).isRequired,
  rows: arrayOf(shape({})).isRequired,
  selectedUuid: string.isRequired,
  setFocusNextItem: func.isRequired,
  state: shape({
    pageIndex: number,
  }).isRequired,
}

export default memo(ActionsCell)
