const formatMyListsData = data =>
  data
    .map(({name, uuid, ...rest}) => ({
      listName: name,
      listUuid: uuid,
      ...rest,
    }))
    .slice()
    .sort((a, b) => a.listName.localeCompare(b.listName))

export default formatMyListsData
