import {useEffect, useState} from 'react'
import {useLazyQuery, useMutation} from '@apollo/client'
import {useLocation} from 'react-router-dom'
import {isBerlinScopeCheck} from 'utils'
import {
  GET_IS_AGGREGATED_LIST_MARKED_AS_DONE,
  GET_IS_CUSTOM_LIST_MARKED_AS_DONE,
  GET_IS_LIST_MARKED_AS_DONE,
} from './queries'
import MARK_LIST_AS_DONE from './mutations'

const useMarkListAsDone = ({
  listType,
  listUuid,
  reviewed,
  scope,
  week,
  year,
}) => {
  const [isMarkedAsDone, setIsMarkedAsDone] = useState(null)
  const [isError, setIsError] = useState(false)
  const {pathname} = useLocation()
  const standardListVariables = {
    listUuid,
  }
  const aggregatedVariables = {
    page: 1,
    pageSize: 1,
    scope: isBerlinScopeCheck(scope),
    week,
    year,
    reviewed: reviewed || null,
  }
  const [
    getIsListMarkedAsDone,
    {
      data: isListMarkedAsDoneData,
      error: isListMarkedAsDoneQueryError,
      loading: isListMarkedAsDoneQueryLoading,
    },
  ] = useLazyQuery(GET_IS_LIST_MARKED_AS_DONE, {
    onError: () => setIsError(true),
    variables: standardListVariables,
  })
  const customListVariables = {
    page: 1,
    pageSize: 1,
    listUuid,
    reviewed: reviewed || null,
  }
  const [
    getIsCustomListMarkedAsDone,
    {
      data: isCustomListMarkedAsDoneData,
      error: isCustomListMarkedAsDoneQueryError,
      loading: isCustomListMarkedAsDoneQueryLoading,
    },
  ] = useLazyQuery(GET_IS_CUSTOM_LIST_MARKED_AS_DONE, {
    onError: () => setIsError(true),
    variables: customListVariables,
  })
  const [
    getIsAggregatedMarkedAsDone,
    {
      data: isAggregatedListMarkedAsDoneData,
      error: isAggregatedListMarkedAsDoneQueryError,
      loading: isAggregatedListMarkedAsDoneQueryLoading,
    },
  ] = useLazyQuery(GET_IS_AGGREGATED_LIST_MARKED_AS_DONE, {
    onError: () => setIsError(true),
    variables: aggregatedVariables,
  })
  useEffect(() => {
    if (listType === 'aggregated') {
      getIsAggregatedMarkedAsDone()
    } else if (listType === 'my-lists-companies-list') {
      getIsCustomListMarkedAsDone()
    } else {
      getIsListMarkedAsDone()
    }
  }, [
    getIsAggregatedMarkedAsDone,
    getIsCustomListMarkedAsDone,
    getIsListMarkedAsDone,
    listType,
  ])
  useEffect(() => {
    if (isAggregatedListMarkedAsDoneData) {
      if (scope) {
        setIsMarkedAsDone(
          isAggregatedListMarkedAsDoneData.organizationListed.objects[0]
            .listUuid[`${scope}Done`],
        )
      } else {
        setIsMarkedAsDone(
          isAggregatedListMarkedAsDoneData.organizationListed.objects[0]
            .listUuid.totalDone,
        )
      }
    }
    if (isCustomListMarkedAsDoneData) {
      setIsMarkedAsDone(
        isCustomListMarkedAsDoneData.customListOrganizationPaginated.objects[0]
          .listUuid.totalDone,
      )
    }
    if (isListMarkedAsDoneData) {
      if (!scope) {
        setIsMarkedAsDone(isListMarkedAsDoneData.listing.objects[0].totalDone)
      } else {
        setIsMarkedAsDone(
          isListMarkedAsDoneData.listing.objects[0][`${scope}Done`],
        )
      }
    }
  }, [
    isAggregatedListMarkedAsDoneData,
    isCustomListMarkedAsDoneData,
    isListMarkedAsDoneData,
    listType,
    scope,
  ])
  const [
    markListAsDone,
    {error: isMarkAsDoneError, loading: isMarkAsDoneLoading},
  ] = useMutation(MARK_LIST_AS_DONE, {
    onError: () => setIsError(true),
    refetchQueries: (listUuid &&
      !pathname.startsWith('/my-lists') && [
        {query: GET_IS_LIST_MARKED_AS_DONE, variables: standardListVariables},
      ]) ||
      (listUuid &&
        pathname.startsWith('/my-lists') && [
          {
            query: GET_IS_CUSTOM_LIST_MARKED_AS_DONE,
            variables: customListVariables,
          },
        ]) || [
        {
          query: GET_IS_AGGREGATED_LIST_MARKED_AS_DONE,
          variables: aggregatedVariables,
        },
      ],
  })
  const handleMarkListAsDone = () => {
    const bcnDone = scope === 'bcn' || null
    const ldnDone = scope === 'ldn' || null
    const berDone = scope === 'ber' || null
    const unkDone = scope === 'unk' || null
    const totalDone = !scope || null
    const bcnNotDone = isMarkedAsDone && bcnDone && false
    const ldnNotDone = isMarkedAsDone && ldnDone && false
    const berNotDone = isMarkedAsDone && berDone && false
    const unkNotDone = isMarkedAsDone && unkDone && false
    const totalNotDone = isMarkedAsDone && totalDone && false
    if (!isMarkedAsDone) {
      markListAsDone({
        variables: {
          bcnDone,
          ldnDone,
          mucDone: berDone,
          totalDone,
          unkDone,
          uuid: listUuid,
          week,
          year,
        },
      })
    } else {
      markListAsDone({
        variables: {
          bcnDone: bcnNotDone,
          ldnDone: ldnNotDone,
          mucDone: berNotDone,
          totalDone: totalNotDone,
          unkDone: unkNotDone,
          uuid: listUuid,
          week,
          year,
        },
      })
    }
  }
  return {
    handleMarkListAsDone,
    isAggregatedListMarkedAsDoneQueryError,
    isAggregatedListMarkedAsDoneQueryLoading,
    isCustomListMarkedAsDoneQueryError,
    isCustomListMarkedAsDoneQueryLoading,
    isError,
    isListMarkedAsDoneQueryError,
    isListMarkedAsDoneQueryLoading,
    isMarkAsDoneError,
    isMarkAsDoneLoading,
    isMarkedAsDone,
    setIsError,
  }
}

export default useMarkListAsDone
