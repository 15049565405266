import {useState} from 'react'
import {useMutation} from '@apollo/client'
import {useAuth} from 'context'
import {GET_COMPANY_LABELS} from 'constants/queries'
import GET_PERSON_LABELS from 'pages/PersonProfile/components/CommentsAndActivityHistory/queries'
import {DELETE_COMPANY_LABEL, DELETE_PERSON_LABEL} from './mutations'
import {GET_COMPANY_SUMMARY_LABELS, GET_PERSON_SUMMARY_LABELS} from './queries'

const useLabelItem = ({id, isPerson}) => {
  const [isError, setIsError] = useState(false)
  const {authState} = useAuth()
  const loggedUser = authState.username
  const [
    deleteLabel,
    {error: isDeleteLabelError, loading: isDeleteLabelLoading},
  ] = useMutation(isPerson ? DELETE_PERSON_LABEL : DELETE_COMPANY_LABEL, {
    onError: e => {
      setIsError(true)
      console.error(e)
    },
    refetchQueries: isPerson
      ? [
          {query: GET_PERSON_LABELS, variables: {uuid: id}},
          {query: GET_PERSON_SUMMARY_LABELS, variables: {uuid: id}},
        ]
      : [
          {query: GET_COMPANY_LABELS, variables: {uuid: id}},
          {query: GET_COMPANY_SUMMARY_LABELS, variables: {uuid: id}},
        ],
  })
  const handleDeleteLabel = uuid => {
    deleteLabel({
      variables: {
        uuid,
      },
    })
  }
  return {
    handleDeleteLabel,
    isDeleteLabelError,
    isDeleteLabelLoading,
    isError,
    loggedUser,
    setIsError,
  }
}

export default useLabelItem
