import {arrayOf, shape} from 'prop-types'
import {FallbackImgCompanySm} from 'assets'
import {Flex, Image, Link, ListItem, Text} from 'components'

const CompaniesCell = ({
  row: {
    original: {organizations},
  },
}) => (
  <Flex as="ul" css={{fw: 'wrap'}}>
    {organizations.map(({name, logoUrl, permalink, uuid}) => (
      <ListItem key={uuid} variant="portfolio-categories">
        {permalink && uuid ? (
          <Link
            external
            href={`/companies/${permalink}?uuid=${uuid}`}
            title={name}
          >
            <Flex css={{ai: 'center'}}>
              <Image
                alt={`${name} logo`}
                css={{mr: '$4'}}
                size="22"
                src={logoUrl || FallbackImgCompanySm}
                variant="company"
              />
              <Text variant="name-cell">{name}</Text>
            </Flex>
          </Link>
        ) : (
          <Flex css={{ai: 'center'}}>
            <Image
              alt={`${name} logo`}
              css={{mr: '$4'}}
              size="22"
              src={logoUrl || FallbackImgCompanySm}
              variant="company"
            />
            <Text variant="name-cell">{name}</Text>
          </Flex>
        )}
      </ListItem>
    ))}
  </Flex>
)

CompaniesCell.propTypes = {
  row: shape({
    original: shape({
      organizations: arrayOf(shape({})),
    }),
  }).isRequired,
}

export default CompaniesCell
