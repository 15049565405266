import {styled} from 'styles'

const ListItem = styled('li', {
  mb: '$8',
  '&:last-of-type': {
    mb: '$24',
  },
  variants: {
    variant: {
      comments: {
        '&:last-of-type': {
          mb: 0,
        },
      },
      'duplicate-companies': {
        lh: '$body',
        mr: '$16',
        '&:last-of-type': {
          mb: 0,
        },
      },
      'portfolio-categories': {
        lh: '$body',
        mb: '$8',
        mr: '$16',
        '&:last-of-type': {
          mb: 0,
        },
      },
      'portfolio-companies': {
        lh: '$body',
        mr: '$16',
        '&:last-of-type': {
          mb: '$8',
        },
      },
      'menu-item': {
        h: '$48',
        mb: '$8',
        transition: 'width 0.25s ease-in-out',
        '& > .nav-link': {
          ai: 'center',
          br: '$pill',
          color: '$grey200',
          d: 'flex',
          h: '$full',
          p: '$14',
          txtd: 'none',
          w: '$full',
          '@bp3': {
            '&:hover': {
              bgc: '$primaryLight',
              color: '$primary',
              transition: 'all 0.25s ease-in',
            },
          },
        },
      },
    },
  },
})

export default ListItem
