import {memo} from 'react'
import {bool} from 'prop-types'
import {SortIcon, SortDownIcon, SortUpIcon} from 'assets'
import {Icon} from 'components'

const Sort = ({isSorted, isSortedDesc, canSort}) => (
  <>
    {isSorted && isSortedDesc && (
      <Icon size="7">
        <SortDownIcon data-testid="sort-down" />
      </Icon>
    )}
    {isSorted && !isSortedDesc && (
      <Icon size="7">
        <SortUpIcon data-testid="sort-up" />
      </Icon>
    )}
    {!isSorted && canSort && (
      <Icon size="7">
        <SortIcon data-testid="sort" />
      </Icon>
    )}
  </>
)

Sort.propTypes = {
  isSorted: bool.isRequired,
  isSortedDesc: bool.isRequired,
  canSort: bool.isRequired,
}

export default memo(Sort)
