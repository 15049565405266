import {createContext, useContext, useState} from 'react'
import {node} from 'prop-types'

const InputQueryContext = createContext({})

const useInputQuery = () => useContext(InputQueryContext)

const InputQueryProvider = ({children}) => {
  const [query, setQuery] = useState('')
  return (
    <InputQueryContext.Provider
      value={{
        query,
        setQuery,
      }}
    >
      {children}
    </InputQueryContext.Provider>
  )
}

InputQueryProvider.propTypes = {
  children: node.isRequired,
}

export {InputQueryProvider, useInputQuery}
