import {gql} from '@apollo/client'

const GET_CURRENT_TEAM_PIPEDRIVE = gql`
  query currentTeam($orgUuid: String!) {
    currentTeam(orgUuid: $orgUuid) {
      uuid
      personName
      personUuid {
        uuid
      }
      title
    }
  }
`

export default GET_CURRENT_TEAM_PIPEDRIVE
