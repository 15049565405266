import {useMemo, useState} from 'react'
import {useMutation} from '@apollo/client'
import {useAuth} from 'context'
import {GET_EVENT_EDITION} from 'pages/Events/queries'
import TOGGLE_USER_ATTENDING_EVENT from './mutations'

const useUserAttending = ({
  allUsers,
  editionUuid,
  listType,
  usersAttending,
}) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const isEventsTable = listType === 'events'
  const {authState} = useAuth()
  const loggedUsername = authState.username
  const isUserAttending = useMemo(
    () =>
      usersAttending &&
      allUsers?.length > 0 &&
      loggedUsername &&
      usersAttending
        .split(',')
        .some(
          id =>
            id === allUsers.find(user => user.username === loggedUsername)?.id,
        ),
    [allUsers, loggedUsername, usersAttending],
  )
  const [
    toggleUserAttendingEvent,
    {
      error: isToggleUserAttendingEventError,
      loading: isToggleUserAttendingEventLoading,
    },
  ] = useMutation(TOGGLE_USER_ATTENDING_EVENT, {
    onCompleted: () => setIsSuccess(true),
    onError: e => {
      setIsError(true)
      console.error(e)
    },
  })
  const handleUserIsAttending = () =>
    toggleUserAttendingEvent({
      variables: {editionUuid},
      refetchQueries: [
        {
          query: GET_EVENT_EDITION,
          variables: {editionUuid},
        },
      ],
    })
  return {
    handleUserIsAttending,
    isError,
    isEventsTable,
    isSuccess,
    isToggleUserAttendingEventError,
    isToggleUserAttendingEventLoading,
    isUserAttending,
    setIsError,
    setIsSuccess,
  }
}

export default useUserAttending
