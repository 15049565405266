import {forwardRef, memo, useEffect, useRef} from 'react'
import {bool} from 'prop-types'
import {Checkbox} from 'components'

const IndeterminateCheckbox = forwardRef(({indeterminate, ...rest}, ref) => {
  const defaultRef = useRef()
  const resolvedRef = ref || defaultRef
  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])
  return (
    <>
      <Checkbox
        aria-label="select"
        type="checkbox"
        ref={resolvedRef}
        {...rest}
      />
    </>
  )
})

IndeterminateCheckbox.propTypes = {
  indeterminate: bool.isRequired,
}

export default memo(IndeterminateCheckbox)
