import {createPortal} from 'react-dom'
import useModal from './hooks'
import Box from './styles'

const Modal = ({
  children,
  isSaveToList,
  modal,
  position,
  reviewFrom,
  setModal,
}) => {
  const {el, modalRef} = useModal({modal, setModal})
  return (
    modal &&
    createPortal(
      <Box
        data-testid="modal"
        variant="modal-container"
        css={
          position === 'right' || reviewFrom === 'companies-list-right'
            ? {'@bp3': {jc: 'flex-end'}}
            : undefined
        }
      >
        <Box
          variant="modal-content"
          ref={modalRef}
          css={
            (position === 'right' || reviewFrom === 'companies-list-right'
              ? {'@bp3': {margin: 0, right: '$24'}}
              : undefined) ||
            (isSaveToList && {'@bp3': {w: 'min-content'}})
          }
        >
          {children}
        </Box>
      </Box>,
      el.current,
    )
  )
}

export default Modal
