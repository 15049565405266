import {useState} from 'react'
import {bool, func} from 'prop-types'
import {Flex} from 'components'
import {BackgroundLine, TabButton} from './styles'

const TabNavBar = ({selectedTab, setSelectedTab}) => {
  const [hoveredTab, setHoveredTab] = useState('')
  return (
    <Flex css={{mb: '$16', position: 'relative'}} data-testid="tab-nav-bar">
      <TabButton
        css={{
          '&:focus': {
            txtd: 'none',
          },
        }}
        hoveredTab={
          hoveredTab === 'companies' ||
          (hoveredTab === '' && selectedTab === 'companies')
        }
        onClick={() => setSelectedTab('companies')}
        onMouseOver={() => setHoveredTab('companies')}
        onMouseOut={() => setHoveredTab('')}
        size="ghost-xlarge"
        variant="ghost"
      >
        Companies
      </TabButton>
      <TabButton
        css={{
          '&:focus': {
            txtd: 'none',
          },
        }}
        hoveredTab={
          hoveredTab === 'funding-rounds' ||
          (hoveredTab === '' && selectedTab === 'funding-rounds')
        }
        onClick={() => setSelectedTab('funding-rounds')}
        onMouseOver={() => setHoveredTab('funding-rounds')}
        onMouseOut={() => setHoveredTab('')}
        size="ghost-xlarge"
        variant="ghost"
      >
        Funding&nbsp;Rounds
      </TabButton>
      <TabButton
        css={{
          '&:focus': {
            txtd: 'none',
          },
        }}
        hoveredTab={
          hoveredTab === 'categories' ||
          (hoveredTab === '' && selectedTab === 'categories')
        }
        onClick={() => setSelectedTab('categories')}
        onMouseOver={() => setHoveredTab('categories')}
        onMouseOut={() => setHoveredTab('')}
        size="ghost-xlarge"
        variant="ghost"
      >
        Categories
      </TabButton>
      <BackgroundLine />
    </Flex>
  )
}

TabNavBar.propTypes = {
  selectedTab: bool.isRequired,
  setSelectedTab: func.isRequired,
}

export default TabNavBar
