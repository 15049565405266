import {arrayOf, shape, string} from 'prop-types'
import {Flex} from 'components'
import LabelItem from './components'

const LabelList = ({allLabels, labels}) => (
  <Flex as="ul">
    {labels.map(({labelUuid: {color, name}, uuid}) => (
      <LabelItem key={uuid} allLabels={allLabels} color={color} name={name} />
    ))}
  </Flex>
)

LabelList.propTypes = {
  allLabels: arrayOf(
    shape({
      addedByName: string,
      commentText: string,
      createdAt: string,
      labelUuid: shape({
        color: string,
        name: string,
      }),
      uuid: string,
    }),
  ).isRequired,
  labels: arrayOf(
    shape({
      labelUuid: shape({
        color: string,
        name: string,
      }),
      uuid: string,
    }),
  ).isRequired,
}

export default LabelList
