import {formatFallbackImg} from 'utils'

const formatInvestorsDropdownResults = data =>
  data
    .filter(({searchType}) => searchType === 'InvestorType')
    .map(investor => ({
      ...investor,
      fallbackLogoUrl: formatFallbackImg(investor.logoUrl),
      permalink: investor.investorPermalink,
    }))
    .map(({__typename, investorPermalink, ...investor}) => investor)

export default formatInvestorsDropdownResults
