import {useCallback, useEffect, useState} from 'react'
import {useMutation} from '@apollo/client'
import {useAuth} from 'context'
import {GET_EVENT_EDITION} from 'pages/Events/queries'
import EDIT_EVENT from 'pages/Events/mutations'

const useRequestWebScraping = ({
  editionUuid,
  listType,
  webscrapingRequestByUsername,
}) => {
  const [requestWebScrapingModal, setRequestWebScrapingModal] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isCancel, setIsCancel] = useState(false)
  const isEventsTable = listType === 'events'
  const {authState} = useAuth()
  const loggedUser = authState.username
  const isLoggedUserWhoRequested = loggedUser === webscrapingRequestByUsername
  const [
    requestWebscraping,
    {error: isRequesWebscrapingError, loading: isRequesWebscrapingLoading},
  ] = useMutation(EDIT_EVENT, {
    onCompleted: () => {
      setIsSuccess(true)
      setRequestWebScrapingModal(false)
    },
    onError: e => {
      setIsError(true)
      console.error(e)
    },
  })
  useEffect(() => {
    let timer
    if (isCancel) {
      timer = setTimeout(() => setIsCancel(false), 5000)
    }
    return () => clearTimeout(timer)
  }, [isCancel, setIsCancel])
  const handleCancelRequest = useCallback(() => {
    requestWebscraping({
      variables: {
        editionUuid,
        webscrapingRequest: false,
      },
      refetchQueries: [{query: GET_EVENT_EDITION, variables: {editionUuid}}],
    })
    setIsCancel(true)
  }, [editionUuid, requestWebscraping])
  return {
    handleCancelRequest,
    isCancel,
    isError,
    isEventsTable,
    isLoggedUserWhoRequested,
    isRequesWebscrapingError,
    isRequesWebscrapingLoading,
    isSuccess,
    requestWebscraping,
    requestWebScrapingModal,
    setIsError,
    setIsSuccess,
    setRequestWebScrapingModal,
  }
}

export default useRequestWebScraping
