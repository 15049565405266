import {CustomSort} from 'components/table'
import CompaniesCell from './cells'

const categoriesColumns = [
  {
    Header: 'Category',
    accessor: 'category',
    sortType: CustomSort,
  },
  {
    Header: 'Count',
    accessor: 'count',
  },
  {
    Header: 'Companies',
    accessor: row => row.organizations.map(company => company.name).join(' '),
    Cell: CompaniesCell,
    sortType: CustomSort,
  },
]

export default categoriesColumns
