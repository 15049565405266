import {memo} from 'react'
import {arrayOf, bool, func, number, shape, string} from 'prop-types'
import {
  Box,
  Button,
  CancelButton,
  ErrorMessage,
  Flex,
  Label,
  Loader,
  MentionInput,
} from 'components'
import {CreateNewListButton, MyListsFilter, MyListsList} from './components'
import useSaveToListForm from './hooks'

const SaveToListForm = ({
  commentText,
  companiesToSave,
  inMyActionlist,
  logoUrl,
  orgName,
  orgUuid,
  setCommentText,
  setCompaniesSavedOrRemoved,
  setCompanySavedOrRemoved,
  setCompanySavedOrRemovedLogoUrl,
  setCreateNewListModal,
  setIsRemoveError,
  setIsRemoveSuccess,
  setIsSaveError,
  setIsSaveSuccess,
  setSaveToListModal,
  setSelectedListsNamesToRemove,
  setSelectedListsNamesToSave,
  totalCount,
}) => {
  const {
    commentTextRef,
    filteredData,
    handleSaveToListOrRemoveFromList,
    handleSubmit,
    initialSelectedListNames,
    initialSelectedListUuid,
    isActionListSelected,
    isEveryCompanyInMyActionList,
    isFiltered,
    isMyListsQueryError,
    isMyListsQueryLoading,
    isSaveOrRemoveLoading,
    isSomeOrgInMyActionList,
    myLists,
    setFilteredData,
    setInitialSelectedListNames,
    setInitialSelectedListUuid,
    setIsActionListSelected,
    setIsFiltered,
    setSelectedListsUuidsToRemove,
    setSelectedListsUuidsToSave,
  } = useSaveToListForm({
    commentText,
    companiesToSave,
    inMyActionlist,
    logoUrl,
    orgName,
    orgUuid,
    setCommentText,
    setCompaniesSavedOrRemoved,
    setCompanySavedOrRemoved,
    setCompanySavedOrRemovedLogoUrl,
    setIsRemoveError,
    setIsRemoveSuccess,
    setIsSaveError,
    setIsSaveSuccess,
    setSaveToListModal,
    totalCount,
  })
  return (
    <Flex
      as="form"
      css={{minWidth: '280px'}}
      direction="column"
      onSubmit={handleSubmit(handleSaveToListOrRemoveFromList)}
    >
      <Box
        css={{
          mb: '$24',
          '@initial': {w: '311px'},
          '@bp0': {w: '350px'},
          '@bp1': {w: '638px'},
          '@bp2': {w: '704px'},
          '@bp3': {w: '560px'},
          '@landscape678': {w: '603px'},
          '@landscape678plus': {w: '672px'},
          '@landscapeX': {w: '748px'},
        }}
      >
        <Label htmlFor="commentText">Comment</Label>
        <MentionInput
          comment={commentText}
          inputRef={commentTextRef}
          name="commentText"
          onChange={e => setCommentText(e.target.value)}
          onKeyDown={({key}) => key === 'Escape' && setSaveToListModal(false)}
        />
      </Box>
      {isMyListsQueryError && (
        <ErrorMessage css={{mb: '$16'}}>
          An error occurred getting my lists data. Please try to refresh or
          report this bug.
        </ErrorMessage>
      )}
      {isMyListsQueryLoading && (
        <Loader size="20" css={{mb: '$8'}} data-testid="list-loader" />
      )}
      {myLists?.length > -1 && myLists?.length <= 7 && (
        <MyListsList
          companiesToSave={companiesToSave}
          data={myLists}
          initialSelectedListNames={initialSelectedListNames}
          initialSelectedListUuid={initialSelectedListUuid}
          inMyActionlist={inMyActionlist}
          isActionListSelected={isActionListSelected}
          isEveryCompanyInMyActionList={isEveryCompanyInMyActionList}
          isSomeOrgInMyActionList={isSomeOrgInMyActionList}
          orgUuid={orgUuid}
          setInitialSelectedListNames={setInitialSelectedListNames}
          setInitialSelectedListUuid={setInitialSelectedListUuid}
          setIsActionListSelected={setIsActionListSelected}
          setSelectedListsNamesToRemove={setSelectedListsNamesToRemove}
          setSelectedListsNamesToSave={setSelectedListsNamesToSave}
          setSelectedListsUuidsToRemove={setSelectedListsUuidsToRemove}
          setSelectedListsUuidsToSave={setSelectedListsUuidsToSave}
        />
      )}
      {myLists?.length > 7 && (
        <>
          <MyListsFilter
            data={myLists}
            setIsFiltered={setIsFiltered}
            setFilteredData={setFilteredData}
          />
          <MyListsList
            companiesToSave={companiesToSave}
            data={isFiltered ? filteredData : myLists}
            initialSelectedListNames={initialSelectedListNames}
            initialSelectedListUuid={initialSelectedListUuid}
            inMyActionlist={inMyActionlist}
            isActionListSelected={isActionListSelected}
            isEveryCompanyInMyActionList={isEveryCompanyInMyActionList}
            isSomeOrgInMyActionList={isSomeOrgInMyActionList}
            orgUuid={orgUuid}
            setInitialSelectedListNames={setInitialSelectedListNames}
            setInitialSelectedListUuid={setInitialSelectedListUuid}
            setIsActionListSelected={setIsActionListSelected}
            setSelectedListsNamesToRemove={setSelectedListsNamesToRemove}
            setSelectedListsNamesToSave={setSelectedListsNamesToSave}
            setSelectedListsUuidsToRemove={setSelectedListsUuidsToRemove}
            setSelectedListsUuidsToSave={setSelectedListsUuidsToSave}
          />
        </>
      )}
      <CreateNewListButton
        setCreateNewListModal={setCreateNewListModal}
        setSaveToListModal={setSaveToListModal}
      />
      <Flex css={{as: 'flex-end', gap: '$16', mt: '$24'}}>
        <CancelButton onClick={() => setSaveToListModal(false)}>
          Cancel
        </CancelButton>
        <Flex as={Button} data-testid="save-btn" type="submit">
          Save
          {isSaveOrRemoveLoading && (
            <Loader size="14" css={{ml: '$8'}} data-testid="loader" />
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

SaveToListForm.propTypes = {
  commentText: string.isRequired,
  companiesToSave: arrayOf(shape({})).isRequired,
  inMyActionlist: bool.isRequired,
  logoUrl: string.isRequired,
  orgName: string.isRequired,
  orgUuid: string.isRequired,
  setCommentText: func.isRequired,
  setCompaniesSavedOrRemoved: func.isRequired,
  setCompanySavedOrRemoved: func.isRequired,
  setCompanySavedOrRemovedLogoUrl: func.isRequired,
  setCreateNewListModal: func.isRequired,
  setIsRemoveError: func.isRequired,
  setIsRemoveSuccess: func.isRequired,
  setIsSaveError: func.isRequired,
  setIsSaveSuccess: func.isRequired,
  setSaveToListModal: func.isRequired,
  setSelectedListsNamesToRemove: func.isRequired,
  setSelectedListsNamesToSave: func.isRequired,
  totalCount: number.isRequired,
}

export default memo(SaveToListForm)
