import {useState} from 'react'
import {node} from 'prop-types'
import {useHistory} from 'react-router-dom'
import {Flex, Separator} from 'components'
import {useKeyShortcut, useKeyShortcutCtrlAlt} from 'hooks'
import {Menu, Header} from './components'
import {Box, MainContainer} from './styles'

const AppShell = ({children}) => {
  const [extendMenu, setExtendMenu] = useState(false)
  const {goBack, push} = useHistory()
  useKeyShortcutCtrlAlt('1', () => push('/dashboard'))
  useKeyShortcutCtrlAlt('2', () => push('/companies'))
  useKeyShortcutCtrlAlt('3', () => push('/people'))
  useKeyShortcutCtrlAlt('4', () => push('/investors'))
  useKeyShortcutCtrlAlt('6', () => push('/events'))
  useKeyShortcutCtrlAlt('7', () => push('/my-lists'))
  useKeyShortcutCtrlAlt('8', () => push('/shortcuts'))
  useKeyShortcutCtrlAlt('9', () => push('/settings'))
  useKeyShortcutCtrlAlt('0', () => push('/logout'))
  useKeyShortcut('\\', () => goBack())
  return (
    <>
      <Menu extendMenu={extendMenu} setExtendMenu={setExtendMenu} />
      <Flex as="main" direction="column" css={{m: 0}}>
        <MainContainer
          extended={extendMenu ? 'yes' : 'no'}
          data-testid="main-container"
        >
          <Box variant="app-shell-header">
            <Header extendMenu={extendMenu} />
            <Separator css={{d: 'none', '@bp3': {d: 'block'}}} />
          </Box>
          <Box variant="app-shell-content">{children}</Box>
        </MainContainer>
      </Flex>
    </>
  )
}

AppShell.propTypes = {
  children: node.isRequired,
}

export default AppShell
