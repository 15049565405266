import {useState} from 'react'
import {arrayOf, bool, func, shape, string} from 'prop-types'
import {
  Box,
  CloseModalButton,
  Grid,
  Modal,
  Text,
  UserFeedback,
} from 'components'
import ReviewForm from './components'

const ReviewModal = ({
  companiesToReview,
  companyToReview,
  modal,
  reviewFrom,
  setFocusNextItem,
  setIsReviewLoading,
  setModal,
}) => {
  const [isError, setIsError] = useState(false)
  const finalCompaniesToReview =
    companiesToReview && companiesToReview.length
      ? companiesToReview
      : companyToReview
  if (isError)
    return (
      <UserFeedback isError={isError} setIsError={setIsError}>
        An error occurred. Please try again or report this bug.
      </UserFeedback>
    )
  return (
    <Modal modal={modal} setModal={setModal} reviewFrom={reviewFrom}>
      <CloseModalButton setModal={setModal} />
      <Box>
        {companiesToReview?.length ? (
          <>
            <Text variant="modal-title">Are these companies nautable?</Text>
            <Grid
              as="ul"
              css={{
                gtc: 'auto auto auto',
                gridGap: '$8',
                mb: '$16',
              }}
            >
              {companiesToReview.map(({orgUuid, name}) => (
                <Box as="li" key={orgUuid}>
                  <Text variant="selected-companies">{name}</Text>
                </Box>
              ))}
            </Grid>
          </>
        ) : (
          <Text variant="modal-title">
            Is {companyToReview?.length && companyToReview[0].name} nautable?
          </Text>
        )}
        <ReviewForm
          finalCompaniesToReview={finalCompaniesToReview}
          reviewFrom={reviewFrom}
          setFocusNextItem={setFocusNextItem}
          setIsError={setIsError}
          setIsReviewLoading={setIsReviewLoading}
          setModal={setModal}
        />
      </Box>
    </Modal>
  )
}

ReviewModal.propTypes = {
  companiesToReview: arrayOf(shape({})).isRequired,
  companyToReview: arrayOf(shape({})).isRequired,
  modal: bool.isRequired,
  reviewFrom: string.isRequired,
  setFocusNextItem: func.isRequired,
  setIsReviewLoading: func.isRequired,
  setModal: func.isRequired,
}

export default ReviewModal
