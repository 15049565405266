import {memo} from 'react'
import {string} from 'prop-types'
import {Text} from 'components'

const Description = ({description}) => (
  <Text css={{mt: '$8', color: '$grey500', fontSize: '$14', lh: '$body'}}>
    {description}
  </Text>
)

Description.propTypes = {
  description: string.isRequired,
}

export default memo(Description)
