import {ArrowDownIcon} from 'assets'
import {Icon} from 'components'

const ArrowDown = () => (
  <Icon color="grey300" size="17">
    <ArrowDownIcon data-testid="icon" />
  </Icon>
)

export default ArrowDown
