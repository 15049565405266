import {Text as BaseText} from 'components/primitives'
import {Flex as BaseFlex} from 'components/layout'
import {styled} from 'styles'

export const Text = styled(BaseText, {
  variants: {
    variant: {
      'header-menu': {
        d: 'inline',
        fontSize: '$14',
        ml: '$16',
      },
    },
  },
})

export const Flex = styled(BaseFlex, {
  variants: {
    variant: {
      'user-menu-item': {
        '&:first-of-type': {
          mb: '0.25rem',
        },
        '& > .nav-link': {
          ai: 'center',
          br: '$pill',
          color: '$grey200',
          d: 'flex',
          h: '$full',
          p: '$16',
          txtd: 'none',
          w: '168px',
          '&:hover': {
            bgc: '$primaryLight',
            color: '$primary',
            transition: 'all 0.25s ease-in',
          },
        },
      },
    },
  },
})
