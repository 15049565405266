import {useState} from 'react'
import {arrayOf, bool, func, shape, string} from 'prop-types'
import {Modal, CloseModalButton, Text, UserFeedback} from 'components'
import EditEventForm from './components'

const EditEventModal = ({
  allUsers,
  city,
  countryCode,
  description,
  editionUuid,
  endsOn,
  linkedinUrl,
  modal,
  name,
  onlineEvent,
  recurringEvent,
  scope,
  setModal,
  startsOn,
  ticketPrice,
  usersAttending,
  website,
}) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  if (isSuccess)
    return (
      <UserFeedback isSuccess={isSuccess} setIsSuccess={setIsSuccess}>
        Event edited successfully
      </UserFeedback>
    )
  if (isError)
    return (
      <UserFeedback isError={isError} setIsError={setIsError}>
        Error editing event. Please try again or report this bug.
      </UserFeedback>
    )
  return (
    <Modal modal={modal} setModal={setModal}>
      <CloseModalButton setModal={setModal} />
      <Text variant="modal-title">Edit event</Text>
      <EditEventForm
        allUsers={allUsers}
        city={city}
        countryCode={countryCode}
        description={description}
        editionUuid={editionUuid}
        endsOn={endsOn}
        linkedinUrl={linkedinUrl}
        name={name}
        onlineEvent={onlineEvent}
        recurringEvent={recurringEvent}
        scope={scope}
        setIsError={setIsError}
        setIsSuccess={setIsSuccess}
        setModal={setModal}
        startsOn={startsOn}
        ticketPrice={ticketPrice}
        usersAttending={usersAttending}
        website={website}
      />
    </Modal>
  )
}

EditEventModal.propTypes = {
  allUsers: arrayOf(shape({})).isRequired,
  city: string.isRequired,
  countryCode: string.isRequired,
  description: string.isRequired,
  editionUuid: string.isRequired,
  endsOn: string.isRequired,
  linkedinUrl: string.isRequired,
  modal: bool.isRequired,
  name: string.isRequired,
  onlineEvent: bool.isRequired,
  recurringEvent: bool.isRequired,
  scope: string.isRequired,
  setModal: func.isRequired,
  startsOn: string.isRequired,
  ticketPrice: string.isRequired,
  usersAttending: string.isRequired,
  website: string.isRequired,
}

export default EditEventModal
