import {Text as BaseText} from 'components/primitives'
import {styled} from 'styles'

const Text = styled(BaseText, {
  variants: {
    variant: {
      'search-total-hits': {
        color: '$grey300',
        d: 'inline',
        fontSize: '$16',
        fontWeight: '$300',
      },
    },
  },
})

export default Text
