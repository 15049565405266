import {forwardRef} from 'react'
import {renderToStaticMarkup} from 'react-dom/server'
import {node, string} from 'prop-types'
import {SelectArrowIcon} from 'assets'
import {Box} from 'components'
import {useDeviceDetect} from 'hooks'
import {styled} from 'styles'

const svgString = encodeURIComponent(renderToStaticMarkup(<SelectArrowIcon />))

const SelectStyled = styled('select', {
  appearance: 'none',
  b: 'none',
  background: `no-repeat right url("data:image/svg+xml,${svgString}")`,
  color: '$grey300',
  fontSize: '$13',
  outline: 'none',
  pr: '$16',
  textOverflow: 'ellipsis',
  w: 'fill-available',
  '& > option': {
    color: '$black',
  },
})

const Select = forwardRef((props, ref) => {
  const {isMoz, isWebKit} = useDeviceDetect()
  return (
    <Box
      css={{
        b: '1px solid $grey100',
        br: '$small',
        p: '7px',
        minWidth: 'max-content',
        '&:focus-within': {
          boc: '$grey300',
        },
        borderColor: props.bordercolor === 'red' && '$red',
      }}
      data-testid="select-container"
    >
      <SelectStyled
        ref={ref}
        {...props}
        css={{
          w:
            (isMoz && '-moz-available') ||
            (isWebKit && '-webkit-fill-available') ||
            '$full',
        }}
      >
        {props.children}
      </SelectStyled>
    </Box>
  )
})

Select.propTypes = {
  children: node.isRequired,
  bordercolor: string.isRequired,
}

export default Select
