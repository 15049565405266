import {memo, useState} from 'react'
import {arrayOf, shape, string} from 'prop-types'
import {useLocation} from 'react-router-dom'
import {FallbackImgPersonSm} from 'assets'
import {Box, Flex, Image, Link, SocialLinksList, Text} from 'components'
import {LabelList} from 'components/patterns/Labels/components'
import {getName} from 'utils'

const PersonNameCell = ({
  row: {
    original: {
      allLabels,
      cbUrl,
      facebookUrl,
      firstName,
      labels,
      lastName,
      linkedinUrl,
      logoUrl,
      name,
      permalink,
      twitterUrl,
      uuid,
    },
  },
}) => {
  const {pathname} = useLocation()
  const [isImageLoadError, setIsImageLoadError] = useState(false)
  const personName = name || getName(firstName, lastName)
  return (
    <Flex css={{mr: '$8'}}>
      <Image
        alt={name}
        onError={() => setIsImageLoadError(true)}
        size="32"
        src={
          isImageLoadError
            ? FallbackImgPersonSm
            : logoUrl || FallbackImgPersonSm
        }
        variant="company"
      />
      <Flex direction="column" css={{ml: '$8', w: '-webkit-fill-available'}}>
        {permalink && uuid && personName ? (
          <Link
            css={{mb: '$4'}}
            external
            href={`/people/${permalink}?uuid=${uuid}`}
            title={`${personName} profile`}
          >
            <Text variant="name-cell" css={{whiteSpace: 'nowrap'}}>
              {personName}
            </Text>
          </Link>
        ) : (
          personName && (
            <Text variant="name-cell" css={{whiteSpace: 'nowrap', mb: '$8'}}>
              {personName}
            </Text>
          )
        )}
        {labels?.length > 0 && pathname === '/people' ? (
          <Box css={{mb: '$8'}}>
            <LabelList allLabels={allLabels} labels={labels} />
          </Box>
        ) : null}
        {(cbUrl || linkedinUrl || twitterUrl || facebookUrl) && (
          <SocialLinksList
            cbUrl={cbUrl}
            facebookUrl={facebookUrl}
            linkedinUrl={linkedinUrl}
            name={personName}
            twitterUrl={twitterUrl}
          />
        )}
      </Flex>
    </Flex>
  )
}

PersonNameCell.propTypes = {
  row: shape({
    original: shape({
      allLabels: arrayOf(shape({})),
      cbUrl: string,
      facebookUrl: string,
      linkedinUrl: string,
      logoUrl: string,
      name: string,
      permalink: string,
      twitterUrl: string,
      uuid: string,
    }),
  }).isRequired,
}

export default memo(PersonNameCell)
