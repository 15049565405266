import {memo} from 'react'
import {arrayOf, bool, func, node, shape, string} from 'prop-types'
import {MultiSelect} from 'react-multi-select-component'
import {ArrowDown, ArrowUp, ClearSelectedIcon} from './components'
import useFilter from './hooks'
import {SelectStyles, Text} from './styles'

const Filter = ({children, disableSearch, onChange, options, value}) => {
  const {isCloseToBottomCheck, selectRef, setDropdownHeight} = useFilter()
  return (
    <SelectStyles
      data-testid="filter"
      css={{
        '& > .rmsc .dropdown-content': isCloseToBottomCheck && {
          top: 'auto',
          bottom: '100%',
          pt: 0,
          pb: '$32',
        },
      }}
      onClick={() => {
        if (selectRef?.current) {
          setTimeout(() => {
            const element = selectRef.current?.querySelector(
              '.dropdown-content',
            )
            if (element) {
              setDropdownHeight(element.offsetHeight)
            }
          }, 100)
        }
      }}
      ref={selectRef}
    >
      <Text variant="filter">{children}</Text>
      <MultiSelect
        ArrowRenderer={({expanded}) => (expanded ? <ArrowUp /> : <ArrowDown />)}
        ClearIcon={<ClearSelectedIcon />}
        ClearSelectedIcon={<ClearSelectedIcon />}
        disableSearch={disableSearch}
        hasSelectAll={false}
        labelledBy="Select"
        onChange={onChange}
        options={options}
        value={value}
      />
    </SelectStyles>
  )
}

Filter.propTypes = {
  children: node.isRequired,
  disableSearch: bool.isRequired,
  onChange: func.isRequired,
  options: arrayOf(shape({})).isRequired,
  value: string.isRequired,
}

export default memo(Filter)
