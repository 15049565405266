import {useEffect, useMemo, useState, useRef} from 'react'
import {useMutation, useQuery} from '@apollo/client'
import {useLocation} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import {
  REMOVE_COMPANY_FROM_LIST,
  REMOVE_FROM_ACTION_LIST,
  SAVE_COMPANY_TO_LIST,
  SAVE_TO_ACTION_LIST,
} from 'constants/mutations'
import {
  GET_MY_LIST_COMPANIES_LIST_PAGE_INFO_OWNER_OR_COLLABORATOR,
  GET_MY_LISTS_RELATED,
  GET_COMPANY_IN_LISTS,
} from 'constants/queries'
import {useRouterQuery} from 'hooks'
import {getMentionedUserIds} from 'utils'
import {
  GET_ACTION_LIST_PAGE_COUNT_UPDATE_DATE,
  GET_MY_LIST_COMPANIES_LIST_COMPANIES_SAVED_OR_REMOVED,
  GET_MY_LISTS_SAVE_FORM,
  GET_SUMMARY_IN_MY_LISTS,
} from './queries'
import formatMyListsData from './utils'

const useSaveToListForm = ({
  commentText,
  companiesToSave,
  inMyActionlist,
  logoUrl,
  orgName,
  orgUuid,
  setCommentText,
  setCompaniesSavedOrRemoved,
  setCompanySavedOrRemoved,
  setCompanySavedOrRemovedLogoUrl,
  setIsRemoveError,
  setIsRemoveSuccess,
  setIsSaveError,
  setIsSaveSuccess,
  setSaveToListModal,
  totalCount,
}) => {
  const [filteredData, setFilteredData] = useState([])
  const [isFiltered, setIsFiltered] = useState(false)
  const [isActionListSelected, setIsActionListSelected] = useState(false)
  const [selectedListsUuidsToSave, setSelectedListsUuidsToSave] = useState([])
  const [selectedListsUuidsToRemove, setSelectedListsUuidsToRemove] = useState(
    [],
  )
  const [initialSelectedListUuid, setInitialSelectedListUuid] = useState([])
  const [initialSelectedListNames, setInitialSelectedListNames] = useState([])
  const commentTextRef = useRef(null)
  const {handleSubmit} = useForm({mode: 'onBlur'})
  const {pathname} = useLocation()
  const routerQuery = useRouterQuery()
  const reviewed = routerQuery.get('reviewed') === 'true'
  const companiesOrgUuids =
    companiesToSave?.length > 0 &&
    companiesToSave.map(company => company.orgUuid).join(',')
  const {
    data: myListsData,
    error: isMyListsQueryError,
    loading: isMyListsQueryLoading,
  } = useQuery(GET_MY_LISTS_SAVE_FORM, {
    fetchPolicy: 'network-only',
    variables:
      companiesToSave?.length > 0 ? {orgUuid: companiesOrgUuids} : {orgUuid},
    onError: error => console.error(error),
  })
  const [saveToList, {loading: isSaveToListLoading}] = useMutation(
    SAVE_COMPANY_TO_LIST,
    {
      onError: err => {
        setIsSaveError(true)
        console.error(err)
      },
      onCompleted: () => setIsSaveSuccess(true),
    },
  )
  const [removeFromList, {loading: isRemoveFromListLoading}] = useMutation(
    REMOVE_COMPANY_FROM_LIST,
    {
      onError: err => {
        setIsRemoveError(true)
        console.error(err)
      },
      onCompleted: () => setIsRemoveSuccess(true),
    },
  )
  const [saveToActionList, {loading: isSaveToActionListLoading}] = useMutation(
    SAVE_TO_ACTION_LIST,
    {
      onError: err => {
        setIsSaveError(true)
        console.error(err)
      },
      onCompleted: () => setIsSaveSuccess(true),
    },
  )
  const [
    removeFromActionList,
    {loading: isRemoveFromActionListLoading},
  ] = useMutation(REMOVE_FROM_ACTION_LIST, {
    onError: err => {
      setIsRemoveError(true)
      console.error(err)
    },
    onCompleted: () => setIsRemoveSuccess(true),
  })
  const myLists = useMemo(
    () => myListsData && formatMyListsData(myListsData.customListSaveForm),
    [myListsData],
  )
  const isEveryCompanyInMyActionList = useMemo(
    () =>
      companiesToSave &&
      companiesToSave.every(company => company.inMyActionlist),
    [companiesToSave],
  )
  const isSomeOrgInMyActionList = useMemo(
    () =>
      myListsData?.customListSaveForm?.length > 0 &&
      myListsData.customListSaveForm[0].isSomeOrgInMyActionList,
    [myListsData],
  )
  useEffect(() => {
    if (commentTextRef?.current) {
      commentTextRef.current.focus()
    }
  }, [])
  useEffect(() => {
    if (inMyActionlist) setIsActionListSelected(inMyActionlist)
  }, [inMyActionlist, setIsActionListSelected])
  useEffect(() => {
    return () => setCommentText('')
  }, [setCommentText])
  const handleSaveToListOrRemoveFromList = () => {
    // Save selected companies to Action List (if not in action list already)
    if (
      isActionListSelected &&
      (!isSomeOrgInMyActionList || !isEveryCompanyInMyActionList) &&
      companiesToSave?.length > 0
    ) {
      setCompaniesSavedOrRemoved(
        companiesToSave.filter(company => !company.inMyActionlist),
      )
      companiesToSave
        .filter(company => !company.inMyActionlist)
        .map(company =>
          saveToActionList({
            variables: {
              commentText,
              mentionedUserIds: getMentionedUserIds(commentText),
              uuid: company.orgUuid,
            },
            refetchQueries: [
              {query: GET_COMPANY_IN_LISTS, variables: {uuid: company.orgUuid}},
            ],
          }),
        )
    }
    // Save individual company to Action List
    if (
      isActionListSelected &&
      !inMyActionlist &&
      orgUuid &&
      !companiesToSave
    ) {
      setCompanySavedOrRemoved(orgName)
      setCompanySavedOrRemovedLogoUrl(logoUrl)
      saveToActionList({
        variables: {
          commentText,
          mentionedUserIds: getMentionedUserIds(commentText),
          uuid: orgUuid,
        },
        refetchQueries: [
          {query: GET_COMPANY_IN_LISTS, variables: {uuid: orgUuid}},
        ],
      })
    }
    // Remove selected companies from Action List
    if (
      !isActionListSelected &&
      (isSomeOrgInMyActionList || isEveryCompanyInMyActionList) &&
      companiesToSave?.length > 0
    ) {
      setCompaniesSavedOrRemoved(
        companiesToSave.filter(company => company.inMyActionlist),
      )
      companiesToSave
        .filter(company => company.inMyActionlist)
        .map(company =>
          removeFromActionList({
            variables: {uuid: company.orgUuid},
            refetchQueries: (pathname === '/action-list' && [
              {
                query: GET_ACTION_LIST_PAGE_COUNT_UPDATE_DATE,
                variables: reviewed && {reviewed},
              },
            ]) || [
              {query: GET_COMPANY_IN_LISTS, variables: {uuid: company.orgUuid}},
            ],
          }),
        )
    }
    // Remove individual company from Action List
    if (
      !isActionListSelected &&
      inMyActionlist &&
      orgUuid &&
      !companiesToSave
    ) {
      setCompanySavedOrRemoved(orgName)
      setCompanySavedOrRemovedLogoUrl(logoUrl)
      removeFromActionList({
        variables: {uuid: orgUuid},
        refetchQueries: (pathname === '/action-list' && [
          {
            query: GET_ACTION_LIST_PAGE_COUNT_UPDATE_DATE,
            variables: reviewed && {reviewed},
          },
        ]) || [{query: GET_COMPANY_IN_LISTS, variables: {uuid: orgUuid}}],
      })
    }
    // Save selected companies to selected lists
    if (companiesToSave?.length > 0 && selectedListsUuidsToSave?.length > 0) {
      setCompaniesSavedOrRemoved(companiesToSave)
      selectedListsUuidsToSave.map(listUuid =>
        companiesToSave.map(company =>
          saveToList({
            variables: {
              commentText,
              listUuid,
              mentionedUserIds: getMentionedUserIds(commentText),
              orgUuid: company.orgUuid,
            },
            refetchQueries: [
              {query: GET_COMPANY_IN_LISTS, variables: {uuid: company.orgUuid}},
            ],
          }),
        ),
      )
    }
    // Save individual company to selected lists
    if (!companiesToSave && orgUuid && selectedListsUuidsToSave?.length > 0) {
      setCompanySavedOrRemoved(orgName)
      setCompanySavedOrRemovedLogoUrl(logoUrl)
      selectedListsUuidsToSave.map(listUuid =>
        saveToList({
          variables: {
            commentText,
            listUuid,
            mentionedUserIds: getMentionedUserIds(commentText),
            orgUuid,
          },
          refetchQueries: (pathname.startsWith('/companies/') && [
            {query: GET_SUMMARY_IN_MY_LISTS, variables: {uuid: orgUuid}},
            {query: GET_MY_LISTS_RELATED, variables: {orgUuid}},
          ]) || [{query: GET_COMPANY_IN_LISTS, variables: {uuid: orgUuid}}],
        }),
      )
    }
    // Remove selected companies from selected lists
    if (companiesToSave?.length > 0 && selectedListsUuidsToRemove?.length > 0) {
      setCompaniesSavedOrRemoved(companiesToSave)
      selectedListsUuidsToRemove.map(listUuid =>
        companiesToSave.map(company => {
          if (company.inMyList) {
            removeFromList({
              variables: {listUuid, orgUuid: company.orgUuid},
              refetchQueries:
                (pathname.startsWith('/my-lists/') && [
                  {
                    query: GET_MY_LIST_COMPANIES_LIST_COMPANIES_SAVED_OR_REMOVED,
                    variables: reviewed
                      ? {
                          listUuid,
                          reviewed,
                          page: 1,
                          pageSize: totalCount > 100 ? 999999 : 100,
                        }
                      : {
                          listUuid,
                          page: 1,
                          pageSize: totalCount > 100 ? 999999 : 100,
                        },
                  },
                  {
                    query: GET_MY_LIST_COMPANIES_LIST_PAGE_INFO_OWNER_OR_COLLABORATOR,
                    variables: {uuid: listUuid},
                  },
                ]) ||
                ((pathname.startsWith('/list/') ||
                  pathname === '/companies') && [
                  {
                    query: GET_COMPANY_IN_LISTS,
                    variables: {uuid: company.orgUuid},
                  },
                ]),
            })
          }
          return null
        }),
      )
    }
    // Remove individual company from selected lists
    if (!companiesToSave && orgUuid && selectedListsUuidsToRemove?.length > 0) {
      setCompanySavedOrRemoved(orgName)
      setCompanySavedOrRemovedLogoUrl(logoUrl)
      selectedListsUuidsToRemove.map(listUuid =>
        removeFromList({
          variables: {listUuid, orgUuid},
          refetchQueries:
            (pathname.startsWith('/companies/') && [
              {query: GET_SUMMARY_IN_MY_LISTS, variables: {uuid: orgUuid}},
              {query: GET_MY_LISTS_RELATED, variables: {orgUuid}},
            ]) ||
            ((pathname === '/companies' ||
              pathname.startsWith('/list/') ||
              pathname === '/vc-tracker') && [
              {query: GET_COMPANY_IN_LISTS, variables: {uuid: orgUuid}},
            ]) ||
            (pathname.startsWith('/my-lists/') && [
              {
                query: GET_MY_LIST_COMPANIES_LIST_COMPANIES_SAVED_OR_REMOVED,
                variables: reviewed
                  ? {
                      listUuid,
                      reviewed,
                      page: 1,
                      pageSize: totalCount > 100 ? 999999 : 100,
                    }
                  : {
                      listUuid,
                      page: 1,
                      pageSize: totalCount > 100 ? 999999 : 100,
                    },
              },
              {
                query: GET_MY_LIST_COMPANIES_LIST_PAGE_INFO_OWNER_OR_COLLABORATOR,
                variables: {uuid: listUuid},
              },
            ]),
        }),
      )
    }
    setSaveToListModal(false)
  }
  const isSaveOrRemoveLoading =
    isSaveToListLoading ||
    isRemoveFromListLoading ||
    isSaveToActionListLoading ||
    isRemoveFromActionListLoading
  return {
    commentTextRef,
    filteredData,
    handleSaveToListOrRemoveFromList,
    handleSubmit,
    initialSelectedListNames,
    initialSelectedListUuid,
    isActionListSelected,
    isEveryCompanyInMyActionList,
    isFiltered,
    isMyListsQueryError,
    isMyListsQueryLoading,
    isSaveOrRemoveLoading,
    isSomeOrgInMyActionList,
    myLists,
    setFilteredData,
    setInitialSelectedListNames,
    setInitialSelectedListUuid,
    setIsActionListSelected,
    setIsFiltered,
    setSelectedListsUuidsToRemove,
    setSelectedListsUuidsToSave,
  }
}

export default useSaveToListForm
