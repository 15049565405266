export const formatLastReview = lastReview =>
  lastReview
    ? (lastReview.nautable === 'YES' && 'Yes') ||
      (lastReview.nautable === 'NO' && 'No') ||
      (lastReview.nautable === 'NOT_SURE' && 'Not Sure')
    : 'Not Rev'

export const formatNotNautableReason = reason =>
  (reason === 'not_in_nauta_scope' && 'not in Nauta scope') ||
  reason.split('_').join(' ')
