import {useEffect, useRef, useState} from 'react'
import {useMutation} from '@apollo/client'
import camelcaseKeys from 'camelcase-keys'
import {useForm} from 'react-hook-form'
import ADD_TO_PIPEDRIVE from './mutations'
import {GET_COMPANY_PROFILE_PIPEDRIVE, GET_COMPANY_PIPEDRIVE} from './queries'

const useAddToPipedriveForm = ({
  addFrom,
  city,
  country,
  diversityFounders,
  foundedOnYear,
  funding,
  homepageUrl,
  name,
  orgUuid,
  setIsSuccess,
  setModal,
  shortDescription,
}) => {
  const [errorMsg, setErrorMsg] = useState('')
  const [pipedriveUrl, setPipedriveUrl] = useState('')
  const companyNameRef = useRef(null)
  const initialValues = {
    channel: '',
    channelDetail: '',
    companyCity: city,
    contactPersonUuid: '',
    companyCountry: country,
    currency: 'usd',
    description: shortDescription,
    diversityFounders:
      (diversityFounders === true && 'true') ||
      (diversityFounders === false && 'false') ||
      (diversityFounders === 'not sure' && 'not sure') ||
      diversityFounders,
    companyFoundedOnYear: foundedOnYear !== 'unk' ? foundedOnYear : '',
    fundingStage: '',
    fundingTotalUsd: funding,
    organizationName: name,
    dealName: name,
    sector: '',
    source: '',
    website: homepageUrl,
  }

  const {register, handleSubmit, errors, watch, setValue} = useForm({
    mode: 'onBlur',
  })
  const watchChannel = watch('channel')
  useEffect(() => {
    if (
      watchChannel === 'nautadb_lists' ||
      watchChannel === 'thesis_research'
    ) {
      setValue('source', 'outbound')
    } else if (
      watchChannel === 'commutities_and_network_referrals' ||
      watchChannel === 'cold_inbound_message' ||
      watchChannel === 'fundraising_advisors' ||
      watchChannel === 'website_inbound' ||
      watchChannel === 'co-investors_and_follow-on_funds'
    ) {
      setValue('source', 'inbound')
    } else {
      setValue('source', '')
    }
  }, [watchChannel, setValue])

  const [
    addToPipedrive,
    {error: isAddToPipedriveError, loading: isAddToPipedriveLoading},
  ] = useMutation(ADD_TO_PIPEDRIVE, {
    onCompleted: () => {
      setIsSuccess(true)
      setModal(false)
    },
    onError: ({graphQLErrors}) => {
      if (graphQLErrors) {
        const [error] = graphQLErrors?.map(({message}) =>
          camelcaseKeys(JSON.parse(message.replace(/'/g, '"'))),
        )
        setErrorMsg(error?.msg)
        setPipedriveUrl(error?.url)
      }
    },
  })

  useEffect(() => companyNameRef?.current && companyNameRef.current.focus(), [])
  const handleAddToPipedrive = ({
    channel,
    channelDetail,
    companyCity,
    contactPersonUuid,
    companyCountry,
    currency,
    dealName,
    description,
    hasDiverseFounders,
    companyFoundedOnYear,
    fundingStage,
    fundingTotalUsd,
    organizationName,
    sector,
    source,
    website,
  }) => {
    addToPipedrive({
      variables: {
        channel,
        channelDetail,
        city: companyCity,
        contactPersonUuid,
        country: companyCountry,
        currency,
        dealName,
        description,
        diversityFounders: hasDiverseFounders,
        foundedOnYear: companyFoundedOnYear,
        fundingStage,
        fundingTotalUsd,
        homepageUrl: website,
        organizationName,
        sector,
        source,
        uuid: orgUuid,
      },
      refetchQueries: (addFrom === 'company-profile' && [
        {
          query: GET_COMPANY_PROFILE_PIPEDRIVE,
          variables: {uuid: orgUuid},
        },
      ]) || [
        {
          query: GET_COMPANY_PIPEDRIVE,
          variables: {uuid: orgUuid},
        },
      ],
    })
  }
  return {
    companyNameRef,
    errorMsg,
    errors,
    handleAddToPipedrive,
    handleSubmit,
    initialValues,
    isAddToPipedriveError,
    isAddToPipedriveLoading,
    pipedriveUrl,
    register,
  }
}

export default useAddToPipedriveForm
