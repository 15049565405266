import {memo} from 'react'
import {bool, func, number, string} from 'prop-types'
import {useLocation} from 'react-router-dom'
import {Box, Flex, Loader} from 'components'
import {
  GoToPage,
  Nav,
  PageOfTotal,
  ResultsPerPageSelect,
  ShowingResultsCounter,
} from './components'

const Pagination = ({
  allDataLoading,
  canNextPage,
  canPreviousPage,
  gotoPage,
  isFiltered,
  listType,
  nextPage,
  pageCount,
  pageIndex,
  pageLoading,
  pageSize,
  previousPage,
  setPageSize,
  totalCount,
  totalFilteredCount,
}) => {
  const {pathname} = useLocation()
  const pageInView = pageIndex + 1
  return (
    <Box data-testid="pagination">
      <Flex
        direction={{'@initial': 'column', '@bp1': 'row'}}
        css={{jc: 'space-between', mt: '$16'}}
      >
        {pageLoading ? (
          <Loader size="16" css={{ml: 'auto'}} data-testid="loader" />
        ) : (
          <>
            {listType !== 'founders-list' && (
              <ShowingResultsCounter
                allDataLoading={allDataLoading}
                isFiltered={isFiltered}
                listType={listType}
                pageCount={pageCount}
                pageIndex={pageIndex}
                pageSize={pageSize}
                totalCount={totalCount}
                totalFilteredCount={totalFilteredCount}
              />
            )}
            {pathname !== '/dashboard' && pathname !== '/my-lists' && (
              <PageOfTotal
                allDataLoading={allDataLoading}
                currentPage={pageIndex === 0 ? 1 : pageInView}
                totalPages={pageCount}
              />
            )}
          </>
        )}
        {(pathname === '/dashboard' || pathname === '/my-lists') &&
          pageCount !== 1 && (
            <Nav
              canNextPage={canNextPage}
              canPreviousPage={canPreviousPage}
              gotoPage={gotoPage}
              nextPage={nextPage}
              pageCount={pageCount}
              previousPage={previousPage}
            />
          )}
      </Flex>
      {pathname !== '/dashboard' && pathname !== '/my-lists' && pageCount > 1 && (
        <Flex
          direction={{'@initial': 'column', '@bp1': 'row'}}
          css={{
            mt: '$16',
            '@bp1': {jc: 'space-between'},
          }}
        >
          <ResultsPerPageSelect
            listType={listType}
            pageIndex={pageIndex}
            pageSize={pageSize}
            setPageSize={setPageSize}
            totalCount={totalCount}
          />
          {pageCount < 50 && (
            <GoToPage
              gotoPage={gotoPage}
              listType={listType}
              pageCount={pageCount}
              pageIndex={pageIndex}
              pageInView={pageInView}
            />
          )}
          <Nav
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            gotoPage={gotoPage}
            listType={listType}
            nextPage={nextPage}
            pageCount={pageCount}
            pageInView={pageInView}
            previousPage={previousPage}
          />
        </Flex>
      )}
    </Box>
  )
}

Pagination.propTypes = {
  allDataLoading: bool.isRequired,
  canNextPage: bool.isRequired,
  canPreviousPage: bool.isRequired,
  gotoPage: func.isRequired,
  isFiltered: bool.isRequired,
  listType: string.isRequired,
  nextPage: func.isRequired,
  pageCount: number.isRequired,
  pageIndex: number.isRequired,
  pageLoading: bool.isRequired,
  pageSize: number.isRequired,
  previousPage: func.isRequired,
  setPageSize: func.isRequired,
  totalCount: number.isRequired,
  totalFilteredCount: number.isRequired,
}

export default memo(Pagination)
