import {memo} from 'react'
import {arrayOf, bool, func, number, shape, string} from 'prop-types'
import {
  CreateNewListModal,
  OtherReasonModal,
  Pagination,
  ReviewModal,
  UserFeedback,
} from 'components'
import {ShareCompanyModal} from 'components/patterns/ShareCompany/components'
import {SaveToListModal} from 'components/patterns/SaveToList/components'
import {AddToPipedriveModal} from 'components/patterns/AddToPipedrive/components'
import {ShowColumnsModal, Table, TableActions} from './components'
import useCompaniesListTable from './hooks'

const CompaniesListTable = ({
  allDataLoading,
  columns,
  data,
  fundingRoundTypes,
  listType,
  listUuid,
  pageCount: controlledPageCount,
  pageIndex: controlledPageIndex,
  pageSize: controlledPageSize,
  portfolioCategories,
  portfolioLocation,
  reviewed,
  scope,
  setFilteredCompaniesLength,
  setIsFiltered,
  setShowShortcuts,
  showShortcuts,
  totalCount,
  week,
  year,
}) => {
  const {
    addToPipedriveModal,
    allColumns,
    canNextPage,
    canPreviousPage,
    commentText,
    companiesSavedOrRemoved,
    companiesToOpenLinks,
    companiesToReview,
    companiesToSave,
    companiesToShare,
    companyToReview,
    companyToSave,
    companyToShare,
    createNewListModal,
    cursor,
    filters,
    getTableBodyProps,
    getTableProps,
    gotoPage,
    handleAddReview,
    hasHiddenColumns,
    headerGroups,
    isColumnsSavedSuccess,
    isCompaniesPageOrInvestorPage,
    isReviewError,
    isReviewLoading,
    nextPage,
    otherReasonModal,
    page,
    pageCount,
    pageIndex,
    pageSize,
    prepareRow,
    previousPage,
    reviewModal,
    rows,
    rowsRefs,
    saveRemoveListModal,
    selected,
    setAddToPipedriveModal,
    setCommentText,
    setCompaniesSavedOrRemoved,
    setCreateNewListModal,
    setCursor,
    setFocusNextItem,
    setIsColumnsSavedSuccess,
    setIsReviewError,
    setIsReviewLoading,
    setOtherReasonModal,
    setPageSize,
    setReviewModal,
    setSaveRemoveListModal,
    setSelected,
    setShareModal,
    setShowColumnsModal,
    shareModal,
    showColumnsModal,
  } = useCompaniesListTable({
    columns,
    controlledPageCount,
    controlledPageIndex,
    controlledPageSize,
    data,
    listType,
    listUuid,
    scope,
    setFilteredCompaniesLength,
    setIsFiltered,
  })
  return (
    <>
      {listType !== 'my-lists-page' && listType !== 'action-list-my-lists' && (
        <TableActions
          companiesToOpenLinks={companiesToOpenLinks}
          companiesToReviewLength={companiesToReview.length}
          companiesToSaveLength={companiesToSave.length}
          companiesToShareLength={companiesToShare.length}
          hasHiddenColumns={hasHiddenColumns}
          isReviewLoading={isReviewLoading}
          listType={listType}
          listUuid={listUuid}
          reviewed={reviewed}
          scope={scope}
          setReviewModal={setReviewModal}
          setSaveRemoveListModal={setSaveRemoveListModal}
          setShareModal={setShareModal}
          setShowColumnsModal={setShowColumnsModal}
          setShowShortcuts={setShowShortcuts}
          showShortcuts={showShortcuts}
          week={week}
          year={year}
        />
      )}
      <Table
        cursor={cursor}
        fundingRoundTypes={fundingRoundTypes}
        getTableBodyProps={getTableBodyProps}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        isTableReviewLoading={!companiesToReview.length && isReviewLoading}
        listType={listType}
        page={page}
        portfolioCategories={portfolioCategories}
        portfolioLocation={portfolioLocation}
        prepareRow={prepareRow}
        ref={rowsRefs}
        selectedUuid={selected?.original?.uuid}
        setCursor={setCursor}
        setFocusNextItem={setFocusNextItem}
        setSelected={setSelected}
      />
      <Pagination
        allDataLoading={allDataLoading}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
        isFiltered={filters.length > 0}
        listType={listType}
        nextPage={nextPage}
        pageCount={
          isCompaniesPageOrInvestorPage ? controlledPageCount : pageCount
        }
        pageIndex={
          isCompaniesPageOrInvestorPage ? controlledPageIndex : pageIndex
        }
        pageSize={isCompaniesPageOrInvestorPage ? controlledPageSize : pageSize}
        previousPage={previousPage}
        setPageSize={setPageSize}
        totalCount={totalCount}
        totalFilteredCount={rows.length}
      />
      {selected?.original && (
        <AddToPipedriveModal
          addFrom="companies-list"
          city={selected.original.city}
          countryName={selected.original.country}
          foundedYear={selected.original.year}
          funding={selected.original.funding}
          homepageUrl={selected.original.homepageUrl}
          modal={addToPipedriveModal}
          name={selected.original.name}
          orgUuid={selected.original.uuid}
          setModal={setAddToPipedriveModal}
          shortDescription={selected.original.shortDescription}
        />
      )}
      {selected?.original && (
        <SaveToListModal
          commentText={commentText}
          companiesToSave={
            companiesToSave.length > 0 ? companiesToSave : companyToSave
          }
          companiesSavedOrRemoved={companiesSavedOrRemoved}
          logoUrl={selected.original.logoUrl}
          modal={saveRemoveListModal}
          orgName={selected.original.name}
          orgUuid={selected.original.uuid}
          setCommentText={setCommentText}
          setCompaniesSavedOrRemoved={setCompaniesSavedOrRemoved}
          setCreateNewListModal={setCreateNewListModal}
          setModal={setSaveRemoveListModal}
          totalCount={totalCount}
        />
      )}
      <CreateNewListModal
        commentText={commentText}
        companiesSavedOrRemoved={companiesSavedOrRemoved}
        companiesToSave={companiesToSave}
        modal={createNewListModal}
        setCompaniesSavedOrRemoved={setCompaniesSavedOrRemoved}
        setModal={setCreateNewListModal}
      />
      <ShareCompanyModal
        companiesToShare={
          companiesToShare.length > 0 ? companiesToShare : companyToShare
        }
        modal={shareModal}
        setModal={setShareModal}
      />
      <ReviewModal
        companiesToReview={companiesToReview}
        companyToReview={companyToReview}
        modal={reviewModal}
        reviewFrom="companies-list"
        setFocusNextItem={setFocusNextItem}
        setIsReviewLoading={setIsReviewLoading}
        setModal={setReviewModal}
      />
      <ShowColumnsModal
        allColumns={allColumns}
        modal={showColumnsModal}
        setIsColumnsSavedSuccess={setIsColumnsSavedSuccess}
        setModal={setShowColumnsModal}
      />
      {isColumnsSavedSuccess && (
        <UserFeedback
          isSuccess={isColumnsSavedSuccess}
          setIsSuccess={setIsColumnsSavedSuccess}
        >
          Columns saved successfully
        </UserFeedback>
      )}
      {isReviewError && (
        <UserFeedback isError={isReviewError} setIsError={setIsReviewError}>
          An error occurred adding the review. Please try again or report this
          bug.
        </UserFeedback>
      )}
      {selected?.original && (
        <OtherReasonModal
          handleAddReview={handleAddReview}
          modal={otherReasonModal}
          orgName={selected.original.name}
          setModal={setOtherReasonModal}
        />
      )}
    </>
  )
}

CompaniesListTable.propTypes = {
  allDataLoading: bool.isRequired,
  columns: arrayOf(shape({})).isRequired,
  data: arrayOf(shape({})).isRequired,
  fundingRoundTypes: arrayOf(shape({})),
  listType: string.isRequired,
  listUuid: string.isRequired,
  pageCount: number.isRequired,
  pageIndex: number.isRequired,
  pageSize: number.isRequired,
  portfolioCategories: arrayOf(shape({})),
  portfolioLocation: arrayOf(shape({})),
  reviewed: bool.isRequired,
  scope: string.isRequired,
  setFilteredCompaniesLength: func.isRequired,
  setIsFiltered: func.isRequired,
  setShowShortcuts: func.isRequired,
  showShortcuts: bool.isRequired,
  totalCount: number.isRequired,
  week: string.isRequired,
  year: string.isRequired,
}

CompaniesListTable.defaultProps = {
  fundingRoundTypes: undefined,
  portfolioCategories: undefined,
  portfolioLocation: undefined,
}

export default memo(CompaniesListTable)
