import {shape, string} from 'prop-types'
import {Text} from 'components'
import {formatCategoriesAndTags} from 'utils'

const CategoriesCell = ({
  row: {
    original: {categoryList},
  },
}) =>
  categoryList ? (
    <Text
      css={{
        lh: '$body',
        maxWidth: '480px',
        minWidth: '144px',
        overflowWrap: 'break-word',
      }}
    >
      {formatCategoriesAndTags(categoryList)}
    </Text>
  ) : (
    '-'
  )

CategoriesCell.propTypes = {
  row: shape({
    original: shape({
      categoryList: string,
    }),
  }).isRequired,
}

export default CategoriesCell
