import {gql} from '@apollo/client'

export const GET_REVIEWS = gql`
  query reviews($orgUuid: String!) {
    reviews: review(orgUuid: $orgUuid) {
      createdAt
      nautable
      notNautableOther
      notNautableReason
      userId
      userName
      uuid
    }
  }
`

export const GET_COMPANY_LAST_REVIEW = gql`
  query organization($uuid: String) {
    organization(uuid: $uuid) {
      lastReview {
        uuid
        nautable
        notNautableOther
        notNautableReason
        createdAt
        userName
      }
      uuid
    }
  }
`
