const formatListName = name => {
  const shortName = name
    .split(' ')
    .map(w => w.slice(0, 1))
    .join('')
    .toUpperCase()
    .slice(0, 3)
  return shortName.length === 1 ? name.slice(0, 3).toUpperCase() : shortName
}

export default formatListName
