import getLast15Years from './utils'

const currentYear = new Date().getFullYear()

const YEAR_OPTIONS = [
  {id: 'year-00', value: '', label: 'Select...'},
  ...getLast15Years(currentYear),
]

export default YEAR_OPTIONS
