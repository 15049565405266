import {arrayOf, shape, string} from 'prop-types'
import {Box, ListItem} from 'components'
import {formatComment, formatDateTime, formatFirstAndLastName} from 'utils'
import Text from './styles'
import formatSharedWithPeople from './utils'

const ShareItem = ({commentText, createdAt, sharedWith, whoShares}) => (
  <ListItem variant="comments" data-testid="share-item">
    <Box css={{'@initial': {d: 'block'}, '@bp1': {d: 'inline'}}}>
      <Text variant="username">
        {formatFirstAndLastName(whoShares.username)}
      </Text>
      <Text variant="shared-with">shared with</Text>
      <Text variant="shared-with-people">
        {formatSharedWithPeople(sharedWith, whoShares)}
      </Text>
      <Text variant="date">{formatDateTime(createdAt)}</Text>
    </Box>
    {commentText && <Text variant="comment">{formatComment(commentText)}</Text>}
  </ListItem>
)

ShareItem.propTypes = {
  commentText: string.isRequired,
  createdAt: string.isRequired,
  sharedWith: arrayOf(shape({})).isRequired,
  whoShares: shape({}).isRequired,
}

export default ShareItem
