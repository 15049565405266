import {memo} from 'react'
import {bool} from 'prop-types'
import {Loader, UserFeedback} from 'components'
import {
  ClearSearchButton,
  SearchInput,
  SearchLoader,
  SearchResultsList,
} from './components'
import useSearchBar from './hooks'
import {Box, Flex} from './styles'

const SearchBar = ({extendMenu}) => {
  const {
    companiesDropdownResults,
    errorMsg,
    investorDropdownResults,
    isError,
    isNotCompaniesAndPeopleAndInvestorsPage,
    isSearchError,
    isSearchLoading,
    pathname,
    query,
    searchBackgroundRef,
    searchDropdown,
    searchInputRef,
    searchLoading,
    setIsError,
    setQuery,
    setSearchDropdown,
  } = useSearchBar()
  return (
    <>
      <Box data-testid="search-bar">
        <Flex
          variant="search-bar"
          css={{
            '@bp3': {
              w: extendMenu ? '226px' : '336px',
            },
            '@bp4': {
              w: extendMenu ? '480px' : '560px',
            },
            '@bp5': {
              w: extendMenu ? '600px' : '700px',
            },
          }}
        >
          <SearchLoader
            isLoading={
              pathname === '/companies' ||
              pathname === '/people' ||
              pathname === '/investors'
                ? searchLoading
                : isSearchLoading
            }
          />
          <SearchInput
            ref={searchInputRef}
            setSearchDropdown={setSearchDropdown}
          />
          {query && (
            <ClearSearchButton
              searchInputRef={searchInputRef}
              setQuery={setQuery}
            />
          )}
        </Flex>
        {query && searchDropdown && isNotCompaniesAndPeopleAndInvestorsPage && (
          <Box
            data-testid="modal-search-container"
            variant="modal-search-container"
            type="search"
            ref={searchBackgroundRef}
          >
            <Box
              css={{
                '@bp3': {
                  left: extendMenu ? '208px' : '96px',
                  w: extendMenu ? '226px' : '336px',
                },
                '@bp4': {
                  left: extendMenu ? '208px' : '96px',
                  w: extendMenu ? '480px' : '560px',
                },
                '@bp5': {
                  left: extendMenu ? '216px' : '104px',
                  w: extendMenu ? '600px' : '700px',
                },
              }}
              data-testid="modal-search-content"
              variant="modal-search-content"
            >
              {isSearchLoading && (
                <Loader size="20" css={{ml: '$24', my: '$24'}} />
              )}
              {companiesDropdownResults?.length > 0 && (
                <SearchResultsList
                  data={companiesDropdownResults}
                  searchType="Companies"
                />
              )}
              {investorDropdownResults?.length > 0 && (
                <SearchResultsList
                  data={investorDropdownResults}
                  searchType="Investors"
                />
              )}
            </Box>
          </Box>
        )}
      </Box>
      {isSearchError && isError && (
        <UserFeedback isError={isError} setIsError={setIsError}>
          {errorMsg}
        </UserFeedback>
      )}
    </>
  )
}

SearchBar.propTypes = {
  extendMenu: bool.isRequired,
}

export default memo(SearchBar)
