import {memo} from 'react'
import {bool, func} from 'prop-types'
import {Box} from 'components'
import {MobileButtonBurger, MobileButtonStyled} from './styles'

const MobileButton = ({showMobileMenu, setShowMobileMenu}) => (
  <Box css={{d: 'block', '@bp3': {d: 'none'}}}>
    <MobileButtonStyled
      data-testid="mobile-button"
      onClick={() => setShowMobileMenu(!showMobileMenu)}
    >
      <MobileButtonBurger data-testid="burger" open={showMobileMenu && 'yes'} />
    </MobileButtonStyled>
  </Box>
)

MobileButton.propTypes = {
  showMobileMenu: bool.isRequired,
  setShowMobileMenu: func.isRequired,
}

export default memo(MobileButton)
