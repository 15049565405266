import BaseFlex from 'components/layout/Flex'
import {keyframes, styled} from 'styles'

const showUserMenu = keyframes({
  from: {
    opacity: 0,
    transform: 'translateY(-5%)',
  },
  to: {
    opacity: 1,
    transform: 'translateY(0%)',
  },
})

const Flex = styled(BaseFlex, {
  variants: {
    variant: {
      'user-menu-list': {
        ai: 'flex-start',
        animation: `${showUserMenu} 0.25s ease-in`,
        bgc: '$white',
        br: '0.25rem 0rem 0rem 0.25rem',
        bs: '$2',
        p: '$8',
        position: 'absolute',
        right: 0,
      },
    },
  },
})

export default Flex
