import {memo} from 'react'
import {arrayOf, bool, func, shape, string} from 'prop-types'
import {HeartIcon, HeartSemiIcon} from 'assets'
import {Box, Button, Flex, Icon, Text} from 'components'
import userActionListItem from './hooks'

const ActionListItem = ({
  companiesToSave,
  initialSelectedListNames,
  isActionListSelected,
  isEveryCompanyInMyActionList,
  isSomeOrgInMyActionList,
  orgUuid,
  setIsActionListSelected,
  setSelectedListsNamesToRemove,
  setSelectedListsNamesToSave,
}) => {
  const {
    handleSelectActionListToRemove,
    handleSelectActionListToSave,
    isSave,
    isSomeActive,
  } = userActionListItem({
    companiesToSave,
    initialSelectedListNames,
    isActionListSelected,
    isEveryCompanyInMyActionList,
    isSomeOrgInMyActionList,
    orgUuid,
    setIsActionListSelected,
    setSelectedListsNamesToRemove,
    setSelectedListsNamesToSave,
  })
  return (
    <>
      <Button
        color="grey"
        css={{
          d: 'flex',
          ai: 'center',
          p: '$8',
          w: '$full',
          '&:focus': {
            txtd: 'none',
          },
          color: isSave ? '$grey300' : '$primary',
          '&:hover': {
            bgc: '$grey75',
            color: isSave ? '$grey300' : '$primary',
          },
        }}
        onClick={
          isSave ? handleSelectActionListToSave : handleSelectActionListToRemove
        }
        size="ghost-small"
        title={isSave ? `Save to Action List` : `Remove from Action List`}
        type="button"
        variant="ghost"
      >
        <Flex
          css={{
            color: '$grey200',
            wh: '$32',
            bgc: '$grey100',
            br: '$small',
            fontSize: '$11',
            jc: 'center',
            ai: 'center',
          }}
        >
          <Box>AL</Box>
        </Flex>
        <Text css={{ml: '$8', color: '$grey400'}}>Action List</Text>
        <Icon
          css={{
            ml: 'auto',
            mr: '$16',
          }}
          data-testid="icon"
          size="16"
        >
          {companiesToSave?.length > 0 &&
            isSomeOrgInMyActionList &&
            !isEveryCompanyInMyActionList &&
            isSomeActive && <HeartSemiIcon />}
          {((companiesToSave?.length > 0 && isEveryCompanyInMyActionList) ||
            !isSomeActive) && <HeartIcon />}
        </Icon>
      </Button>
    </>
  )
}

ActionListItem.propTypes = {
  companiesToSave: arrayOf(shape({})).isRequired,
  initialSelectedListNames: arrayOf(string).isRequired,
  isActionListSelected: bool.isRequired,
  isEveryCompanyInMyActionList: bool.isRequired,
  isSomeOrgInMyActionList: bool.isRequired,
  orgUuid: string.isRequired,
  setIsActionListSelected: func.isRequired,
  setSelectedListsNamesToRemove: func.isRequired,
  setSelectedListsNamesToSave: func.isRequired,
}

export default memo(ActionListItem)
