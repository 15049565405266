import {arrayOf, bool, func, number, shape, string} from 'prop-types'
import {
  CreateNewListModal,
  Box,
  Pagination,
  ReviewModal,
  TableBody,
  TableHead,
  TableStyles,
  UserFeedback,
  OtherReasonModal,
} from 'components'
import {ShareCompanyModal} from 'components/patterns/ShareCompany/components'
import {SaveToListModal} from 'components/patterns/SaveToList/components'
import {AddToPipedriveModal} from 'components/patterns/AddToPipedrive/components'
import usePortfolioTable from './hooks'

const PortfolioTable = ({
  allDataLoading,
  columns,
  data,
  investorUuid,
  listType,
  totalCount,
}) => {
  const {
    addToPipedriveModal,
    canNextPage,
    canPreviousPage,
    commentText,
    companiesSavedOrRemoved,
    companyToReview,
    companyToSave,
    companyToShare,
    createNewListModal,
    cursor,
    filters,
    getTableBodyProps,
    getTableProps,
    gotoPage,
    handleAddReview,
    headerGroups,
    isReviewError,
    isReviewLoading,
    nextPage,
    otherReasonModal,
    page,
    pageCount,
    pageIndex,
    pageSize,
    prepareRow,
    previousPage,
    reviewModal,
    rows,
    rowsRefs,
    saveRemoveListModal,
    selected,
    setAddToPipedriveModal,
    setCommentText,
    setCompaniesSavedOrRemoved,
    setCreateNewListModal,
    setCursor,
    setFocusNextItem,
    setIsReviewError,
    setIsReviewLoading,
    setOtherReasonModal,
    setPageSize,
    setReviewModal,
    setSaveRemoveListModal,
    setSelected,
    setShareModal,
    shareModal,
  } = usePortfolioTable({columns, data})
  return (
    <Box data-testid="portfolio-table">
      <TableStyles>
        <table {...getTableProps()}>
          <TableHead canFilter canSort headerGroups={headerGroups} />
          <TableBody
            cursor={cursor}
            data={page}
            getTableBodyProps={getTableBodyProps}
            investorUuid={investorUuid}
            isTableReviewLoading={isReviewLoading}
            listType={listType}
            prepareRow={prepareRow}
            ref={rowsRefs}
            selectedUuid={selected?.original?.orgUuid}
            setCursor={setCursor}
            setSelected={setSelected}
          />
        </table>
      </TableStyles>
      <Pagination
        allDataLoading={allDataLoading}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
        isFiltered={filters.length > 0}
        listType={listType}
        nextPage={nextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        previousPage={previousPage}
        setPageSize={setPageSize}
        totalCount={totalCount}
        totalFilteredCount={rows.length}
      />
      {selected?.original && (
        <AddToPipedriveModal
          addFrom="companies-list"
          city={selected.original.city}
          countryName={selected.original.country}
          foundedYear={selected.original.year}
          funding={selected.original.funding}
          homepageUrl={selected.original.homepageUrl}
          modal={addToPipedriveModal}
          name={selected.original.name}
          orgUuid={selected.original.uuid}
          setModal={setAddToPipedriveModal}
          shortDescription={selected.original.shortDescription}
        />
      )}
      {selected?.original && (
        <SaveToListModal
          commentText={commentText}
          companiesToSave={companyToSave}
          companiesSavedOrRemoved={companiesSavedOrRemoved}
          logoUrl={selected.original.logoUrl}
          modal={saveRemoveListModal}
          orgName={selected.original.name}
          orgUuid={selected.original.uuid}
          setCommentText={setCommentText}
          setCompaniesSavedOrRemoved={setCompaniesSavedOrRemoved}
          setCreateNewListModal={setCreateNewListModal}
          setModal={setSaveRemoveListModal}
          totalCount={totalCount}
        />
      )}
      <CreateNewListModal
        commentText={commentText}
        companiesSavedOrRemoved={companiesSavedOrRemoved}
        companiesToSave={companyToSave}
        modal={createNewListModal}
        setCompaniesSavedOrRemoved={setCompaniesSavedOrRemoved}
        setModal={setCreateNewListModal}
      />
      <ShareCompanyModal
        companiesToShare={companyToShare}
        modal={shareModal}
        setModal={setShareModal}
      />
      <ReviewModal
        companyToReview={companyToReview}
        modal={reviewModal}
        reviewFrom="companies-list"
        setFocusNextItem={setFocusNextItem}
        setIsReviewLoading={setIsReviewLoading}
        setModal={setReviewModal}
      />
      {isReviewError && (
        <UserFeedback isError={isReviewError} setIsError={setIsReviewError}>
          An error occurred adding the review. Please try again or report this
          bug.
        </UserFeedback>
      )}
      {selected?.original && (
        <OtherReasonModal
          handleAddReview={handleAddReview}
          modal={otherReasonModal}
          orgName={selected.original.name}
          setModal={setOtherReasonModal}
        />
      )}
    </Box>
  )
}

PortfolioTable.propTypes = {
  allDataLoading: bool.isRequired,
  columns: arrayOf(shape({})).isRequired,
  data: arrayOf(shape({})).isRequired,
  investorUuid: string.isRequired,
  listType: string.isRequired,
  pageCount: number.isRequired,
  pageIndex: number.isRequired,
  pageSize: number.isRequired,
  setCompaniesPage: func.isRequired,
  setFundingRoundsPage: func.isRequired,
  totalCount: number.isRequired,
}

export default PortfolioTable
