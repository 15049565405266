import {gql} from '@apollo/client'

export const GET_IS_COMPANY_INVESTOR = gql`
  query organization($uuid: String) {
    organization(uuid: $uuid) {
      isInvestor
      uuid
    }
  }
`

export const GET_IS_PERSON_INVESTOR = gql`
  query person($uuid: String!) {
    person(uuid: $uuid) {
      isInvestor
      uuid
    }
  }
`

export const GET_INVESTOR_PORTFOLIO_FUNDING_ROUNDS = gql`
  query investorPortfolioFundingRounds(
    $investorUuid: String
    $page: Int
    $pageSize: Int
  ) {
    investorPortfolioFundingRounds(
      investorUuid: $investorUuid
      page: $page
      pageSize: $pageSize
    ) {
      page
      pages
      hasNext
      hasPrev
      count
      totalCount
      objects {
        announcedOn
        city
        countryCode
        investmentType
        name
        orgName
        orgUuid {
          categoryList
          cbUrl
          diversityFounders
          extra
          homepageUrl
          linkedinUrl
          logoUrl
          organizationpipedrive {
            pipedriveUrl
            uuid {
              uuid
            }
          }
          permalink
          twitterUrl
          uuid
        }
        raisedAmountUsd
        uuid
      }
    }
  }
`
