import {func, number, string} from 'prop-types'
import {Flex, Select, Text} from 'components'
import useGoToPage from './hooks'

const GoToPage = ({gotoPage, listType, pageCount, pageIndex, pageInView}) => {
  const {
    gotoPageOptions,
    isPortfolioCategories,
    isPortfolioCompanies,
    isPortfolioFundingRounds,
    setCompaniesSearchPage,
    setInvestorsSearchPage,
    setIsPageChanged,
    setPeopleSearchPage,
  } = useGoToPage({listType, pageCount})
  return (
    <Flex css={{ai: 'center'}} data-testid="go-to-page">
      <Text css={{mr: '$8'}} variant="pagination">
        Go to page:
      </Text>
      <Select
        aria-label="Go to page"
        data-testid="select-goto-page"
        title="Go to page"
        onChange={({target: {value}}) => {
          const pageToGo = Number(value)
          if (
            isPortfolioCompanies ||
            isPortfolioFundingRounds ||
            isPortfolioCategories
          ) {
            setIsPageChanged(true)
          }
          gotoPage(pageToGo - 1)
          if (listType === 'companies-page') {
            setCompaniesSearchPage(pageToGo)
          }
          if (listType === 'people') {
            setPeopleSearchPage(pageToGo)
          }
          if (listType === 'investors-page') {
            setInvestorsSearchPage(pageToGo)
          }
        }}
        type="number"
        value={pageIndex === 0 ? 1 : pageInView}
      >
        {gotoPageOptions.map(pageItem => (
          <option
            data-testid="options-goto-page"
            key={pageItem}
            value={pageItem}
          >
            {pageItem}
          </option>
        ))}
      </Select>
    </Flex>
  )
}

GoToPage.propTypes = {
  gotoPage: func.isRequired,
  listType: string.isRequired,
  pageCount: number.isRequired,
  pageIndex: number.isRequired,
  pageInView: number.isRequired,
}

export default GoToPage
