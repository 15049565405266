import {formatFirstAndLastName} from 'utils'

const getSharedWith = (data, username) =>
  data
    .map(
      share =>
        share.whoShares.username === username &&
        share.sharedWith.map(person => formatFirstAndLastName(person.username)),
    )
    .flat()
    .filter(Boolean)
    .filter((item, pos, self) => self.indexOf(item) === pos)
    .join(', ')

export default getSharedWith
