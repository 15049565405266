import {gql} from '@apollo/client'

export const CREATE_NEW_LIST = gql`
  mutation createCustomList(
    $collaboratorIds: String
    $description: String
    $name: String!
  ) {
    createCustomList(
      collaboratorIds: $collaboratorIds
      description: $description
      name: $name
    ) {
      customList {
        uuid
        name
        description
        creator {
          username
        }
        createdAt
      }
    }
  }
`

export const REMOVE_COMPANY_FROM_LIST = gql`
  mutation removeOrganizationFromCustomList(
    $listUuid: String!
    $orgUuid: String!
  ) {
    removeOrganizationFromCustomList(listUuid: $listUuid, orgUuid: $orgUuid) {
      customListOrganization {
        id
        listUuid {
          name
          uuid
        }
        orgUuid {
          name
          uuid
        }
      }
    }
  }
`

export const SAVE_COMPANY_TO_LIST = gql`
  mutation addOrganizationToCustomList(
    $commentText: String
    $mentionedUserIds: String
    $listUuid: String!
    $orgUuid: String!
  ) {
    addOrganizationToCustomList(
      commentText: $commentText
      mentionedUserIds: $mentionedUserIds
      listUuid: $listUuid
      orgUuid: $orgUuid
    ) {
      customListOrganization {
        id
        listUuid {
          name
          uuid
        }
        orgUuid {
          name
          uuid
        }
      }
    }
  }
`
