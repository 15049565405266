import {memo} from 'react'
import {arrayOf, bool, func, shape} from 'prop-types'
import {Flex} from 'components'
import MenuItem from './components'

const MenuList = ({extendMenu, navList, setShowMobileMenu, showMobileMenu}) => (
  <Flex as="ul" direction="column">
    {navList.map(({id, label, path, title}) => (
      <MenuItem
        key={id}
        extendMenu={extendMenu}
        label={label}
        path={path}
        setShowMobileMenu={setShowMobileMenu}
        showMobileMenu={showMobileMenu}
        title={title}
      />
    ))}
  </Flex>
)

MenuList.propTypes = {
  extendMenu: bool.isRequired,
  navList: arrayOf(shape({})).isRequired,
  setShowMobileMenu: func.isRequired,
  showMobileMenu: bool.isRequired,
}

export default memo(MenuList)
