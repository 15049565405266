import {arrayOf, func, shape, string} from 'prop-types'
import {
  AddCollaborators,
  Box,
  Button,
  CancelButton,
  Flex,
  InputField,
  Loader,
  Text,
  TextareaField,
} from 'components'
import useCreateNewListForm from './hooks'

const CreateNewListForm = ({
  commentText,
  companiesToSave,
  orgUuid,
  setCompaniesSavedOrRemoved,
  setIsCreateError,
  setIsCreateSuccess,
  setIsSaveError,
  setIsSaveSuccess,
  setListName,
  setModal,
}) => {
  const {
    checkedUsersIds,
    errors,
    handleCreateNewList,
    handleSubmit,
    isSaveLoading,
    listNameRef,
    pathname,
    register,
    setCheckedUsersIds,
  } = useCreateNewListForm({
    commentText,
    companiesToSave,
    orgUuid,
    setCompaniesSavedOrRemoved,
    setIsCreateError,
    setIsCreateSuccess,
    setIsSaveError,
    setIsSaveSuccess,
    setListName,
    setModal,
  })
  return (
    <Flex
      as="form"
      direction="column"
      onSubmit={handleSubmit(handleCreateNewList)}
    >
      <InputField
        errors={errors.name}
        name="name"
        register={e => {
          register(e, {required: true})
          listNameRef.current = e
        }}
        onKeyDown={({key}) => key === 'Escape' && setModal(false)}
        required
        type="text"
      >
        Name
      </InputField>
      <TextareaField
        css={{mt: '$16'}}
        name="description"
        register={register}
        rows={1}
      >
        Description
      </TextareaField>
      <Box css={{mt: '$16'}}>
        <Text css={{mb: '$16'}} variant="modal-subtitle">
          Add collaborators (optional)
        </Text>
        <AddCollaborators
          checkedUsersIds={checkedUsersIds}
          setCheckedUsersIds={setCheckedUsersIds}
        />
      </Box>
      <Flex css={{as: 'flex-end', gap: '$16', mt: '$24'}}>
        {pathname !== '/create-list' && (
          <CancelButton onClick={() => setModal(false)}>Cancel</CancelButton>
        )}
        <Flex as={Button} type="submit">
          Save
          {isSaveLoading && (
            <Loader size="14" css={{ml: '$8'}} data-testid="loader" />
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

CreateNewListForm.propTypes = {
  commentText: string.isRequired,
  companiesToSave: arrayOf(shape({})).isRequired,
  orgUuid: string.isRequired,
  setCompaniesSavedOrRemoved: func.isRequired,
  setIsCreateError: func.isRequired,
  setIsCreateSuccess: func.isRequired,
  setIsSaveError: func.isRequired,
  setIsSaveSuccess: func.isRequired,
  setListName: func.isRequired,
  setModal: func.isRequired,
}

export default CreateNewListForm
