import {gql} from '@apollo/client'

export const DELETE_LIST = gql`
  mutation deleteCustomList($uuid: String!) {
    deleteCustomList(uuid: $uuid) {
      customList {
        uuid
      }
    }
  }
`

export const REMOVE_MYSELF_AS_COLLABORATOR = gql`
  mutation removeCollaboratorsFromCustomList(
    $collaboratorIds: String
    $uuid: String!
  ) {
    removeCollaboratorsFromCustomList(
      collaboratorIds: $collaboratorIds
      uuid: $uuid
    ) {
      customList {
        uuid
        customlistcollaboratorSet {
          id
          isOwner
          collaborator {
            firstName
            lastName
          }
        }
      }
    }
  }
`
