import {formatFirstAndLastName} from 'utils'

const formatSharedWithPeople = (sharedWith, whoShares) =>
  sharedWith
    .map(
      ({username}) =>
        username !== whoShares.username && formatFirstAndLastName(username),
    )
    .filter(Boolean)
    .join(', ')
    .replace(/,(?!.*,)/gim, ' and')

export default formatSharedWithPeople
