const formatInvestmentType = value =>
  value
    .split(',')
    .map(c =>
      c
        .split('_')
        .map(w => w.charAt(0).toUpperCase() + w.slice(1))
        .join(' '),
    )
    .join(', ')

export default formatInvestmentType
