const filterTypes = () => ({
  text: (rows, id, filterValue) =>
    rows.filter(row => {
      const rowValue = row.values[id]
      return rowValue !== undefined
        ? String(rowValue)
            .toLowerCase()
            .startsWith(String(filterValue).toLowerCase())
        : true
    }),
})

export default filterTypes
