import {memo} from 'react'
import {func} from 'prop-types'
import UserMenuItem from './components'
import Flex from './styles'

const userMenuList = [
  {
    id: 'dropdown-nav-my-lists',
    label: 'My Lists',
    path: 'my-lists',
    title: 'My Lists - 6',
  },
  {
    id: 'dropdown-nav-shortcuts',
    label: 'Shortcuts',
    path: 'shortcuts',
    title: 'Shortcuts - 7',
  },
  {
    id: 'dropdown-nav-settings',
    label: 'Settings',
    path: 'settings',
    title: 'Settings - 8',
  },
  {
    id: 'dropdown-nav-logout',
    label: 'Logout',
    path: 'logout',
    title: 'Logout - 0',
  },
]

const UserMenuList = ({setDropdownOpen}) => (
  <Flex
    as="ul"
    direction="column"
    onMouseLeave={() => setDropdownOpen(false)}
    variant="user-menu-list"
    data-testid="user-menu-list"
  >
    {userMenuList.map(({id, label, path, title}) => (
      <UserMenuItem key={id} label={label} path={path} title={title} />
    ))}
  </Flex>
)

UserMenuList.propTypes = {
  setDropdownOpen: func.isRequired,
}

export default memo(UserMenuList)
