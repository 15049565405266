import {useState} from 'react'
import {string} from 'prop-types'
import {FallbackImgCompanySm, FallbackImgPersonSm} from 'assets'
import {Box, Flex, Image, Link} from 'components'
import {useInputQuery} from 'context'
import Text from './styles'

const SearchResultsItem = ({
  fallbackLogoUrl,
  logoUrl,
  name,
  permalink,
  shortDescription,
  type,
  uuid,
}) => {
  const [isImageLoadError, setIsImageLoadError] = useState(false)
  const {setQuery} = useInputQuery()
  return (
    <Link
      to={
        (type === 'organization' && `/companies/${permalink}?uuid=${uuid}`) ||
        (type === 'person' && `/people/${permalink}?uuid=${uuid}`)
      }
      variant="search-results-item"
      onClick={() => setQuery('')}
    >
      <Box css={{wh: '$32'}}>
        <Image
          alt={type === 'person' ? name : `${name} logo`}
          onError={() => setIsImageLoadError(true)}
          size="32"
          src={
            isImageLoadError
              ? fallbackLogoUrl
              : logoUrl ||
                (type === 'organization' && FallbackImgCompanySm) ||
                (type === 'person' && FallbackImgPersonSm)
          }
          variant={type === 'organization' ? 'company' : 'person'}
        />
      </Box>
      <Flex direction="column" css={{my: '$8'}}>
        <Text variant="search-results-name">{name}</Text>
        <Text variant="search-results-description">
          {type === 'organization' && shortDescription}
          {type === 'person' && (
            <>
              {shortDescription && (
                <Text css={{lh: '$body'}}>
                  {shortDescription && (
                    <Text css={{lh: '$body'}}>{shortDescription}</Text>
                  )}
                </Text>
              )}
            </>
          )}
        </Text>
      </Flex>
    </Link>
  )
}

SearchResultsItem.propTypes = {
  fallbackLogoUrl: string.isRequired,
  logoUrl: string.isRequired,
  name: string.isRequired,
  permalink: string.isRequired,
  shortDescription: string.isRequired,
  type: string.isRequired,
  uuid: string.isRequired,
}

export default SearchResultsItem
