import {gql} from '@apollo/client'

const GET_COMPANY_LABELS = gql`
  query organizationLabel($uuid: String!) {
    organizationLabel(uuid: $uuid) {
      addedByName
      commentText
      createdAt
      labelUuid {
        color
        name
        uuid
      }
      uuid
    }
  }
`

export default GET_COMPANY_LABELS
