import {node, shape} from 'prop-types'
import {Text} from 'components'

const ErrorMessage = ({children, css}) => (
  <Text variant="error" css={css}>
    {children}
  </Text>
)

ErrorMessage.propTypes = {
  children: node.isRequired,
  css: shape({}).isRequired,
}

export default ErrorMessage
