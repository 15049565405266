import {useState} from 'react'
import {bool, func, number, string} from 'prop-types'
import {
  CloseModalButton,
  Flex,
  Image,
  Modal,
  Text,
  UserFeedback,
} from 'components'
import {FallbackImgCompanySm} from 'assets'
import RemoveFromListForm from './components'

const RemoveFromListModal = ({
  isLastItem,
  itemIndex,
  listName,
  listUuid,
  logoUrl,
  modal,
  orgName,
  orgUuid,
  pageIndex,
  previousPage,
  setIsLoading,
  setModal,
}) => {
  const [isRemoveSuccess, setIsRemoveSuccess] = useState(false)
  const [isRemoveError, setIsRemoveError] = useState(false)
  const [companyRemoved, setCompanyRemoved] = useState('')
  const [logoUrlRemoved, setLogoUrlRemove] = useState('')
  if (isRemoveSuccess)
    return (
      <UserFeedback
        hasImage
        isSuccess={isRemoveSuccess}
        setIsSuccess={setIsRemoveSuccess}
      >
        <Flex css={{ai: 'center'}}>
          <Image
            alt={`${companyRemoved} logo`}
            css={{mr: '$8'}}
            size="32"
            src={logoUrlRemoved || FallbackImgCompanySm}
            variant="company"
          />
          {companyRemoved} removed from {listName}
        </Flex>
      </UserFeedback>
    )
  if (isRemoveError)
    return (
      <UserFeedback isError={isRemoveError} setIsError={setIsRemoveError}>
        Error removing
        {companyRemoved} from
        {listName}. Please, try again or report this bug.
      </UserFeedback>
    )
  return (
    <Modal modal={modal} setModal={setModal} data-testid="remove-modal">
      <CloseModalButton setModal={setModal} />
      <Text variant="modal-title">
        Remove {orgName} from {listName}?
      </Text>
      <RemoveFromListForm
        isLastItem={isLastItem}
        itemIndex={itemIndex}
        listName={listName}
        listUuid={listUuid}
        logoUrl={logoUrl}
        orgName={orgName}
        orgUuid={orgUuid}
        pageIndex={pageIndex}
        previousPage={previousPage}
        setCompanyRemoved={setCompanyRemoved}
        setIsLoading={setIsLoading}
        setIsRemoveError={setIsRemoveError}
        setIsRemoveSuccess={setIsRemoveSuccess}
        setLogoUrlRemove={setLogoUrlRemove}
        setModal={setModal}
      />
    </Modal>
  )
}

RemoveFromListModal.propTypes = {
  isLastItem: bool.isRequired,
  itemIndex: number.isRequired,
  listName: string.isRequired,
  listUuid: string.isRequired,
  logoUrl: string.isRequired,
  modal: bool.isRequired,
  orgName: string.isRequired,
  orgUuid: string.isRequired,
  pageIndex: number.isRequired,
  previousPage: func.isRequired,
  setIsLoading: func.isRequired,
  setModal: func.isRequired,
}

export default RemoveFromListModal
