import BaseBox from 'components/layout/Box'
import {styled} from 'styles'

const Box = styled(BaseBox, {
  variants: {
    variant: {
      'social-link': {
        bgc: '$grey100',
        br: '$small',
        '&:hover': {
          bgc: '$secondary',
          color: '$white',
          'span > svg': {
            color: '$white',
          },
        },
      },
    },
  },
})

export default Box
