import {gql} from '@apollo/client'

export const REMOVE_FROM_ACTION_LIST = gql`
  mutation removeFromActionList($uuid: String!) {
    removeFromActionList(uuid: $uuid) {
      actionList {
        id
      }
    }
  }
`

export const SAVE_TO_ACTION_LIST = gql`
  mutation addToActionList(
    $commentText: String
    $mentionedUserIds: String
    $userIds: String
    $uuid: String!
  ) {
    addToActionList(
      commentText: $commentText
      mentionedUserIds: $mentionedUserIds
      userIds: $userIds
      uuid: $uuid
    ) {
      actionList {
        id
        sharedBy {
          firstName
          lastName
        }
        commentText
        createdAt
      }
    }
  }
`
