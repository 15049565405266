import {memo} from 'react'
import {shape, string} from 'prop-types'
import {parseJSONData} from 'utils'
import Text from './styles'

const JobTitleCell = ({
  row: {
    original: {defaultPosition, jobTitle},
  },
}) => (
  <Text variant="job-title-cell">
    {jobTitle ||
      (defaultPosition && parseJSONData(defaultPosition).title) ||
      ''}
  </Text>
)

JobTitleCell.propTypes = {
  row: shape({
    original: shape({
      defaultPosition: string,
      jobTitle: string,
    }),
  }).isRequired,
}

export default memo(JobTitleCell)
