import {bool, func, string} from 'prop-types'
import {PipedriveIcon, WarningIcon} from 'assets'
import {
  Button,
  CancelButton,
  Flex,
  Grid,
  Icon,
  InputField,
  Loader,
  SelectField,
  SocialLink,
  TextareaField,
} from 'components'
import DIVERSE_TEAM_OPTIONS from 'constants/options'
import {
  CURRENCY_OPTIONS,
  FUNDING_STAGE_OPTIONS,
  SECTOR_OPTIONS,
  SOURCE_OPTIONS,
  CHANNEL_OPTIONS,
} from './options'
import ContactField from './components'
import useAddToPipedriveForm from './hooks'
import Text from './styles'

const AddToPipedriveForm = ({
  addFrom,
  city,
  country,
  diversityFounders,
  foundedOnYear,
  funding,
  homepageUrl,
  name,
  orgUuid,
  setIsSuccess,
  setModal,
  shortDescription,
}) => {
  const {
    companyNameRef,
    errorMsg,
    errors,
    handleAddToPipedrive,
    handleSubmit,
    initialValues,
    isAddToPipedriveError,
    isAddToPipedriveLoading,
    pipedriveUrl,
    register,
  } = useAddToPipedriveForm({
    addFrom,
    city,
    country,
    diversityFounders,
    foundedOnYear,
    funding,
    homepageUrl,
    name,
    orgUuid,
    setIsSuccess,
    setModal,
    shortDescription,
  })
  return (
    <Flex
      as="form"
      direction="column"
      css={{fw: 'wrap'}}
      onSubmit={handleSubmit(handleAddToPipedrive)}
    >
      <Grid variant="modal" data-testid="pdmodal">
        <InputField
          name="organizationName"
          defaultValue={initialValues.organizationName}
          type="text"
          onKeyDown={({key}) => key === 'Escape' && setModal(false)}
          register={e => {
            register(e, {required: true})
            companyNameRef.current = e
          }}
          required
          errors={errors.organizationName}
        >
          Company name
        </InputField>
        <InputField
          name="dealName"
          defaultValue={initialValues.dealName}
          type="text"
          register={register}
        >
          Deal name
        </InputField>
        <InputField
          name="website"
          defaultValue={initialValues.website}
          type="text"
          register={register}
        >
          Website
        </InputField>
        <InputField
          name="companyFoundedOnYear"
          defaultValue={initialValues.companyFoundedOnYear}
          type="text"
          register={register}
        >
          Founded on year
        </InputField>
        <InputField
          name="companyCity"
          defaultValue={initialValues.companyCity}
          type="text"
          register={register}
        >
          City
        </InputField>
        <InputField
          name="companyCountry"
          defaultValue={initialValues.companyCountry}
          type="text"
          register={register}
        >
          Country
        </InputField>
        <TextareaField
          name="description"
          defaultValue={initialValues.description}
          register={register}
          rows={2}
        >
          Description
        </TextareaField>
        <InputField
          name="fundingTotalUsd"
          defaultValue={initialValues.fundingTotalUsd}
          type="number"
          register={register}
        >
          Total funding raised
        </InputField>
        <SelectField
          name="currency"
          defaultValue={initialValues.currency}
          options={CURRENCY_OPTIONS}
          register={register}
        >
          Currency
        </SelectField>
        <SelectField
          name="channel"
          defaultValue={initialValues.channel}
          options={CHANNEL_OPTIONS}
          register={register({required: true})}
          required
          errors={errors.channel}
        >
          Channel
        </SelectField>

        <InputField
          name="channelDetail"
          defaultValue={initialValues.channelDetail}
          type="text"
          register={register({required: true})}
          required
          errors={errors.channelDetail}
        >
          Channel detail
        </InputField>

        <SelectField
          name="source"
          defaultValue={initialValues.source}
          options={SOURCE_OPTIONS}
          register={register({required: true})}
          required
          errors={errors.source}
        >
          Source
        </SelectField>

        <SelectField
          name="sector"
          defaultValue={initialValues.sector}
          options={SECTOR_OPTIONS}
          register={register}
        >
          Sector
        </SelectField>
        <SelectField
          name="fundingStage"
          defaultValue={initialValues.fundingStage}
          options={FUNDING_STAGE_OPTIONS}
          register={register}
        >
          Funding stage
        </SelectField>
        <ContactField
          orgUuid={orgUuid}
          defaultValue={initialValues.contactPersonUuid}
          register={register}
        />
        <SelectField
          name="hasDiverseFounders"
          defaultValue={initialValues.diversityFounders}
          options={DIVERSE_TEAM_OPTIONS}
          register={register({required: true})}
          required
          errors={errors.hasDiverseFounders}
        >
          Diverse founding team?
        </SelectField>
      </Grid>
      <Flex
        direction={{'@initial': 'column', '@bp2': 'row'}}
        css={{ai: 'center', jc: 'space-between', mt: '-10px'}}
      >
        <Flex
          direction="column"
          css={{
            as: 'flex-start',
            '@bp2': {mb: 0, as: 'center'},
          }}
        >
          {isAddToPipedriveError ? (
            <Flex
              css={{
                '@initial': {ai: 'center', mt: '$8', mb: '$16'},
                '@bp3': {mb: 0, w: '400px'},
              }}
            >
              <Text variant="form-error-pipedrive">{errorMsg}</Text>
              {pipedriveUrl && (
                <SocialLink
                  href={pipedriveUrl}
                  icon={<PipedriveIcon />}
                  title="Pipedrive - p"
                  onClick={() => setModal(false)}
                  variant="pipedrive"
                />
              )}
            </Flex>
          ) : (
            <Flex css={{ai: 'center'}}>
              <Icon color="yellow100" size="16">
                <WarningIcon />
              </Icon>
              <Text variant="warning">
                Attention, this action cannot be undone.
              </Text>
            </Flex>
          )}
        </Flex>
        <Flex css={{as: 'flex-end', gap: '$16', mt: '$24'}}>
          <CancelButton onClick={() => setModal(false)}>Cancel</CancelButton>
          <Flex as={Button} type="submit">
            Add to Pipedrive
            {isAddToPipedriveLoading && (
              <Loader size="16" css={{ml: '$8'}} data-testid="loader" />
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

AddToPipedriveForm.propTypes = {
  addFrom: string.isRequired,
  city: string.isRequired,
  country: string.isRequired,
  diversityFounders: bool.isRequired,
  foundedOnYear: string.isRequired,
  funding: string.isRequired,
  homepageUrl: string.isRequired,
  name: string.isRequired,
  orgUuid: string.isRequired,
  setIsSuccess: func.isRequired,
  setModal: func.isRequired,
  shortDescription: string.isRequired,
}

export default AddToPipedriveForm
