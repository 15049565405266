import {useState} from 'react'
import {bool, func, string} from 'prop-types'
import {Redirect, useLocation} from 'react-router-dom'
import {CloseModalButton, Modal, Text, UserFeedback} from 'components'
import DeleteListForm from './components'

const DeleteListModal = ({creatorUsername, modal, name, setModal, uuid}) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const {pathname} = useLocation()
  if (isError)
    return (
      <UserFeedback isError={isError} setIsError={setIsError}>
        Error deleting list. Please, try again or report this bug.
      </UserFeedback>
    )
  if (isSuccess && (pathname === '/my-lists' || pathname === '/dashboard'))
    return (
      <UserFeedback isSuccess={isSuccess} setIsSuccess={setIsSuccess}>
        List deleted successfully
      </UserFeedback>
    )
  if (isSuccess && pathname.startsWith('/my-lists/'))
    return <Redirect to="/dashboard" />
  return (
    <Modal modal={modal} setModal={setModal}>
      <CloseModalButton setModal={setModal} />
      <Text variant="modal-title">Delete {name}</Text>
      <DeleteListForm
        creatorUsername={creatorUsername}
        setIsError={setIsError}
        setIsSuccess={setIsSuccess}
        setModal={setModal}
        uuid={uuid}
      />
    </Modal>
  )
}

DeleteListModal.propTypes = {
  creatorUsername: string.isRequired,
  modal: bool.isRequired,
  name: string.isRequired,
  setModal: func.isRequired,
  uuid: string.isRequired,
}

export default DeleteListModal
