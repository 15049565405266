const getUsersToMention = users =>
  users
    .map(user => {
      const name = `${user.firstName} ${user.lastName}`
      return {
        id: user.id,
        display: name,
        username: user.username,
      }
    })
    .slice()
    .sort((a, b) => a.display.localeCompare(b.display))

export default getUsersToMention
