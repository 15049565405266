import {bool, func, string} from 'prop-types'
import {Modal, CloseModalButton, OtherReasonForm, Text} from 'components'

const OtherReasonModal = ({handleAddReview, modal, orgName, setModal}) => (
  <Modal modal={modal} setModal={setModal}>
    <CloseModalButton setModal={setModal} />
    <Text variant="modal-title">
      Add other reason (not Nautable) - {orgName}
    </Text>
    <OtherReasonForm handleAddReview={handleAddReview} setModal={setModal} />
  </Modal>
)

OtherReasonModal.propTypes = {
  handleAddReview: func.isRequired,
  modal: bool.isRequired,
  orgName: string.isRequired,
  setModal: func.isRequired,
}

export default OtherReasonModal
