import {gql} from '@apollo/client'

export const GET_MY_LIST_COMPANIES_LIST_INFO_EDITED = gql`
  query customListOrganization($listUuid: String!, $reviewed: Boolean) {
    customListOrganization(listUuid: $listUuid, reviewed: $reviewed) {
      id
      listUuid {
        creator {
          id
          firstName
          lastName
          usercustomfield {
            id
            office
          }
          username
        }
        description
        customlistcollaboratorSet {
          id
          isOwner
          collaborator {
            id
            firstName
            lastName
            username
          }
        }
        name
        updatedAt
        uuid
      }
    }
  }
`

export const GET_MY_LISTS_EDITED = gql`
  query customList($uuid: String) {
    customList(uuid: $uuid) {
      customlistcollaboratorSet {
        id
        isOwner
        collaborator {
          firstName
          lastName
          username
        }
      }
      description
      name
      updatedAt
      uuid
    }
  }
`
