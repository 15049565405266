import {useState} from 'react'
import {string} from 'prop-types'
import {RemoveEventButton, RemoveEventModal} from './components'

const RemoveEvent = ({editionUuid, eventUuid, listType, name}) => {
  const [removeEventModal, setRemoveEventModal] = useState(false)
  return (
    <>
      <RemoveEventButton listType={listType} setModal={setRemoveEventModal} />
      <RemoveEventModal
        editionUuid={editionUuid}
        eventUuid={eventUuid}
        listType={listType}
        modal={removeEventModal}
        name={name}
        setModal={setRemoveEventModal}
      />
    </>
  )
}

RemoveEvent.propTypes = {
  editionUuid: string.isRequired,
  eventUuid: string.isRequired,
  listType: string.isRequired,
  name: string.isRequired,
}

export default RemoveEvent
