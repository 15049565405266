import {memo} from 'react'
import {arrayOf, bool, shape, string, number} from 'prop-types'
import {Pagination, TableBody, TableHead} from 'components'
import ListsTableStyles from './components'
import useListsTable from './hooks'

const ListsTable = ({
  allDataLoading,
  columns,
  data,
  listType,
  numberOfRows,
}) => {
  const {
    canNextPage,
    canPreviousPage,
    getTableBodyProps,
    getTableProps,
    gotoPage,
    headerGroups,
    listsTableStylesVariant,
    nextPage,
    page,
    pageCount,
    pageIndex,
    pageSize,
    prepareRow,
    previousPage,
    setPageSize,
  } = useListsTable({columns, data, listType, numberOfRows})
  return (
    <>
      <ListsTableStyles variant={listsTableStylesVariant} data-testid="table">
        <table {...getTableProps()}>
          <TableHead canSort headerGroups={headerGroups} />
          <TableBody
            data={page}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
          />
        </table>
      </ListsTableStyles>
      <Pagination
        allDataLoading={allDataLoading}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
        listType={listType}
        nextPage={nextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        previousPage={previousPage}
        setPageSize={setPageSize}
        totalCount={data.length}
      />
    </>
  )
}

ListsTable.propTypes = {
  allDataLoading: bool.isRequired,
  columns: arrayOf(shape({})).isRequired,
  data: arrayOf(shape({})).isRequired,
  listType: string.isRequired,
  numberOfRows: number.isRequired,
}

export default memo(ListsTable)
