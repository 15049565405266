import {memo} from 'react'
import {shape, string} from 'prop-types'
import {useLocation} from 'react-router-dom'
import {parseJSONData} from 'utils'
import Text from './styles'

const JobDescriptionCell = ({
  row: {
    original: {defaultPosition},
  },
}) => {
  const {pathname} = useLocation()
  return (
    <Text variant="job-description">
      {(defaultPosition && parseJSONData(defaultPosition).description) ||
        (pathname === '/people-list/stealth' && ' ') ||
        '-'}
    </Text>
  )
}
JobDescriptionCell.propTypes = {
  row: shape({
    original: shape({
      defaultPosition: string,
    }),
  }).isRequired,
}

export default memo(JobDescriptionCell)
