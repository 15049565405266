import {memo} from 'react'
import {arrayOf, shape, string} from 'prop-types'
import {useLocation} from 'react-router-dom'
import {Box} from 'components'
import {getTextColor} from 'utils'
import Text from './styles'
import {getSharedToOthers, getSharedWith} from './utils'

const SharedOrSavedByCell = ({
  row: {
    original: {
      color,
      createdAt,
      firstName,
      lastName,
      organizationsharesSet,
      username,
    },
  },
}) => {
  const date = createdAt.split(',').slice(0, 2).join(',')
  const {pathname} = useLocation()
  const sharesToOthers =
    pathname === '/action-list' && getSharedToOthers(organizationsharesSet)
  const sharedWith =
    pathname === '/action-list' && getSharedWith(sharesToOthers, username)
  return (
    <Box>
      <Text
        variant="shared-by-cell-name"
        css={{
          color: getTextColor(color),
          bgc:
            (color !== undefined && color !== 'ffffff' && color) ||
            (color === 'ffffff' && '$grey200') ||
            '$black',
        }}
      >
        {firstName} {lastName}
      </Text>
      <Text variant="shared-by-cell-date">on {date}</Text>
      {pathname === '/action-list' && sharedWith?.length > 0 && (
        <>
          <Text variant="shared-by-cell-time">with </Text>
          <Text variant="shared-by-cell-shared-with">{sharedWith}</Text>
        </>
      )}
    </Box>
  )
}

SharedOrSavedByCell.propTypes = {
  row: shape({
    original: shape({
      color: string,
      createdAt: string,
      firstName: string,
      lastName: string,
      organizationsharesSet: arrayOf(shape({})),
      username: string,
    }),
  }).isRequired,
}

export default memo(SharedOrSavedByCell)
