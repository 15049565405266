import {useState} from 'react'
import {arrayOf, bool, func, string} from 'prop-types'
import {CloseModalButton, Modal, Text, UserFeedback} from 'components'
import {useAuth} from 'context'
import ShareListForm from './components'

const ShareListModal = ({
  collaboratorsIds,
  creatorUsername,
  modal,
  name,
  setModal,
  uuid,
}) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const {authState} = useAuth()
  const loggedUsername = authState.username
  if (isError)
    return (
      <UserFeedback isError={isError} setIsError={setIsError}>
        Error modifying collaborators. Please, try again or report this bug.
      </UserFeedback>
    )
  if (isSuccess)
    return (
      <UserFeedback isSuccess={isSuccess} setIsSuccess={setIsSuccess}>
        Collaborators successfully saved
      </UserFeedback>
    )
  return (
    <Modal modal={modal} setModal={setModal}>
      <CloseModalButton setModal={setModal} />
      <Text variant="modal-title">
        {loggedUsername === creatorUsername
          ? `Edit ${name} Collaborators`
          : `Add Collaborators to ${name}`}
      </Text>
      <ShareListForm
        collaboratorsIds={collaboratorsIds}
        creatorUsername={creatorUsername}
        loggedUsername={loggedUsername}
        setIsError={setIsError}
        setIsSuccess={setIsSuccess}
        setModal={setModal}
        uuid={uuid}
      />
    </Modal>
  )
}

ShareListModal.propTypes = {
  collaboratorsIds: arrayOf(string).isRequired,
  creatorUsername: string.isRequired,
  modal: bool.isRequired,
  name: string.isRequired,
  setModal: func.isRequired,
  uuid: string.isRequired,
}

export default ShareListModal
