import {memo, useState} from 'react'
import {bool, func, string} from 'prop-types'
import {CloseModalButton, Modal, Text, UserFeedback} from 'components'
import AddToPipedriveForm from './components'

const AddToPipedriveModal = ({
  addFrom,
  city,
  country,
  diversityFounders,
  foundedOnYear,
  funding,
  homepageUrl,
  modal,
  name,
  orgUuid,
  setModal,
  shortDescription,
}) => {
  const [isSuccess, setIsSuccess] = useState(false)
  if (isSuccess)
    return (
      <UserFeedback isSuccess={isSuccess} setIsSuccess={setIsSuccess}>
        Company successfully added to Pipedrive
      </UserFeedback>
    )
  return (
    <Modal modal={modal} setModal={setModal}>
      <CloseModalButton setModal={setModal} />
      <Text variant="modal-title">Add {name} to Pipedrive</Text>
      <AddToPipedriveForm
        addFrom={addFrom}
        city={city}
        country={country}
        diversityFounders={diversityFounders}
        foundedOnYear={foundedOnYear}
        funding={funding}
        homepageUrl={homepageUrl}
        name={name}
        orgUuid={orgUuid}
        setIsSuccess={setIsSuccess}
        setModal={setModal}
        shortDescription={shortDescription}
      />
    </Modal>
  )
}

AddToPipedriveModal.propTypes = {
  addFrom: string.isRequired,
  city: string.isRequired,
  country: string.isRequired,
  diversityFounders: bool.isRequired,
  foundedOnYear: string.isRequired,
  funding: string.isRequired,
  homepageUrl: string.isRequired,
  modal: bool.isRequired,
  name: string.isRequired,
  orgUuid: string.isRequired,
  setModal: func.isRequired,
  shortDescription: string.isRequired,
}

export default memo(AddToPipedriveModal)
