import {bool, number} from 'prop-types'
import {Loader, Text} from 'components'
import {formatNumber} from 'utils'

const PageOfTotal = ({allDataLoading, currentPage, totalPages}) => (
  <Text variant="pagination" data-testid="page-of-total">
    Page <Text variant="pagination-strong">{formatNumber(currentPage)}</Text> of{' '}
    {allDataLoading ? (
      <Loader size="14" css={{d: 'inline-block', ml: '$8'}} />
    ) : (
      <Text variant="pagination-strong">{formatNumber(totalPages)}</Text>
    )}
  </Text>
)

PageOfTotal.propTypes = {
  allDataLoading: bool.isRequired,
  currentPage: number.isRequired,
  totalPages: number.isRequired,
}

export default PageOfTotal
