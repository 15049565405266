import {createContext, useContext, useState} from 'react'
import Cookies from 'js-cookie'
import {node} from 'prop-types'
import api from 'api'
import client from 'client'
import {formatFirstName, formatLastName} from 'utils'

const AuthContext = createContext({})

const useAuth = () => useContext(AuthContext)

const AuthProvider = ({children}) => {
  const localStorageUsername = localStorage.getItem('username')
  const cookiesUsername = Cookies.get('username')
  const [authState, setAuthState] = useState({
    status: 'pending',
    error: null,
    username: localStorageUsername || cookiesUsername || '',
    firstName:
      (localStorageUsername && formatFirstName(localStorageUsername)) ||
      (cookiesUsername && formatFirstName(cookiesUsername)) ||
      '',
    lastName:
      (localStorageUsername && formatLastName(localStorageUsername)) ||
      (cookiesUsername && formatLastName(cookiesUsername)) ||
      '',
  })
  const onLogin = ({exp, username}) => {
    const expireDate = new Date(+`${exp}000`)
    document.cookie = `signedin=true; path=/; domain=${
      document.domain
    }; expires=${expireDate.toUTCString()};`
    document.cookie = `username=${username}; path=/; domain=${
      document.domain
    }; expires=${expireDate.toUTCString()};`
    setAuthState({
      status: 'success',
      error: null,
      username,
      firstName: formatFirstName(username),
      lastName: formatLastName(username),
    })
    localStorage.setItem('username', username)
  }
  const onLogout = () => {
    document.cookie = `signedin=; path=/; domain=${document.domain}; expires=Thu, 01 Jan 1970 00:00:00 GMT;`
    document.cookie = `username=; path=/; domain=${document.domain}; expires=Thu, 01 Jan 1970 00:00:00 GMT;`
    setAuthState({
      status: 'loggedout',
      error: null,
      username: null,
      fistName: null,
      lastName: null,
    })
    localStorage.removeItem('username')
    client.resetStore()
    const cleanCookieOnServer = async () => {
      await api.get(`/logout`, {withCredentials: true})
    }
    cleanCookieOnServer()
  }
  return (
    <AuthContext.Provider
      value={{
        authState,
        onLogin,
        onLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: node.isRequired,
}

export {AuthContext, AuthProvider, useAuth}
