import {memo, useState} from 'react'
import {arrayOf, bool, shape, string} from 'prop-types'
import {FallbackImgCompanySm, FallbackImgPersonSm, StarIcon} from 'assets'
import {Box, Flex, Icon, Image, Link, SocialLinksList, Text} from 'components'
import {LabelList} from 'components/patterns/Labels/components'

const NameCell = ({
  row: {
    original: {
      allLabels,
      betalistUrl,
      capterraUrl,
      cbUrl,
      diversityFounders,
      fallbackLogoUrl,
      homepageUrl,
      labels,
      linkedinUrl,
      logoUrl,
      name,
      permalink,
      twitterUrl,
      type,
      uuid,
    },
  },
}) => {
  const [isImageLoadError, setIsImageLoadError] = useState(false)
  return (
    <Flex css={{mr: '$8'}}>
      <Image
        alt={type === 'person' ? name : `${name} logo`}
        onError={() => setIsImageLoadError(true)}
        size="32"
        src={
          (isImageLoadError && fallbackLogoUrl) ||
          (isImageLoadError && type === 'person' && FallbackImgPersonSm) ||
          (isImageLoadError &&
            type === 'organization' &&
            FallbackImgCompanySm) ||
          logoUrl ||
          FallbackImgCompanySm
        }
        variant="company"
      />
      <Flex direction="column" css={{ml: '$8', w: '-webkit-fill-available'}}>
        {permalink && uuid && (
          <Link
            css={{mb: '$4'}}
            external
            href={`/${
              type === 'person' ? 'people' : 'companies'
            }/${permalink}?uuid=${uuid}`}
          >
            <Text variant="name-cell">{name}</Text>
            {(diversityFounders === 'true' || diversityFounders === true) && (
              <Icon
                color="yellow100"
                css={{d: 'inline-block', ml: '$4', verticalAlign: 'bottom'}}
                data-testid="star"
                size="16"
              >
                <StarIcon />
              </Icon>
            )}
          </Link>
        )}
        {labels?.length > 0 ? (
          <Box css={{mb: '$8'}}>
            <LabelList allLabels={allLabels} labels={labels} />
          </Box>
        ) : null}
        <SocialLinksList
          betalistUrl={betalistUrl}
          capterraUrl={capterraUrl}
          cbUrl={cbUrl}
          homepageUrl={homepageUrl}
          linkedinUrl={linkedinUrl}
          name={name}
          twitterUrl={twitterUrl}
        />
      </Flex>
    </Flex>
  )
}

NameCell.propTypes = {
  row: shape({
    original: shape({
      allLabels: arrayOf(shape({})),
      betalistUrl: string,
      capterraUrl: string,
      cbUrl: string,
      diversityFounders: bool,
      fallbackLogoUrl: string,
      homepageUrl: string,
      labels: arrayOf(shape({})),
      linkedinUrl: string,
      logoUrl: string,
      name: string,
      permalink: string,
      twitterUrl: string,
      type: string,
      uuid: string,
    }),
  }).isRequired,
}

export default memo(NameCell)
