import {styled} from 'styles'

const Flex = styled('div', {
  boxSizing: 'border-box',
  d: 'flex',
  variants: {
    direction: {
      column: {
        fd: 'column',
      },
      row: {
        fd: 'row',
      },
    },
  },
})

export default Flex
