import {memo} from 'react'
import {string} from 'prop-types'
import {PipedriveIcon} from 'assets'
import {SocialLink} from 'components'

const PipedriveLink = ({pipedriveUrl}) => (
  <SocialLink
    href={pipedriveUrl}
    icon={<PipedriveIcon />}
    title="Pipedrive - p"
    variant="pipedrive-cell"
  />
)

PipedriveLink.propTypes = {
  pipedriveUrl: string.isRequired,
}

export default memo(PipedriveLink)
