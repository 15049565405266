import {shape, string} from 'prop-types'
import {Flex, Text} from 'components'

const TeamCell = ({
  row: {
    original: {team},
  },
}) =>
  team !== 'unk' ? (
    <Flex direction="column">
      <Text css={{whiteSpace: 'nowrap', lh: '$body'}}>{team}</Text>
    </Flex>
  ) : null

TeamCell.propTypes = {
  row: shape({
    original: shape({
      team: string,
    }),
  }).isRequired,
}

export default TeamCell
