import {memo, useState} from 'react'
import {bool, func} from 'prop-types'
import {Logo, Separator} from 'components'
import {ExtendButton, MenuNav, MobileButton} from './components'
import {Box, Flex} from './styles'

const Menu = ({extendMenu, setExtendMenu}) => {
  const [showExtendMenuButton, setShowExtendMenuButton] = useState(false)
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  return (
    <Box
      as="header"
      data-testid="menu"
      css={{
        bs: showMobileMenu ? '$2' : 'none',
        '@initial': {
          h: showMobileMenu ? '100vh' : 'auto',
        },
        '@bp3': {
          bs: '$2',
          w: extendMenu ? '192px' : '80px',
        },
      }}
      onMouseEnter={() => setShowExtendMenuButton(true)}
      onMouseLeave={() => setShowExtendMenuButton(false)}
      variant="menu"
    >
      <Flex variant="menu">
        <Logo showLabel={extendMenu} />
        <MobileButton
          setShowMobileMenu={setShowMobileMenu}
          showMobileMenu={showMobileMenu}
        />
      </Flex>
      <Separator css={{'@initial': {d: 'block'}, '@bp3': {d: 'none'}}} />
      {showExtendMenuButton && (
        <ExtendButton extendMenu={extendMenu} setExtendMenu={setExtendMenu} />
      )}
      <MenuNav
        extendMenu={extendMenu}
        setShowMobileMenu={setShowMobileMenu}
        showMobileMenu={showMobileMenu}
      />
    </Box>
  )
}

Menu.propTypes = {
  extendMenu: bool.isRequired,
  setExtendMenu: func.isRequired,
}

export default memo(Menu)
