import {memo} from 'react'
import {string} from 'prop-types'
import {NavLink} from 'react-router-dom'
import {LogoutIcon, SettingsIcon, ShortcutsIcon, HeartEmpty2Icon} from 'assets'
import {Icon} from 'components'
import {Flex, Text} from './styles'

const UserMenuItem = ({label, path, title}) => (
  <Flex as="li" variant="user-menu-item">
    <NavLink
      activeStyle={{
        backgroundColor: '#f9f2f5',
        color: '#8c003d',
      }}
      className="nav-link"
      title={title}
      to={`/${path}`}
    >
      <Icon size="20">
        {(path === 'my-lists' && <HeartEmpty2Icon />) ||
          (path === 'shortcuts' && <ShortcutsIcon />) ||
          (path === 'settings' && <SettingsIcon />) ||
          (path === 'logout' && <LogoutIcon />)}
      </Icon>
      <Text variant="header-menu">{label}</Text>
    </NavLink>
  </Flex>
)

UserMenuItem.propTypes = {
  label: string.isRequired,
  path: string.isRequired,
  title: string.isRequired,
}

export default memo(UserMenuItem)
