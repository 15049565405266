const getFormattedCollaboratorsUsers = (users, collaboratorsIds) =>
  users.map(({id, firstName, lastName, username, usercustomfield}) => {
    const name = `${firstName} ${lastName}`
    const {office} = usercustomfield
    const checkedId = collaboratorsIds?.length
      ? collaboratorsIds.find(userId => userId === id)
      : null
    return {
      id,
      name,
      username,
      office,
      isChecked: id === checkedId,
      isDisabled: id === checkedId,
    }
  })

export default getFormattedCollaboratorsUsers
