import {arrayOf, func, shape, string} from 'prop-types'
import {
  Box,
  Button,
  Flex,
  Label,
  MentionInput,
  OtherReasonForm,
} from 'components'
import useReviewForm from './hooks'

const ReviewForm = ({
  finalCompaniesToReview,
  reviewFrom,
  setFocusNextItem,
  setIsError,
  setIsReviewLoading,
  setModal,
}) => {
  const {
    commentText,
    commentTextRef,
    handleAddReview,
    handleNoCompetitionTooHigh,
    handleNoNotActive,
    handleNoNotInNautaScope,
    handleNoNotInteresting,
    handleNoPortfolioOverlap,
    handleNoSmallMarket,
    handleNoTeam,
    handleNoTooLate,
    handleNotSure,
    handleYes,
    setCommentText,
    yesRef,
  } = useReviewForm({
    finalCompaniesToReview,
    reviewFrom,
    setFocusNextItem,
    setIsError,
    setIsReviewLoading,
    setModal,
  })
  return (
    <>
      <Box css={{mb: '$16'}}>
        <Label htmlFor="commentText">Comment</Label>
        <MentionInput
          comment={commentText}
          inputRef={commentTextRef}
          name="commentText"
          onChange={e => setCommentText(e.target.value)}
          onKeyDown={({key}) => key === 'Escape' && setModal(false)}
        />
      </Box>
      <Flex
        direction="column"
        css={
          reviewFrom === 'companies-list-right'
            ? {'@bp3': {ai: 'flex-end'}}
            : {'@bp1': {fd: 'row'}}
        }
        data-testid="review-form"
      >
        <Box
          css={
            reviewFrom === 'companies-list-right'
              ? {mb: '$16'}
              : {mr: '$32', mb: '$16'}
          }
          data-testid="review-yes"
        >
          <Button
            color="green"
            onClick={handleYes}
            ref={yesRef}
            onKeyDown={({key}) => key === 'Escape' && setModal(false)}
            size="small"
            type="button"
          >
            Yes - 1
          </Button>
        </Box>
        <Box
          css={
            reviewFrom === 'companies-list-right'
              ? {mb: '$16'}
              : {mr: '$32', mb: '$16'}
          }
          data-testid="review-not-sure"
        >
          <Button
            color="yellow"
            onClick={handleNotSure}
            size="small"
            type="button"
          >
            Not sure - 2
          </Button>
        </Box>
        <Flex
          direction="column"
          css={
            reviewFrom === 'companies-list-right'
              ? {ai: 'flex-start', '@bp3': {ai: 'flex-end'}}
              : {ai: 'flex-start'}
          }
          data-testid="review-no"
        >
          <Button
            color="red"
            css={{mb: '$16'}}
            onClick={handleNoTooLate}
            size="small"
            type="button"
          >
            No, too late - 3
          </Button>
          <Button
            color="red"
            css={{mb: '$16'}}
            onClick={handleNoTeam}
            size="small"
            type="button"
          >
            No, team - 4
          </Button>
          <Button
            color="red"
            css={{mb: '$16'}}
            onClick={handleNoNotInNautaScope}
            size="small"
            type="button"
          >
            No, not in Nauta scope - 5
          </Button>
          <Button
            color="red"
            css={{mb: '$16'}}
            onClick={handleNoSmallMarket}
            size="small"
            type="button"
          >
            No, small market - 6
          </Button>
          <Button
            color="red"
            css={{mb: '$16'}}
            onClick={handleNoCompetitionTooHigh}
            size="small"
            type="button"
          >
            No, competition too high - 7
          </Button>
          <Button
            color="red"
            css={{mb: '$16'}}
            onClick={handleNoPortfolioOverlap}
            size="small"
            type="button"
          >
            No, portfolio overlap - 8
          </Button>
          <Button
            color="red"
            css={{mb: '$16'}}
            onClick={handleNoNotActive}
            size="small"
            type="button"
          >
            No, not active - 9
          </Button>
          <Button
            color="red"
            css={{mb: '$16'}}
            onClick={handleNoNotInteresting}
            size="small"
            type="button"
          >
            No, not interesting - 0
          </Button>
          <OtherReasonForm handleAddReview={handleAddReview} />
        </Flex>
      </Flex>
    </>
  )
}

ReviewForm.propTypes = {
  finalCompaniesToReview: arrayOf(shape({})).isRequired,
  reviewFrom: string.isRequired,
  setFocusNextItem: func.isRequired,
  setIsError: func.isRequired,
  setIsReviewLoading: func.isRequired,
  setModal: func.isRequired,
}

export default ReviewForm
