import {gql} from '@apollo/client'

const GET_ACTION_LIST = gql`
  query actionList($reviewed: Boolean) {
    actionList(reviewed: $reviewed) {
      id
      commentText
      createdAt
      sharedBy {
        firstName
        lastName
        username
        usercustomfield {
          id
          color
        }
      }
      totalCount
      totalReviews
      updateDate
      uuid {
        cbUrl
        city
        countryCode
        diversityFounders
        employeeCount
        extra
        foundedOn
        homepageUrl
        inMyActionlist
        inMyList
        lastComment {
          commentText
          updatedAt
          userName
        }
        lastReview {
          uuid
          nautable
          notNautableOther
          notNautableReason
          createdAt
          userName
        }
        linkedinUrl
        logoUrl
        name
        organizationlabelSet {
          addedByName
          commentText
          createdAt
          labelUuid {
            color
            name
            uuid
          }
          uuid
        }
        organizationpipedrive {
          uuid {
            uuid
          }
          pipedriveUrl
        }
        organizationscore {
          uuid {
            uuid
          }
          scorePercentile
        }
        organizationsharesSet {
          createdAt
          whoShares {
            username
            firstName
            lastName
          }
          sharedWith {
            id
            username
            firstName
            lastName
          }
        }
        permalink
        shortDescription
        source
        totalFundingUsd
        twitterUrl
        uuid
      }
    }
  }
`

export default GET_ACTION_LIST
