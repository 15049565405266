import {Flex as BaseFlex} from 'components/layout'
import {Text as BaseText} from 'components/primitives'
import {keyframes, styled} from 'styles'

const fadeIn = keyframes({
  from: {opacity: 0},
  to: {opacity: 1},
})

export const Flex = styled(BaseFlex, {
  variants: {
    variant: {
      'user-feedback': {
        ai: 'center',
        animation: `${fadeIn} 0.25s ease-in`,
        bottom: '$24',
        br: '$small',
        bs: '$2',
        left: '50%',
        padding: '$24 $32',
        position: 'fixed',
        transform: 'translate(-50%, 0)',
        w: 'auto',
        zIndex: '$zMax',
      },
    },
  },
})

export const Text = styled(BaseText, {
  variants: {
    variant: {
      feedback: {
        color: '$grey500',
        fontSize: '$14',
      },
    },
  },
})
