import {gql} from '@apollo/client'

export const GET_IS_LIST_MARKED_AS_DONE = gql`
  query listing($listUuid: String) {
    listing(listUuid: $listUuid) {
      objects {
        bcnDone
        ldnDone
        berDone: mucDone
        totalDone
        unkDone
        uuid
      }
    }
  }
`

export const GET_IS_CUSTOM_LIST_MARKED_AS_DONE = gql`
  query customListOrganizationPaginated(
    $listUuid: String!
    $reviewed: Boolean
    $page: Int
    $pageSize: Int
  ) {
    customListOrganizationPaginated(
      listUuid: $listUuid
      reviewed: $reviewed
      page: $page
      pageSize: $pageSize
    ) {
      objects {
        id
        listUuid {
          name
          totalDone
          uuid
        }
      }
    }
  }
`

export const GET_IS_AGGREGATED_LIST_MARKED_AS_DONE = gql`
  query organizationListed(
    $page: Int
    $pageSize: Int
    $listUuid: String
    $scope: String
    $reviewed: Boolean
    $week: Int
    $year: Int
  ) {
    organizationListed(
      page: $page
      pageSize: $pageSize
      listUuid: $listUuid
      scope: $scope
      reviewed: $reviewed
      week: $week
      year: $year
    ) {
      objects {
        id
        listUuid {
          bcnDone
          ldnDone
          berDone: mucDone
          unkDone
          totalDone
          uuid
          week
          year
        }
      }
    }
  }
`
