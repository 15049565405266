export const getBetalistUrl = extra => extra?.betalistData?.betalist_url

export const getCapterraUrl = extra => {
  if (extra !== null && extra?.capterraData?.capterra_url) {
    return extra.capterraData.capterra_url.startsWith(
      'https://www.capterra.com',
    )
      ? extra.capterraData.capterra_url
      : `https://www.capterra.com/${extra.capterraData.capterra_url}`
  }
  return null
}

export const getPipedriveUrl = data => data?.pipedriveUrl
