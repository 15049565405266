import {memo} from 'react'
import {func, node, string} from 'prop-types'
import {Icon, Link} from 'components'
import Box from './styles'

const SocialLink = ({ariaLabel, href, icon, onClick, title, variant}) => (
  <Link
    ariaLabel={ariaLabel}
    external
    href={href}
    onClick={onClick}
    title={title}
  >
    <Box
      css={{
        ml: variant === 'pipedrive' ? '$8' : undefined,
        wh: variant === 'profile' ? '$32' : '22px',
        mr: variant === 'profile' ? '$8' : '$4',
      }}
      variant="social-link"
    >
      <Icon color="grey300" size="full">
        {icon}
      </Icon>
    </Box>
  </Link>
)

SocialLink.propTypes = {
  ariaLabel: string.isRequired,
  href: string.isRequired,
  icon: node.isRequired,
  onClick: func.isRequired,
  title: string.isRequired,
  variant: string.isRequired,
}

export default memo(SocialLink)
