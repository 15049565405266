import {memo} from 'react'
import {bool, func, number, string} from 'prop-types'
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  FirstPageIcon,
  LastPageIcon,
} from 'assets'
import {Button, Grid, Icon} from 'components'
import useNav from './hooks'

const Nav = ({
  canNextPage,
  canPreviousPage,
  gotoPage,
  listType,
  nextPage,
  pageCount,
  pageInView,
  previousPage,
}) => {
  const {
    handleGoToFirstPage,
    handleGoToLastPage,
    handleGoToNextPage,
    handleGoToPreviousPage,
  } = useNav({
    gotoPage,
    listType,
    nextPage,
    pageCount,
    pageInView,
    previousPage,
  })
  return (
    <Grid
      css={{
        gcg: '$8',
        gtc: 'repeat(4, auto)',
        mt: '$16',
        '@bp1': {mt: 0},
      }}
      data-testid="nav"
    >
      <Button
        aria-label="Go to first page"
        css={{
          lh: '$solid',
          cursor: !canPreviousPage ? 'default' : undefined,
        }}
        disabled={!canPreviousPage}
        onClick={handleGoToFirstPage}
        size="small"
        title={canPreviousPage ? 'Go to first page' : ''}
        type="button"
        variant="secondary"
      >
        <Icon size="16">
          <FirstPageIcon />
        </Icon>
      </Button>
      <Button
        aria-label="Go to previous page"
        css={{
          lh: '$solid',
          cursor: !canPreviousPage ? 'default' : undefined,
        }}
        disabled={!canPreviousPage}
        onClick={handleGoToPreviousPage}
        size="small"
        title={canPreviousPage ? 'Go to previous page - left arrow' : ''}
        type="button"
        variant="secondary"
      >
        <Icon size="16">
          <ArrowLeftIcon />
        </Icon>
      </Button>
      <Button
        aria-label="Go to next page"
        css={{lh: '$solid', cursor: !canNextPage ? 'default' : ''}}
        disabled={!canNextPage}
        onClick={handleGoToNextPage}
        size="small"
        title={canNextPage ? 'Go to next page - right arrow' : ''}
        type="button"
        variant="secondary"
      >
        <Icon size="16">
          <ArrowRightIcon />
        </Icon>
      </Button>
      <Button
        aria-label="Go to last page"
        css={{lh: '$solid', cursor: !canNextPage ? 'default' : ''}}
        disabled={!canNextPage}
        onClick={handleGoToLastPage}
        size="small"
        title={canNextPage ? 'Go to last page' : ''}
        type="button"
        variant="secondary"
      >
        <Icon size="16">
          <LastPageIcon />
        </Icon>
      </Button>
    </Grid>
  )
}

Nav.propTypes = {
  canNextPage: bool.isRequired,
  canPreviousPage: bool.isRequired,
  gotoPage: func.isRequired,
  listType: string.isRequired,
  nextPage: func.isRequired,
  pageCount: number.isRequired,
  pageInView: number.isRequired,
  previousPage: func.isRequired,
}

export default memo(Nav)
