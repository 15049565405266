import {formatFallbackImg, getEditedOrOriginal, formatExtra} from 'utils'

const formatCompaniesDropdownResults = data =>
  data
    .filter(({searchType}) => searchType === 'OrganizationType')
    .map(
      ({
        companyPermalink,
        extra,
        logoUrl,
        name,
        shortDescription,
        ...company
      }) => {
        const extraData = formatExtra(extra)
        return {
          ...company,
          logoUrl,
          fallbackLogoUrl: formatFallbackImg(logoUrl),
          name: getEditedOrOriginal('name', extraData, name),
          type: 'organization',
          permalink: companyPermalink,
          shortDescription: getEditedOrOriginal(
            'shortDescription',
            extraData,
            shortDescription,
          ),
        }
      },
    )
    .map(({__typename, companyPermalink, ...company}) => company)

export default formatCompaniesDropdownResults
