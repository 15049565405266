import {gql} from '@apollo/client'

export const GET_COMPANY_COMMENTS = gql`
  query comments($orgUuid: String!) {
    comments: comment(orgUuid: $orgUuid) {
      commentText
      createdAt
      orgUuid
      updatedAt
      userId
      userName
      uuid
    }
  }
`

export const GET_EVENT_COMMENTS = gql`
  query eventComment($eventUuid: String!) {
    eventComment(eventUuid: $eventUuid) {
      uuid
      eventUuid
      userName
      createdAt
      updatedAt
      commentText
    }
  }
`

export const GET_PERSON_COMMENTS = gql`
  query personComment($personUuid: String!) {
    comments: personComment(personUuid: $personUuid) {
      commentText
      createdAt
      personUuid
      updatedAt
      userId
      userName
      uuid
    }
  }
`
