import {
  ActionsCell,
  CommentsOnCell,
  CustomSort,
  LastCommentCell,
  LocationCell,
  NameCell,
  NautableCell,
  NewEmployeesCell,
  PipedriveCell,
  SelectColumnFilter,
  SharedOrSavedByCell,
  SharedByFilter,
  TeamCell,
} from 'components/table'
import {formatAmountUSD} from 'utils'

const name = {
  Header: 'Name',
  accessor: 'name',
  Cell: NameCell,
  sortType: CustomSort,
}

const pipedrive = {
  Header: 'PD',
  accessor: 'isOnPipedrive',
  Cell: PipedriveCell,
  Filter: SelectColumnFilter,
  filter: 'equals',
}

const location = {
  Header: 'Location',
  accessor: 'location',
  Cell: LocationCell,
}

const year = {
  Header: 'Year',
  accessor: 'foundedOnYear',
  Cell: ({value}) => (value !== 'unk' ? value : null),
  Filter: SelectColumnFilter,
  filter: 'equals',
}

const team = {
  Header: 'Team',
  accessor: 'team',
  Cell: TeamCell,
  Filter: SelectColumnFilter,
  filter: 'equals',
}

const newEmployees = {
  Header: 'New employees / mo',
  accessor: 'newEmployeesPerMonthValue',
  Cell: NewEmployeesCell,
  Filter: SelectColumnFilter,
  filter: 'equals',
}

const funding = {
  Header: 'Funding',
  accessor: 'funding',
  Cell: ({value}) => (value ? formatAmountUSD(value) : null),
  disableFilters: true,
}

const shortDescription = {
  Header: 'Short description',
  accessor: 'shortDescription',
  sortType: CustomSort,
}

const percentile = {
  Header: '%tile',
  accessor: 'scorePercentile',
  Cell: ({value}) => (value !== 'unk' ? `${value}th` : null),
  Filter: SelectColumnFilter,
  filter: 'equals',
}

const nautable = {
  Header: 'Nautable',
  accessor: 'nautable',
  Cell: NautableCell,
  Filter: SelectColumnFilter,
  filter: 'equals',
}

const savedBy = {
  Header: 'Saved by',
  accessor: row => `${row.savedByName.firstName} ${row.savedByName.lastName}`,
  Cell: SharedOrSavedByCell,
  Filter: SharedByFilter,
  filter: 'equals',
}

const sharedBy = {
  Header: 'Shared by',
  accessor: row => `${row.sharedBy.firstName} ${row.sharedBy.lastName}`,
  Cell: SharedOrSavedByCell,
  Filter: SharedByFilter,
  filter: 'equals',
}

const commentOnShareOrSave = {
  Header: 'Comment on share/save',
  accessor: 'commentOnSaveOrShare',
  Cell: CommentsOnCell,
  disableSortBy: true,
  disableFilters: true,
}

const commentOnSave = {
  Header: 'Comment on save',
  accessor: 'commentOnSave',
  Cell: CommentsOnCell,
  disableSortBy: true,
  disableFilters: true,
}

const lastComment = {
  Header: 'Last comment',
  accessor: 'lastComment.commentText',
  Cell: LastCommentCell,
  disableSortBy: true,
}

const actions = {
  Header: 'Actions',
  accessor: 'actions',
  Cell: ActionsCell,
  disableSortBy: true,
  disableFilters: true,
}

const source = {
  Header: 'Source',
  accessor: 'listType',
  Filter: SelectColumnFilter,
  filter: 'equals',
}

export const actionListColumns = [
  name,
  pipedrive,
  location,
  year,
  team,
  funding,
  shortDescription,
  percentile,
  nautable,
  lastComment,
  sharedBy,
  commentOnShareOrSave,
  actions,
]

export const aggregatedColumns = [
  name,
  pipedrive,
  location,
  year,
  team,
  funding,
  shortDescription,
  percentile,
  nautable,
  lastComment,
  source,
  actions,
]

export const employeeGrowthColumns = [
  name,
  pipedrive,
  location,
  year,
  team,
  newEmployees,
  funding,
  shortDescription,
  percentile,
  nautable,
  lastComment,
  actions,
]

export const myListsCompaniesListColumns = [
  name,
  pipedrive,
  location,
  year,
  team,
  funding,
  shortDescription,
  percentile,
  nautable,
  lastComment,
  savedBy,
  commentOnSave,
  actions,
]

export const searchCompaniesColumns = [
  name,
  pipedrive,
  location,
  year,
  team,
  funding,
  shortDescription,
  percentile,
  nautable,
  actions,
]

export const standardColumns = [
  name,
  pipedrive,
  location,
  year,
  team,
  funding,
  shortDescription,
  percentile,
  nautable,
  lastComment,
  actions,
]
