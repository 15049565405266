import {
  formatCountryToString,
  formatExtra,
  formatLocation,
  getEditedOrOriginal,
  getEditedOrOriginalDiversityFounders,
  getEditedOrOriginalHomepageUrl,
  getEditedOrOriginalTwitterUrl,
  getPipedriveUrl,
} from 'utils'

const formatPortfolioFundingRounds = data =>
  data
    .map(round => {
      const extraData = formatExtra(round.orgUuid.extra)
      const country = formatCountryToString(round.countryCode)
      return {
        ...round,
        categoryList: round.orgUuid.categoryList,
        cbUrl: round.orgUuid.cbUrl,
        country,
        diversityFounders: getEditedOrOriginalDiversityFounders(
          extraData,
          round.orgUuid.diversityFounders,
        ),
        homepageUrl: getEditedOrOriginalHomepageUrl(
          extraData,
          round.orgUuid.homepageUrl,
        ),
        linkedinUrl: getEditedOrOriginal(
          'linkedinUrl',
          extraData,
          round.orgUuid.linkedinUrl,
        ),
        location: formatLocation(round.city, country),
        logoUrl: round.orgUuid.logoUrl,
        orgName: getEditedOrOriginal('name', extraData, round.orgName),
        orgPermalink: round.orgUuid.permalink,
        orgUuid: round.orgUuid.uuid,
        pipedriveUrl: getPipedriveUrl(round.orgUuid.organizationpipedrive),
        twitterUrl: getEditedOrOriginalTwitterUrl(
          extraData,
          round.orgUuid.twitterUrl,
        ),
      }
    })
    .map(({__typename, countryCode, ...round}) => round)
    .slice()
    .sort((a, b) => new Date(b.announcedOn) - new Date(a.announcedOn))

export default formatPortfolioFundingRounds
