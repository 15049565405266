import {memo} from 'react'
import {func} from 'prop-types'
import {AddListIcon} from 'assets'
import {Button, Icon, Flex, Text} from 'components'

const CreateNewListButton = ({setCreateNewListModal, setSaveToListModal}) => (
  <Button
    css={{
      mt: '$16',
      bgc: 'transparent',
      w: '$full',
      '&:hover': {boc: 'transparent'},
      '&:focus': {bs: 'none'},
      pl: '$8',
    }}
    onClick={() => {
      setSaveToListModal(false)
      setCreateNewListModal(true)
    }}
    title="Create new list"
    type="button"
    size="small"
  >
    <Flex css={{ai: 'center'}}>
      <Icon data-testid="icon" size="32">
        <AddListIcon />
      </Icon>
      <Text css={{ml: '$8', color: '$primary'}}>Create new list</Text>
    </Flex>
  </Button>
)

CreateNewListButton.propTypes = {
  setCreateNewListModal: func.isRequired,
  setSaveToListModal: func.isRequired,
}

export default memo(CreateNewListButton)
