import {memo} from 'react'
import {arrayOf, shape, string} from 'prop-types'
import {Box} from 'components'
import {formatDate} from 'utils'
import Text from './styles'

const NewEmployeesCell = ({
  row: {
    original: {newEmployeesPerMonth, newEmployeesPerMonthValue},
  },
}) =>
  newEmployeesPerMonthValue !== 'unk' ? (
    <Box>
      <Text variant="new-employees-latest-value">
        {newEmployeesPerMonth.new_employees_per_month}
      </Text>
      <Text variant="new-employees">
        From:{' '}
        <Text variant="new-employees-value">
          {newEmployeesPerMonth.staff_count_previous}
        </Text>{' '}
        in{' '}
        {formatDate(
          newEmployeesPerMonth.staff_count_previous_crawled_on_str_date,
        )}
      </Text>
      <Text variant="new-employees">
        To:{' '}
        <Text variant="new-employees-value">
          {newEmployeesPerMonth.staff_count_current}
        </Text>{' '}
        in{' '}
        {formatDate(
          newEmployeesPerMonth.staff_count_current_crawled_on_str_date,
        )}
      </Text>
    </Box>
  ) : null

NewEmployeesCell.propTypes = {
  row: shape({
    original: shape({
      newEmployeesPerMonth: arrayOf(shape({})),
      newEmployeesPerMonthValue: string,
    }),
  }).isRequired,
}

export default memo(NewEmployeesCell)
