import {memo} from 'react'
import {shape, string} from 'prop-types'
import {formatFirstAndLastName, formatDate, formatComment} from 'utils'
import {LastCommentCellContainer, Text} from './styles'

const LastCommentCell = ({
  row: {
    original: {lastComment},
  },
}) =>
  lastComment ? (
    <LastCommentCellContainer>
      <Text variant="last-comment-cell">
        {formatComment(lastComment.commentText)}
      </Text>
      <Text variant="nautable-cell-by">
        By{' '}
        <Text variant="nautable-cell-name">
          {formatFirstAndLastName(lastComment.userName)}
        </Text>
        <Text variant="nautable-cell-date">
          on {formatDate(lastComment.updatedAt)}
        </Text>
      </Text>
    </LastCommentCellContainer>
  ) : null

LastCommentCell.propTypes = {
  row: shape({
    original: shape({
      lastComment: shape({
        commentText: string,
        updatedAt: string,
        userName: string,
      }),
    }),
  }).isRequired,
}

export default memo(LastCommentCell)
