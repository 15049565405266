import {useEffect, useRef} from 'react'
import {useMutation} from '@apollo/client'
import {
  REMOVE_COMPANY_FROM_LIST,
  REMOVE_FROM_ACTION_LIST,
} from 'constants/mutations'
import {
  GET_ACTION_LIST_COMPANIES_MY_LISTS_REMOVED,
  GET_MY_LISTS_REMOVED,
} from './queries'

const useRemoveFromListForm = ({
  isLastItem,
  itemIndex,
  listName,
  listUuid,
  logoUrl,
  orgName,
  orgUuid,
  pageIndex,
  previousPage,
  setCompanyRemoved,
  setIsLoading,
  setIsRemoveError,
  setIsRemoveSuccess,
  setLogoUrlRemove,
  setModal,
}) => {
  const yesRef = useRef(null)
  const [removeFromList, {loading: isRemoveFromListLoading}] = useMutation(
    REMOVE_COMPANY_FROM_LIST,
    {
      onCompleted: () => {
        setIsRemoveSuccess(true)
        setModal(false)
        setCompanyRemoved(orgName)
        setLogoUrlRemove(logoUrl)
        if (pageIndex !== 0 && isLastItem && itemIndex === pageIndex * 3) {
          previousPage()
        }
      },
      onError: err => {
        setIsRemoveError(true)
        console.error(err)
      },
    },
  )
  const [
    removeFromActionList,
    {loading: isRemoveFromActionListLoading},
  ] = useMutation(REMOVE_FROM_ACTION_LIST, {
    onCompleted: () => {
      setIsRemoveSuccess(true)
      setModal(false)
      setCompanyRemoved(orgName)
      setLogoUrlRemove(logoUrl)
      if (pageIndex !== 0 && isLastItem && itemIndex === pageIndex * 3) {
        previousPage()
      }
    },
    onError: err => {
      setIsRemoveError(true)
      console.error(err)
    },
  })
  useEffect(() => yesRef?.current && yesRef.current.focus(), [])
  useEffect(() => {
    if (isRemoveFromListLoading || isRemoveFromActionListLoading) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [isRemoveFromListLoading, isRemoveFromActionListLoading, setIsLoading])
  const handleRemoveFromList = () => {
    if (listName === 'Action List') {
      removeFromActionList({
        variables: {uuid: orgUuid},
        refetchQueries: [{query: GET_ACTION_LIST_COMPANIES_MY_LISTS_REMOVED}],
      })
    } else {
      removeFromList({
        variables: {listUuid, orgUuid},
        refetchQueries: [{query: GET_MY_LISTS_REMOVED}],
      })
    }
  }
  return {
    handleRemoveFromList,
    yesRef,
  }
}

export default useRemoveFromListForm
