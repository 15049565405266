const getFormattedUsers = users =>
  users.map(({id, firstName, lastName, username, usercustomfield}) => {
    const name = `${firstName} ${lastName}`
    const {office} = usercustomfield
    return {
      id,
      name,
      username,
      office,
      isChecked: false,
    }
  })

export default getFormattedUsers
