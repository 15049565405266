import {memo} from 'react'
import {string} from 'prop-types'
import {Text} from 'components'

const Owner = ({creatorName}) => (
  <Text css={{mt: '$8', lh: '$body'}} variant="card-subtitle-my-lists">
    Owner
    <Text
      css={{
        d: 'inline',
        color: '$grey300',
        fontWeight: '$300',
        lh: '$body',
        ml: '$8',
      }}
    >
      {creatorName}
    </Text>
  </Text>
)

Owner.propTypes = {
  creatorName: string.isRequired,
}

export default memo(Owner)
