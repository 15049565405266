import {arrayOf, func, node, shape, string, bool} from 'prop-types'
import {Box, Label, Select, Text} from 'components'
import SelectOptions from './components'

const SelectField = ({
  children,
  css,
  defaultValue,
  name,
  options,
  register,
  required,
  errors,
}) => {
  return (
    <Box css={css}>
      <Label color={errors && 'red'} htmlFor={name}>
        {children}
        {required && <Text variant="required">*</Text>}
      </Label>
      <Select
        data-testid="select"
        defaultValue={defaultValue}
        id={name}
        name={name}
        ref={register}
        bordercolor={errors && 'red'}
      >
        {options.map(({id, ...props}) => (
          <SelectOptions key={id} {...props} />
        ))}
      </Select>
      {errors && errors.type === 'required' && (
        <Text variant="form-error">Required</Text>
      )}
    </Box>
  )
}

SelectField.propTypes = {
  children: node.isRequired,
  css: node.isRequired,
  defaultValue: string.isRequired,
  name: string.isRequired,
  options: arrayOf(shape({})).isRequired,
  register: func.isRequired,
  required: bool.isRequired,
  errors: func.isRequired,
}

export default SelectField
