import {useEffect, useState} from 'react'
import formatListName from './utils'

const useMyListsListItem = ({
  companiesToSave,
  initialSelectedListNames,
  initialSelectedListUuid,
  isEveryOrgInList,
  isOrgInList,
  isSomeOrgInList,
  listName,
  listUuid,
  orgUuid,
  setSelectedListsNamesToRemove,
  setSelectedListsNamesToSave,
  setSelectedListsUuidsToRemove,
  setSelectedListsUuidsToSave,
}) => {
  const [isListSelected, setIsListSelected] = useState(
    (orgUuid && isOrgInList) ||
      (companiesToSave?.length > 0 && isEveryOrgInList),
  )
  const [isSomeActive, setIsSomeActive] = useState(isSomeOrgInList)
  useEffect(() => {
    if (companiesToSave?.length > 0 && isEveryOrgInList) {
      setIsListSelected(true)
    }
  }, [companiesToSave, isEveryOrgInList, setIsListSelected])
  const handleSelectListToBeSaved = () => {
    setIsListSelected(true)
    setIsSomeActive(false)
    const initialSelectedUuid = initialSelectedListUuid.find(
      uuid => uuid === listUuid,
    )
    if (initialSelectedUuid !== listUuid || isSomeOrgInList) {
      setSelectedListsUuidsToSave(prevSelectedListsUuidsToSave => [
        ...prevSelectedListsUuidsToSave,
        listUuid,
      ])
    }
    const initialSelectedName = initialSelectedListNames.find(
      name => name === listName,
    )
    if (initialSelectedName !== listName || isSomeOrgInList) {
      setSelectedListsNamesToSave(prevSelectedListsNamesToSave => [
        ...prevSelectedListsNamesToSave,
        listName,
      ])
    }
    setSelectedListsUuidsToRemove(prevSelectedListsUuidsToRemove =>
      prevSelectedListsUuidsToRemove.filter(uuid => uuid !== listUuid),
    )
    setSelectedListsNamesToRemove(prevSelectedListsNamesToRemove =>
      prevSelectedListsNamesToRemove.filter(name => name !== listName),
    )
  }
  const handleSelectListToBeRemoved = () => {
    setIsListSelected(false)
    setIsSomeActive(false)
    const initialSelectedUuid = initialSelectedListUuid.find(
      uuid => uuid === listUuid,
    )
    if (initialSelectedUuid === listUuid || isSomeOrgInList) {
      setSelectedListsUuidsToRemove(prevSelectedListsUuidsToRemove => [
        ...prevSelectedListsUuidsToRemove,
        listUuid,
      ])
    }
    const initialSelectedNames = initialSelectedListNames.find(
      name => name === listName,
    )
    if (initialSelectedNames === listName || isSomeOrgInList) {
      setSelectedListsNamesToRemove(prevSelectedListsNamesToRemove => [
        ...prevSelectedListsNamesToRemove,
        listName,
      ])
    }
    setSelectedListsUuidsToSave(prevSelectedListsUuidsToSave =>
      prevSelectedListsUuidsToSave.filter(uuid => uuid !== listUuid),
    )
    setSelectedListsNamesToSave(prevSelectedListsNamesToSave =>
      prevSelectedListsNamesToSave.filter(name => name !== listName),
    )
  }
  const isSave =
    (orgUuid && !isListSelected) ||
    (companiesToSave?.length > 0 && !isListSelected)
  const formattedListName = formatListName(listName)
  return {
    formattedListName,
    handleSelectListToBeRemoved,
    handleSelectListToBeSaved,
    isSave,
    isSomeActive,
  }
}

export default useMyListsListItem
