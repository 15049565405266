import {func, string} from 'prop-types'
import {Box, ErrorMessage, Label, Loader, Select} from 'components'
import useContactField from './hooks'

const ContactField = ({defaultValue, orgUuid, register}) => {
  const {
    CONTACT_OPTIONS,
    currentTeamData,
    isCurrentTeamQueryError,
    isCurrentTeamQueryLoading,
  } = useContactField({orgUuid})
  return (
    <Box variant={isCurrentTeamQueryError && 'span-column'}>
      <Label htmlFor="contactPersonUuid">Contact</Label>
      {isCurrentTeamQueryLoading && <Loader size="20" data-testid="loader" />}
      {isCurrentTeamQueryError && (
        <ErrorMessage>
          An error occurred getting the company&apos;s current team data. Please
          try to refresh or report this bug.
        </ErrorMessage>
      )}
      {currentTeamData && !isCurrentTeamQueryError && (
        <Select
          data-testid="select"
          defaultValue={defaultValue}
          id="contactPersonUuid"
          name="contactPersonUuid"
          ref={register}
        >
          {CONTACT_OPTIONS.map(({id, value, label}) => (
            <option key={id} value={value} data-testid="options">
              {label}
            </option>
          ))}
        </Select>
      )}
    </Box>
  )
}

ContactField.propTypes = {
  defaultValue: string.isRequired,
  orgUuid: string.isRequired,
  register: func.isRequired,
}

export default ContactField
