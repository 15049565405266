import {memo} from 'react'
import {arrayOf, bool, func, number, shape, string} from 'prop-types'
import {Box, Flex, ShortcutsButton} from 'components'
import {
  BulkOpenLinks,
  BulkReviewButton,
  BulkSaveButton,
  BulkShareButton,
  MarkListAsDoneButton,
  ShowColumnsButton,
} from './components'

const TableActions = ({
  companiesToOpenLinks,
  companiesToReviewLength,
  companiesToSaveLength,
  companiesToShareLength,
  hasHiddenColumns,
  isReviewLoading,
  listType,
  listUuid,
  reviewed,
  scope,
  setReviewModal,
  setSaveRemoveListModal,
  setShareModal,
  setShowColumnsModal,
  setShowShortcuts,
  showShortcuts,
  week,
  year,
}) => (
  <Flex direction={{'@initial': 'column', '@bp1': 'row'}} css={{mb: '$8'}}>
    <Box css={{mr: 0, '@bp1': {mr: '$16'}}}>
      <BulkOpenLinks
        companiesToOpenLinks={companiesToOpenLinks}
        listType={listType}
      />
    </Box>
    <Flex direction="row" css={{fw: 'wrap', fg: '1'}}>
      {listType !== 'investors-page' && (
        <>
          <BulkReviewButton
            companiesToReviewLength={companiesToReviewLength}
            isReviewLoading={isReviewLoading}
            setReviewModal={setReviewModal}
          />
          <BulkShareButton
            companiesToShareLength={companiesToShareLength}
            setShareModal={setShareModal}
          />
          <BulkSaveButton
            companiesToSaveLength={companiesToSaveLength}
            setSaveRemoveListModal={setSaveRemoveListModal}
          />
        </>
      )}
      <ShortcutsButton
        setShowShortcuts={setShowShortcuts}
        showShortcuts={showShortcuts}
      />
      {listType !== 'investors-page' && (
        <ShowColumnsButton
          hasHiddenColumns={hasHiddenColumns}
          setShowColumnsModal={setShowColumnsModal}
        />
      )}
    </Flex>
    {listType !== 'action-list' &&
      listType !== 'companies-page' &&
      listType !== 'action-list-my-lists' &&
      listType !== 'investors-page' && (
        <MarkListAsDoneButton
          listType={listType}
          listUuid={listUuid}
          reviewed={reviewed}
          scope={scope}
          week={week}
          year={year}
        />
      )}
  </Flex>
)

TableActions.propTypes = {
  companiesToOpenLinks: arrayOf(shape({})).isRequired,
  companiesToReviewLength: number.isRequired,
  companiesToSaveLength: number.isRequired,
  companiesToShareLength: number.isRequired,
  hasHiddenColumns: bool.isRequired,
  isReviewLoading: bool.isRequired,
  listType: string.isRequired,
  listUuid: string.isRequired,
  reviewed: bool.isRequired,
  scope: string.isRequired,
  setReviewModal: func.isRequired,
  setSaveRemoveListModal: func.isRequired,
  setShareModal: func.isRequired,
  setShowColumnsModal: func.isRequired,
  setShowShortcuts: func.isRequired,
  showShortcuts: bool.isRequired,
  week: string.isRequired,
  year: string.isRequired,
}

export default memo(TableActions)
