import {memo, useState} from 'react'
import {bool, shape, string} from 'prop-types'
import {AddToPipedrive} from 'components'
import PipedriveLink from './components'

const PipedriveCell = ({
  row: {
    original: {
      city,
      country,
      diversityFounders,
      foundedOnYear,
      funding,
      homepageUrl,
      isOnPipedrive,
      name,
      pipedriveUrl,
      shortDescription,
      uuid,
    },
  },
}) => {
  const [addToPipedriveModal, setAddToPipedriveModal] = useState(false)
  return isOnPipedrive === 'No' ? (
    <AddToPipedrive
      addFrom="companies-list"
      city={city}
      country={country}
      diversityFounders={diversityFounders}
      foundedOnYear={foundedOnYear}
      funding={funding}
      homepageUrl={homepageUrl}
      modal={addToPipedriveModal}
      name={name}
      setModal={setAddToPipedriveModal}
      shortDescription={shortDescription}
      uuid={uuid}
      variant="ghost"
    />
  ) : (
    <PipedriveLink pipedriveUrl={pipedriveUrl} />
  )
}

PipedriveCell.propTypes = {
  row: shape({
    original: shape({
      city: string,
      countryCode: string,
      diversityFounders: bool,
      funding: string,
      homepageUrl: string,
      isOnPipedrive: string,
      name: string,
      pipedriveUrl: string,
      shortDescription: string,
      uuid: string,
      year: string,
    }),
  }).isRequired,
}

export default memo(PipedriveCell)
