const useSelectAllCheckbox = ({
  checkedUsersIdsOtherOffices,
  checkedUsersIdsSameOffice,
  isCheckedMyself,
  myId,
  office,
  setCheckedUsersIds,
  setCheckedUsersIdsSameOffice,
  setUsers,
  users,
}) => {
  const handleSelectAll = checked => {
    let newUser = [...users]
    if (!checked) {
      newUser = newUser.map(user => ({...user, isChecked: false}))
      if (isCheckedMyself) {
        setCheckedUsersIds([...checkedUsersIdsOtherOffices, myId])
        setCheckedUsersIdsSameOffice([])
      } else {
        setCheckedUsersIds([...checkedUsersIdsOtherOffices])
        setCheckedUsersIdsSameOffice([])
      }
    } else {
      newUser = newUser.map(user => ({...user, isChecked: true}))
      const selectedUsersId = newUser.map(user => user.id)
      if (isCheckedMyself) {
        setCheckedUsersIdsSameOffice([...selectedUsersId])
        setCheckedUsersIds([
          ...new Set([
            ...checkedUsersIdsSameOffice,
            ...checkedUsersIdsOtherOffices,
            ...selectedUsersId,
            myId,
          ]),
        ])
      } else {
        setCheckedUsersIdsSameOffice([...selectedUsersId])
        setCheckedUsersIds([
          ...new Set([
            ...checkedUsersIdsSameOffice,
            ...checkedUsersIdsOtherOffices,
            ...selectedUsersId,
          ]),
        ])
      }
    }
    setUsers(prevUsers => ({...prevUsers, [office]: newUser}))
  }
  return {
    handleSelectAll,
  }
}

export default useSelectAllCheckbox
