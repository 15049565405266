import {
  ActionsCell,
  CategoriesCell,
  CustomSort,
  DateCell,
  LocationCell,
  NameCell,
  NautableCell,
  PipedriveCell,
  SelectColumnFilter,
  ValueOrEmptyCell,
} from 'components/table'

const portfolioCompaniesColumns = [
  {
    Header: 'Company',
    accessor: 'name',
    Cell: NameCell,
    sortType: CustomSort,
  },
  {
    Header: 'PD',
    accessor: 'isOnPipedrive',
    Cell: PipedriveCell,
    Filter: SelectColumnFilter,
    filter: 'equals',
  },
  {
    Header: 'Location',
    accessor: 'location',
    Cell: LocationCell,
  },
  {
    Header: 'Year',
    accessor: 'foundedOnYear',
    Cell: ValueOrEmptyCell,
    Filter: SelectColumnFilter,
    filter: 'equals',
  },
  {
    Header: 'Team',
    accessor: 'team',
    Cell: ValueOrEmptyCell,
    Filter: SelectColumnFilter,
    filter: 'equals',
  },
  {
    Header: 'First investment on',
    accessor: 'firstInvestmentOn',
    Cell: DateCell,
  },
  {
    Header: 'Categories',
    accessor: 'categoryList',
    Cell: CategoriesCell,
  },
  {
    Header: 'Short description',
    accessor: 'shortDescription',
    sortType: CustomSort,
  },
  {
    Header: 'Nautable',
    accessor: 'nautable',
    Cell: NautableCell,
    Filter: SelectColumnFilter,
    filter: 'equals',
  },
  {
    Header: 'Actions',
    accessor: 'uuid',
    Cell: ActionsCell,
    disableSortBy: true,
    disableFilters: true,
  },
]

export default portfolioCompaniesColumns
