import {bool, string} from 'prop-types'
import {CheckCircleIcon, CheckCircleOutlinedIcon} from 'assets'
import {Button, Icon, Loader, UserFeedback} from 'components'
import useMarkListAsDone from './hooks'

const MarkListAsDoneButton = ({
  listType,
  listUuid,
  scope,
  week,
  year,
  reviewed,
}) => {
  const {
    handleMarkListAsDone,
    isAggregatedListMarkedAsDoneQueryError,
    isAggregatedListMarkedAsDoneQueryLoading,
    isCustomListMarkedAsDoneQueryError,
    isCustomListMarkedAsDoneQueryLoading,
    isError,
    isListMarkedAsDoneQueryError,
    isListMarkedAsDoneQueryLoading,
    isMarkAsDoneError,
    isMarkAsDoneLoading,
    isMarkedAsDone,
    setIsError,
  } = useMarkListAsDone({listType, listUuid, scope, week, year, reviewed})
  return (
    <>
      <Button
        css={{
          as: 'flex-start',
          w: 'fit-content',
          mt: '$16',
          whiteSpace: 'nowrap',
          '@bp2': {mt: 0, ml: '$16'},
          '@landscape678': {mt: 0},
          svg: {
            color: isMarkedAsDone ? '$green' : '$grey300',
          },
        }}
        onClick={() => handleMarkListAsDone()}
        size="small"
        title={`${
          (isMarkedAsDone === null && 'loading') ||
          (isMarkedAsDone && 'Unmark') ||
          (!isMarkedAsDone && 'Mark')
        } as done`}
        type="button"
        variant="secondary-icon"
      >
        {isMarkAsDoneLoading ||
        isMarkedAsDone === null ||
        isAggregatedListMarkedAsDoneQueryLoading ||
        isCustomListMarkedAsDoneQueryLoading ||
        isListMarkedAsDoneQueryLoading ? (
          <Loader size="16" data-testid="loader" />
        ) : (
          <>
            <Icon size="14" color="green" css={{mr: '5px'}}>
              {isMarkedAsDone ? (
                <CheckCircleIcon />
              ) : (
                <CheckCircleOutlinedIcon />
              )}
            </Icon>
            {isMarkedAsDone ? 'Unmark' : 'Mark'} as done
          </>
        )}
      </Button>
      {(isListMarkedAsDoneQueryError ||
        isMarkAsDoneError ||
        isCustomListMarkedAsDoneQueryError ||
        isAggregatedListMarkedAsDoneQueryError) &&
        isError && (
          <UserFeedback isError={isError} setIsError={setIsError}>
            An error occurred {isMarkAsDoneError ? 'updating' : 'getting'} the
            list status. Please try again or report this bug.
          </UserFeedback>
        )}
    </>
  )
}

MarkListAsDoneButton.propTypes = {
  listType: string.isRequired,
  listUuid: string.isRequired,
  scope: string.isRequired,
  week: string.isRequired,
  year: string.isRequired,
  reviewed: bool.isRequired,
}

export default MarkListAsDoneButton
