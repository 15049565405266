import {Arrow, Close, Content} from '@radix-ui/react-popover'
import {keyframes, styled} from 'styles'

export const PopoverClose = styled(Close, {
  all: 'unset',
  as: 'end',
  mt: '$8',
  ml: '$16',
})

export const PopoverArrow = styled(Arrow, {
  fill: '$white',
})

const slideUp = keyframes({
  '0%': {opacity: 0, transform: 'translateY(10px)'},
  '100%': {opacity: 1, transform: 'translateY(0)'},
})

const slideDown = keyframes({
  '0%': {opacity: 0, transform: 'translateY(-10px)'},
  '100%': {opacity: 1, transform: 'translateY(0)'},
})

export const PopoverContent = styled(Content, {
  br: '$small',
  bs: '$3',
  bgc: '$white',
  p: '$24',
  '&[data-side="top"]': {animationName: slideUp},
  '&[data-side="bottom"]': {animationName: slideDown},
  animationDuration: '0.6s',
  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
})
