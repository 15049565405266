import {memo} from 'react'
import {func} from 'prop-types'
import {CloseIcon} from 'assets'
import {Box, Button, Icon} from 'components'

const CloseModalButton = ({onClick}) => (
  <Box css={{position: 'absolute', top: '$16', right: '$16'}}>
    <Button
      onClick={onClick}
      size="ghost-small"
      title="Close modal"
      type="button"
      variant="ghost-icon"
    >
      <Icon color="grey200" size="32">
        <CloseIcon />
      </Icon>
    </Button>
  </Box>
)

CloseModalButton.propTypes = {
  onClick: func.isRequired,
}

export default memo(CloseModalButton)
