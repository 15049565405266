import {memo, useState} from 'react'
import {arrayOf, bool, func, shape, string} from 'prop-types'
import {Button, Flex, Loader, ReviewModal} from 'components'

const ReviewCompany = ({
  buttonSize,
  companiesToReview,
  companyToReview,
  isTableReviewLoading,
  modal,
  reviewFrom,
  setFocusNextItem,
  setModal,
  variant,
}) => {
  const [isReviewLoading, setIsReviewLoading] = useState(false)
  return (
    <>
      {reviewFrom === 'company-profile' ? (
        <Button
          size={buttonSize}
          variant={variant}
          onClick={() => setModal(true)}
          title="Add review - r"
        >
          {isReviewLoading ? (
            <Flex>
              Add review
              <Loader size="16" css={{ml: '$8'}} data-testid="loader" />
            </Flex>
          ) : (
            'Add review'
          )}
        </Button>
      ) : (
        <Button
          color="grey"
          size={buttonSize}
          variant={variant}
          onClick={() => setModal(true)}
          title="Add review - r"
        >
          {isReviewLoading || isTableReviewLoading ? (
            <Loader size="16" data-testid="loader" />
          ) : (
            'Review'
          )}
        </Button>
      )}
      <ReviewModal
        companiesToReview={companiesToReview}
        companyToReview={companyToReview}
        modal={modal}
        reviewFrom={reviewFrom}
        setFocusNextItem={setFocusNextItem}
        setIsReviewLoading={setIsReviewLoading}
        setModal={setModal}
      />
    </>
  )
}

ReviewCompany.propTypes = {
  buttonSize: string.isRequired,
  companiesToReview: arrayOf(shape({})).isRequired,
  companyToReview: arrayOf(shape({})).isRequired,
  isTableReviewLoading: bool.isRequired,
  modal: bool.isRequired,
  reviewFrom: string.isRequired,
  setFocusNextItem: func.isRequired,
  setModal: func.isRequired,
  variant: string.isRequired,
}

export default memo(ReviewCompany)
