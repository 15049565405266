import {useEffect, useRef, useState} from 'react'
import {useQuery, useMutation} from '@apollo/client'
import camelcaseKeys from 'camelcase-keys'
import {useForm} from 'react-hook-form'
import {useAuth} from 'context'
import {GET_USERS} from 'constants/queries'
import {
  formatCountryToAlpha3,
  formatOffice,
  getFormattedUsers,
  getMentionedUserIds,
  getMyselfInAllUsers,
  getUsersOtherOffices,
  getUsersSameOffice,
} from 'utils'
import ADD_NEW_COMPANY from './mutations'

const useAddCompanyForm = ({setModal}) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [permalink, setPermalink] = useState('')
  const [uuid, setUuid] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [url, setUrl] = useState('')
  const [myOffice, setMyOffice] = useState('')
  const [users, setUsers] = useState({})
  const [usersSameOffice, setUsersSameOffice] = useState([])
  const [usersOtherOffices, setUsersOtherOffices] = useState([])
  const [myUser, setMyUser] = useState({})
  const [checkedUsersIds, setCheckedUsersIds] = useState([])
  const [isCheckedMyself, setIsCheckedMyself] = useState(true)
  const [checkedUsersIdsSameOffice, setCheckedUsersIdsSameOffice] = useState([])
  const [myId, setMyId] = useState('')
  const [
    checkedUsersIdsOtherOffices,
    setCheckedUsersIdsOtherOffices,
  ] = useState([])
  const [comment, setComment] = useState('')
  const companyNameRef = useRef(null)
  const {register, handleSubmit, errors} = useForm({mode: 'onBlur'})
  const {authState} = useAuth()
  const me = authState.username
  const {
    data: usersData,
    error: isUsersQueryError,
    loading: isUsersQueryLoading,
  } = useQuery(GET_USERS)
  const [
    addNewCompany,
    {error: isAddNewCompanyError, loading: isAddNewCompanyLoading},
  ] = useMutation(ADD_NEW_COMPANY, {
    onCompleted: ({addOrganization: {addOrganization}}) => {
      setUuid(addOrganization.uuid)
      setPermalink(addOrganization.permalink)
      setIsSuccess(true)
      setModal(false)
    },
    onError: ({graphQLErrors}) => {
      if (graphQLErrors) {
        const [error] = graphQLErrors?.map(({message}) =>
          camelcaseKeys(JSON.parse(message.replace(/'/g, '"'))),
        )
        setErrorMsg(error?.msg)
        setUrl(error?.url?.slice(23))
      }
    },
  })
  useEffect(() => companyNameRef?.current && companyNameRef.current.focus(), [])
  useEffect(() => {
    if (usersSameOffice && usersOtherOffices && myOffice) {
      setUsers({
        [myOffice]: usersSameOffice,
        otherOffices: usersOtherOffices,
        me: myUser,
      })
    }
  }, [usersSameOffice, usersOtherOffices, myUser, myOffice])
  useEffect(() => {
    if (usersData?.users && me) {
      const formattedUsers = getFormattedUsers(usersData.users)
      const myself = getMyselfInAllUsers(formattedUsers, me)
      setMyUser({...myself, isChecked: true})
      setMyId(myself.id)
      setCheckedUsersIds([myself.id])
      setMyOffice(myself.office)
      setUsersSameOffice(getUsersSameOffice(formattedUsers, myself))
      setUsersOtherOffices(getUsersOtherOffices(formattedUsers, myself))
    }
  }, [usersData, me])
  const handleAddNewCompany = ({
    city,
    countryCode,
    diversityFounders,
    foundedOnYear,
    homepageUrl,
    linkedinUrl,
    name,
    shortDescription,
  }) => {
    addNewCompany({
      variables: {
        city,
        commentText: comment,
        countryCode: formatCountryToAlpha3(countryCode),
        diversityFounders,
        foundedOn: foundedOnYear,
        homepageUrl,
        linkedinUrl,
        mentionedUserIds: getMentionedUserIds(comment),
        name,
        shortDescription,
        userIds: checkedUsersIds.join(),
      },
    })
  }
  const handleSaveToMyActionList = e => {
    const {checked} = e.target
    setUsers({...users, me: {...users.me, isChecked: checked}})
    if (checked) {
      setIsCheckedMyself(true)
      setCheckedUsersIds([...checkedUsersIds, myId])
    } else {
      setIsCheckedMyself(false)
      setCheckedUsersIds(checkedUsersIds.filter(id => id !== myId))
    }
  }
  const formattedMyOffice = formatOffice(myOffice)
  return {
    checkedUsersIds,
    checkedUsersIdsOtherOffices,
    checkedUsersIdsSameOffice,
    comment,
    companyNameRef,
    errorMsg,
    errors,
    formattedMyOffice,
    handleAddNewCompany,
    handleSaveToMyActionList,
    handleSubmit,
    isAddNewCompanyError,
    isAddNewCompanyLoading,
    isCheckedMyself,
    isSuccess,
    isUsersQueryError,
    isUsersQueryLoading,
    myId,
    myOffice,
    permalink,
    register,
    setCheckedUsersIds,
    setCheckedUsersIdsOtherOffices,
    setCheckedUsersIdsSameOffice,
    setComment,
    setUsers,
    url,
    users,
    usersData,
    uuid,
  }
}

export default useAddCompanyForm
