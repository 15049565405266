import {func, string} from 'prop-types'
import {ErrorMessage, Loader} from 'components'
import CategoriesTable from './components'
import useCategories from './hooks'

const Categories = ({investorUuid, selectedTab, setHasCategories}) => {
  const {
    columns,
    data,
    isPortfolioCategoriesQueryError,
    isPortfolioCategoriesQueryLoading,
  } = useCategories({investorUuid, setHasCategories})
  if (isPortfolioCategoriesQueryLoading && selectedTab === 'categories')
    return <Loader size="20" data-testid="loader" />
  if (isPortfolioCategoriesQueryError)
    return (
      <ErrorMessage css={{mb: '$16'}}>
        An error occurred getting the portfolio categories data. Please try to
        refresh or report this bug.
      </ErrorMessage>
    )
  return (
    selectedTab === 'categories' &&
    data?.length > 0 && (
      <CategoriesTable
        columns={columns}
        data={data}
        listType="portfolio-categories"
      />
    )
  )
}

Categories.propTypes = {
  investorUuid: string.isRequired,
  selectedTab: string.isRequired,
  setHasCategories: func.isRequired,
}

export default Categories
