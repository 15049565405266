import {memo, useMemo} from 'react'
import {arrayOf, func, number, shape, string} from 'prop-types'
import {SelectFilter} from 'components'

const SelectColumnFilter = ({
  column: {filterValue, setFilter, preFilteredRows, id},
  gotoPage,
  state: {pageIndex},
}) => {
  const options = useMemo(() => {
    const newOptions = new Set()
    preFilteredRows.forEach(row => newOptions.add(row.values[id]))
    return [...newOptions.values()]
  }, [id, preFilteredRows])
  const sortedOptions = options.sort((a, b) =>
    typeof a === 'number' ? a - b : a.localeCompare(b),
  )
  return (
    <SelectFilter
      filterValue={filterValue}
      gotoPage={gotoPage}
      pageIndex={pageIndex}
      setFilter={setFilter}
      sortedOptions={sortedOptions}
    />
  )
}

SelectColumnFilter.propTypes = {
  column: shape({
    filterValue: shape({}),
    id: string,
    preFilteredRows: arrayOf(shape({})),
    setFilter: func,
  }).isRequired,
  gotoPage: func.isRequired,
  state: shape({
    pageIndex: number,
  }).isRequired,
}

export default memo(SelectColumnFilter)
