import {useCallback, useRef, useEffect, useState} from 'react'
import {useMutation} from '@apollo/client'
import {ADD_COMPANY_COMMENT, ADD_REVIEW} from 'constants/mutations'
import {
  GET_COMPANY_COMMENTS,
  GET_COMPANY_LAST_REVIEW,
  GET_REVIEWS,
} from 'constants/queries'
import {useKeyShortcut} from 'hooks'
import {getMentionedUserIds} from 'utils'
import GET_COMPANY_LAST_COMMENT from './queries'

const useReviewForm = ({
  finalCompaniesToReview,
  reviewFrom,
  setFocusNextItem,
  setIsError,
  setIsReviewLoading,
  setModal,
}) => {
  const [commentText, setCommentText] = useState('')
  const yesRef = useRef()
  const commentTextRef = useRef(null)
  useEffect(() => yesRef?.current && yesRef.current.focus(), [])
  const [addReview] = useMutation(ADD_REVIEW, {
    onCompleted: () => setIsReviewLoading(false),
    onError: error => {
      setIsError(true)
      console.error(error)
    },
  })
  const [addComment] = useMutation(ADD_COMPANY_COMMENT, {
    onError: error => {
      setIsError(true)
      console.error(error)
    },
  })
  const handleAddReview = useCallback(
    (nautable, notNautableReason, notNautableOther) => {
      setIsReviewLoading(true)
      finalCompaniesToReview.map(({orgUuid}) => {
        addReview({
          variables: {
            orgUuid,
            nautable,
            notNautableReason,
            notNautableOther,
          },
          refetchQueries: (reviewFrom === 'company-profile' && [
            {query: GET_REVIEWS, variables: {orgUuid}},
          ]) || [
            {
              query: GET_COMPANY_LAST_REVIEW,
              variables: {
                uuid: orgUuid,
              },
            },
          ],
        })
        if (commentText) {
          addComment({
            variables: {
              orgUuid,
              commentText,
              mentionedUserIds: getMentionedUserIds(commentText),
            },
            refetchQueries: (reviewFrom === 'company-profile' && [
              {query: GET_COMPANY_COMMENTS, variables: {orgUuid}},
            ]) || [
              {
                query: GET_COMPANY_LAST_COMMENT,
                variables: {uuid: orgUuid},
              },
            ],
          })
          setCommentText('')
        }
        return null
      })
      if (reviewFrom !== 'company-profile') setFocusNextItem(true)
      setModal(false)
    },
    [
      addComment,
      addReview,
      commentText,
      finalCompaniesToReview,
      reviewFrom,
      setFocusNextItem,
      setIsReviewLoading,
      setModal,
    ],
  )
  const handleYes = () => handleAddReview('yes')
  const handleNotSure = () => handleAddReview('not_sure')
  const handleNoTooLate = () => handleAddReview('no', 'too_late')
  const handleNoTeam = () => handleAddReview('no', 'team')
  const handleNoNotInNautaScope = () =>
    handleAddReview('no', 'not_in_nauta_scope')
  const handleNoSmallMarket = () => handleAddReview('no', 'small_market')
  const handleNoCompetitionTooHigh = () =>
    handleAddReview('no', 'competition_too_high')
  const handleNoPortfolioOverlap = () =>
    handleAddReview('no', 'portfolio_overlap')
  const handleNoNotActive = () => handleAddReview('no', 'not_active')
  const handleNoNotInteresting = () => handleAddReview('no', 'not_interesting')
  useKeyShortcut('1', () => handleYes(), true)
  useKeyShortcut('2', () => handleNotSure(), true)
  useKeyShortcut('3', () => handleNoTooLate(), true)
  useKeyShortcut('4', () => handleNoTeam(), true)
  useKeyShortcut('5', () => handleNoNotInNautaScope(), true)
  useKeyShortcut('6', () => handleNoSmallMarket(), true)
  useKeyShortcut('7', () => handleNoCompetitionTooHigh(), true)
  useKeyShortcut('8', () => handleNoPortfolioOverlap(), true)
  useKeyShortcut('9', () => handleNoNotActive(), true)
  useKeyShortcut('0', () => handleNoNotInteresting(), true)
  return {
    commentText,
    commentTextRef,
    handleAddReview,
    handleNoCompetitionTooHigh,
    handleNoNotActive,
    handleNoNotInNautaScope,
    handleNoNotInteresting,
    handleNoPortfolioOverlap,
    handleNoSmallMarket,
    handleNoTeam,
    handleNoTooLate,
    handleNotSure,
    handleYes,
    setCommentText,
    yesRef,
  }
}

export default useReviewForm
