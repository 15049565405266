import {string} from 'prop-types'

const ValueOrEmptyCell = ({value}) =>
  (value === 'unknown' && 'Unknown') || value || '-'

ValueOrEmptyCell.propTypes = {
  value: string,
}

ValueOrEmptyCell.defaultProps = {
  value: '',
}

export default ValueOrEmptyCell
