import {ArrowUpIcon} from 'assets'
import {Icon} from 'components'

const ArrowUp = () => (
  <Icon color="grey300" size="17">
    <ArrowUpIcon data-testid="icon" />
  </Icon>
)

export default ArrowUp
