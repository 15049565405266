import {memo} from 'react'
import {string} from 'prop-types'
import {Text} from 'components'

const OverviewItem = ({title, value}) => (
  <>
    <Text variant="grid-title">{title}</Text>
    <Text variant="grid-value">{value}</Text>
  </>
)

OverviewItem.propTypes = {
  title: string.isRequired,
  value: string.isRequired,
}

export default memo(OverviewItem)
