import {bool, func, string} from 'prop-types'
import {Button, CancelButton, Flex, InputField, Loader} from 'components'
import useRequestWebScrapingForm from './hooks'

const RequestWebScrapingForm = ({
  editionUuid,
  isRequesWebscrapingLoading,
  requestWebscraping,
  setModal,
}) => {
  const {
    errors,
    handleRequestWebScraping,
    handleSubmit,
    register,
    webScrapingCommentRef,
  } = useRequestWebScrapingForm({editionUuid, requestWebscraping})
  return (
    <Flex
      as="form"
      direction="column"
      onSubmit={handleSubmit(handleRequestWebScraping)}
    >
      <InputField
        errors={errors.webscrapingComment}
        name="webscrapingComment"
        register={e => {
          register(e, {required: true})
          webScrapingCommentRef.current = e
        }}
        onKeyDown={({key}) => key === 'Escape' && setModal(false)}
        required
        type="text"
      >
        Comment (Please add the companies link)
      </InputField>
      <Flex css={{as: 'flex-end', gap: '$16', mt: '$24'}}>
        <CancelButton onClick={() => setModal(false)}>Cancel</CancelButton>
        <Flex as={Button} type="submit">
          Request
          {isRequesWebscrapingLoading && (
            <Loader size="14" css={{ml: '$8'}} data-testid="loader" />
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

RequestWebScrapingForm.propTypes = {
  editionUuid: string.isRequired,
  isRequesWebscrapingLoading: bool.isRequired,
  requestWebscraping: func.isRequired,
  setModal: func.isRequired,
}

export default RequestWebScrapingForm
