import {func, number, string} from 'prop-types'
import {Flex, Select, Text} from 'components'
import useResultsPerPageSelect from './hooks'

const ResultsPerPageSelect = ({
  listType,
  pageIndex,
  pageSize,
  setPageSize,
  totalCount,
}) => {
  const {
    element,
    pageSizesList,
    pathname,
    setCompaniesSearchPage,
    setCompaniesSearchPageSize,
    setInvestorsSearchPage,
    setInvestorsSearchPageSize,
    setPeopleSearchPage,
    setPeopleSearchPageSize,
  } = useResultsPerPageSelect({listType, totalCount})
  return (
    <Flex
      css={{
        ai: 'center',
        mb: '$8',
        '@bp1': {mb: 0},
      }}
      data-testid="results-per-page-select"
    >
      <Text variant="pagination" css={{mr: '$8'}}>
        {element} per page:
      </Text>
      <Select
        aria-label={`${element} per page`}
        data-testid="select-companies-per-page"
        title={`${element} per page`}
        type="number"
        value={pageSize}
        onChange={({target: {value}}) => {
          const newPageSize = Number(value)
          setPageSize(newPageSize)
          if (pathname === '/companies') {
            if (pageIndex + 1 > 1) {
              setCompaniesSearchPage(1)
            }
            setCompaniesSearchPageSize(newPageSize)
          }
          if (pathname === '/investors') {
            if (pageIndex + 1 > 1) {
              setInvestorsSearchPage(1)
            }
            setInvestorsSearchPageSize(newPageSize)
          }
          if (pathname === '/people') {
            if (pageIndex + 1 > 1) {
              setPeopleSearchPage(1)
            }
            setPeopleSearchPageSize(newPageSize)
          }
        }}
      >
        {pageSizesList.map(pageSizeSelectItem => (
          <option
            data-testid="options-companies-per-page"
            key={pageSizeSelectItem}
            value={
              pageSizeSelectItem !== 'all' ? pageSizeSelectItem : totalCount
            }
          >
            {pageSizeSelectItem}
          </option>
        ))}
      </Select>
    </Flex>
  )
}

ResultsPerPageSelect.propTypes = {
  listType: string.isRequired,
  pageIndex: number.isRequired,
  pageSize: number.isRequired,
  setPageSize: func.isRequired,
  totalCount: number.isRequired,
}

export default ResultsPerPageSelect
