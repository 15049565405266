import {bool, string} from 'prop-types'
import {Box, Button, Loader, UserFeedback} from 'components'
import {formatComment, formatDateTime, formatFirstAndLastName} from 'utils'
import useLabelItem from './hooks'
import {ListItem, Text} from './styles'

const LabelItem = ({
  color,
  commentText,
  createdAt,
  id,
  isPerson,
  label,
  username,
  uuid,
}) => {
  const {
    handleDeleteLabel,
    isDeleteLabelError,
    isDeleteLabelLoading,
    isError,
    loggedUser,
    setIsError,
  } = useLabelItem({
    id,
    isPerson,
  })
  return (
    <ListItem>
      <Box>
        <Text variant="username">{formatFirstAndLastName(username)}</Text>
        <Text variant="added-label">added the label</Text>
        <Text variant="label" css={{color}}>
          {label}
        </Text>
        <Text variant="date">{formatDateTime(createdAt)}</Text>
        {commentText && (
          <Text variant="comment">{formatComment(commentText)}</Text>
        )}
        {username === loggedUser && (
          <Button
            color="grey"
            css={{d: 'inline-flex'}}
            onClick={() => handleDeleteLabel(uuid)}
            size="ghost-small"
            type="button"
            variant="ghost"
          >
            Delete{' '}
            {isDeleteLabelLoading && (
              <Loader size="14" css={{ml: '$8'}} data-testid="loader" />
            )}
          </Button>
        )}
      </Box>
      {isError && isDeleteLabelError && (
        <UserFeedback isError={isError} setIsError={setIsError}>
          Error deleting label. Please try again or report this bug.
        </UserFeedback>
      )}
    </ListItem>
  )
}

LabelItem.propTypes = {
  color: string.isRequired,
  commentText: string.isRequired,
  createdAt: string.isRequired,
  id: string.isRequired,
  isPerson: bool.isRequired,
  label: string.isRequired,
  username: string.isRequired,
  uuid: string.isRequired,
}

export default LabelItem
