import {Text as BaseText} from 'components/primitives'
import {styled} from 'styles'

const Text = styled(BaseText, {
  variants: {
    variant: {
      'job-description': {
        d: '-webkit-box',
        lh: '$body',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 5,
        maxWidth: '300px',
      },
    },
  },
})

export default Text
