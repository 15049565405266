import {memo} from 'react'
import {arrayOf, bool, number, string} from 'prop-types'
import {useLocation} from 'react-router-dom'
import {Box, Flex, Loader, Text} from 'components'
import {
  Actions,
  Collaborators,
  Description,
  Owner,
  Name,
  UpdatedOn,
} from './components'

const MyListsHeader = ({
  collaborators,
  collaboratorsIds,
  creatorId,
  creatorName,
  creatorOffice,
  creatorUsername,
  description,
  filteredCompaniesLength,
  isCreatorOrCollaborator,
  isFiltered,
  isReviewed,
  loading,
  loggedUserUsername,
  name,
  total,
  updatedAt,
  uuid,
}) => {
  const {pathname} = useLocation()
  const isMyListsPage = pathname === '/my-lists'
  return (
    (name ||
      description ||
      updatedAt ||
      total ||
      creatorName ||
      collaborators) && (
      <Box css={{mb: '$24', w: '$full'}}>
        <Flex
          css={{
            ai: 'baseline',
            fd: 'column',
            jc: 'space-between',
            mb: '$8',
            '@bp1': {fd: isMyListsPage ? 'row' : 'column', mb: 0},
            '@bp4': {fd: 'row'},
          }}
        >
          {isMyListsPage ? (
            <Name name={name} uuid={uuid} />
          ) : (
            <Flex css={{ai: 'baseline', fd: 'column', '@bp1': {fd: 'row'}}}>
              <Text as="h1" css={{mb: '$8'}} variant="section-title">
                My List Companies - {name}
              </Text>
              {loading && !isMyListsPage && (
                <Loader size="16" css={{ml: '$8'}} />
              )}
              {total ? (
                <Text
                  css={{
                    d: 'inline',
                    lh: '$body',
                    color: '$grey300',
                    fontWeight: '$300',
                    mb: '$8',
                    '@bp1': {ml: '$8'},
                    '@bp3': {
                      mb: 0,
                    },
                  }}
                >
                  (
                  {isFiltered && filteredCompaniesLength !== total
                    ? `${filteredCompaniesLength} of ${total}`
                    : total}{' '}
                  companies{isReviewed && ' reviewed'})
                </Text>
              ) : null}
            </Flex>
          )}
          {loading && isMyListsPage && (
            <Box css={{fg: 2}}>
              <Loader size="16" css={{ml: '$8'}} />
            </Box>
          )}
          {updatedAt && (
            <UpdatedOn
              name={name}
              isMyListsPage={isMyListsPage}
              updatedAt={updatedAt}
            />
          )}
          {isCreatorOrCollaborator && name !== 'Action List' && (
            <Actions
              collaboratorsIds={collaboratorsIds}
              creatorId={creatorId}
              creatorOffice={creatorOffice}
              creatorUsername={creatorUsername}
              description={description}
              isMyListsPage={isMyListsPage}
              loggedUserUsername={loggedUserUsername}
              name={name}
              uuid={uuid}
            />
          )}
        </Flex>
        {description && <Description description={description} />}
        {creatorName && <Owner creatorName={creatorName} />}
        {collaborators && <Collaborators collaborators={collaborators} />}
      </Box>
    )
  )
}

MyListsHeader.propTypes = {
  collaborators: string.isRequired,
  collaboratorsIds: arrayOf(string).isRequired,
  creatorId: string.isRequired,
  creatorName: string.isRequired,
  creatorOffice: string.isRequired,
  creatorUsername: string.isRequired,
  description: string.isRequired,
  filteredCompaniesLength: string.isRequired,
  isCreatorOrCollaborator: bool.isRequired,
  isFiltered: bool.isRequired,
  isReviewed: bool.isRequired,
  loading: bool.isRequired,
  loggedUserUsername: string.isRequired,
  name: string.isRequired,
  total: number.isRequired,
  updatedAt: string.isRequired,
  uuid: string.isRequired,
}

export default memo(MyListsHeader)
