import {useEffect, useMemo, useRef, useState} from 'react'
import {useLazyQuery} from '@apollo/client'
import {useLocation} from 'react-router-dom'
import {useInputQuery, useSearch} from 'context'
import {useClickInside} from 'hooks'
import SEARCH from './queries'
import {
  formatCompaniesDropdownResults,
  formatInvestorsDropdownResults,
} from './utils'

const useSearchBar = () => {
  const [searchDropdown, setSearchDropdown] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const searchBackgroundRef = useRef()
  const searchInputRef = useRef()
  const {pathname} = useLocation()
  const {searchLoading} = useSearch()
  const {query, setQuery} = useInputQuery()
  useClickInside(searchBackgroundRef, () => setSearchDropdown(false))
  const [
    runDropdownSearch,
    {data: searchData, loading: isSearchLoading, error: isSearchError},
  ] = useLazyQuery(SEARCH, {
    onCompleted: () => setSearchDropdown(true),
    onError: error => {
      setIsError(true)
      setErrorMsg(error?.toString())
      console.error(error)
    },
  })
  const companiesDropdownResults = useMemo(
    () =>
      searchData?.searchResults?.length > 0
        ? formatCompaniesDropdownResults(searchData.searchResults)
        : [],
    [searchData],
  )
  const investorDropdownResults = useMemo(
    () =>
      searchData?.searchResults?.length > 0
        ? formatInvestorsDropdownResults(searchData.searchResults)
        : [],
    [searchData],
  )
  const isNotCompaniesAndPeopleAndInvestorsPage =
    pathname !== '/companies' &&
    pathname !== '/people' &&
    pathname !== '/investors'
  useEffect(() => {
    if (query?.length && isNotCompaniesAndPeopleAndInvestorsPage) {
      runDropdownSearch({
        variables: {
          q: query,
          organization: true,
          person: false,
          investor: true,
          size: 5,
        },
        suspend: false,
      })
    }
  }, [isNotCompaniesAndPeopleAndInvestorsPage, query, runDropdownSearch])
  return {
    companiesDropdownResults,
    errorMsg,
    investorDropdownResults,
    isError,
    isNotCompaniesAndPeopleAndInvestorsPage,
    isSearchError,
    isSearchLoading,
    pathname,
    query,
    searchBackgroundRef,
    searchDropdown,
    searchInputRef,
    searchLoading,
    setIsError,
    setQuery,
    setSearchDropdown,
  }
}

export default useSearchBar
