import {useState} from 'react'
import {shape, string} from 'prop-types'
import {
  CrunchbaseIcon,
  FacebookIcon,
  FallbackImgPersonLg,
  LinkedinIcon,
  TwitterIcon,
} from 'assets'
import {Image, Link, SocialLink} from 'components'
import {formatFallbackImg} from 'utils'
import {Flex, Text} from './styles'

const PeopleItem = ({
  personName,
  personUuid: {
    cbUrl,
    facebookUrl,
    linkedinUrl,
    logoUrl,
    permalink,
    twitterUrl,
    uuid,
  },
  title,
}) => {
  const [isImageLoadError, setIsImageLoadError] = useState(false)
  return (
    <Flex
      as="li"
      direction="column"
      variant="people-item"
      data-testid="people-item"
    >
      <Image
        alt={personName}
        css={{mb: '$8'}}
        onError={() => setIsImageLoadError(true)}
        size={{'@initial': '64', '@bp2': '100'}}
        src={
          isImageLoadError
            ? formatFallbackImg(logoUrl)
            : logoUrl || FallbackImgPersonLg
        }
        variant="person"
      />
      <Link
        external
        href={`/people/${permalink}?uuid=${uuid}`}
        variant="name"
        title={personName}
      >
        {personName}
      </Link>
      <Text variant="person-title">{title}</Text>
      <Flex>
        {cbUrl && (
          <SocialLink
            href={cbUrl}
            icon={<CrunchbaseIcon />}
            title={`${personName} Crunchbase`}
            variant="people"
          />
        )}
        {linkedinUrl && (
          <SocialLink
            href={linkedinUrl}
            icon={<LinkedinIcon />}
            title={`${personName} LinkedIn`}
            variant="people"
          />
        )}
        {twitterUrl && (
          <SocialLink
            href={twitterUrl}
            icon={<TwitterIcon />}
            title={`${personName} Twitter`}
            variant="people"
          />
        )}
        {facebookUrl && (
          <SocialLink
            href={facebookUrl}
            icon={<FacebookIcon />}
            title={`${personName} Facebook`}
            variant="people"
          />
        )}
      </Flex>
    </Flex>
  )
}

PeopleItem.propTypes = {
  personName: string.isRequired,
  personUuid: shape({
    cbUrl: string,
    facebookUrl: string,
    linkedinUrl: string,
    logoUrl: string,
    permalink: string,
    twitterUrl: string,
    uuid: string,
  }).isRequired,
  title: string.isRequired,
}

export default PeopleItem
