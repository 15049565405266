import {styled} from 'styles'

const Label = styled('label', {
  color: '$grey300',
  d: 'block',
  fontSize: '$13',
  lh: '$solid',
  mb: '$8',
  variants: {
    color: {
      red: {
        color: '$red',
      },
    },
  },
})

export default Label
