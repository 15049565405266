import {styled} from 'styles'

const Textarea = styled('textarea', {
  b: '1px solid $grey100',
  br: '$small',
  fontSize: '$13',
  h: 'auto',
  lh: '$body',
  outline: 'none',
  overflow: 'auto',
  p: '$8',
  resize: 'none',
  w: '$full',
  '&::placeholder': {
    color: '$grey200',
  },
  '&:focus': {
    boc: '$black',
  },
  variants: {
    borderColor: {
      red: {
        boc: '$red',
      },
    },
    size: {
      large: {
        p: '$12',
      },
    },
  },
})

export default Textarea
