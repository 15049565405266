import {arrayOf, bool, func, shape} from 'prop-types'
import {Input} from 'components'
import getFilteredData from './utils'

const MyListsFilter = ({data, setIsFiltered, setFilteredData}) => (
  <Input
    aria-label="Search"
    css={{mb: '$8'}}
    onChange={e => {
      if (e.target.value) {
        setIsFiltered(true)
      } else {
        setIsFiltered(false)
      }
      setFilteredData(getFilteredData(data, e.target.value.toLowerCase()))
    }}
    placeholder="Search list"
    size="small"
  />
)

MyListsFilter.propTypes = {
  data: arrayOf(shape({})).isRequired,
  setIsFiltered: bool.isRequired,
  setFilteredData: func.isRequired,
}

export default MyListsFilter
