import {memo} from 'react'
import {bool, string} from 'prop-types'
import {Text} from 'components'

const UpdatedOn = ({name, isMyListsPage, updatedAt}) => (
  <Text
    css={{
      color: '$grey200',
      fontSize: '$11',
      fg: '2',
      lh: '$body',
      mb: name === 'Action List' ? 0 : '$8',
      '@bp1': {
        ml: isMyListsPage ? '$8' : 0,
        mb: isMyListsPage ? 0 : '$8',
      },
      '@bp4': {
        ml: '$8',
        mb: 0,
      },
    }}
  >
    Updated on {updatedAt}
  </Text>
)

UpdatedOn.propTypes = {
  name: string.isRequired,
  isMyListsPage: bool.isRequired,
  updatedAt: string.isRequired,
}

export default memo(UpdatedOn)
