import {gql} from '@apollo/client'

export const GET_ACTION_LIST_PAGE_COUNT_UPDATE_DATE = gql`
  query actionList($reviewed: Boolean) {
    actionList(reviewed: $reviewed) {
      id
      totalCount
      totalReviews
      updateDate
      uuid {
        uuid
      }
    }
  }
`

export const GET_SUMMARY_IN_MY_LISTS = gql`
  query organization($uuid: String, $permalink: String) {
    summary: organization(uuid: $uuid, permalink: $permalink) {
      uuid
      inMyActionlist
      inMyList
    }
  }
`

export const GET_MY_LIST_COMPANIES_LIST_COMPANIES_SAVED_OR_REMOVED = gql`
  query customListOrganizationPaginated(
    $listUuid: String!
    $reviewed: Boolean
    $page: Int
    $pageSize: Int
  ) {
    customListOrganizationPaginated(
      listUuid: $listUuid
      reviewed: $reviewed
      page: $page
      pageSize: $pageSize
    ) {
      totalCount
      objects {
        id
        orgUuid {
          inMyActionlist
          inMyList
          uuid
        }
      }
    }
  }
`

export const GET_MY_LISTS_SAVE_FORM = gql`
  query customListSaveForm($orgUuid: String!) {
    customListSaveForm(orgUuid: $orgUuid) {
      uuid
      name
      description
      isOrgInList
      isEveryOrgInList
      isSomeOrgInList
      isOrgInMyActionList
      isEveryOrgInMyActionList
      isSomeOrgInMyActionList
    }
  }
`
