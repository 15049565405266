import {memo} from 'react'
import {bool, string, arrayOf, shape} from 'prop-types'
// import {bool, string} from 'prop-types'
import {
  BetalistIcon,
  CapterraIcon,
  CrunchbaseIcon,
  FacebookIcon,
  GlobeIcon,
  LinkedinIcon,
  PipedriveIcon,
  TwitterIcon,
  VcrmIcon,
} from 'assets'
import {Flex, SocialLink} from 'components'
import {useKeyShortcut} from 'hooks'
import {openLinkNewTab} from 'utils'

const ProfileLinks = ({
  betalistUrl,
  capterraUrl,
  cbUrl,
  facebookUrl,
  homepageUrl,
  isInvestor,
  isWebScrapingEvent,
  linkedinUrl,
  pipedriveUrl,
  twitterUrl,
  organizationvcrm,
}) => {
  useKeyShortcut(
    'b',
    () => betalistUrl && !isInvestor && openLinkNewTab(betalistUrl),
  )
  useKeyShortcut('c', () => cbUrl && !isInvestor && openLinkNewTab(cbUrl))
  useKeyShortcut(
    'f',
    () => facebookUrl && !isInvestor && openLinkNewTab(facebookUrl),
  )
  useKeyShortcut(
    'h',
    () =>
      homepageUrl &&
      !isInvestor &&
      !isWebScrapingEvent &&
      openLinkNewTab(homepageUrl),
  )
  useKeyShortcut(
    'l',
    () => linkedinUrl && !isInvestor && openLinkNewTab(linkedinUrl),
  )
  useKeyShortcut(
    'p',
    () => pipedriveUrl && !isInvestor && openLinkNewTab(pipedriveUrl),
  )
  useKeyShortcut(
    'C',
    () => capterraUrl && !isInvestor && openLinkNewTab(capterraUrl),
  )
  useKeyShortcut(
    't',
    () => twitterUrl && !isInvestor && openLinkNewTab(twitterUrl),
  )
  return (
    <Flex css={{mb: '$16', mt: '$16'}} data-testid="profile-links">
      {homepageUrl && (
        <SocialLink
          href={homepageUrl}
          icon={<GlobeIcon />}
          title={isWebScrapingEvent ? 'Homepage' : 'Homepage - h'}
          variant="profile"
        />
      )}
      {cbUrl && (
        <SocialLink
          href={cbUrl}
          icon={<CrunchbaseIcon />}
          title="Crunchbase - c"
          variant="profile"
        />
      )}
      {linkedinUrl && (
        <SocialLink
          href={linkedinUrl}
          icon={<LinkedinIcon />}
          title="LinkedIn - l"
          variant="profile"
        />
      )}
      {twitterUrl && (
        <SocialLink
          href={twitterUrl}
          icon={<TwitterIcon />}
          title="Twitter - t"
          variant="profile"
        />
      )}
      {facebookUrl && (
        <SocialLink
          href={facebookUrl}
          icon={<FacebookIcon />}
          title="Facebook - f"
          variant="profile"
        />
      )}
      {betalistUrl && (
        <SocialLink
          href={betalistUrl}
          icon={<BetalistIcon />}
          title="Betalist - b"
          variant="profile"
        />
      )}
      {capterraUrl && (
        <SocialLink
          href={capterraUrl}
          icon={<CapterraIcon />}
          title="Capterra - C"
          variant="profile"
        />
      )}
      {pipedriveUrl && (
        <SocialLink
          href={pipedriveUrl}
          icon={<PipedriveIcon />}
          isCompanyProfile
          title="Pipedrive - p"
          variant="profile"
        />
      )}
      {organizationvcrm && (
        <SocialLink
          href={organizationvcrm.vcrmUrl}
          icon={<VcrmIcon />}
          title="VCRM"
          variant="profile"
        />
      )}
    </Flex>
  )
}

ProfileLinks.propTypes = {
  betalistUrl: string.isRequired,
  capterraUrl: string.isRequired,
  cbUrl: string.isRequired,
  facebookUrl: string.isRequired,
  homepageUrl: string.isRequired,
  isInvestor: bool.isRequired,
  isWebScrapingEvent: bool.isRequired,
  linkedinUrl: string.isRequired,
  pipedriveUrl: string.isRequired,
  twitterUrl: string.isRequired,
  organizationvcrm: arrayOf(shape({})).isRequired,
}

export default memo(ProfileLinks)
