import {gql} from '@apollo/client'

export const ADD_COMPANY_LABEL = gql`
  mutation createOrganizationLabel(
    $commentText: String
    $labelUuid: String!
    $mentionedUserIds: String
    $organizationUuid: String!
  ) {
    createOrganizationLabel(
      commentText: $commentText
      labelUuid: $labelUuid
      mentionedUserIds: $mentionedUserIds
      organizationUuid: $organizationUuid
    ) {
      organizationLabel {
        addedByName
        labelUuid {
          color
          name
          uuid
        }
        organizationUuid {
          name
          uuid
        }
        uuid
      }
    }
  }
`

export const ADD_PERSON_LABEL = gql`
  mutation createPersonLabel(
    $commentText: String
    $labelUuid: String!
    $mentionedUserIds: String
    $personUuid: String!
  ) {
    createPersonLabel(
      commentText: $commentText
      labelUuid: $labelUuid
      mentionedUserIds: $mentionedUserIds
      personUuid: $personUuid
    ) {
      personLabel {
        addedByName
        labelUuid {
          color
          name
          uuid
        }
        personUuid {
          uuid
        }
        uuid
      }
    }
  }
`
