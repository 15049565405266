import {memo} from 'react'
import {bool, func, string} from 'prop-types'
import {AddToPipedriveButton, AddToPipedriveModal} from './components'

const AddToPipedrive = ({
  addFrom,
  city,
  country,
  diversityFounders,
  foundedOnYear,
  funding,
  homepageUrl,
  modal,
  name,
  setModal,
  shortDescription,
  uuid,
  variant,
}) => (
  <>
    <AddToPipedriveButton
      addFrom={addFrom}
      setModal={setModal}
      title="Add to Pipedrive - p"
      variant={variant}
    />
    <AddToPipedriveModal
      addFrom={addFrom}
      city={city}
      country={country}
      diversityFounders={diversityFounders}
      foundedOnYear={foundedOnYear}
      funding={funding}
      homepageUrl={homepageUrl}
      modal={modal}
      name={name}
      orgUuid={uuid}
      setModal={setModal}
      shortDescription={shortDescription}
    />
  </>
)

AddToPipedrive.propTypes = {
  addFrom: string.isRequired,
  city: string.isRequired,
  country: string.isRequired,
  diversityFounders: bool.isRequired,
  foundedOnYear: string.isRequired,
  funding: string.isRequired,
  homepageUrl: string.isRequired,
  modal: bool.isRequired,
  name: string.isRequired,
  setModal: func.isRequired,
  shortDescription: string.isRequired,
  uuid: string.isRequired,
  variant: string.isRequired,
}

export default memo(AddToPipedrive)
