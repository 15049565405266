import {memo} from 'react'
import {arrayOf, bool, string} from 'prop-types'
import {DeleteList, EditList, Flex, ShareList} from 'components'

const Actions = ({
  collaboratorsIds,
  creatorId,
  creatorOffice,
  creatorUsername,
  description,
  isMyListsPage,
  loggedUserUsername,
  name,
  uuid,
}) => (
  <Flex
    css={{
      fw: isMyListsPage ? 'nowrap' : 'wrap',
      '@bp2': {mt: 0},
    }}
  >
    {loggedUserUsername === creatorUsername && (
      <EditList
        creatorId={creatorId}
        creatorOffice={creatorOffice}
        description={description}
        name={name}
        uuid={uuid}
      />
    )}
    {name !== 'Action List' && (
      <>
        <DeleteList creatorUsername={creatorUsername} name={name} uuid={uuid} />
        <ShareList
          collaboratorsIds={collaboratorsIds}
          creatorUsername={creatorUsername}
          name={name}
          uuid={uuid}
        />
      </>
    )}
  </Flex>
)

Actions.propTypes = {
  collaboratorsIds: arrayOf(string).isRequired,
  creatorId: string.isRequired,
  creatorOffice: string.isRequired,
  creatorUsername: string.isRequired,
  description: string.isRequired,
  isMyListsPage: bool.isRequired,
  loggedUserUsername: string.isRequired,
  name: string.isRequired,
  uuid: string.isRequired,
}

export default memo(Actions)
