import {arrayOf, func, string} from 'prop-types'
import {WarningIcon} from 'assets'
import {
  AddCollaborators,
  Button,
  CancelButton,
  Icon,
  Flex,
  Loader,
  Text,
} from 'components'
import useShareListForm from './hooks'

const ShareListForm = ({
  collaboratorsIds,
  creatorUsername,
  loggedUsername,
  setIsError,
  setIsSuccess,
  setModal,
  uuid,
}) => {
  const {
    checkedUsersIds,
    handleAddOrUpdateCollaborators,
    handleSubmit,
    isAddCollaboratorsLoading,
    isUpdateCollaboratorsLoading,
    setCheckedUsersIds,
  } = useShareListForm({
    collaboratorsIds,
    creatorUsername,
    loggedUsername,
    setIsError,
    setIsSuccess,
    setModal,
    uuid,
  })
  return (
    <Flex
      as="form"
      direction="column"
      onSubmit={handleSubmit(handleAddOrUpdateCollaborators)}
    >
      <AddCollaborators
        collaboratorsIds={collaboratorsIds}
        creatorUsername={creatorUsername}
        checkedUsersIds={checkedUsersIds}
        setCheckedUsersIds={setCheckedUsersIds}
      />
      {loggedUsername !== creatorUsername && (
        <Flex css={{ai: 'center', mt: '$16'}}>
          <Icon color="yellow100" size="16">
            <WarningIcon />
          </Icon>
          <Text variant="warning">
            Attention, as a collaborator you can only add more collaborators.
            You cannot remove already defined collaborators.
          </Text>
        </Flex>
      )}
      <Flex css={{as: 'flex-end', gap: '$16', mt: '$24'}}>
        <CancelButton onClick={() => setModal(false)}>Cancel</CancelButton>
        <Flex as={Button} type="submit">
          {loggedUsername !== creatorUsername ? 'Add' : 'Save'}
          {(isUpdateCollaboratorsLoading || isAddCollaboratorsLoading) && (
            <Loader size="14" css={{ml: '$8'}} />
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

ShareListForm.propTypes = {
  collaboratorsIds: arrayOf(string).isRequired,
  creatorUsername: string.isRequired,
  loggedUsername: string.isRequired,
  setIsError: func.isRequired,
  setIsSuccess: func.isRequired,
  setModal: func.isRequired,
  uuid: string.isRequired,
}

export default ShareListForm
