import {useEffect, useMemo, useRef, useState} from 'react'
import {useMutation, useQuery} from '@apollo/client'
import {useForm} from 'react-hook-form'
import {useLocation} from 'react-router-dom'
import {GET_USERS} from 'constants/queries'
import {CHANGE_OWNER_OF_LIST, EDIT_LIST} from './mutations'
import {
  GET_MY_LISTS_EDITED,
  GET_MY_LIST_COMPANIES_LIST_INFO_EDITED,
} from './queries'

const useEditListForm = ({
  creatorId,
  creatorOffice,
  setIsError,
  setIsSuccess,
  setModal,
  uuid,
}) => {
  const [ownerId, setOwnerId] = useState('')
  const listNameRef = useRef(null)
  const {register, handleSubmit, errors} = useForm({mode: 'onBlur'})
  const {pathname} = useLocation()
  const scrollTop = () => window.scrollTo({top: 0, behavior: 'smooth'})
  const {
    data: usersData,
    error: isUsersQueryError,
    loading: isUsersQueryLoading,
  } = useQuery(GET_USERS, {
    onError: error => console.error(error),
  })
  const [
    editList,
    {data: editListData, loading: isEditListLoading},
  ] = useMutation(EDIT_LIST, {
    onCompleted: () => {
      setModal(false)
      setIsSuccess(true)
      if (pathname === '/my-lists') scrollTop()
    },
    onError: error => {
      setModal(false)
      setIsError(true)
      console.error(error)
    },
  })
  const [
    changeOwnerOfList,
    {loading: isChangeOwnerOfListLoading},
  ] = useMutation(CHANGE_OWNER_OF_LIST, {
    onError: error => {
      setModal(false)
      setIsError(true)
      console.error(error)
    },
  })
  useEffect(() => listNameRef?.current && listNameRef.current.focus(), [])
  const users = useMemo(() => {
    const ownerIsTechUser = creatorOffice === 'tech'
    if (usersData && !ownerIsTechUser) {
      return usersData.users.filter(
        ({username}) =>
          username !== 'test@nautacapital.com' &&
          username !== 'test2@nautacapital.com' &&
          username !== 'nautadb@nautacapital.com',
      )
    }
    if (usersData && ownerIsTechUser) {
      return usersData.users
    }
    return []
  }, [creatorOffice, usersData])
  const LIST_OWNER_OPTIONS =
    users &&
    users
      .map(({id, firstName, lastName}) => ({
        id,
        value: id,
        label: `${firstName} ${lastName}`,
      }))
      .slice()
      .sort((a, b) => a.label.localeCompare(b.label))
  const handleEditList = data => {
    setOwnerId(data.ownerId)
    editList({
      variables: {
        description: data.description || ' ',
        name: data.name,
        uuid,
      },
      refetchQueries:
        (data.ownerId === creatorId && [
          {query: GET_MY_LISTS_EDITED, variables: {uuid}},
        ]) ||
        (pathname.startsWith('/my-lists/') && [
          {
            query: GET_MY_LIST_COMPANIES_LIST_INFO_EDITED,
            variables: {listUuid: uuid},
          },
        ]),
    })
  }
  useEffect(() => {
    const ownerIsNotCreator = ownerId !== creatorId
    if (editListData && ownerIsNotCreator) {
      changeOwnerOfList({
        variables: {
          newOwnerId: ownerId,
          uuid,
        },
        refetchQueries:
          [{query: GET_MY_LISTS_EDITED, variables: {uuid}}] ||
          (pathname.startsWith('/my-lists/') && [
            {
              query: GET_MY_LIST_COMPANIES_LIST_INFO_EDITED,
              variables: {listUuid: uuid},
            },
          ]),
      })
    }
  }, [changeOwnerOfList, creatorId, editListData, ownerId, pathname, uuid])
  const isSaveLoading = isEditListLoading || isChangeOwnerOfListLoading
  const showOwnerSelect =
    LIST_OWNER_OPTIONS?.length > 0 && !isUsersQueryLoading && !isUsersQueryError
  return {
    errors,
    handleEditList,
    handleSubmit,
    isSaveLoading,
    LIST_OWNER_OPTIONS,
    listNameRef,
    register,
    showOwnerSelect,
  }
}

export default useEditListForm
