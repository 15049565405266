import {func} from 'prop-types'
import {
  AddCollaborators,
  Box,
  Button,
  CancelButton,
  Checkbox,
  Flex,
  Input,
  InputField,
  Label,
} from 'components'
import useUploadListForm from './hooks'
import {Dropzone, Text} from './styles'

const UploadListForm = ({setIsSuccess, setModal}) => {
  const {
    acceptedFiles,
    checkedUsersIds,
    dropzoneRef,
    errors,
    getInputProps,
    getRootProps,
    handleSubmit,
    handleUploadList,
    isDragAccept,
    isDragActive,
    isDragReject,
    register,
    setCheckedUsersIds,
  } = useUploadListForm({setIsSuccess, setModal})
  return (
    <Flex
      as="form"
      data-testid="upload-list-form"
      direction="column"
      onSubmit={handleSubmit(handleUploadList)}
    >
      <Text variant="upload-list-rule">
        The excel file must contain the columns &quot;company_name&quot; and
        &quot;homepage_url&quot;
      </Text>
      <Dropzone
        {...getRootProps()}
        ref={dropzoneRef}
        variant={
          (isDragAccept && 'accept') ||
          (isDragReject && 'reject') ||
          (isDragActive && 'active')
        }
      >
        <input {...getInputProps()} data-testid="file" />
        {acceptedFiles.length > 0 ? (
          <ul>
            {acceptedFiles.map(({name}) => (
              <li key={name}>
                <Text>{name}</Text>
              </li>
            ))}
          </ul>
        ) : (
          <Text>
            Drag &apos;n&apos; drop the excel file here, or click to select the
            file
          </Text>
        )}
      </Dropzone>
      <Box css={{mb: '$24'}}>
        <Label htmlFor="name" color={errors.name && 'red'}>
          Name<Text variant="required">*</Text>
        </Label>
        <Input
          borderColor={errors.name && 'red'}
          id="name"
          name="name"
          ref={register({required: true})}
          type="text"
        />
        {errors.name && <Text variant="form-error">Required</Text>}
      </Box>
      <Box css={{mb: '$16'}}>
        <Text css={{mb: '$16'}} variant="modal-subtitle">
          Save to new list (optional)
        </Text>
        <Label
          css={{d: 'inline-block', whiteSpace: 'nowrap'}}
          htmlFor="createCustomList"
        >
          <Checkbox
            css={{appearance: 'auto', w: 'auto', mr: '$8'}}
            id="createCustomList"
            name="createCustomList"
            ref={register}
            type="checkbox"
          />
          Also save as a new list in My Lists
        </Label>
      </Box>
      <InputField name="description" register={register} type="text">
        Description
      </InputField>
      <Box css={{mt: '$16'}}>
        <Text css={{mb: '$16'}} variant="modal-subtitle">
          Add collaborators (optional)
        </Text>
        <AddCollaborators
          checkedUsersIds={checkedUsersIds}
          setCheckedUsersIds={setCheckedUsersIds}
        />
      </Box>
      <Flex css={{as: 'flex-end', gap: '$16', mt: '$24'}}>
        <CancelButton onClick={() => setModal(false)}>Cancel</CancelButton>
        <Button type="submit">Upload list</Button>
      </Flex>
    </Flex>
  )
}

UploadListForm.propTypes = {
  setIsSuccess: func.isRequired,
  setModal: func.isRequired,
}

export default UploadListForm
