import {bool, func} from 'prop-types'
import {AddCompanyForm, CloseModalButton, Modal, Text} from 'components'

const AddCompanyModal = ({modal, setModal}) => (
  <Modal modal={modal} setModal={setModal}>
    <CloseModalButton setModal={setModal} />
    <Text variant="modal-title">Add Company to NautaDB</Text>
    <AddCompanyForm setModal={setModal} />
  </Modal>
)

AddCompanyModal.propTypes = {
  modal: bool.isRequired,
  setModal: func.isRequired,
}

export default AddCompanyModal
