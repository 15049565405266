import {bool, func} from 'prop-types'
import {Button, CancelButton, Flex, Loader} from 'components'
import useRemoveEventForm from './hooks'

const RemoveEventForm = ({
  handleRemoveEvent,
  isRemoveEventLoading,
  setModal,
}) => {
  const {deleteRef} = useRemoveEventForm()
  return (
    <Flex css={{jc: 'flex-end', gap: '$16', mt: '$24'}}>
      <CancelButton onClick={() => setModal(false)}>Cancel</CancelButton>
      <Flex
        as={Button}
        color="red"
        onClick={handleRemoveEvent}
        ref={deleteRef}
        type="button"
      >
        Delete
        {isRemoveEventLoading && (
          <Loader size="14" css={{ml: '$8'}} data-testid="loader" />
        )}
      </Flex>
    </Flex>
  )
}

RemoveEventForm.propTypes = {
  handleRemoveEvent: func.isRequired,
  isRemoveEventLoading: bool.isRequired,

  setModal: func.isRequired,
}

export default RemoveEventForm
