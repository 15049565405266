import {useEffect} from 'react'
import {bool, func, node} from 'prop-types'
import {CheckCircleIcon} from 'assets'
import {Icon} from 'components'
import {Flex, Text} from './styles'

const UserFeedback = ({
  children,
  hasImage,
  isCopied,
  isError,
  isSuccess,
  setIsCopied,
  setIsError,
  setIsSuccess,
  setSelectedListsNamesToRemove,
  setSelectedListsNamesToSave,
}) => {
  useEffect(() => {
    let timer
    if (isSuccess) {
      timer = setTimeout(() => setIsSuccess(false), 4000)
    }
    if (isError) {
      timer = setTimeout(() => setIsError(false), 4000)
    }
    if (isCopied) {
      timer = setTimeout(() => setIsCopied(false), 4000)
    }
    return () => clearTimeout(timer)
  }, [isSuccess, setIsSuccess, isError, setIsError, isCopied, setIsCopied])
  useEffect(() => {
    return () => {
      if (setSelectedListsNamesToSave) setSelectedListsNamesToSave([])
      if (setSelectedListsNamesToRemove) setSelectedListsNamesToRemove([])
    }
  }, [setSelectedListsNamesToSave, setSelectedListsNamesToRemove])
  return (
    <Flex
      css={{
        b: `${isError ? '1px solid $red' : '$none'}`,
        bgc: `${isError ? '#fff7f7' : '$white'}`,
      }}
      data-testid="user-feedback"
      variant="user-feedback"
    >
      {(isSuccess || isCopied) && !hasImage && (
        <Icon color="green" size="16" css={{mr: '$8'}}>
          <CheckCircleIcon />
        </Icon>
      )}
      <Text variant={isError ? 'error' : 'feedback'}>{children}</Text>
    </Flex>
  )
}

UserFeedback.propTypes = {
  children: node.isRequired,
  hasImage: bool.isRequired,
  isCopied: bool.isRequired,
  isError: bool.isRequired,
  isSuccess: bool.isRequired,
  setIsCopied: func.isRequired,
  setIsError: func.isRequired,
  setIsSuccess: func.isRequired,
  setSelectedListsNamesToRemove: func.isRequired,
  setSelectedListsNamesToSave: func.isRequired,
}

export default UserFeedback
