import BaseBox from 'components/layout/Box'
import BaseFlex from 'components/layout/Flex'
import {styled} from 'styles'

export const Box = styled(BaseBox, {
  variants: {
    variant: {
      'search-bar-header': {
        d: 'none',
        '@bp3': {
          d: 'block',
        },
      },
    },
  },
})

export const Flex = styled(BaseFlex, {
  variants: {
    variant: {
      header: {
        d: 'none',
        '@bp3': {
          d: 'flex',
          jc: 'space-between',
          ai: 'center',
          py: '$8',
          mt: '$4',
        },
      },
    },
  },
})
