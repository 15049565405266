import {memo} from 'react'
import {arrayOf, shape, string} from 'prop-types'
import {useLocation} from 'react-router-dom'
import {
  Root as HoverCardRoot,
  Trigger as HoverCardTrigger,
} from '@radix-ui/react-hover-card'
import {Flex, Text} from 'components'
import {
  getTextColor,
  formatComment,
  formatFirstAndLastName,
  formatDate,
} from 'utils'
import {
  ListItem,
  HoverCardArrow,
  HoverCardContent,
  LabelCreator,
  LabelDate,
  LabelText,
  LabelBy,
  LabelDetailItem,
} from './styles'

const LabelItem = ({allLabels, color, name}) => {
  const {pathname} = useLocation()
  return (
    <ListItem>
      <HoverCardRoot openDelay={200} closeDelay={100}>
        <HoverCardTrigger>
          <LabelText
            css={{
              fontSize:
                pathname.startsWith('/companies/') ||
                pathname.startsWith('/people/')
                  ? '$12'
                  : '$11',
              color: getTextColor(color),
              bgc: color || '$black',
            }}
          >
            {name}
          </LabelText>
        </HoverCardTrigger>
        <HoverCardContent sideOffset={8}>
          <HoverCardArrow />
          <Flex as="ul" direction="column">
            {allLabels?.length > 0 &&
              allLabels.map(
                label =>
                  label.labelUuid.name === name && (
                    <LabelDetailItem key={label.uuid}>
                      {label.commentText && (
                        <Text variant="comment" css={{fontSize: '$13'}}>
                          {formatComment(label.commentText)}
                        </Text>
                      )}
                      <LabelBy>
                        By{' '}
                        <LabelCreator>
                          {formatFirstAndLastName(label.addedByName)}
                        </LabelCreator>
                        <LabelDate>on {formatDate(label.createdAt)}</LabelDate>
                      </LabelBy>
                    </LabelDetailItem>
                  ),
              )}
          </Flex>
        </HoverCardContent>
      </HoverCardRoot>
    </ListItem>
  )
}

LabelItem.propTypes = {
  allLabels: arrayOf(
    shape({
      addedByName: string,
      commentText: string,
      createdAt: string,
      labelUuid: shape({
        color: string,
        name: string,
      }),
      uuid: string,
    }),
  ).isRequired,
  color: string.isRequired,
  name: string.isRequired,
}

export default memo(LabelItem)
