import {func, string} from 'prop-types'
import {WarningIcon} from 'assets'
import {
  Button,
  CancelButton,
  ErrorMessage,
  Flex,
  Icon,
  Loader,
  Text,
} from 'components'
import useDeleteListForm from './hooks'

const DeleteListForm = ({
  creatorUsername,
  setIsError,
  setIsSuccess,
  setModal,
  uuid,
}) => {
  const {
    deleteRef,
    handleRemove,
    isDeleteLoading,
    isListOwner,
    isUserQueryError,
    isUserQueryLoading,
  } = useDeleteListForm({
    creatorUsername,
    setIsError,
    setIsSuccess,
    setModal,
    uuid,
  })
  if (isUserQueryError)
    return (
      <ErrorMessage>
        Error getting user data. Please try to refresh or report this bug.
      </ErrorMessage>
    )
  return (
    <Flex direction="column">
      <Text css={{fontSize: '$14'}}>
        Are you sure you want to delete this list?
      </Text>
      <Flex css={{ai: 'center', mt: '$24'}}>
        <Icon color="yellow100" size="16">
          <WarningIcon />
        </Icon>
        <Text variant="warning">
          {isListOwner
            ? 'Attention, this will delete permanently the list and all its contents for all users.'
            : 'Attention, this will remove you as a collaborator. The list will continue to exist for other users.'}
        </Text>
      </Flex>
      {!isUserQueryLoading ? (
        <Flex css={{as: 'flex-end', gap: '$16', mt: '$24'}}>
          <CancelButton onClick={() => setModal(false)}>Cancel</CancelButton>
          <Flex
            as={Button}
            color="red"
            ref={deleteRef}
            onClick={handleRemove}
            type="button"
          >
            Delete
            {isDeleteLoading && (
              <Loader size="14" css={{ml: '$8'}} data-testid="loader" />
            )}
          </Flex>
        </Flex>
      ) : (
        <Loader size="20" />
      )}
    </Flex>
  )
}

DeleteListForm.propTypes = {
  creatorUsername: string.isRequired,
  setIsError: func.isRequired,
  setIsSuccess: func.isRequired,
  setModal: func.isRequired,
  uuid: string.isRequired,
}

export default DeleteListForm
