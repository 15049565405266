import {gql} from '@apollo/client'

const ADD_REVIEW = gql`
  mutation createOrganizationReview(
    $orgUuid: String!
    $nautable: String!
    $notNautableReason: String
    $notNautableOther: String
  ) {
    createOrganizationReview(
      orgUuid: $orgUuid
      nautable: $nautable
      notNautableReason: $notNautableReason
      notNautableOther: $notNautableOther
    ) {
      review {
        uuid
        orgUuid {
          uuid
        }
        nautable
        notNautableReason
        notNautableOther
      }
    }
  }
`

export default ADD_REVIEW
