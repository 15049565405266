import {forwardRef, memo} from 'react'
import {arrayOf, bool, func, number, shape, string} from 'prop-types'
import {TableBody, TableHead, TableStyles} from 'components'

const Table = forwardRef(
  (
    {
      cursor,
      fundingRoundTypes,
      getTableBodyProps,
      getTableProps,
      headerGroups,
      isTableReviewLoading,
      listType,
      page,
      portfolioCategories,
      portfolioLocation,
      prepareRow,
      selectedUuid,
      setCursor,
      setFocusNextItem,
      setSelected,
    },
    ref,
  ) => (
    <TableStyles
      variant={
        (listType === 'my-lists-page' || listType === 'action-list-my-lists') &&
        'dashboard'
      }
    >
      <table {...getTableProps()}>
        <TableHead
          canFilter
          canSort
          fundingRoundTypes={fundingRoundTypes}
          headerGroups={headerGroups}
          portfolioCategories={portfolioCategories}
          portfolioLocation={portfolioLocation}
        />
        <TableBody
          cursor={cursor}
          data={page}
          getTableBodyProps={getTableBodyProps}
          isCompaniesTable
          isTableReviewLoading={isTableReviewLoading}
          listType={listType}
          prepareRow={prepareRow}
          ref={ref}
          selectedUuid={selectedUuid}
          setCursor={setCursor}
          setFocusNextItem={setFocusNextItem}
          setSelected={setSelected}
        />
      </table>
    </TableStyles>
  ),
)

Table.propTypes = {
  cursor: number.isRequired,
  fundingRoundTypes: arrayOf(shape({})),
  getTableBodyProps: func.isRequired,
  getTableProps: func.isRequired,
  headerGroups: arrayOf(shape({})).isRequired,
  isTableReviewLoading: bool.isRequired,
  listType: string.isRequired,
  page: arrayOf(shape({})).isRequired,
  portfolioCategories: arrayOf(shape({})),
  portfolioLocation: arrayOf(shape({})),
  prepareRow: func.isRequired,
  selectedUuid: string,
  setCursor: func.isRequired,
  setFocusNextItem: func.isRequired,
  setSelected: func.isRequired,
}

Table.defaultProps = {
  selectedUuid: undefined,
  fundingRoundTypes: undefined,
  portfolioCategories: undefined,
  portfolioLocation: undefined,
}

export default memo(Table)
