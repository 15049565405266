import {useEffect, useMemo} from 'react'
import {useLazyQuery} from '@apollo/client'
import {useLocation} from 'react-router-dom'
import {
  GET_COMPANY_LABEL_CHOICES,
  GET_PERSON_LABEL_CHOICES,
} from 'components/patterns/Labels/components/AddLabel/queries'
import formatLabelOptions from './utils'

const useLabelChoices = () => {
  const {pathname} = useLocation()
  const isPeople = pathname === '/people'
  const isInvestors = pathname === '/investors'
  const isCompanies = pathname === '/companies'
  const [
    getPersonLabelChoices,
    {
      data: personLabelChoicesData,
      error: isPersonLabelChoicesQueryError,
      loading: isPersonLabelChoicesQueryLoading,
    },
  ] = useLazyQuery(GET_PERSON_LABEL_CHOICES, {
    onError: error => console.error(error),
  })
  const [
    getCompanyLabelChoices,
    {
      data: companyLabelChoicesData,
      error: isCompanyLabelChoicesQueryError,
      loading: isCompanyLabelChoicesQueryLoading,
    },
  ] = useLazyQuery(GET_COMPANY_LABEL_CHOICES, {
    onError: error => console.error(error),
  })
  useEffect(() => {
    if (isInvestors) {
      getPersonLabelChoices()
      getCompanyLabelChoices()
    }
    if (isPeople) {
      getPersonLabelChoices()
    }
    if (isCompanies) {
      getCompanyLabelChoices()
    }
  }, [
    isInvestors,
    isCompanies,
    isPeople,
    getPersonLabelChoices,
    getCompanyLabelChoices,
  ])
  const LABEL_OPTIONS = useMemo(
    () =>
      (personLabelChoicesData &&
        isPeople &&
        formatLabelOptions(personLabelChoicesData.personLabelChoices)) ||
      (isCompanies &&
        companyLabelChoicesData &&
        formatLabelOptions(companyLabelChoicesData.organizationLabelChoices)) ||
      (isInvestors && [{value: 'Contact', label: 'Contact'}]),
    [
      companyLabelChoicesData,
      isCompanies,
      isInvestors,
      isPeople,
      personLabelChoicesData,
    ],
  )
  return {
    isCompanyLabelChoicesQueryError,
    isCompanyLabelChoicesQueryLoading,
    isPersonLabelChoicesQueryError,
    isPersonLabelChoicesQueryLoading,
    LABEL_OPTIONS,
  }
}

export default useLabelChoices
