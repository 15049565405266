import {memo} from 'react'
import {string} from 'prop-types'
import {Link} from 'components'

const Name = ({name, uuid}) => (
  <Link
    aria-label={name === 'Action List' ? 'action list' : `my list ${name}`}
    css={{
      color: '$primary',
      fontSize: '$20',
      lh: '$body',
      mb: '$8',
      '&:focus': {txtd: 'underline'},
      '&:hover': {color: '$secondary'},
      '@bp1': {
        mb: 0,
      },
    }}
    external
    href={
      name === 'Action List'
        ? 'action-list'
        : `/my-lists/${name}?listUuid=${uuid}`
    }
  >
    {name}
  </Link>
)

Name.propTypes = {
  name: string.isRequired,
  uuid: string.isRequired,
}

export default memo(Name)
