import {gql} from '@apollo/client'

export const GET_COMPANY_LABEL_CHOICES = gql`
  query organizationLabelChoices {
    organizationLabelChoices {
      color
      name
      uuid
    }
  }
`

export const GET_PERSON_LABEL_CHOICES = gql`
  query personLabelChoices {
    personLabelChoices {
      color
      name
      uuid
    }
  }
`
