import {gql} from '@apollo/client'

const SEARCH = gql`
  query search(
    $q: String
    $organization: Boolean
    $person: Boolean
    $investor: Boolean
    $size: Int
  ) {
    searchResults: search(
      q: $q
      organization: $organization
      person: $person
      investor: $investor
      size: $size
    ) {
      ... on OrganizationType {
        companyPermalink: permalink
        extra
        logoUrl
        name
        searchType: __typename
        shortDescription
        uuid
      }
      ... on InvestorType {
        investorPermalink: permalink
        logoUrl
        name
        searchType: __typename
        shortDescription
        type
        uuid
      }
    }
  }
`

export default SEARCH
