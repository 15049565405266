import {memo} from 'react'
import {bool, node, number, string} from 'prop-types'
import {Link} from 'components'
import Text from './styles'

const ListLink = ({
  children,
  name,
  reviewed,
  scope,
  source,
  uuid,
  week,
  year,
}) =>
  children === 0 ? (
    <Text variant="disabled-list-link">0</Text>
  ) : (
    <Link
      css={{d: 'block', lh: '$body', '&:focus': {txtd: 'underline'}}}
      external
      href={
        (source === 'AGGREGATED' &&
          `/list/aggregated?week=${week}&year=${year}${
            scope && `&scope=${scope}`
          }${reviewed && `&reviewed=${reviewed}`}`) ||
        (source === 'action-list' &&
          `/action-list${reviewed && `?&reviewed=${reviewed}`}`) ||
        (source === 'my-lists' &&
          `/my-lists/${name}?listUuid=${uuid}${
            reviewed && `&reviewed=${reviewed}`
          }`) ||
        (source === 'STEALTH' &&
          `/people-list/${source && source.toLowerCase()}?listUuid=${uuid}${
            scope && `&scope=${scope}`
          }`) ||
        `/list/${source && source.toLowerCase()}?listUuid=${uuid}${
          name && `&name=${name}`
        }${scope && `&scope=${scope}`}${reviewed && `&reviewed=${reviewed}`}`
      }
    >
      {children}
    </Link>
  )

ListLink.propTypes = {
  children: node.isRequired,
  name: string,
  reviewed: bool,
  scope: string,
  source: string.isRequired,
  uuid: string.isRequired,
  week: number,
  year: number,
}

ListLink.defaultProps = {
  name: '',
  reviewed: '',
  scope: '',
  week: undefined,
  year: undefined,
}

export default memo(ListLink)
