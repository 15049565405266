import {func} from 'prop-types'
import {Box} from 'components'

const TableFilter = ({render}) => <Box css={{py: '$8'}}>{render('Filter')}</Box>

TableFilter.propTypes = {
  render: func.isRequired,
}

export default TableFilter
