import {keyframes, styled} from 'styles'

const fadeIn = keyframes({
  from: {opacity: 0},
  to: {opacity: 1},
})

const Button = styled('button', {
  b: '1px solid transparent',
  bgc: '$primary',
  br: '$small',
  color: '$white',
  cursor: 'pointer',
  fontSize: '$14',
  lh: '$body',
  outline: 'none',
  px: '$16',
  py: '$12',
  ta: 'center',
  us: 'none',
  '&:hover': {
    b: '1px solid $primary',
    bgc: '$white',
    color: '$primary',
  },
  '&:focus': {
    boc: '$white',
    bs: '0 0 0 1px $colors$primary',
    txtd: 'none',
  },
  '&:disabled': {
    b: '1px solid $grey100',
    bgc: '$white',
    color: '$grey200',
  },
  variants: {
    color: {
      secondary: {
        color: '$secondary',
        boc: '$secondary',
        '&:hover': {
          color: '$secondary',
          txtd: 'underline',
        },
      },
      green: {
        bgc: '$green',
        boc: '$green',
        '&:hover': {
          b: '1px solid $green',
          color: '$green',
        },
        '&:focus': {
          bs: '0 0 0 2px $colors$green',
        },
      },
      red: {
        bgc: '$red',
        boc: ' $red',
        '&:hover': {
          b: '1px solid $red',
          color: '$red',
        },
        '&:focus': {
          bs: '0 0 0 2px $colors$red',
        },
      },
      yellow: {
        bgc: '$yellow200',
        boc: '$yellow200',
        '&:hover': {
          b: '1px solid $yellow200',
          color: '$yellow200',
        },
        '&:focus': {
          bs: '0 0 0 2px $colors$yellow200',
        },
      },
      primary: {
        color: '$primary',
      },
      grey: {
        color: '$grey300',
        '&:hover': {
          color: '$primary',
        },
      },
      grey600: {
        color: '$grey600',
        '&:hover': {
          color: '$primary',
        },
      },
    },
    size: {
      small: {
        fontSize: '$13',
        px: '$12',
        py: '$8',
      },
      'ghost-small': {
        b: 0,
        fontSize: '$13',
        p: 0,
      },
      'ghost-regular': {
        b: 0,
        p: 0,
      },
      'ghost-large': {
        b: 0,
        fontSize: '$16',
        p: 0,
      },
      'ghost-xlarge': {
        b: 0,
        fontSize: '$18',
        p: 0,
      },
    },
    variant: {
      'extend-menu': {
        d: 'none',
        '@bp3': {
          animation: `${fadeIn} 0.25s ease-in`,
          b: 0,
          bgc: 'transparent',
          cursor: 'pointer',
          d: 'block',
          outline: 'none',
          p: 0,
          position: 'fixed',
          top: '78px',
          transition: 'left 0.25s ease-in-out',
          zIndex: '$z100',
          '&:focus': {
            bs: 'none',
          },
          '&:hover': {
            b: 0,
          },
        },
      },
      secondary: {
        bgc: '$white',
        boc: '$grey100',
        color: '$grey600',
        '&:focus': {
          color: '$primary',
        },
        '&:active': {
          boc: '$primary',
          color: '$primary',
        },
      },
      'secondary-icon': {
        ai: 'center',
        b: '1px solid $grey100',
        bgc: '$white',
        color: '$grey600',
        d: 'flex',
        svg: {
          color: '$grey300',
        },
        '&:hover': {
          svg: {
            color: '$primary',
          },
        },
        '&:focus': {
          color: '$primary',
          svg: {
            color: '$primary',
          },
        },
        '&:active': {
          boc: '$primary',
          color: '$primary',
          svg: {
            color: '$primary',
          },
        },
        '&:disabled': {
          d: 'flex',
          color: '$grey200',
          cursor: 'default',
          svg: {
            color: '$grey200',
          },
          '&:hover': {
            svg: {
              color: '$grey200',
            },
          },
        },
      },
      ghost: {
        ai: 'center',
        bgc: 'transparent',
        d: 'flex',
        '&:hover': {
          b: 0,
          bgc: 'transparent',
        },
        '&:focus': {
          b: 0,
          bs: 'none',
          txtd: 'underline',
        },
        '&:disabled': {
          b: 0,
          cursor: 'default',
        },
      },
      'ghost-icon': {
        ai: 'center',
        bgc: 'transparent',
        d: 'flex',
        '&:hover': {
          b: 0,
          bgc: 'transparent',
          svg: {
            color: '$primary',
          },
        },
        '&:focus': {
          b: 0,
          bs: 'none',
          txtd: 'underline',
          svg: {
            color: '$primary',
          },
        },
        '&:disabled': {
          b: 0,
          cursor: 'default',
        },
      },
    },
  },
})

export default Button
