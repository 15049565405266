import {memo} from 'react'
import {ListRequestIcon} from 'assets'
import {Button, Icon, Loader, Text} from 'components'
import {bool, func} from 'prop-types'

const RequestWSEventPageButton = ({
  handleCancelRequest,
  hasRequestedWebScraping,
  isRequesWebscrapingLoading,
  setModal,
}) => (
  <Button
    color={hasRequestedWebScraping ? 'primary' : 'grey600'}
    css={{
      svg: {
        color: hasRequestedWebScraping ? '$primary' : '$grey300',
      },
      whiteSpace: 'nowrap',
    }}
    onClick={() =>
      hasRequestedWebScraping ? handleCancelRequest() : setModal(true)
    }
    size="small"
    title={
      hasRequestedWebScraping
        ? 'Cancel web scraping request'
        : 'Request web scraping'
    }
    type="button"
    variant="secondary-icon"
  >
    {isRequesWebscrapingLoading ? (
      <Loader size="16" css={{mr: '$8'}} />
    ) : (
      <Icon css={{mr: '$8'}} size="14">
        <ListRequestIcon />
      </Icon>
    )}
    <Text>
      {hasRequestedWebScraping
        ? 'Cancel web scraping request'
        : 'Request web scraping'}
    </Text>
  </Button>
)

RequestWSEventPageButton.propTypes = {
  handleCancelRequest: func.isRequired,
  hasRequestedWebScraping: bool.isRequired,
  isRequesWebscrapingLoading: bool.isRequired,
  setModal: func.isRequired,
}

export default memo(RequestWSEventPageButton)
