import {useCallback, useEffect, useMemo} from 'react'
import {useFilters, usePagination, useTable, useSortBy} from 'react-table'
import {DefaultColumnFilter, filterTypes} from 'components'
import {useDispatch, useSearch} from 'context'
import {useKeyDown} from 'hooks'

const useCategoriesTable = ({data, columns}) => {
  const defaultColumn = useMemo(() => ({Filter: DefaultColumnFilter}), [])
  const {
    canNextPage,
    canPreviousPage,
    getTableProps,
    getTableBodyProps,
    gotoPage,
    headerGroups,
    nextPage,
    page,
    pageCount,
    prepareRow,
    previousPage,
    rows,
    setPageSize,
    state: {pageIndex, pageSize, filters},
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: {
        pageIndex: 0,
        pageSize: 25,
      },
      manualPagination: false,
    },
    useFilters,
    useSortBy,
    usePagination,
  )
  const dispatch = useDispatch()
  const setIsPageChanged = useCallback(
    isPageChanged => dispatch({type: 'setIsPageChanged', isPageChanged}),
    [dispatch],
  )
  const {isModalOpen, isPageChanged} = useSearch()
  const table = document.getElementById('table')?.parentNode
  const scrollTopOfTable = useCallback(
    () =>
      table?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      }),
    [table],
  )
  useKeyDown('ArrowRight', () => {
    if (page.length && !isModalOpen && canNextPage) {
      nextPage()
      setIsPageChanged(true)
    }
  })
  useKeyDown('ArrowLeft', () => {
    if (page.length && !isModalOpen && canPreviousPage) {
      previousPage()
      setIsPageChanged(true)
    }
  })
  useEffect(() => {
    if (isPageChanged) {
      scrollTopOfTable()
      setIsPageChanged(false)
    }
  }, [isPageChanged, scrollTopOfTable, setIsPageChanged])
  return {
    canNextPage,
    canPreviousPage,
    filters,
    getTableBodyProps,
    getTableProps,
    gotoPage,
    headerGroups,
    nextPage,
    page,
    pageCount,
    pageIndex,
    pageSize,
    prepareRow,
    previousPage,
    rows,
    setPageSize,
  }
}

export default useCategoriesTable
