import {Text as BaseText} from 'components/primitives'
import {styled} from 'styles'

const Text = styled(BaseText, {
  variants: {
    variant: {
      'new-employees': {
        color: '$grey300',
        d: 'inline-block',
        fontSize: '$11',
      },
      'new-employees-latest-value': {
        color: '$grey500',
        lh: '$body',
      },
      'new-employees-value': {
        color: '$grey500',
        d: 'inline',
      },
    },
  },
})

export default Text
