import {Text as BaseText} from 'components/primitives'
import {styled} from 'styles'

const Text = styled(BaseText, {
  variants: {
    variant: {
      'job-title-cell': {
        lh: '$body',
        maxWidth: '200px',
        minWidth: '144px',
        overflowWrap: 'break-word',
      },
    },
  },
})

export default Text
