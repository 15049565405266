import {memo, useState} from 'react'
import {string} from 'prop-types'
import {FallbackImgCompanyLg, FallbackImgPersonLg} from 'assets'
import {Box, Image} from 'components'

const ProfileImage = ({name, logoUrl, variant}) => {
  const [isImageLoadError, setIsImageLoadError] = useState(false)
  return (
    <Box css={{mr: '$24', mb: '$16'}} data-testid="profile-image">
      <Image
        alt={name || 'profile image'}
        onError={() => setIsImageLoadError(true)}
        size={{'@initial': '64', '@bp2': '100'}}
        src={
          isImageLoadError
            ? (variant === 'person' && FallbackImgPersonLg) ||
              FallbackImgCompanyLg
            : logoUrl ||
              (variant === 'person' && FallbackImgPersonLg) ||
              FallbackImgCompanyLg
        }
        variant={variant}
      />
    </Box>
  )
}

ProfileImage.propTypes = {
  name: string.isRequired,
  logoUrl: string.isRequired,
  variant: string.isRequired,
}

export default memo(ProfileImage)
