import {useEffect, useRef} from 'react'
import {useForm} from 'react-hook-form'
import {GET_EVENT_EDITION} from 'pages/Events/queries'

const useRequestWebScrapingForm = ({editionUuid, requestWebscraping}) => {
  const webScrapingCommentRef = useRef(null)
  const {register, handleSubmit, errors} = useForm({mode: 'onBlur'})
  useEffect(
    () =>
      webScrapingCommentRef?.current && webScrapingCommentRef.current.focus(),
    [],
  )
  const handleRequestWebScraping = ({webscrapingComment}) => {
    requestWebscraping({
      variables: {
        editionUuid,
        webscrapingRequest: true,
        webscrapingComment,
      },
      refetchQueries: [{query: GET_EVENT_EDITION, variables: {editionUuid}}],
    })
  }
  return {
    errors,
    handleRequestWebScraping,
    handleSubmit,
    register,
    webScrapingCommentRef,
  }
}

export default useRequestWebScrapingForm
