import {useEffect, useState} from 'react'
import {useTable, usePagination, useSortBy} from 'react-table'

const useListsTable = ({columns, data, listType, numberOfRows}) => {
  const [initialState] = useState({
    pageIndex: 0,
  })
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {pageIndex, pageSize},
  } = useTable(
    {
      columns,
      data,
      initialState,
    },
    useSortBy,
    usePagination,
  )
  const isWeeklyList = listType === 'weekly-list'
  const isUploadedLists = listType === 'uploaded-lists'
  const isWebScrapings = listType === 'web-scrapings'
  const isMyLists = listType === 'my-lists'
  const isFoundersStealthList = listType === 'founders-list'
  const listsTableStylesVariant =
    (isUploadedLists && 'uploaded') ||
    (isWebScrapings && 'scrapings') ||
    (isWeeklyList && 'weekly') ||
    (isMyLists && 'my-lists') ||
    (isFoundersStealthList && 'founders-stealth')
  useEffect(() => setPageSize(numberOfRows || 4), [setPageSize, numberOfRows])
  return {
    canNextPage,
    canPreviousPage,
    getTableBodyProps,
    getTableProps,
    gotoPage,
    headerGroups,
    listsTableStylesVariant,
    nextPage,
    page,
    pageCount,
    pageIndex,
    pageSize,
    prepareRow,
    previousPage,
    setPageSize,
  }
}

export default useListsTable
