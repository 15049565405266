import BaseBox from 'components/layout/Box'
import {styled} from 'styles'

const Box = styled(BaseBox, {
  variants: {
    variant: {
      'modal-container': {
        h: '100%',
        left: 0,
        oy: 'scroll',
        position: 'fixed',
        top: 0,
        w: '100vw',
        zIndex: '$zMax',
        '@bp3': {
          ai: 'center',
          bgc: '$modalBackground',
          d: 'flex',
        },
      },
      'modal-content': {
        bgc: '$white',
        h: 'auto',
        minHeight: '100vh',
        px: '$32',
        py: '$64',
        w: '100vw',
        '@bp3': {
          br: '$small',
          h: 'auto',
          margin: '0 auto',
          maxHeight: '100%',
          minHeight: 'auto',
          oy: 'auto',
          position: 'relative',
          px: '$128',
          py: '$32',
          w: 'auto',
        },
      },
    },
  },
})

export default Box
