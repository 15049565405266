import {useState} from 'react'
import {string} from 'prop-types'
import {DeleteListButton, DeleteListModal} from './components'

const DeleteList = ({creatorUsername, name, uuid}) => {
  const [modal, setModal] = useState(false)
  return (
    <>
      <DeleteListButton setModal={setModal} />
      <DeleteListModal
        creatorUsername={creatorUsername}
        modal={modal}
        name={name}
        setModal={setModal}
        uuid={uuid}
      />
    </>
  )
}

DeleteList.propTypes = {
  creatorUsername: string.isRequired,
  name: string.isRequired,
  uuid: string.isRequired,
}

export default DeleteList
