import {arrayOf, shape, string} from 'prop-types'
import {UserAttendingIcon} from 'assets'
import {Button, Icon, Loader, Text, UserFeedback} from 'components'
import useUserAttending from './hooks'

const UserAttending = ({allUsers, editionUuid, listType, usersAttending}) => {
  const {
    handleUserIsAttending,
    isError,
    isEventsTable,
    isSuccess,
    isToggleUserAttendingEventError,
    isToggleUserAttendingEventLoading,
    isUserAttending,
    setIsError,
    setIsSuccess,
  } = useUserAttending({allUsers, editionUuid, listType, usersAttending})
  return (
    <>
      <Button
        color={
          (isUserAttending && isEventsTable && 'primary') ||
          (!isUserAttending && isEventsTable && 'grey')
        }
        css={
          !isEventsTable && {
            whiteSpace: 'nowrap',
            svg: {color: isUserAttending ? '$primary' : '$grey300'},
          }
        }
        onClick={handleUserIsAttending}
        size={isEventsTable ? 'ghost-small' : 'small'}
        title={`Mark as ${isUserAttending ? 'not ' : ''}attending`}
        type="button"
        variant={isEventsTable ? 'ghost' : 'secondary-icon'}
      >
        {isToggleUserAttendingEventLoading ? (
          <Loader size="16" css={{mr: '$8'}} />
        ) : (
          <Icon css={isEventsTable ? {ml: 0} : {mr: '$8'}} size="14">
            <UserAttendingIcon />
          </Icon>
        )}
        {!isEventsTable && (
          <Text>Mark as {isUserAttending && 'not '}attending</Text>
        )}
      </Button>
      {isSuccess && (
        <UserFeedback isSuccess={isSuccess} setIsSuccess={setIsSuccess}>
          Event attendance updated successfully
        </UserFeedback>
      )}
      {isToggleUserAttendingEventError && isError && (
        <UserFeedback isError={isError} setIsError={setIsError}>
          Error setting attendance. Please try again or report this bug.
        </UserFeedback>
      )}
    </>
  )
}

UserAttending.propTypes = {
  allUsers: arrayOf(shape({})).isRequired,
  editionUuid: string.isRequired,
  listType: string.isRequired,
  usersAttending: string.isRequired,
}

export default UserAttending
