import {useCallback} from 'react'

const useShowColumnsModal = ({
  allColumns,
  setIsColumnsSavedSuccess,
  setModal,
}) => {
  const handleSelectAllColumns = useCallback(
    () =>
      allColumns?.forEach(
        c =>
          (c.isVisible && c.id !== 'name' && c.toggleHidden()) ||
          (c.id !== 'name' && c.toggleHidden()),
      ),
    [allColumns],
  )
  const handleDeselectAllColumns = useCallback(
    () =>
      allColumns?.forEach(
        c =>
          (!c.isVisible && c.toggleHidden()) ||
          (c.id !== 'name' && c.toggleHidden()),
      ),
    [allColumns],
  )
  const handleSaveColumnsSelection = () => {
    const columnsToHide = allColumns?.filter(c => !c.isVisible).map(c => c.id)
    localStorage.setItem(`columns_to_hide`, JSON.stringify(columnsToHide))
    setModal(false)
    setIsColumnsSavedSuccess(true)
  }
  const handleCancelOrClose = useCallback(() => {
    const columnsToHideLocalStorage = JSON.parse(
      localStorage.getItem(`columns_to_hide`),
    )
    allColumns?.forEach(
      c =>
        (columnsToHideLocalStorage?.some(s => c.id.includes(s)) &&
          c.isVisible &&
          c.toggleHidden()) ||
        (columnsToHideLocalStorage?.some(s => c.id.includes(s)) &&
          !c.isVisible &&
          c.getToggleHiddenProps()) ||
        (columnsToHideLocalStorage?.some(s => !c.id.includes(s)) &&
          !c.isVisible &&
          c.toggleHidden()) ||
        (columnsToHideLocalStorage?.length === 0 &&
          !c.isVisible &&
          c.toggleHidden()) ||
        (columnsToHideLocalStorage === null &&
          !c.isVisible &&
          c.toggleHidden()) ||
        (columnsToHideLocalStorage === null &&
          c.isVisible &&
          c.id !== 'name' &&
          c.getToggleHiddenProps()),
    )
    setModal(false)
  }, [allColumns, setModal])
  return {
    handleCancelOrClose,
    handleDeselectAllColumns,
    handleSaveColumnsSelection,
    handleSelectAllColumns,
  }
}

export default useShowColumnsModal
