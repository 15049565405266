import {arrayOf, shape, string} from 'prop-types'
import {Box, Flex, Text} from 'components'
import PeopleItem from './components'

const PeopleList = ({list, title}) => (
  <Box>
    <Text as="h4" variant="card-subtitle">
      {title}
    </Text>
    <Flex as="ul" css={{mb: '$24', fw: 'wrap', '@bp2': {mr: '$64'}}}>
      {list.map(person => (
        <PeopleItem key={person.uuid} {...person} />
      ))}
    </Flex>
  </Box>
)

PeopleList.propTypes = {
  list: arrayOf(shape({})).isRequired,
  title: string.isRequired,
}

export default PeopleList
