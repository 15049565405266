import {node} from 'prop-types'
import {Route, Redirect} from 'react-router-dom'
import Cookies from 'js-cookie'
import {AppShell, TabTitle} from 'components'
import {SearchProvider, InputQueryProvider} from 'context'
import formatHelmetTitle from './utils'

const PrivateRoute = ({children, ...rest}) => (
  <Route
    {...rest}
    render={({location}) =>
      Cookies.get('signedin') ? (
        <SearchProvider>
          <InputQueryProvider>
            <TabTitle>{formatHelmetTitle(location.pathname)}</TabTitle>
            <AppShell>{children}</AppShell>
          </InputQueryProvider>
        </SearchProvider>
      ) : (
        <Redirect to={{pathname: '/', state: {from: location}}} />
      )
    }
  />
)

PrivateRoute.propTypes = {
  children: node.isRequired,
}

export default PrivateRoute
