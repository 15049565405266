import {memo} from 'react'
import {arrayOf, shape, string} from 'prop-types'
import {Button, Flex, Select} from 'components'
import useBulkOpenLinks from './hooks'

const BulkOpenLinks = ({companiesToOpenLinks, listType}) => {
  const {
    handleOpenLinks,
    linksOptions,
    openLinkSelectedOption,
    setOpenLinkSelectedOption,
  } = useBulkOpenLinks({companiesToOpenLinks, listType})
  return (
    <Flex>
      <Select
        aria-label="Open links from"
        onChange={e => setOpenLinkSelectedOption(e.target.value)}
        value={openLinkSelectedOption}
      >
        <option value="">Open links from:</option>
        {linksOptions.map(selectedLinksItem => (
          <option
            key={selectedLinksItem}
            onClick={handleOpenLinks}
            value={selectedLinksItem}
          >
            {selectedLinksItem}
          </option>
        ))}
      </Select>
      <Button
        css={{ml: '$8', w: '$full'}}
        disabled={companiesToOpenLinks.length === 0}
        onClick={handleOpenLinks}
        size="small"
        title="Open links"
        type="button"
        variant="secondary"
      >
        Open
      </Button>
    </Flex>
  )
}

BulkOpenLinks.propTypes = {
  companiesToOpenLinks: arrayOf(shape({})).isRequired,
  listType: string.isRequired,
}

export default memo(BulkOpenLinks)
