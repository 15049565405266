import {formatTextCapitalize} from 'utils'

export const getUsernameFrom = email => email.split('@')[0].split('.')

export const formatFirstName = value =>
  formatTextCapitalize(getUsernameFrom(value)[0])

export const formatLastName = value =>
  getUsernameFrom(value)[1]
    ? formatTextCapitalize(getUsernameFrom(value)[1])
    : ''

export const formatFirstAndLastName = value =>
  getUsernameFrom(value)[1]
    ? `${formatFirstName(value)} ${formatLastName(value)}`
    : `${formatFirstName(value)}`

export const getName = (first, last) =>
  (!first && last) || (!last && first) || (first && last && `${first} ${last}`)
