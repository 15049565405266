import {memo, forwardRef, useEffect, useRef} from 'react'
import {bool, func, node, string} from 'prop-types'
import {Button} from 'components'

const IndeterminateButton = forwardRef(
  ({ariaLabel, indeterminate, children, onClick, ...rest}, ref) => {
    const defaultRef = useRef()
    const resolvedRef = ref || defaultRef
    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])
    return (
      <>
        <Button
          {...rest}
          aria-label={ariaLabel}
          color="secondary"
          css={{mr: '$8'}}
          onClick={onClick}
          ref={resolvedRef}
          size="ghost-small"
          type="checkbox"
          variant="ghost"
        >
          {children}
        </Button>
      </>
    )
  },
)

IndeterminateButton.propTypes = {
  ariaLabel: string.isRequired,
  children: node.isRequired,
  indeterminate: bool.isRequired,
  onClick: func.isRequired,
}

export default memo(IndeterminateButton)
