import {memo} from 'react'
import {arrayOf, func, shape, string} from 'prop-types'
import {Checkbox, Grid, Label} from 'components'
import useCheckboxList from './hooks'

const CheckboxList = ({
  checkedUsersIds,
  checkedUsersIdsOtherOffices,
  checkedUsersIdsSameOffice,
  creatorUsername,
  loggedUserUsername,
  myOffice,
  office,
  setCheckedUsersIds,
  setCheckedUsersIdsOtherOffices,
  setCheckedUsersIdsSameOffice,
  setUsers,
  users,
}) => {
  const {handleChange} = useCheckboxList({
    checkedUsersIds,
    checkedUsersIdsOtherOffices,
    checkedUsersIdsSameOffice,
    myOffice,
    office,
    setCheckedUsersIds,
    setCheckedUsersIdsOtherOffices,
    setCheckedUsersIdsSameOffice,
    setUsers,
    users,
  })
  return (
    <Grid
      as="ul"
      css={{
        gtc: '1fr',
        gcg: '$16',
        '@bp3': {gtc: office === 'otherOffices' ? '1fr 1fr 1fr' : '1fr'},
      }}
    >
      {users &&
        users.map(({id, name, isChecked, isDisabled}) => (
          <li key={id}>
            <Label htmlFor={name}>
              <Checkbox
                aria-label={`Select ${name}`}
                css={{mr: '$8'}}
                type="checkbox"
                id={id}
                name={name}
                disabled={loggedUserUsername !== creatorUsername && isDisabled}
                checked={isChecked}
                onChange={({target}) =>
                  handleChange(target.id, target.checked, target.name)
                }
              />
              {name}
            </Label>
          </li>
        ))}
    </Grid>
  )
}

CheckboxList.propTypes = {
  checkedUsersIds: arrayOf(string).isRequired,
  checkedUsersIdsOtherOffices: arrayOf(string).isRequired,
  checkedUsersIdsSameOffice: arrayOf(string).isRequired,
  creatorUsername: string.isRequired,
  loggedUserUsername: string.isRequired,
  myOffice: string.isRequired,
  office: string.isRequired,
  setCheckedUsersIds: func.isRequired,
  setCheckedUsersIdsOtherOffices: func.isRequired,
  setCheckedUsersIdsSameOffice: func.isRequired,
  setUsers: func.isRequired,
  users: arrayOf(shape({})).isRequired,
}

export default memo(CheckboxList)
