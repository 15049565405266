import {memo} from 'react'
import {arrayOf, func, number, shape} from 'prop-types'
import {v4 as uuidv4} from 'uuid'
import {Select} from 'components'

const SelectFilter = ({
  filterValue,
  gotoPage,
  pageIndex,
  setFilter,
  sortedOptions,
}) => (
  <Select
    aria-label="Select filter"
    value={filterValue}
    onChange={e => {
      if (pageIndex > 0) {
        gotoPage(0)
      }
      setFilter(e.target.value || undefined)
    }}
  >
    <option value="">All</option>
    {sortedOptions.map(option => (
      <option key={uuidv4()} value={option}>
        {option}
      </option>
    ))}
  </Select>
)

SelectFilter.propTypes = {
  filterValue: shape({}).isRequired,
  gotoPage: func.isRequired,
  pageIndex: number.isRequired,
  setFilter: func.isRequired,
  sortedOptions: arrayOf(shape({})).isRequired,
}

export default memo(SelectFilter)
