import {arrayOf, shape, string} from 'prop-types'
import {useTable} from 'react-table'
import {TableBody, TableHead, TableStyles} from 'components'

const Table = ({columns, data, listType}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })
  return (
    <TableStyles data-testid="table">
      <table {...getTableProps()}>
        <TableHead headerGroups={headerGroups} />
        <TableBody
          data={rows}
          getTableBodyProps={getTableBodyProps}
          listType={listType}
          prepareRow={prepareRow}
        />
      </table>
    </TableStyles>
  )
}

Table.propTypes = {
  columns: arrayOf(shape({})).isRequired,
  data: arrayOf(shape({})).isRequired,
  listType: string.isRequired,
}

export default Table
