const useCheckboxList = ({
  checkedUsersIds,
  checkedUsersIdsOtherOffices,
  checkedUsersIdsSameOffice,
  myOffice,
  office,
  setCheckedUsersIds,
  setCheckedUsersIdsOtherOffices,
  setCheckedUsersIdsSameOffice,
  setUsers,
  users,
}) => {
  const handleChange = (id, checked, name) => {
    const newUsers = [...users]
    const changedUsers = newUsers.map(user =>
      user.name === name ? {...user, isChecked: checked} : user,
    )
    setUsers(prevUsers => ({...prevUsers, [office]: changedUsers}))
    if (checked) {
      if (office === myOffice) {
        setCheckedUsersIdsSameOffice([...checkedUsersIdsSameOffice, id])
      } else {
        setCheckedUsersIdsOtherOffices([...checkedUsersIdsOtherOffices, id])
      }
      setCheckedUsersIds([...checkedUsersIds, id])
    } else {
      if (office === myOffice) {
        setCheckedUsersIdsSameOffice(
          checkedUsersIdsSameOffice.filter(item => item !== id),
        )
      } else {
        setCheckedUsersIdsOtherOffices(
          checkedUsersIdsOtherOffices.filter(item => item !== id),
        )
      }
      setCheckedUsersIds(checkedUsersIds.filter(item => item !== id))
    }
  }
  return {handleChange}
}

export default useCheckboxList
