import {gql} from '@apollo/client'

const GET_COMPANY_LAST_COMMENT = gql`
  query organization($uuid: String) {
    organization(uuid: $uuid) {
      lastComment {
        uuid
        commentText
        createdAt
        userName
        updatedAt
      }
      uuid
    }
  }
`

export default GET_COMPANY_LAST_COMMENT
