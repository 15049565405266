import {bool, string} from 'prop-types'
import {Flex} from 'components'
import Text from './styles'

const ShortcutItem = ({shortcutKey, children, color, isReview}) => (
  <Flex css={{ai: 'baseline', mr: '$8'}}>
    <Text
      variant="shortcut-key"
      css={{
        bgc: isReview ? color : '$grey100',
        color: isReview ? '$white' : color,
      }}
    >
      {shortcutKey}
    </Text>
    <Text css={{color}} variant="shortcut-description">
      {children}
    </Text>
  </Flex>
)

ShortcutItem.propTypes = {
  children: string.isRequired,
  color: string.isRequired,
  isReview: bool.isRequired,
  shortcutKey: string.isRequired,
}

export default ShortcutItem
