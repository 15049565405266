import {memo} from 'react'
import {bool, func, node, shape, string} from 'prop-types'
import {Link as InternalLink} from 'react-router-dom'
import {styled} from 'styles'

const ExternalLink = styled('a', {
  color: '$black',
  txtd: 'none',
  '&:hover': {
    color: '$secondary',
  },
  '&:focus': {
    outline: 'none',
  },
  variants: {
    variant: {
      name: {
        color: '$grey500',
        fontSize: '$14',
        fontWeight: '$500',
        lh: '$body',
      },
    },
  },
})

const InternalLinkStyled = styled(InternalLink, {
  color: '$black',
  txtd: 'none',
  '&:hover': {
    color: '$secondary',
  },
  variants: {
    variant: {
      'search-results-type': {
        ai: 'center',
        color: '$primary',
        d: 'flex',
        p: '$24',
        '&:hover': {
          bgc: '$grey100',
          color: '$primary',
        },
        '&:focus': {
          bgc: '$grey100',
          outline: 'none',
        },
      },
      'search-results-item': {
        ai: 'center',
        color: '$grey500',
        d: 'flex',
        h: '48px',
        px: '$24',
        w: '100%',
        '&:hover': {
          bgc: '$grey100',
        },
        '&:focus': {
          bgc: '$grey100',
          outline: 'none',
        },
      },
    },
  },
})

const Link = ({
  ariaLabel,
  to,
  href,
  external,
  onClick,
  children,
  css,
  title,
  variant,
}) =>
  external ? (
    <ExternalLink
      aria-label={ariaLabel}
      css={css}
      href={href}
      onClick={onClick}
      rel="noreferrer"
      target="_blank"
      title={title}
      variant={variant}
    >
      {children}
    </ExternalLink>
  ) : (
    <InternalLinkStyled
      css={css}
      onClick={onClick}
      to={to}
      title={title}
      variant={variant}
    >
      {children}
    </InternalLinkStyled>
  )

Link.propTypes = {
  ariaLabel: string,
  children: node.isRequired,
  css: shape({}).isRequired,
  external: bool.isRequired,
  href: string,
  onClick: func.isRequired,
  title: string,
  to: string,
  variant: string.isRequired,
}

Link.defaultProps = {
  ariaLabel: undefined,
  href: '',
  title: '',
  to: '',
}

export default memo(Link)
