import {gql} from '@apollo/client'

const ADD_NEW_COMPANY = gql`
  mutation addOrganization(
    $city: String
    $commentText: String
    $countryCode: String
    $diversityFounders: String!
    $foundedOn: String
    $homepageUrl: String!
    $linkedinUrl: String
    $mentionedUserIds: String
    $name: String!
    $shortDescription: String
    $userIds: String
  ) {
    addOrganization(
      city: $city
      commentText: $commentText
      countryCode: $countryCode
      diversityFounders: $diversityFounders
      foundedOn: $foundedOn
      homepageUrl: $homepageUrl
      linkedinUrl: $linkedinUrl
      mentionedUserIds: $mentionedUserIds
      name: $name
      shortDescription: $shortDescription
      userIds: $userIds
    ) {
      addOrganization {
        uuid
        homepageUrl
        name
        permalink
      }
    }
  }
`

export default ADD_NEW_COMPANY
