import {useState} from 'react'
import {useKeyShortcut} from 'hooks'

const useHeader = () => {
  const [addCompanyModal, setAddCompanyModal] = useState(false)
  const [uploadListModal, setUploadListModal] = useState(false)
  const [createNewListModal, setCreateNewListModal] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  useKeyShortcut('a', () => setAddCompanyModal(!addCompanyModal), true)
  useKeyShortcut('u', () => setUploadListModal(!uploadListModal), true)
  useKeyShortcut('n', () => setCreateNewListModal(!createNewListModal), true)
  return {
    addCompanyModal,
    createNewListModal,
    dropdownOpen,
    setAddCompanyModal,
    setCreateNewListModal,
    setDropdownOpen,
    setUploadListModal,
    uploadListModal,
  }
}

export default useHeader
