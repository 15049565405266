import {Text as BaseText} from 'components/primitives'
import {styled} from 'styles'

const Text = styled(BaseText, {
  variants: {
    variant: {
      'investors-filter-selected': {
        color: '$grey400',
        d: 'inline',
        fontSize: '$11',
        fontWeight: '$500',
      },
      'investors-filter-title': {
        d: 'inline',
        fontSize: '$11',
      },
    },
  },
})

export default Text
