import {memo} from 'react'
import {bool} from 'prop-types'
import {SearchIcon} from 'assets'
import {Box, Loader, Icon} from 'components'

const SearchLoader = ({isLoading}) => (
  <Box css={{mr: '$8'}}>
    {isLoading ? (
      <Loader size="20" data-testid="loader" />
    ) : (
      <Icon color="grey200" size="20" css={{mt: '2px'}}>
        <SearchIcon />
      </Icon>
    )}
  </Box>
)

SearchLoader.propTypes = {
  isLoading: bool.isRequired,
}

export default memo(SearchLoader)
