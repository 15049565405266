import {styled} from 'styles'

const Box = styled('div', {
  boxSizing: 'border-box',
  variants: {
    variant: {
      'search-bar': {
        d: 'block',
        mb: '$24',
        '@bp3': {
          d: 'none',
        },
      },
      'span-column': {
        gridColumn: '1',
        '@bp2': {
          gridColumn: '1/3',
        },
      },
    },
  },
})

export default Box
