import {memo, useState} from 'react'
import {bool, func, node, shape, string} from 'prop-types'
import {Button, Icon, Text} from 'components'

const HeaderButton = ({
  css,
  dropdownOpen,
  icon,
  iconFilled,
  label,
  setAddCompanyModal,
  setCreateNewListModal,
  setDropdownOpen,
  setUploadListModal,
  title,
}) => {
  const [isHover, setIsHover] = useState(false)
  return (
    <Button
      aria-label={title}
      color="primary"
      css={css}
      onClick={() => {
        if (setAddCompanyModal) setAddCompanyModal(true)
        if (setCreateNewListModal) setCreateNewListModal(true)
        if (setUploadListModal) setUploadListModal(true)
        if (setDropdownOpen) setDropdownOpen(!dropdownOpen)
      }}
      onFocus={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onMouseOver={() => setIsHover(true)}
      size="ghost-large"
      title={title}
      type="button"
      variant="ghost-icon"
    >
      {label && <Text css={{mr: '$8'}}>{label}</Text>}
      <Icon color="primary" size="20">
        {!isHover ? icon : iconFilled}
      </Icon>
    </Button>
  )
}

HeaderButton.propTypes = {
  css: shape({}).isRequired,
  dropdownOpen: bool.isRequired,
  icon: node.isRequired,
  iconFilled: node.isRequired,
  label: string.isRequired,
  setAddCompanyModal: func.isRequired,
  setCreateNewListModal: func.isRequired,
  setDropdownOpen: func.isRequired,
  setUploadListModal: func.isRequired,
  title: string.isRequired,
}

export default memo(HeaderButton)
