import BaseBox from 'components/layout/Box'
import BaseFlex from 'components/layout/Flex'
import {styled} from 'styles'

export const Box = styled(BaseBox, {
  variants: {
    variant: {
      'modal-search-container': {
        bgc: '$searchBackground',
        h: '100%',
        left: 0,
        oy: 'scroll',
        position: 'fixed',
        top: 0,
        w: '100vw',
        zIndex: '$z500',
        '@bp3': {
          ai: 'center',
          d: 'flex',
        },
      },
      'modal-search-content': {
        bgc: '$white',
        br: '$small',
        h: 'auto',
        left: '$24',
        maxHeight: '88vh',
        mb: '$24',
        mr: '$24',
        oy: 'scroll',
        position: 'absolute',
        top: '144px',
        w: 'calc(100% - 48px)',
        zIndex: '$zMax',
        '@bp3': {
          left: '96px',
          top: '56px',
        },
      },
    },
  },
})

export const Flex = styled(BaseFlex, {
  variants: {
    variant: {
      'search-bar': {
        ai: 'center',
        bgc: '$grey75',
        br: '$small',
        pl: '$4',
        position: 'relative',
        w: '$full',
        zIndex: '$z600',
        mt: '$8',
        '@bp3': {
          bgc: '$white',
          mb: 0,
          mt: 0,
        },
      },
    },
  },
})
