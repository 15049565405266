import {memo, useState} from 'react'
import {arrayOf, bool, func, shape} from 'prop-types'
import {
  Box,
  CloseModalButton,
  Grid,
  Modal,
  Text,
  UserFeedback,
} from 'components'
import ShareCompanyForm from './components'

const ShareCompanyModal = ({companiesToShare, modal, setModal}) => {
  const [isSuccess, setIsSuccess] = useState(false)
  if (isSuccess)
    return (
      <UserFeedback isSuccess={isSuccess} setIsSuccess={setIsSuccess}>
        {companiesToShare.length === 1
          ? 'Company successfully shared'
          : 'Companies successfully shared'}
      </UserFeedback>
    )
  return (
    <Modal modal={modal} setModal={setModal}>
      <CloseModalButton setModal={setModal} />
      <Text variant="modal-title">
        {companiesToShare.length === 1
          ? `Share ${companiesToShare[0].name} with`
          : 'Share companies with'}
      </Text>
      {companiesToShare.length > 1 && (
        <Grid
          as="ul"
          css={{
            gtc: 'auto auto auto',
            gridGap: '$8',
            mb: '$16',
          }}
        >
          {companiesToShare.map(({uuid, name}) => (
            <Box as="li" key={uuid}>
              <Text variant="selected-companies">{name}</Text>
            </Box>
          ))}
        </Grid>
      )}
      <ShareCompanyForm
        companiesToShare={companiesToShare}
        setIsSuccess={setIsSuccess}
        setModal={setModal}
      />
    </Modal>
  )
}

ShareCompanyModal.propTypes = {
  companiesToShare: arrayOf(shape({})).isRequired,
  modal: bool.isRequired,
  setModal: func.isRequired,
}

export default memo(ShareCompanyModal)
