import {Text as BaseText} from 'components/primitives'
import {styled} from 'styles'

const Text = styled(BaseText, {
  variants: {
    variant: {
      'disabled-list-link': {
        color: '$grey200',
        cursor: 'not-allowed',
        lh: '$body',
        pe: 'none',
      },
    },
  },
})

export default Text
