import {gql} from '@apollo/client'

const GET_MY_LIST_COLLABORATORS = gql`
  query customList($uuid: String) {
    customList(uuid: $uuid) {
      uuid
      customlistcollaboratorSet {
        id
        isOwner
        collaborator {
          id
          firstName
          lastName
          username
        }
      }
    }
  }
`

export default GET_MY_LIST_COLLABORATORS
