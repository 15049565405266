import {useMemo} from 'react'
import {useQuery} from '@apollo/client'
import GET_CURRENT_TEAM_PIPEDRIVE from './queries'

const contactSelectOption = {id: '0', value: '', label: 'Select a contact'}

const useContactField = ({orgUuid}) => {
  const {
    data: currentTeamData,
    error: isCurrentTeamQueryError,
    loading: isCurrentTeamQueryLoading,
  } = useQuery(GET_CURRENT_TEAM_PIPEDRIVE, {
    variables: {orgUuid},
    onError: error => console.error(error),
  })
  const currentTeamOptions = useMemo(
    () =>
      currentTeamData &&
      currentTeamData.currentTeam.map(({personUuid, personName, title}) => ({
        id: personUuid.uuid,
        value: personUuid.uuid,
        label: `${personName} (${title})`,
      })),
    [currentTeamData],
  )
  const CONTACT_OPTIONS = useMemo(
    () =>
      currentTeamOptions
        ? [contactSelectOption, ...currentTeamOptions]
        : [contactSelectOption],
    [currentTeamOptions],
  )
  return {
    CONTACT_OPTIONS,
    currentTeamData,
    isCurrentTeamQueryError,
    isCurrentTeamQueryLoading,
  }
}

export default useContactField
