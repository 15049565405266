import {gql} from '@apollo/client'

const MARK_LIST_AS_DONE = gql`
  mutation updateDoneListing(
    $bcnDone: Boolean
    $ldnDone: Boolean
    $mucDone: Boolean
    $totalDone: Boolean
    $unkDone: Boolean
    $uuid: String
    $week: String
    $year: String
  ) {
    updateDoneListing(
      bcnDone: $bcnDone
      ldnDone: $ldnDone
      mucDone: $mucDone
      totalDone: $totalDone
      unkDone: $unkDone
      uuid: $uuid
      week: $week
      year: $year
    ) {
      listing {
        bcnDone
        mucDone
        ldnDone
        unkDone
        totalDone
        uuid
      }
    }
  }
`

export default MARK_LIST_AS_DONE
