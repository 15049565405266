import {useState} from 'react'
import {useMutation} from '@apollo/client'
import {useForm} from 'react-hook-form'
import {useLocation} from 'react-router-dom'
import {ADD_COLLABORATORS, UPDATE_COLLABORATORS} from './mutations'
import GET_MY_LIST_COLLABORATORS from './queries'

const useShareListForm = ({
  collaboratorsIds,
  creatorUsername,
  loggedUsername,
  setIsError,
  setIsSuccess,
  setModal,
  uuid,
}) => {
  const [checkedUsersIds, setCheckedUsersIds] = useState(collaboratorsIds)
  const {handleSubmit} = useForm({mode: 'onBlur'})
  const {pathname} = useLocation()
  const scrollTop = () => window.scrollTo({top: 0, behavior: 'smooth'})
  const [addCollaborators, {loading: isAddCollaboratorsLoading}] = useMutation(
    ADD_COLLABORATORS,
    {
      onCompleted: () => {
        setModal(false)
        setIsSuccess(true)
        if (pathname === '/my-lists') scrollTop()
      },
      onError: error => {
        setModal(false)
        setIsError(true)
        console.error(error)
      },
    },
  )
  const [
    updateCollaborators,
    {loading: isUpdateCollaboratorsLoading},
  ] = useMutation(UPDATE_COLLABORATORS, {
    onCompleted: () => {
      setModal(false)
      setIsSuccess(true)
      if (pathname === '/my-lists') scrollTop()
    },
    onError: error => {
      setModal(false)
      setIsError(true)
      console.error(error)
    },
  })
  const handleAddOrUpdateCollaborators = () => {
    if (loggedUsername === creatorUsername) {
      updateCollaborators({
        variables: {
          collaboratorIds: checkedUsersIds.join(),
          uuid,
        },
        refetchQueries: [{query: GET_MY_LIST_COLLABORATORS, variables: {uuid}}],
      })
    } else {
      addCollaborators({
        variables: {
          collaboratorIds: checkedUsersIds.join(),
          uuid,
        },
        refetchQueries: [{query: GET_MY_LIST_COLLABORATORS, variables: {uuid}}],
      })
    }
  }
  return {
    checkedUsersIds,
    handleAddOrUpdateCollaborators,
    handleSubmit,
    isAddCollaboratorsLoading,
    isUpdateCollaboratorsLoading,
    setCheckedUsersIds,
  }
}

export default useShareListForm
