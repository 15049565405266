import {normalize} from 'polished'
import {globalCss} from './stitches.config'

const globalStyles = globalCss({
  ...normalize()[0],
  html: {
    backgroundColor: 'white',
    boxSizing: 'border-box',
    fontSize: '16px',
    fontFamily: '$base',
  },
  '*, *::before, *::after': {
    boxSizing: 'inherit',
  },
  'body, h1, h2, h3, h4, h5, h6, p, ol, ul': {
    margin: 0,
    padding: 0,
  },
  'ol, ul': {
    listStyle: 'none',
  },
  'table, caption, tbody, tfoot, thead, tr, th, td': {
    margin: 0,
    padding: 0,
    border: 0,
    fontSize: '100%',
    fontFamily: 'inherit',
    verticalAlign: 'baseline',
  },
  table: {
    borderCollapse: 'collapse',
  },
})

export default globalStyles
