import {memo} from 'react'
import {string} from 'prop-types'
import {Box} from 'components'
import Text from './styles'

const ProfileDescription = ({description}) => (
  <Box css={{mb: '$8'}}>
    <Text variant="short-description">{description}</Text>
  </Box>
)

ProfileDescription.propTypes = {
  description: string.isRequired,
}

export default memo(ProfileDescription)
