import {useEffect, useState} from 'react'

const useActionListItem = ({
  companiesToSave,
  initialSelectedListNames,
  isActionListSelected,
  isEveryCompanyInMyActionList,
  isSomeOrgInMyActionList,
  orgUuid,
  setIsActionListSelected,
  setSelectedListsNamesToRemove,
  setSelectedListsNamesToSave,
}) => {
  const [isSomeActive, setIsSomeActive] = useState(isSomeOrgInMyActionList)
  useEffect(() => {
    if (companiesToSave?.length > 0 && isEveryCompanyInMyActionList) {
      setIsActionListSelected(true)
    }
  }, [companiesToSave, isEveryCompanyInMyActionList, setIsActionListSelected])
  const handleSelectActionListToSave = () => {
    setIsActionListSelected(true)
    setIsSomeActive(false)
    const initialSelectedName = initialSelectedListNames.find(
      name => name === 'Action List',
    )
    if (initialSelectedName !== 'Action List' || isSomeOrgInMyActionList) {
      setSelectedListsNamesToSave(prevSelectedListsNamesToSave => [
        ...prevSelectedListsNamesToSave,
        'Action List',
      ])
    }
    setSelectedListsNamesToRemove(prevSelectedListsNamesToRemove =>
      prevSelectedListsNamesToRemove.filter(name => name !== 'Action List'),
    )
  }
  const handleSelectActionListToRemove = () => {
    setIsActionListSelected(false)
    setIsSomeActive(false)
    setSelectedListsNamesToSave(prevSelectedListsNamesToSave =>
      prevSelectedListsNamesToSave.filter(name => name !== 'Action List'),
    )
    const initialSelectedNames = initialSelectedListNames.find(
      name => name === 'Action List',
    )
    if (initialSelectedNames === 'Action List' || isSomeOrgInMyActionList) {
      setSelectedListsNamesToRemove(prevSelectedListsNamesToRemove => [
        ...prevSelectedListsNamesToRemove,
        'Action List',
      ])
    }
  }
  const isSave =
    (orgUuid && !isActionListSelected) ||
    (companiesToSave?.length > 0 && !isActionListSelected)
  return {
    handleSelectActionListToRemove,
    handleSelectActionListToSave,
    isSave,
    isSomeActive,
  }
}

export default useActionListItem
