import {useEffect} from 'react'

const useClickOutside = (ref, callback) => {
  useEffect(() => {
    const listener = e =>
      ref.current && !ref.current.contains(e.target) && callback()
    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, callback])
}

export default useClickOutside
