import {render} from 'react-dom'
import {ApolloProvider} from '@apollo/client'
import {BrowserRouter as Router} from 'react-router-dom'
import client from 'client'
import {App, TabTitle} from 'components'
import {AuthProvider} from 'context'
import {globalStyles} from 'styles'

const Index = () => {
  globalStyles()
  return (
    <Router>
      <ApolloProvider client={client}>
        <AuthProvider>
          <TabTitle isRoot />
          <App />
        </AuthProvider>
      </ApolloProvider>
    </Router>
  )
}

render(<Index />, document.getElementById('app'))

if (module.hot) {
  module.hot.accept()
}
