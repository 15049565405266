import {Text as BaseText} from 'components/primitives'
import {styled} from 'styles'

export const SelectStyles = styled('div', {
  mb: '$16',
  '&:last-of-type': {
    mb: 0,
  },
  '& > .multi-select': {
    w: '$full',
    fontSize: '$13',
    '--rmsc-bg': '#fff',
    '--rmsc-border': '#eaeaea',
    '--rmsc-gray': '#bcbec0',
    '--rmsc-h': '32px',
    '--rmsc-hover': '#eaeaea',
    '--rmsc-main': '$black',
    '--rmsc-p': '10px',
    '--rmsc-radius': '4px',
    '--rmsc-selected': '#f8f8f8',
    '&:focus': {bs: 'none !important'},
    '&:focus-within': {bs: 'none !important'},
    '@bp3': {
      minWidth: '184px',
    },
  },
  '& > .multi-select > .dropdown-container > .dropdown-heading > .clear-selected-button': {
    '&:focus': {outline: 'none !important'},
  },
  '& > .multi-select > .dropdown-container > .dropdown-heading > .dropdown-heading-value': {
    '&:focus': {outline: 'none !important'},
  },
  '& > .multi-select > .dropdown-container > .dropdown-content > .panel-content > .select-panel > div > .search-clear-button': {
    '&:focus': {outline: 'none !important'},
  },
  '& > .multi-select > .dropdown-container > .dropdown-content > .panel-content > .select-panel > ul > li > .select-item > .item-renderer': {
    d: 'inline-flex',
    w: 'min-content',
  },
  '& > .multi-select > .dropdown-container > .dropdown-content > .panel-content > .select-panel > ul > li > .select-item > .item-renderer > span': {
    whiteSpace: 'nowrap',
  },
})

export const Text = styled(BaseText, {
  variants: {
    variant: {
      filter: {
        color: '$grey300',
        fontSize: '$13',
        mb: '$8',
      },
    },
  },
})
