import {memo} from 'react'
import {shape, string} from 'prop-types'
import {formatComment} from 'utils'

const CommentsOnCell = ({value}) => formatComment(value)

CommentsOnCell.propTypes = {
  row: shape({
    value: string,
  }).isRequired,
}

export default memo(CommentsOnCell)
