import {func, string} from 'prop-types'
import {DeleteIcon} from 'assets'
import {Button, Icon, Text} from 'components'

const RemoveEventButton = ({listType, setModal}) => {
  const isEventsTable = listType === 'events'
  return (
    <Button
      color="grey"
      css={isEventsTable ? {m: 0} : {whiteSpace: 'nowrap', color: '$grey600'}}
      onClick={() => setModal(true)}
      size={isEventsTable ? 'ghost-small' : 'small'}
      title="Remove event"
      type="button"
      variant={isEventsTable ? 'ghost' : 'secondary-icon'}
    >
      <Icon css={!isEventsTable && {mr: '$4'}} size="16">
        <DeleteIcon />
      </Icon>
      {!isEventsTable && <Text>Remove</Text>}
    </Button>
  )
}

RemoveEventButton.propTypes = {
  listType: string.isRequired,
  setModal: func.isRequired,
}

export default RemoveEventButton
