import {useCallback} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {useDispatch, useInputQuery} from 'context'
import {useKeyShortcut} from 'hooks'

const useSearchInput = ({ref, setSearchDropdown}) => {
  const {push} = useHistory()
  const {pathname} = useLocation()
  const {query, setQuery} = useInputQuery()
  const dispatch = useDispatch()
  const setIsInputFocused = isInputFocused =>
    dispatch({type: 'setIsInputFocused', isInputFocused})
  const setCompaniesSearchPage = useCallback(
    companiesSearchPage =>
      dispatch({type: 'setCompaniesSearchPage', companiesSearchPage}),
    [dispatch],
  )
  const setCompaniesSearchPageSize = useCallback(
    companiesSearchPageSize =>
      dispatch({type: 'setCompaniesSearchPageSize', companiesSearchPageSize}),
    [dispatch],
  )
  const setInvestorsSearchPage = useCallback(
    investorsSearchPage =>
      dispatch({type: 'setInvestorsSearchPage', investorsSearchPage}),
    [dispatch],
  )
  const setInvestorsSearchPageSize = useCallback(
    investorsSearchPageSize =>
      dispatch({type: 'setInvestorsSearchPageSize', investorsSearchPageSize}),
    [dispatch],
  )
  const setPeopleSearchPage = useCallback(
    peopleSearchPage =>
      dispatch({type: 'setPeopleSearchPage', peopleSearchPage}),
    [dispatch],
  )
  const setPeopleSearchPageSize = useCallback(
    peopleSearchPageSize =>
      dispatch({type: 'setPeopleSearchPageSize', peopleSearchPageSize}),
    [dispatch],
  )
  useKeyShortcut('/', () => ref.current.focus())
  const handleInputBlur = () => setIsInputFocused(false)
  const handleInputChange = useCallback(
    e => {
      setQuery(e.target.value)
      if (pathname === '/companies') {
        setCompaniesSearchPage(1)
        setCompaniesSearchPageSize(25)
      }
      if (pathname === '/investors') {
        setInvestorsSearchPage(1)
        setInvestorsSearchPageSize(25)
      }
      if (pathname === '/people') {
        setPeopleSearchPage(1)
        setPeopleSearchPageSize(25)
      }
    },
    [
      pathname,
      setCompaniesSearchPage,
      setCompaniesSearchPageSize,
      setInvestorsSearchPage,
      setInvestorsSearchPageSize,
      setPeopleSearchPage,
      setPeopleSearchPageSize,
      setQuery,
    ],
  )
  const handleInputFocus = () => {
    setSearchDropdown(true)
    setIsInputFocused(true)
  }
  const handleKeyDown = ({ctrlKey, key, keyCode}) => {
    if (
      key === 'Enter' &&
      pathname !== '/companies' &&
      pathname !== '/investors' &&
      pathname !== '/people'
    ) {
      push('/companies')
      setSearchDropdown(false)
    }
    if (
      ctrlKey &&
      keyCode === 13 &&
      pathname !== '/companies' &&
      pathname !== '/investors' &&
      pathname !== '/people'
    ) {
      push('/investors')
      setSearchDropdown(false)
    }
    if (key === 'Escape') {
      setQuery('')
      setSearchDropdown(false)
      ref.current.blur()
    }
  }
  return {
    handleInputBlur,
    handleInputChange,
    handleInputFocus,
    handleKeyDown,
    query,
  }
}

export default useSearchInput
