import {Box} from 'components/layout'
import {Text as BaseText} from 'components/primitives'
import {styled} from 'styles'

export const LastCommentCellContainer = styled(Box, {
  mr: '$4',
})

export const Text = styled(BaseText, {
  variants: {
    variant: {
      'last-comment-cell': {
        fontSize: '$13',
        lh: '$body',
        d: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 5,
        maxWidth: '160px',
      },
    },
  },
})
