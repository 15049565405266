import {memo} from 'react'
import {bool, func, string} from 'prop-types'
import {Card, ErrorMessage, Loader, Text} from 'components'
import Content from './components'
import usePortfolio from './hooks'

const Portfolio = ({isCompany, setIsInvestor, uuid}) => {
  const {
    hasCategories,
    hasCompanies,
    hasFundingRounds,
    isInvestor,
    isInvestorQueryError,
    isInvestorQueryLoading,
    setHasCategories,
    setHasCompanies,
    setHasFundingRounds,
  } = usePortfolio({isCompany, setIsInvestor, uuid})
  if (isInvestorQueryLoading)
    return <Loader size="20" data-testid="portfolio-loader" />
  if (isInvestorQueryError)
    return (
      <ErrorMessage css={{mb: '$16'}}>
        An error occurred getting the portfolio data. Please try to refresh or
        report this bug.
      </ErrorMessage>
    )
  return isInvestor && (hasCompanies || hasFundingRounds || hasCategories) ? (
    <Card css={{mb: '$16'}}>
      <Text as="h3" variant="card-title">
        Portfolio
      </Text>
      <Content
        investorUuid={uuid}
        setHasCompanies={setHasCompanies}
        setHasFundingRounds={setHasFundingRounds}
        setHasCategories={setHasCategories}
      />
    </Card>
  ) : null
}

Portfolio.propTypes = {
  isCompany: bool.isRequired,
  setIsInvestor: func.isRequired,
  uuid: string.isRequired,
}

export default memo(Portfolio)
