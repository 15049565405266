import {useState} from 'react'
import {bool, number, string} from 'prop-types'
import {useKeyShortcut} from 'hooks'
import {CreateNewListModal} from 'components'
import {SaveToListButton, SaveToListModal} from './components'

const SaveToList = ({
  inMyActionlist,
  inMyList,
  isCompanyProfile,
  isInvestor,
  isTable,
  logoUrl,
  orgName,
  orgUuid,
  totalCount,
}) => {
  const [saveToListModal, setSaveToListModal] = useState(false)
  const [createNewListModal, setCreateNewListModal] = useState(false)
  const [commentText, setCommentText] = useState('')
  const [companiesSavedOrRemoved, setCompaniesSavedOrRemoved] = useState([])
  useKeyShortcut(
    'L',
    () =>
      isCompanyProfile && !isInvestor && setSaveToListModal(!saveToListModal),
    true,
  )
  return (
    <>
      <SaveToListButton
        isInList={inMyActionlist || inMyList}
        isTable={isTable}
        setModal={setSaveToListModal}
      />
      <SaveToListModal
        commentText={commentText}
        companiesSavedOrRemoved={companiesSavedOrRemoved}
        logoUrl={logoUrl}
        inMyActionlist={inMyActionlist}
        modal={saveToListModal}
        orgName={orgName}
        orgUuid={orgUuid}
        setCommentText={setCommentText}
        setCompaniesSavedOrRemoved={setCompaniesSavedOrRemoved}
        setCreateNewListModal={setCreateNewListModal}
        setModal={setSaveToListModal}
        totalCount={totalCount}
      />
      <CreateNewListModal
        commentText={commentText}
        companiesSavedOrRemoved={companiesSavedOrRemoved}
        logoUrl={logoUrl}
        modal={createNewListModal}
        orgName={orgName}
        orgUuid={orgUuid}
        setCompaniesSavedOrRemoved={setCompaniesSavedOrRemoved}
        setModal={setCreateNewListModal}
      />
    </>
  )
}

SaveToList.propTypes = {
  inMyActionlist: bool.isRequired,
  inMyList: bool.isRequired,
  isCompanyProfile: bool.isRequired,
  isInvestor: bool.isRequired,
  isTable: bool.isRequired,
  logoUrl: string.isRequired,
  orgName: string.isRequired,
  orgUuid: string.isRequired,
  totalCount: number.isRequired,
}

export default SaveToList
