import {gql} from '@apollo/client'

const GET_PERSON_LABELS = gql`
  query personLabel($uuid: String!) {
    personLabel(uuid: $uuid) {
      addedByName
      commentText
      createdAt
      labelUuid {
        color
        name
        uuid
      }
      uuid
    }
  }
`

export default GET_PERSON_LABELS
