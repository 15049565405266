import {memo} from 'react'
import {bool, func} from 'prop-types'
import {MenuExtendIcon} from 'assets'
import {Button, Icon} from 'components'

const ExtendButton = ({extendMenu, setExtendMenu}) => (
  <Button
    data-testid="extend-button"
    variant="extend-menu"
    css={{
      '@initial': {d: 'none'},
      '@bp2': {d: 'none'},
      '@bp3': {
        d: 'block',
        left: extendMenu ? '180px' : '70px',
      },
    }}
    title={extendMenu ? 'Shrink menu' : 'Extend menu'}
    type="button"
    onClick={() => setExtendMenu(!extendMenu)}
  >
    <Icon
      color="primary"
      size="24"
      css={{
        transform: `${extendMenu ? 'rotate(180deg)' : 'rotate(0)'}`,
        transition: 'transform 0.75s 0.25s',
      }}
    >
      <MenuExtendIcon />
    </Icon>
  </Button>
)

ExtendButton.propTypes = {
  extendMenu: bool.isRequired,
  setExtendMenu: func.isRequired,
}

export default memo(ExtendButton)
