import {
  AmountRaisedCell,
  CategoriesCell,
  CustomSort,
  DateCell,
  InvestmentTypeCell,
  LocationCell,
} from 'components/table'
import CompanyCell from './cells'

const portfolioFundingRoundsColumns = [
  {
    Header: 'Company',
    accessor: 'orgName',
    Cell: CompanyCell,
    sortType: CustomSort,
  },
  {
    Header: 'Location',
    accessor: 'location',
    Cell: LocationCell,
  },
  {
    Header: 'Categories',
    accessor: 'categoryList',
    Cell: CategoriesCell,
  },
  {
    Header: 'Announced date',
    accessor: 'announcedOn',
    Cell: DateCell,
  },
  {
    Header: 'Type',
    accessor: 'investmentType',
    Cell: InvestmentTypeCell,
  },
  {
    Header: 'Amount raised (USD)',
    accessor: 'raisedAmountUsd',
    Cell: AmountRaisedCell,
  },
]

export default portfolioFundingRoundsColumns
