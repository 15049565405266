const getUsersOtherOffices = (users, myself) =>
  users
    .filter(
      ({username, office}) =>
        username !== myself.username &&
        username !== 'test@nautacapital.com' &&
        username !== 'test2@nautacapital.com' &&
        username !== 'nautadb@nautacapital.com' &&
        office !== myself.office,
    )
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name))

export default getUsersOtherOffices
