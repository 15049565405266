import {memo} from 'react'
import {shape, string} from 'prop-types'
import {Box, Text} from 'components'
import {
  formatFirstAndLastName,
  formatDate,
  formatNotNautableReason,
} from 'utils'

const NautableCell = ({
  row: {
    original: {lastReview},
  },
}) =>
  lastReview ? (
    <Box css={{mr: '$4'}}>
      {lastReview.nautable === 'YES' && (
        <Text variant="nautable-cell-review" color="green">
          Nautable!
        </Text>
      )}
      {lastReview.nautable === 'NOT_SURE' && (
        <Text variant="nautable-cell-review" color="yellow">
          Not sure.
        </Text>
      )}
      {lastReview.nautable === 'NO' && (
        <Text variant="nautable-cell-review" color="red">
          No,{' '}
          {lastReview.notNautableReason &&
            !lastReview.notNautableOther &&
            `${formatNotNautableReason(lastReview.notNautableReason)}.`}
          {lastReview.notNautableReason &&
            lastReview.notNautableOther &&
            `${lastReview.notNautableReason}: ${lastReview.notNautableOther}.`}
        </Text>
      )}
      <Text variant="nautable-cell-by">
        By{' '}
        <Text variant="nautable-cell-name">
          {formatFirstAndLastName(lastReview.userName)}
        </Text>
        <Text variant="nautable-cell-date">
          on {formatDate(lastReview.createdAt)}
        </Text>
      </Text>
    </Box>
  ) : null

NautableCell.propTypes = {
  row: shape({
    original: shape({
      lastReview: shape({
        nautable: string,
        notNautableReason: string,
        notNautableOther: string,
        userName: string,
        createdAt: string,
      }),
    }),
  }).isRequired,
}

export default memo(NautableCell)
