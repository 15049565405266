import {arrayOf, bool, func} from 'prop-types'
import {
  Box,
  Button,
  CancelButton,
  Checkbox,
  Flex,
  Modal,
  Text,
} from 'components'
import {CloseModalButton, IndeterminateButton} from './components'
import useShowColumnsModal from './hooks'

const ShowColumnsModal = ({
  allColumns,
  modal,
  setIsColumnsSavedSuccess,
  setModal,
}) => {
  const {
    handleCancelOrClose,
    handleDeselectAllColumns,
    handleSaveColumnsSelection,
    handleSelectAllColumns,
  } = useShowColumnsModal({
    allColumns,
    setIsColumnsSavedSuccess,
    setModal,
  })
  return (
    <Modal modal={modal} setModal={setModal}>
      <CloseModalButton onClick={handleCancelOrClose} />
      <Text variant="modal-title">Show columns</Text>
      <Flex direction="column">
        <Flex css={{mb: '$8', fd: 'row'}}>
          <IndeterminateButton
            onClick={handleSelectAllColumns}
            ariaLabel="select-all"
          >
            Select All
          </IndeterminateButton>{' '}
          <IndeterminateButton
            onClick={handleDeselectAllColumns}
            ariaLabel="deselect-all"
          >
            Deselect All
          </IndeterminateButton>
        </Flex>
        {allColumns?.map(({id, Header, getToggleHiddenProps}) => (
          <Box key={id} css={{mb: '$4'}}>
            <label htmlFor={id}>
              <Checkbox
                checked={id === 'name'}
                disabled={id === 'name'}
                type="checkbox"
                {...getToggleHiddenProps()}
              />{' '}
              {(id === 'selection' && 'Selection') ||
                (id === 'isOnPipedrive' && 'Pipedrive (PD)') ||
                (id === 'scorePercentile' && 'Percentile (%tile)') ||
                (id !== 'selection' && Header)}
            </label>
          </Box>
        ))}
        <Flex css={{as: 'flex-end', gap: '$16', mt: '$24'}}>
          <CancelButton onClick={handleCancelOrClose}>Cancel</CancelButton>
          <Button type="button" onClick={handleSaveColumnsSelection}>
            Save
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

ShowColumnsModal.propTypes = {
  allColumns: arrayOf({}).isRequired,
  modal: bool.isRequired,
  setIsColumnsSavedSuccess: func.isRequired,
  setModal: func.isRequired,
  setShowColumnsModal: func.isRequired,
}

export default ShowColumnsModal
