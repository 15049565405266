import {gql} from '@apollo/client'

export const GET_MY_LIST_COMPANIES_LIST_PAGE_INFO_OWNER_OR_COLLABORATOR = gql`
  query customList($uuid: String) {
    customList(uuid: $uuid) {
      createdAt
      creator {
        id
        firstName
        lastName
        usercustomfield {
          id
          office
        }
        username
      }
      description
      customlistcollaboratorSet {
        id
        isOwner
        collaborator {
          id
          firstName
          lastName
          username
        }
      }
      name
      totalCount
      totalReviews
      updatedAt
      uuid
    }
  }
`

export const GET_MY_LISTS_DASHBOARD = gql`
  query customList($uuid: String) {
    customList(uuid: $uuid) {
      name
      description
      creator {
        firstName
        id
        lastName
        usercustomfield {
          id
          office
        }
        username
      }
      createdAt
      customlistcollaboratorSet {
        id
        isOwner
        collaborator {
          id
          firstName
          lastName
          username
        }
      }
      totalDone
      totalCount
      totalReviews
      updatedAt
      uuid
    }
  }
`

export const GET_MY_LISTS_RELATED = gql`
  query customListsRelated($orgUuid: String) {
    customListsRelated(orgUuid: $orgUuid) {
      createdAt
      creator {
        firstName
        lastName
        username
      }
      customlistcollaboratorSet {
        id
        isOwner
        collaborator {
          id
          firstName
          lastName
          username
        }
      }
      description
      name
      totalCount
      totalReviews
      updatedAt
      uuid
    }
  }
`

export const GET_MY_LISTS = gql`
  query customList($uuid: String) {
    customList(uuid: $uuid) {
      name
      description
      creator {
        firstName
        id
        lastName
        usercustomfield {
          id
          office
        }
        username
      }
      createdAt
      customlistcollaboratorSet {
        id
        isOwner
        collaborator {
          id
          firstName
          lastName
          username
        }
      }
      updatedAt
      uuid
    }
  }
`

export const GET_COMPANY_IN_LISTS = gql`
  query organization($uuid: String) {
    organization(uuid: $uuid) {
      inMyActionlist
      inMyList
      uuid
    }
  }
`
