import {memo} from 'react'
import {bool} from 'prop-types'
import {Link} from 'react-router-dom'
import {NautaIcon} from 'assets'
import {Flex, Icon} from 'components'
import Text from './styles'

const Logo = ({showLabel}) => (
  <Flex
    aria-label="NautaDB"
    as={Link}
    css={{
      ai: 'center',
      fg: 1,
      txtd: 'none',
    }}
    to="/dashboard"
  >
    <Icon color="primary" size="32">
      <NautaIcon data-testid="icon" />
    </Icon>
    <Text variant="menu-logo" css={{'@bp3': {d: showLabel ? 'block' : 'none'}}}>
      NautaDB
    </Text>
  </Flex>
)

Logo.propTypes = {
  showLabel: bool.isRequired,
}

export default memo(Logo)
