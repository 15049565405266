import {gql} from '@apollo/client'

export const GET_EVENT_EDITION = gql`
  query customEdition($editionUuid: String!) {
    customEdition(editionUuid: $editionUuid) {
      id
      addedBy {
        firstName
        lastName
        usercustomfield {
          color
        }
      }
      bcnScope
      berScope
      city
      countryCode
      createdAt
      description
      editionFrequency
      editionUuid
      endsOn
      eventUuid
      facebookUrl
      lastComment {
        commentText
        createdAt
        updatedAt
        userName
      }
      ldnScope
      linkedinUrl
      listUuid
      logoUrl
      name
      onlineEvent
      recurringEvent
      startsOn
      ticketPrice
      twitterUrl
      usersAttending
      webscrapingAvailability
      webscrapingRequest
      webscrapingRequestBy {
        firstName
        lastName
        username
      }
      webscrapingRequestDate
      website
    }
  }
`

export const GET_EVENT_ALL_EDITIONS = gql`
  query customEvent($eventUuid: String!) {
    customEvent(eventUuid: $eventUuid) {
      id
      addedBy {
        firstName
        lastName
        usercustomfield {
          color
        }
      }
      bcnScope
      berScope
      city
      countryCode
      createdAt
      description
      editionFrequency
      editionUuid
      endsOn
      eventUuid
      facebookUrl
      lastComment {
        commentText
        createdAt
        updatedAt
        userName
      }
      ldnScope
      linkedinUrl
      listUuid
      logoUrl
      name
      onlineEvent
      recurringEvent
      startsOn
      ticketPrice
      twitterUrl
      usersAttending
      webscrapingAvailability
      webscrapingRequest
      webscrapingRequestBy {
        firstName
        lastName
        username
      }
      webscrapingRequestDate
      website
    }
  }
`

export const GET_EVENTS_REMOVED = gql`
  query customEventsPaginated(
    $page: Int
    $pageSize: Int
    $pastEvents: Boolean
    $futureEvents: Boolean
  ) {
    customEventsPaginated(
      page: $page
      pageSize: $pageSize
      pastEvents: $pastEvents
      futureEvents: $futureEvents
    ) {
      totalCount
      objects {
        id
        editionUuid
        eventUuid
      }
    }
  }
`

export const GET_EVENTS = gql`
  query customEventsPaginated(
    $page: Int
    $pageSize: Int
    $pastEvents: Boolean
    $futureEvents: Boolean
  ) {
    customEventsPaginated(
      page: $page
      pageSize: $pageSize
      pastEvents: $pastEvents
      futureEvents: $futureEvents
    ) {
      totalCount
      objects {
        id
        addedBy {
          id
          username
          firstName
          lastName
          usercustomfield {
            id
            color
          }
        }
        bcnScope
        berScope
        city
        countryCode
        createdAt
        description
        editionFrequency
        editionUuid
        endsOn
        eventUuid
        facebookUrl
        lastComment {
          commentText
          createdAt
          updatedAt
          userName
        }
        ldnScope
        linkedinUrl
        listUuid
        logoUrl
        name
        onlineEvent
        recurringEvent
        startsOn
        ticketPrice
        twitterUrl
        updatedAt
        usersAttending
        webscrapingAvailability
        webscrapingRequest
        webscrapingRequestBy {
          firstName
          lastName
          username
        }
        webscrapingRequestDate
        website
      }
    }
  }
`
