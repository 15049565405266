import {useLocation} from 'react-router-dom'
import {formatNumber} from 'utils'

const useShowingResultsCounter = ({
  listType,
  totalCount,
  totalFilteredCount,
}) => {
  const {pathname} = useLocation()
  const isDashboardPage = pathname === '/dashboard'
  const isCompaniesOrPeopleOrInvestorsPage =
    pathname === '/companies' ||
    pathname === '/people' ||
    pathname === '/investors'
  const isWeeklyList =
    listType === 'weekly-list' || listType === 'founders-list'
  const isUploadedLists = listType === 'uploaded-lists'
  const isWebScrapings = listType === 'web-scrapings'
  const isPeople = listType === 'people'
  const isInvestors = listType === 'investors' || listType === 'investors-page'
  const isPortfolioFundingRounds = listType === 'portfolio-funding-rounds'
  const isPortfolioCategories = listType === 'portfolio-categories'
  const isMyLists = listType === 'my-lists'
  const isVcTracker = listType === 'vc-tracker'
  const isEvents = listType === 'upcoming-events' || listType === 'past-events'
  const resultsType =
    (isWeeklyList && 'weeks') ||
    (isUploadedLists && 'uploaded lists') ||
    (isWebScrapings && 'web scrapings') ||
    (isPeople && 'people') ||
    (isInvestors && 'investors') ||
    (isPortfolioFundingRounds && 'funding rounds') ||
    (isPortfolioCategories && 'categories') ||
    (isMyLists && isDashboardPage && 'lists') ||
    (isVcTracker && 'companies/urls') ||
    (isEvents && 'events') ||
    'companies'
  const totalResults = formatNumber(totalCount)
  const totalFiltered = formatNumber(totalFilteredCount)
  return {
    isCompaniesOrPeopleOrInvestorsPage,
    isDashboardPage,
    resultsType,
    totalFiltered,
    totalResults,
  }
}

export default useShowingResultsCounter
