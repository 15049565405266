import {func, string} from 'prop-types'
import {
  Button,
  CancelButton,
  Flex,
  InputField,
  Loader,
  SelectField,
  TextareaField,
} from 'components'
import useEditListForm from './hooks'

const EditListForm = ({
  creatorId,
  creatorOffice,
  description,
  name,
  setIsError,
  setIsSuccess,
  setModal,
  uuid,
}) => {
  const {
    errors,
    handleEditList,
    handleSubmit,
    isSaveLoading,
    LIST_OWNER_OPTIONS,
    listNameRef,
    register,
    showOwnerSelect,
  } = useEditListForm({
    creatorId,
    creatorOffice,
    setIsError,
    setIsSuccess,
    setModal,
    uuid,
  })
  return (
    <Flex as="form" direction="column" onSubmit={handleSubmit(handleEditList)}>
      <InputField
        defaultValue={name}
        errors={errors.name}
        name="name"
        register={e => {
          register(e, {required: true})
          listNameRef.current = e
        }}
        onKeyDown={({key}) => key === 'Escape' && setModal(false)}
        required
        type="text"
      >
        Name
      </InputField>
      <TextareaField
        defaultValue={description}
        css={{mt: '$16'}}
        name="description"
        register={register}
        rows={2}
      >
        Description
      </TextareaField>
      {showOwnerSelect && (
        <SelectField
          css={{mt: '$16'}}
          defaultValue={creatorId}
          name="ownerId"
          options={LIST_OWNER_OPTIONS}
          register={register}
        >
          Owner
        </SelectField>
      )}
      <Flex css={{as: 'flex-end', gap: '$16', mt: '$24'}}>
        <CancelButton onClick={() => setModal(false)}>Cancel</CancelButton>
        <Flex as={Button} type="submit">
          Save
          {isSaveLoading && (
            <Loader size="14" css={{ml: '$8'}} data-testid="loader" />
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

EditListForm.propTypes = {
  creatorId: string.isRequired,
  creatorOffice: string.isRequired,
  description: string.isRequired,
  name: string.isRequired,
  setIsError: func.isRequired,
  setIsSuccess: func.isRequired,
  setModal: func.isRequired,
  uuid: string.isRequired,
}

export default EditListForm
