import {gql} from '@apollo/client'

const GET_USERS = gql`
  query users {
    users {
      id
      firstName
      lastName
      username
      usercustomfield {
        id
        office
      }
    }
  }
`

export default GET_USERS
