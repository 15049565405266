import {gql} from '@apollo/client'

const GET_INVESTOR_PORTFOLIO_CATEGORIES = gql`
  query investorPortfolioCategories($investorUuid: String) {
    investorPortfolioCategories(investorUuid: $investorUuid) {
      categories {
        category
        count
        organizations {
          name
          logoUrl
          permalink
          uuid
        }
      }
    }
  }
`

export default GET_INVESTOR_PORTFOLIO_CATEGORIES
