import {gql} from '@apollo/client'

const ADD_COMPANY_COMMENT = gql`
  mutation createOrganizationComment(
    $commentText: String!
    $mentionedUserIds: String
    $orgUuid: String!
  ) {
    createOrganizationComment(
      commentText: $commentText
      mentionedUserIds: $mentionedUserIds
      orgUuid: $orgUuid
    ) {
      comment {
        uuid
        orgUuid
        userId
        userName
        createdAt
        commentText
      }
    }
  }
`

export default ADD_COMPANY_COMMENT
