import {memo} from 'react'
import {bool, func, number, string} from 'prop-types'
import {Button, CancelButton, Flex} from 'components'
import useRemoveFromListForm from './hooks'

const RemoveFromListForm = ({
  isLastItem,
  itemIndex,
  listName,
  listUuid,
  logoUrl,
  orgName,
  orgUuid,
  pageIndex,
  previousPage,
  setCompanyRemoved,
  setIsLoading,
  setIsRemoveError,
  setIsRemoveSuccess,
  setLogoUrlRemove,
  setModal,
}) => {
  const {handleRemoveFromList, yesRef} = useRemoveFromListForm({
    isLastItem,
    itemIndex,
    listName,
    listUuid,
    logoUrl,
    orgName,
    orgUuid,
    pageIndex,
    previousPage,
    setCompanyRemoved,
    setIsLoading,
    setIsRemoveError,
    setIsRemoveSuccess,
    setLogoUrlRemove,
    setModal,
  })
  return (
    <Flex css={{jc: 'flex-end', gap: '$16'}} data-testid="remove-form">
      <CancelButton onClick={() => setModal(false)}>Cancel</CancelButton>
      <Button
        color="red"
        onClick={handleRemoveFromList}
        ref={yesRef}
        type="button"
      >
        Yes
      </Button>
    </Flex>
  )
}

RemoveFromListForm.propTypes = {
  isLastItem: bool.isRequired,
  itemIndex: number.isRequired,
  listName: string.isRequired,
  listUuid: string.isRequired,
  logoUrl: string.isRequired,
  orgName: string.isRequired,
  orgUuid: string.isRequired,
  pageIndex: number.isRequired,
  previousPage: func.isRequired,
  setCompanyRemoved: func.isRequired,
  setIsLoading: func.isRequired,
  setIsRemoveError: func.isRequired,
  setIsRemoveSuccess: func.isRequired,
  setLogoUrlRemove: func.isRequired,
  setModal: func.isRequired,
}

export default memo(RemoveFromListForm)
