import {bool, func, string} from 'prop-types'
import {Redirect} from 'react-router-dom'
import {CloseModalButton, Modal, Text, UserFeedback} from 'components'
import RemoveEventForm from './components'
import useRemoveEventModal from './hooks'

const RemoveEventModal = ({
  editionUuid,
  eventUuid,
  listType,
  modal,
  name,
  setModal,
}) => {
  const {
    errorReason,
    handleRemoveEvent,
    isError,
    isEventsTable,
    isRemoveEventError,
    isRemoveEventLoading,
    isSuccess,
    setIsError,
    setIsSuccess,
  } = useRemoveEventModal({editionUuid, eventUuid, listType, setModal})
  if (isError && isRemoveEventError)
    return (
      <UserFeedback isError={isError} setIsError={setIsError}>
        Error removing event.{' '}
        {errorReason || 'Please, try again or report this bug.'}
      </UserFeedback>
    )
  if (isSuccess && isEventsTable)
    return (
      <UserFeedback isSuccess={isSuccess} setIsSuccess={setIsSuccess}>
        Event removed successfully
      </UserFeedback>
    )
  if (isSuccess && !isEventsTable) return <Redirect to="/events" />
  return (
    <Modal modal={modal} setModal={setModal}>
      <CloseModalButton setModal={setModal} />
      <Text variant="modal-title">Remove event</Text>
      <Text css={{fontSize: '$14'}}>
        Are you sure you want to remove the {name} event?
      </Text>
      <RemoveEventForm
        handleRemoveEvent={handleRemoveEvent}
        isRemoveEventLoading={isRemoveEventLoading}
        setModal={setModal}
      />
    </Modal>
  )
}

RemoveEventModal.propTypes = {
  editionUuid: string.isRequired,
  eventUuid: string.isRequired,
  listType: string.isRequired,
  modal: bool.isRequired,
  name: string.isRequired,
  setModal: func.isRequired,
}

export default RemoveEventModal
