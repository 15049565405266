import {Text as BaseText} from 'components/primitives'
import {styled} from 'styles'

const Text = styled(BaseText, {
  variants: {
    variant: {
      'shortcut-description': {
        color: '$grey500',
        fontSize: '$9',
        lh: '$body',
        ml: '$4',
        py: '6px',
        '@bp1': {
          mb: 0,
        },
      },
      'shortcut-key': {
        br: '$small',
        fontSize: '$9',
        fontWeight: '$500',
        lh: '$body',
        px: '$4',
        py: '$2',
        w: 'max-content',
        '@bp1': {
          mb: 0,
        },
      },
    },
  },
})

export default Text
