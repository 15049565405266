import {memo} from 'react'
import {bool, shape, string} from 'prop-types'
import {FallbackImgCompanySm, StarIcon} from 'assets'
import {Flex, Icon, Image, Link, SocialLinksList, Text} from 'components'

const CompanyCell = ({
  row: {
    original: {
      cbUrl,
      diversityFounders,
      homepageUrl,
      linkedinUrl,
      logoUrl,
      orgName,
      orgPermalink,
      orgUuid,
      pipedriveUrl,
      twitterUrl,
    },
  },
}) =>
  orgPermalink && orgUuid ? (
    <Flex css={{mr: '$8'}}>
      <Image
        alt={`${orgName} logo`}
        size="32"
        src={logoUrl || FallbackImgCompanySm}
        variant="company"
      />
      <Flex direction="column" css={{ml: '$8', w: '-webkit-fill-available'}}>
        <Link
          css={{
            ai: 'center',
            d: 'flex',
            mb: '$8',
            maxWidth: '200px',
            minWidth: '144px',
            overflowWrap: 'break-word',
          }}
          external
          href={`/companies/${orgPermalink}?uuid=${orgUuid}`}
          title={orgName}
        >
          <Text>{orgName}</Text>
          {(diversityFounders === 'true' || diversityFounders === true) && (
            <Icon
              color="yellow100"
              css={{ml: '$4'}}
              data-testid="star"
              size="16"
            >
              <StarIcon />
            </Icon>
          )}
        </Link>
        <SocialLinksList
          cbUrl={cbUrl}
          homepageUrl={homepageUrl}
          linkedinUrl={linkedinUrl}
          name={orgName}
          pipedriveUrl={pipedriveUrl}
          twitterUrl={twitterUrl}
        />
      </Flex>
    </Flex>
  ) : (
    <Flex css={{mr: '$8'}}>
      <Image
        alt={`${orgName} logo`}
        size="32"
        src={logoUrl || FallbackImgCompanySm}
        variant="company"
      />
      <Flex direction="column" css={{ml: '$8', w: '-webkit-fill-available'}}>
        <Text>{orgName}</Text>
        {(diversityFounders === 'true' || diversityFounders === true) && (
          <Icon color="yellow100" css={{ml: '$4'}} data-testid="star" size="16">
            <StarIcon />
          </Icon>
        )}
      </Flex>
    </Flex>
  )

CompanyCell.propTypes = {
  row: shape({
    original: shape({
      cbUrl: string,
      diversityFounders: bool,
      homepageUrl: string,
      linkedinUrl: string,
      logoUrl: string,
      orgName: string,
      orgPermalink: string,
      orgUuid: string,
      pipedriveUrl: string,
      twitterUrl: string,
    }),
  }).isRequired,
}

export default memo(CompanyCell)
