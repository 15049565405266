const getEventScope = ({bcnScope, berScope, ldnScope}) => {
  let scope = ''
  if (bcnScope) {
    scope = scope.concat('BCN, ')
  }
  if (berScope) {
    scope = scope.concat('BER, ')
  }
  if (ldnScope) {
    scope = scope.concat('LDN, ')
  }
  return scope.endsWith(', ') ? scope.slice(0, -2) : scope
}

export default getEventScope
