import {keyframes, styled} from 'styles'

const showFilters = keyframes({
  from: {
    opacity: 0,
    transform: 'translateY(-5%)',
  },
  to: {
    opacity: 1,
    transform: 'translateY(0%)',
  },
})

const Card = styled('div', {
  bgc: '$white',
  br: '$small',
  bs: '$2',
  color: '$grey500',
  p: '$24',
  w: '100%',
  variants: {
    variant: {
      login: {
        bs: 'none',
        px: '$32',
        py: '$64',
        '@bp1': {
          px: '$128',
        },
        '@bp3': {
          b: '1px solid $grey100',
          bs: '$1',
          mx: 'auto',
          my: '$128',
          p: '$48',
          w: '$464',
        },
      },
      dashboard: {
        d: 'flex',
        fd: 'column',
        jc: 'space-between',
      },
      'my-list': {
        d: 'flex',
        fd: 'column',
        mb: '$16',
        '@bp3': {mb: 0},
      },
      'profile-column-1': {
        gridColumn: 1,
        mb: '$16',
        w: '$full',
      },
      'profile-column-2': {
        gridColumn: 2,
        mb: '$16',
        w: '$full',
      },
      'search-filters': {
        animation: `${showFilters} 0.25s ease-in`,
        bgc: '$white',
        w: '$full',
        zIndex: '$z400',
        mb: '$16',
        '@bp3': {
          animationName: 'none',
          h: 'min-content',
          mr: '$16',
          position: 'sticky',
          top: '$16',
          w: '232px',
        },
      },
    },
  },
})

export default Card
