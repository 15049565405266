import {useState, useEffect, useRef} from 'react'
import {useMutation} from '@apollo/client'
import {useForm} from 'react-hook-form'
import {useLocation} from 'react-router-dom'
import {ADD_COMPANY_COMMENT} from 'constants/mutations'
import {
  GET_COMPANY_COMMENTS,
  GET_EVENT_COMMENTS,
  GET_PERSON_COMMENTS,
} from 'constants/queries'
import {useKeyShortcut} from 'hooks'
import {getMentionedUserIds} from 'utils'
import {ADD_EVENT_COMMENT, ADD_PERSON_COMMENT} from './mutations'

const useCommentsForm = ({isEvent, isPerson, uuid}) => {
  const [comment, setComment] = useState('')
  const [isError, setIsError] = useState(false)
  const commentRef = useRef(null)
  const {pathname} = useLocation()
  const {register, handleSubmit, errors, clearErrors} = useForm({
    mode: 'onBlur',
  })
  const [
    addComment,
    {error: isAddCommentError, loading: isAddCommentLoading},
  ] = useMutation(
    (isPerson && ADD_PERSON_COMMENT) ||
      (isEvent && ADD_EVENT_COMMENT) ||
      ADD_COMPANY_COMMENT,
    {
      refetchQueries: [
        {
          query:
            (isPerson && GET_PERSON_COMMENTS) ||
            (isEvent && GET_EVENT_COMMENTS) ||
            GET_COMPANY_COMMENTS,
          variables: (isPerson && {personUuid: uuid}) ||
            (isEvent && {eventUuid: uuid}) || {orgUuid: uuid},
        },
      ],
      onError: err => {
        setIsError(true)
        console.error(err)
      },
    },
  )
  useEffect(() => {
    return () => setComment('')
  }, [pathname])
  useEffect(() => {
    let timer
    if (errors.comment) {
      timer = setTimeout(() => clearErrors('comment'), 3000)
    }
    return () => clearTimeout(timer)
  }, [errors.comment, clearErrors])
  useKeyShortcut('.', () => commentRef.current && commentRef.current.focus())
  const handleAddComment = () => {
    const commentAndMentionedUserIds = {
      commentText: comment,
      mentionedUserIds: getMentionedUserIds(comment),
    }
    addComment({
      variables: (isPerson && {
        personUuid: uuid,
        ...commentAndMentionedUserIds,
      }) ||
        (isEvent && {
          eventUuid: uuid,
          ...commentAndMentionedUserIds,
        }) || {
          orgUuid: uuid,
          ...commentAndMentionedUserIds,
        },
    })
    setComment('')
  }
  return {
    comment,
    commentRef,
    errors,
    handleAddComment,
    handleSubmit,
    isAddCommentError,
    isAddCommentLoading,
    isError,
    register,
    setComment,
    setIsError,
  }
}

export default useCommentsForm
