const formatListType = listType => {
  const source = listType?.toLowerCase()
  return (
    (source === 'aggregated' && 'Recurring Lists') ||
    (source === 'betalist' && 'Betalist') ||
    (source === 'capterra' && 'Capterra') ||
    (source === 'custom' && 'Uploaded List') ||
    (source === 'latka' && 'Latka') ||
    (source === 'li_radar' && 'Employee Growth') ||
    (source === 'lsn_search' && 'LSN Search') ||
    (source === 'new_crunchbase' && 'Crunchbase') ||
    (source === 'open_source' && 'Open Source') ||
    (source === 'producthunt' && 'Producthunt') ||
    (source === 'web_scraping' && 'Web Scraping') ||
    (source === 'stealth' && 'Founders @ Stealth') ||
    (source === 'my-lists-companies-list' && 'My Lists') ||
    source
  )
}

export default formatListType
