import {memo} from 'react'
import {arrayOf, bool, func, shape} from 'prop-types'
import {ShareCompanyButton, ShareCompanyModal} from './components'

const ShareCompany = ({companiesToShare, isTable, modal, setModal}) => (
  <>
    <ShareCompanyButton isTable={isTable} setModal={setModal} />
    <ShareCompanyModal
      companiesToShare={companiesToShare}
      modal={modal}
      setModal={setModal}
    />
  </>
)

ShareCompany.propTypes = {
  companiesToShare: arrayOf(shape({})).isRequired,
  isTable: bool.isRequired,
  modal: bool.isRequired,
  setModal: func.isRequired,
}

export default memo(ShareCompany)
