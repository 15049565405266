import {gql} from '@apollo/client'

const GET_MY_USER_ID = gql`
  query userCustomField {
    userCustomField {
      id
      user {
        id
        username
      }
    }
  }
`

export default GET_MY_USER_ID
