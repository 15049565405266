import {
  ListItem as BaseListItem,
  Loader as BaseLoader,
  Text as BaseText,
} from 'components/primitives'
import {styled} from 'styles'

export const ListItem = styled(BaseListItem, {
  ai: 'baseline',
  d: 'flex',
  fd: 'column',
  '@bp1': {fd: 'row'},
  '&:last-of-type': {
    mb: 0,
  },
})

export const Loader = styled(BaseLoader, {
  ml: '$8',
})

export const Text = styled(BaseText, {
  variants: {
    variant: {
      'added-label': {
        color: '$secondary',
        d: 'inline',
        fontSize: '$13',
        fontWeight: '$300',
        ml: '-$4',
        mr: '$4',
      },
      label: {
        color: '$black',
        d: 'inline',
        fontSize: '$14',
        fontWeight: '$500',
        mr: '$8',
      },
    },
  },
})
