import {memo} from 'react'
import {arrayOf, func, shape} from 'prop-types'

import LabelChoiceItem from './components'

const LabelChoices = ({handleAddLabel, labelChoices}) => (
  <ul>
    {labelChoices.map(({uuid, color, name}) => (
      <LabelChoiceItem
        key={uuid}
        color={color}
        handleAddLabel={handleAddLabel}
        name={name}
        uuid={uuid}
      />
    ))}
  </ul>
)

LabelChoices.propTypes = {
  handleAddLabel: func.isRequired,
  labelChoices: arrayOf(shape({})).isRequired,
}

export default memo(LabelChoices)
