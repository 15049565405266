const getUsersSameOffice = (users, myself) =>
  users
    .filter(
      ({username, office}) =>
        username !== myself.username && office === myself.office,
    )
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name))

export default getUsersSameOffice
