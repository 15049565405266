import {useMemo, useState} from 'react'
import {useQuery} from '@apollo/client'
import {useAuth} from 'context'
import {GET_USERS} from 'constants/queries'
import {
  formatOffice,
  getMyselfInAllUsers,
  getUsersOtherOffices,
  getUsersSameOffice,
} from 'utils'
import getFormattedCollaboratorsUsers from './utils'

const useAddCollaborators = ({collaboratorsIds}) => {
  const [checkedUsersIdsSameOffice, setCheckedUsersIdsSameOffice] = useState([])
  const [
    checkedUsersIdsOtherOffices,
    setCheckedUsersIdsOtherOffices,
  ] = useState([])
  const [users, setUsers] = useState({})
  const [myOffice, setMyOffice] = useState('')
  const {authState} = useAuth()
  const loggedUserUsername = authState.username
  const {
    data: usersData,
    error: isUsersQueryError,
    loading: isUsersQueryLoading,
  } = useQuery(GET_USERS, {
    onCompleted: () => {
      const formattedUsers = getFormattedCollaboratorsUsers(
        usersData.users,
        collaboratorsIds,
      )
      const myself = getMyselfInAllUsers(formattedUsers, loggedUserUsername)
      const usersSameOffice = getUsersSameOffice(formattedUsers, myself)
      const usersOtherOffices = getUsersOtherOffices(formattedUsers, myself)
      setUsers({
        [myself.office]: usersSameOffice,
        otherOffices: usersOtherOffices,
      })
      setMyOffice(myself.office)
    },
    onError: error => console.error(error),
  })
  const formattedOffice = useMemo(() => myOffice && formatOffice(myOffice), [
    myOffice,
  ])
  return {
    checkedUsersIdsOtherOffices,
    checkedUsersIdsSameOffice,
    formattedOffice,
    isUsersQueryError,
    isUsersQueryLoading,
    loggedUserUsername,
    myOffice,
    setCheckedUsersIdsOtherOffices,
    setCheckedUsersIdsSameOffice,
    setUsers,
    users,
    usersData,
  }
}

export default useAddCollaborators
