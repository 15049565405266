import {arrayOf, func, string} from 'prop-types'
import {
  Box,
  CheckboxList,
  ErrorMessage,
  Flex,
  Loader,
  SelectAllCheckbox,
  Text,
} from 'components'
import useAddCollaborators from './hooks'

const AddCollaborators = ({
  checkedUsersIds,
  collaboratorsIds,
  creatorUsername,
  setCheckedUsersIds,
}) => {
  const {
    checkedUsersIdsOtherOffices,
    checkedUsersIdsSameOffice,
    formattedOffice,
    isUsersQueryError,
    isUsersQueryLoading,
    loggedUserUsername,
    myOffice,
    setCheckedUsersIdsOtherOffices,
    setCheckedUsersIdsSameOffice,
    setUsers,
    users,
    usersData,
  } = useAddCollaborators({collaboratorsIds})
  return (
    <Box variant="span-column">
      {isUsersQueryError && (
        <ErrorMessage css={{mb: '$16'}}>
          An error occurred getting the users data. Please try to refresh or
          report this bug.
        </ErrorMessage>
      )}
      {isUsersQueryLoading && (
        <Loader size="20" css={{mb: '$8'}} data-testid="loader" />
      )}
      {usersData && (
        <Flex css={{jc: 'space-between'}}>
          <Box css={{mr: '$24'}}>
            <Text variant="office">{formattedOffice} office:</Text>
            <SelectAllCheckbox
              checkedUsersIdsOtherOffices={checkedUsersIdsOtherOffices}
              checkedUsersIdsSameOffice={checkedUsersIdsSameOffice}
              office={myOffice}
              setCheckedUsersIds={setCheckedUsersIds}
              setCheckedUsersIdsSameOffice={setCheckedUsersIdsSameOffice}
              setUsers={setUsers}
              users={users[myOffice]}
            />
            <CheckboxList
              checkedUsersIds={checkedUsersIds}
              checkedUsersIdsOtherOffices={checkedUsersIdsOtherOffices}
              checkedUsersIdsSameOffice={checkedUsersIdsSameOffice}
              creatorUsername={creatorUsername}
              loggedUserUsername={loggedUserUsername}
              myOffice={myOffice}
              office={myOffice}
              setCheckedUsersIds={setCheckedUsersIds}
              setCheckedUsersIdsOtherOffices={setCheckedUsersIdsOtherOffices}
              setCheckedUsersIdsSameOffice={setCheckedUsersIdsSameOffice}
              setUsers={setUsers}
              users={users[myOffice]}
            />
          </Box>
          <Box css={{fg: '0.5'}}>
            <Text variant="office">Other offices:</Text>
            <CheckboxList
              checkedUsersIds={checkedUsersIds}
              checkedUsersIdsOtherOffices={checkedUsersIdsOtherOffices}
              checkedUsersIdsSameOffice={checkedUsersIdsSameOffice}
              creatorUsername={creatorUsername}
              loggedUserUsername={loggedUserUsername}
              myOffice={myOffice}
              office="otherOffices"
              setCheckedUsersIds={setCheckedUsersIds}
              setCheckedUsersIdsOtherOffices={setCheckedUsersIdsOtherOffices}
              setCheckedUsersIdsSameOffice={setCheckedUsersIdsSameOffice}
              setUsers={setUsers}
              users={users.otherOffices}
            />
          </Box>
        </Flex>
      )}
    </Box>
  )
}

AddCollaborators.propTypes = {
  checkedUsersIds: string.isRequired,
  collaboratorsIds: arrayOf(string).isRequired,
  creatorUsername: string.isRequired,
  setCheckedUsersIds: func.isRequired,
}

export default AddCollaborators
