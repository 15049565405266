import {gql} from '@apollo/client'

const ADD_TO_PIPEDRIVE = gql`
  mutation addToPipedrive(
    $channel: String
    $channelDetail: String
    $city: String
    $contactPersonUuid: String
    $country: String
    $currency: String
    $dealName: String
    $description: String
    $diversityFounders: String
    $foundedOnYear: String
    $fundingStage: String
    $fundingTotalUsd: Int
    $homepageUrl: String
    $organizationName: String!
    $sector: String
    $source: String
    $uuid: String!
  ) {
    addToPipedrive(
      channel: $channel
      channelDetail: $channelDetail
      city: $city
      contactPersonUuid: $contactPersonUuid
      country: $country
      currency: $currency
      dealName: $dealName
      description: $description
      diversityFounders: $diversityFounders
      foundedOnYear: $foundedOnYear
      fundingStage: $fundingStage
      fundingTotalUsd: $fundingTotalUsd
      homepageUrl: $homepageUrl
      organizationName: $organizationName
      sector: $sector
      source: $source
      uuid: $uuid
    ) {
      addToPipedrive {
        pipedriveDealId
        uuid
      }
    }
  }
`

export default ADD_TO_PIPEDRIVE
