import {memo} from 'react'
import {arrayOf, bool, func, shape, string} from 'prop-types'
import {CloseIcon} from 'assets'
import {
  Box,
  Button,
  ErrorMessage,
  Flex,
  Icon,
  Label,
  Loader,
  MentionInput,
  Text,
} from 'components'
import LabelChoices from './components'
import {PopoverArrow, PopoverContent, PopoverClose} from './styles'

const LabelPopoverContent = ({
  commentText,
  handleAddLabel,
  isLabelChoicesQueryError,
  isLabelChoicesQueryLoading,
  labelChoices,
  setCommentText,
}) => (
  <PopoverContent sideOffset={8}>
    <PopoverArrow />
    <Flex css={{mb: '$16'}}>
      <Text variant="card-subtitle" css={{fg: '1', mb: 0}}>
        Add label
      </Text>
      <PopoverClose>
        <Button
          as="span"
          size="ghost-small"
          title="Close"
          type="button"
          variant="ghost-icon"
        >
          <Icon color="grey200" size="20">
            <CloseIcon />
          </Icon>
        </Button>
      </PopoverClose>
    </Flex>
    <Box css={{minWidth: '$300', w: '$full', mb: '$16'}}>
      <Label htmlFor="commentText">Comment</Label>
      <MentionInput
        comment={commentText}
        name="commentText"
        onChange={e => setCommentText(e.target.value)}
      />
    </Box>
    {isLabelChoicesQueryError && (
      <ErrorMessage>
        Error getting labels data. Please try again or report this bug.
      </ErrorMessage>
    )}
    {isLabelChoicesQueryLoading && <Loader size="20" />}
    {labelChoices?.length > 0 ? (
      <LabelChoices
        handleAddLabel={handleAddLabel}
        labelChoices={labelChoices}
      />
    ) : null}
  </PopoverContent>
)

LabelPopoverContent.propTypes = {
  commentText: string.isRequired,
  handleAddLabel: func.isRequired,
  isLabelChoicesQueryError: bool.isRequired,
  isLabelChoicesQueryLoading: bool.isRequired,
  labelChoices: arrayOf(shape({})).isRequired,
  setCommentText: func.isRequired,
}

export default memo(LabelPopoverContent)
