import {useEffect} from 'react'
import {useSearch} from 'context'

const useKeyShortcutCtrlAlt = (targetKey, callback, isOpenModal) => {
  const state = useSearch()
  useEffect(() => {
    const listener = e => {
      const activeElement = document.activeElement.nodeName.toLowerCase()
      if (
        isOpenModal &&
        e.ctrlKey &&
        e.altKey &&
        e.key === targetKey &&
        activeElement !== 'textarea' &&
        activeElement !== 'input' &&
        activeElement !== 'select' &&
        !state.isInputFocused
      ) {
        e.preventDefault()
        callback()
      }
      if (
        !isOpenModal &&
        e.ctrlKey &&
        e.altKey &&
        e.key === targetKey &&
        activeElement !== 'textarea' &&
        activeElement !== 'input' &&
        activeElement !== 'select' &&
        !state.isInputFocused &&
        !state.isModalOpen
      ) {
        e.preventDefault()
        callback()
      }
    }
    document.addEventListener('keydown', listener)
    return () => document.removeEventListener('keydown', listener)
  }, [
    targetKey,
    callback,
    isOpenModal,
    state.isInputFocused,
    state.isModalOpen,
  ])
}

export default useKeyShortcutCtrlAlt
