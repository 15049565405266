import {Root as PopoverRoot} from '@radix-ui/react-popover'
import {string} from 'prop-types'
import {UserFeedback} from 'components'
import {LabelPopoverContent, LabelPopoverTrigger} from './components'
import useAddLabel from './hooks'

const AddLabel = ({uuid}) => {
  const {
    commentText,
    errorMsg,
    handleAddLabel,
    isAddLabelError,
    isAddLabelLoading,
    isError,
    isLabelChoicesQueryError,
    isLabelChoicesQueryLoading,
    labelChoices,
    open,
    setCommentText,
    setIsError,
    setOpen,
  } = useAddLabel({uuid})
  return (
    <>
      <PopoverRoot open={open} onOpenChange={setOpen}>
        <LabelPopoverTrigger isAddLabelLoading={isAddLabelLoading} />
        <LabelPopoverContent
          commentText={commentText}
          handleAddLabel={handleAddLabel}
          isLabelChoicesQueryError={isLabelChoicesQueryError}
          isLabelChoicesQueryLoading={isLabelChoicesQueryLoading}
          labelChoices={labelChoices}
          setCommentText={setCommentText}
        />
      </PopoverRoot>
      {isAddLabelError && isError && (
        <UserFeedback isError={isError} setIsError={setIsError}>
          {errorMsg ||
            `An error occurred adding the label. Please try again
          or report this bug.`}
        </UserFeedback>
      )}
    </>
  )
}

AddLabel.propTypes = {
  uuid: string.isRequired,
}

export default AddLabel
