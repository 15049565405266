import {memo} from 'react'
import {bool, func} from 'prop-types'
import {HeartIcon} from 'assets'
import {Button, Icon, Text} from 'components'

const SaveToListButton = ({isInList, isTable, setModal}) => (
  <Button
    color={isInList ? 'primary' : 'grey'}
    css={
      !isTable ? {svg: {color: isInList ? '$primary' : '$grey300'}} : undefined
    }
    onClick={() => setModal(true)}
    size={!isTable ? 'small' : 'ghost-small'}
    title="Save/Remove List - L"
    type="button"
    variant={!isTable ? 'secondary-icon' : 'ghost'}
  >
    <Icon
      color={isInList ? '$primary' : '$grey300'}
      css={!isTable ? {mr: '$4'} : undefined}
      data-testid="icon"
      size="16"
    >
      <HeartIcon />
    </Icon>
    {!isTable && <Text>Save</Text>}
  </Button>
)

SaveToListButton.propTypes = {
  isInList: bool.isRequired,
  isTable: bool.isRequired,
  setModal: func.isRequired,
}

export default memo(SaveToListButton)
