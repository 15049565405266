import {styled} from 'styles'

const Image = styled('img', {
  d: 'block',
  variants: {
    size: {
      20: {
        wh: '$20',
      },
      22: {
        wh: '$22',
      },
      32: {
        wh: '$32',
      },
      64: {
        wh: '$64',
      },
      100: {
        wh: '$100',
      },
      full: {
        wh: '$full',
      },
      fullW: {
        w: '$full',
      },
    },
    variant: {
      company: {
        br: '$small',
        objectFit: 'scale-down',
      },
      person: {
        br: '$small',
        objectFit: 'cover',
      },
    },
  },
})

export default Image
