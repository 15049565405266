import {gql} from '@apollo/client'

const GET_EMAIL_TEMPLATE = gql`
  query userCustomField {
    emailTemplate: userCustomField {
      outreachEmailSubject
      outreachEmailTemplate
    }
  }
`

export default GET_EMAIL_TEMPLATE
