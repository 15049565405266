import {forwardRef, memo} from 'react'
import {arrayOf, bool, func, number, string, shape} from 'prop-types'
import {useLocation} from 'react-router-dom'

const TableBody = forwardRef(
  (
    {
      cursor,
      data,
      getTableBodyProps,
      isCompaniesTable,
      isTableReviewLoading,
      listType,
      prepareRow,
      selectedUuid,
      setCursor,
      setFocusNextItem,
      setSelected,
    },
    ref,
  ) => {
    const {pathname} = useLocation()
    return (
      <tbody {...getTableBodyProps()} data-testid="tbody">
        {data.map((row, i) => {
          prepareRow(row)
          if (isCompaniesTable || listType === 'vc-tracker') {
            return (
              <tr
                {...row.getRowProps()}
                className={`${
                  i === cursor &&
                  pathname !== '/dashboard' &&
                  pathname !== '/my-lists'
                    ? 'active'
                    : ''
                }`}
                onClick={() => {
                  setSelected(row)
                  setCursor(i)
                }}
                ref={ref[i]}
              >
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>
                    {cell.render('Cell', {
                      isTableReviewLoading,
                      selectedUuid,
                      setFocusNextItem,
                    })}
                  </td>
                ))}
              </tr>
            )
          }
          if (
            listType === 'people' ||
            listType === 'investors' ||
            listType === 'people-list'
          ) {
            return (
              <tr
                {...row.getRowProps()}
                className={`${i === cursor ? 'active' : ''}`}
                onClick={() => {
                  setSelected(row)
                  setCursor(i)
                }}
                ref={listType === 'people-list' ? ref[i] : undefined}
              >
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>
                    {cell.render('Cell', {
                      selectedUuid,
                    })}
                  </td>
                ))}
              </tr>
            )
          }
          if (listType === 'portfolio-companies') {
            return (
              <tr
                {...row.getRowProps()}
                className={`${i === cursor ? 'active' : ''}`}
                onClick={() => {
                  setSelected(row)
                  setCursor(i)
                }}
                ref={ref[i]}
              >
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>
                    {cell.render('Cell', {
                      isTableReviewLoading,
                      selectedUuid,
                      setFocusNextItem,
                    })}
                  </td>
                ))}
              </tr>
            )
          }
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td {...cell.getCellProps()}>
                    {cell.render('Cell', {listType})}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    )
  },
)

TableBody.propTypes = {
  cursor: number.isRequired,
  data: arrayOf(shape({})).isRequired,
  getTableBodyProps: func.isRequired,
  isCompaniesTable: bool.isRequired,
  isTableReviewLoading: bool.isRequired,
  listType: string.isRequired,
  prepareRow: func.isRequired,
  selectedUuid: string.isRequired,
  setCursor: bool.isRequired,
  setFocusNextItem: bool.isRequired,
  setSelected: bool.isRequired,
}

export default memo(TableBody)
