import {gql} from '@apollo/client'

const GET_INVESTOR_PORTFOLIO_FUNDING_ROUNDS = gql`
  query investorPortfolioFundingRounds(
    $investorUuid: String
    $page: Int
    $pageSize: Int
  ) {
    investorPortfolioFundingRounds(
      investorUuid: $investorUuid
      page: $page
      pageSize: $pageSize
    ) {
      page
      pages
      hasNext
      hasPrev
      count
      totalCount
      objects {
        announcedOn
        city
        countryCode
        investmentType
        name
        orgName
        orgUuid {
          categoryList
          cbUrl
          diversityFounders
          extra
          homepageUrl
          linkedinUrl
          logoUrl
          organizationpipedrive {
            pipedriveUrl
            uuid {
              uuid
            }
          }
          permalink
          twitterUrl
          uuid
        }
        raisedAmountUsd
        uuid
      }
    }
  }
`

export default GET_INVESTOR_PORTFOLIO_FUNDING_ROUNDS
