import {styled} from 'styles'

const Icon = styled('span', {
  d: 'inline-block',
  variants: {
    color: {
      primary: {
        color: '$primary',
      },
      secondary: {
        color: '$secondary',
      },
      green: {
        color: '$green',
      },
      grey200: {
        color: '$grey200',
      },
      grey300: {
        color: '$grey300',
      },
      yellow100: {
        color: '$yellow100',
      },
      white: {
        color: '$white',
      },
    },
    size: {
      7: {
        wh: '$7',
      },
      9: {
        wh: '9px',
      },
      12: {
        wh: '$12',
      },
      13: {
        wh: '$13',
      },
      14: {
        wh: '$14',
      },
      16: {
        wh: '$16',
      },
      17: {
        wh: '$17',
      },
      19: {
        wh: '$19',
      },
      20: {
        wh: '$20',
      },
      24: {
        wh: '$24',
      },
      32: {
        wh: '$32',
      },
      40: {
        wh: '$40',
      },
      full: {
        wh: '$full',
      },
    },
  },
})

export default Icon
