import {styled} from 'styles'

const TableStyles = styled('div', {
  ox: 'auto',
  oy: 'hidden',
  table: {
    ox: 'auto',
    borderSpacing: 0,
    tableLayout: 'auto',
    w: '$full',
    'th, td': {
      margin: 0,
      ta: 'left',
      '&:last-child': {
        borderRight: 0,
      },
    },
    th: {
      borderBottom: '1px solid $grey100',
      color: '$grey300',
      fontSize: '$13',
      fontWeight: '$400',
      pr: '$8',
      py: '$8',
      verticalAlign: 'top',
      whiteSpace: 'nowrap',
    },
    td: {
      borderBottom: '1px solid $grey100',
      fontSize: '$13',
      pr: '$8',
      py: '$8',
      verticalAlign: 'top',
    },
    'tbody > tr': {
      bgc: '$white',
      transition: 'background-color 0.3s ease',
      '&:last-child': {
        td: {
          borderBottom: 0,
        },
      },
    },
    '.active': {
      bgc: '$active',
      transition: 'background-color 0.3s ease',
    },
  },
  variants: {
    variant: {
      dashboard: {
        table: {
          th: {
            fontSize: '$11',
          },
        },
      },
      'vc-companies': {
        mt: '-$8',
      },
    },
  },
})

export default TableStyles
