import {useCallback, useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {useDispatch, useSearch} from 'context'
import {openLinkNewTab} from 'utils'
import useKeyDown from '../useKeyDown'
import useKeyShortcut from '../useKeyShortcut'

const useTableShortcuts = ({
  canNextPage,
  canPreviousPage,
  isPortfolioTable,
  isVcTable,
  listType,
  listUuid,
  nextPage,
  page,
  pageIndex,
  previousPage,
  rowsRefs,
  scope,
  setCompanyToReview,
  setCompanyToSave,
  setCompanyToShare,
}) => {
  const isCompaniesPage = listType === 'companies-page'
  const isInvestorsPage = listType === 'investors-page'
  const isMyListsPageActionList = listType === 'action-list-my-lists'
  const isMyListsPage = listType === 'my-lists-page'
  const isEventPage = listType === 'web-scraping-event'
  const [showColumnsModal, setShowColumnsModal] = useState(false)
  const [reviewModal, setReviewModal] = useState(false)
  const [saveRemoveListModal, setSaveRemoveListModal] = useState(false)
  const [addToPipedriveModal, setAddToPipedriveModal] = useState(false)
  const [cursor, setCursor] = useState(0)
  const [selected, setSelected] = useState({})
  const [focusNextItem, setFocusNextItem] = useState(false)
  const [shareModal, setShareModal] = useState(false)
  const activeElement = document.activeElement.nodeName.toLowerCase()
  const {push} = useHistory()
  const {pathname} = useLocation()
  const dispatch = useDispatch()
  const setCompaniesSearchPage = useCallback(
    companiesSearchPage =>
      dispatch({type: 'setCompaniesSearchPage', companiesSearchPage}),
    [dispatch],
  )
  const setInvestorsSearchPage = useCallback(
    investorsSearchPage =>
      dispatch({type: 'setInvestorsSearchPage', investorsSearchPage}),
    [dispatch],
  )
  const setIsPageChanged = useCallback(
    isPageChanged => dispatch({type: 'setIsPageChanged', isPageChanged}),
    [dispatch],
  )
  const {isInputFocused, isModalOpen, isPageChanged} = useSearch()
  const scrollTop = useCallback(
    () => window.scrollTo({top: 0, behavior: 'smooth'}),
    [],
  )
  const portfolioTable =
    isPortfolioTable && document.getElementById('table')?.parentNode
  const webScrapingEventTable =
    isEventPage && document.getElementById('pre-table')?.parentNode
  const scrollTopOfPortofolioTable = useCallback(
    () =>
      portfolioTable?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      }),
    [portfolioTable],
  )
  const scrollTopOfWebScrapingEventTable = useCallback(
    () =>
      webScrapingEventTable?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      }),
    [webScrapingEventTable],
  )
  useEffect(() => {
    if (!isMyListsPage && !isPortfolioTable && !isEventPage) {
      scrollTop()
      setCursor(0)
    }
  }, [isEventPage, isMyListsPage, isPortfolioTable, pageIndex, scrollTop])
  useEffect(() => {
    if (isPageChanged && isPortfolioTable) {
      scrollTopOfPortofolioTable()
      setCursor(0)
      setIsPageChanged(false)
    }
  }, [
    isPageChanged,
    isPortfolioTable,
    scrollTopOfPortofolioTable,
    setIsPageChanged,
  ])
  useEffect(() => {
    if (isPageChanged && isEventPage) {
      scrollTopOfWebScrapingEventTable()
      setCursor(0)
      setIsPageChanged(false)
    }
  }, [
    isPageChanged,
    isEventPage,
    scrollTopOfWebScrapingEventTable,
    setIsPageChanged,
  ])
  useEffect(() => {
    if (
      !isInputFocused &&
      !isModalOpen &&
      activeElement !== 'input' &&
      !isMyListsPage &&
      !isMyListsPageActionList &&
      page.length
    ) {
      setSelected(page[cursor])
      if (
        selected !== undefined &&
        Object.keys(selected).length !== 0 &&
        !isVcTable
      ) {
        setCompanyToSave([
          {
            inMyActionlist: selected.original.inMyActionlist,
            inMyList: selected.original.inMyList,
            orgUuid: selected.original.uuid,
            orgName: selected.original.name,
            logoUrl: selected.original.logoUrl,
          },
        ])
        setCompanyToReview([
          {
            orgUuid: selected.original.uuid,
            name: selected.original.name,
          },
        ])
        setCompanyToShare([
          {uuid: selected.original.uuid, name: selected.original.name},
        ])
      }
      if (
        selected !== undefined &&
        Object.keys(selected).length !== 0 &&
        isVcTable &&
        selected.original.orgUuid
      ) {
        setCompanyToSave([
          {
            inMyActionlist: selected.original.orgUuid.inMyActionlist,
            inMyList: selected.original.orgUuid.inMyList,
            orgUuid: selected.original.orgUuid.uuid,
            orgName: selected.original.orgUuid.name,
            logoUrl: selected.original.orgUuid.logoUrl,
          },
        ])
        setCompanyToReview([
          {
            orgUuid: selected.original.orgUuid.uuid,
            name: selected.original.orgUuid.name,
          },
        ])
        setCompanyToShare([
          {
            uuid: selected.original.orgUuid.uuid,
            name: selected.original.orgUuid.name,
          },
        ])
      }
    }
  }, [
    activeElement,
    cursor,
    isMyListsPageActionList,
    isMyListsPage,
    page,
    selected,
    setCompanyToReview,
    setCompanyToSave,
    setCompanyToShare,
    isInputFocused,
    isModalOpen,
    isVcTable,
  ])
  // focus next item after reviewing
  useEffect(() => {
    if (focusNextItem && page.length) {
      setCursor(prevState =>
        prevState < page.length - 1 ? prevState + 1 : prevState,
      )
      setFocusNextItem(false)
    }
  }, [focusNextItem, page.length])
  // scroll into focused item
  useEffect(() => {
    if (
      cursor > 0 &&
      rowsRefs &&
      rowsRefs[cursor] &&
      rowsRefs[cursor].current !== null
    ) {
      rowsRefs[cursor].current?.scrollIntoView({
        behavior: 'auto',
        block: 'center',
      })
    }
  }, [cursor, rowsRefs])
  useKeyDown('ArrowDown', () => {
    if (
      !isMyListsPage &&
      !isMyListsPageActionList &&
      page.length &&
      !isModalOpen
    ) {
      setCursor(prevState =>
        prevState < page.length - 1 ? prevState + 1 : prevState,
      )
    }
  })
  useKeyDown('ArrowUp', () => {
    if (
      !isMyListsPage &&
      !isMyListsPageActionList &&
      page.length &&
      !isModalOpen
    ) {
      setCursor(prevState => (prevState > 0 ? prevState - 1 : prevState))
    }
  })
  useKeyDown('ArrowRight', () => {
    if (
      !isMyListsPage &&
      !isMyListsPageActionList &&
      page.length &&
      !isModalOpen &&
      canNextPage
    ) {
      nextPage()
      if (isCompaniesPage) {
        setCompaniesSearchPage(pageIndex + 2)
      }
      if (isInvestorsPage) {
        setInvestorsSearchPage(pageIndex + 2)
      }
      if (isPortfolioTable || isEventPage) {
        setIsPageChanged(true)
      }
    }
  })
  useKeyDown('ArrowLeft', () => {
    if (
      !isMyListsPage &&
      !isMyListsPageActionList &&
      page.length &&
      !isModalOpen &&
      canPreviousPage
    ) {
      previousPage()
      if (isCompaniesPage) {
        setCompaniesSearchPage(pageIndex)
      }
      if (isInvestorsPage) {
        setInvestorsSearchPage(pageIndex)
      }
      if (isPortfolioTable || isEventPage) {
        setIsPageChanged(true)
      }
    }
  })
  useEffect(() => {
    const listener = e => {
      const keyDownCheck =
        !isInputFocused &&
        !isModalOpen &&
        activeElement !== 'textarea' &&
        activeElement !== 'input' &&
        activeElement !== 'select' &&
        selected &&
        Object.keys(selected).length !== 0
      const isCtrlEnter = e.ctrlKey && e.keyCode === 13 && keyDownCheck
      const isEnter = e.keyCode === 13 && !e.ctrlKey && keyDownCheck
      const section =
        (selected.original?.type === 'person' && 'people') || 'companies'
      const permalink =
        selected.original?.permalink || selected.original?.orgUuid?.permalink
      const uuid = selected.original?.uuid || selected.original?.orgUuid?.uuid
      if (isCtrlEnter && !isVcTable) {
        push({
          pathname: `/${section}/${permalink}`,
          search: `?uuid=${uuid}`,
          state: listUuid &&
            scope && {
              listUuid,
              scope,
            },
        })
      }
      if (isEnter && !isVcTable) {
        openLinkNewTab(
          `${
            window.location.href.split(/\b\/\b/)[0]
          }/${section}/${permalink}?uuid=${uuid}`,
        )
      }
      if (isEnter && isVcTable && selected.original?.orgUuid) {
        openLinkNewTab(
          `${
            window.location.href.split(/\b\/\b/)[0]
          }/companies/${permalink}?uuid=${uuid}`,
        )
      }
      if (isCtrlEnter && isVcTable && selected.original?.orgUuid) {
        push({
          pathname: `/companies/${permalink}`,
          search: `?uuid=${uuid}`,
        })
      }
      if ((isCtrlEnter || isEnter) && isVcTable && !selected.original.orgUuid) {
        openLinkNewTab(selected.original.url)
      }
      return null
    }
    document.addEventListener('keydown', listener)
    return () => document.removeEventListener('keydown', listener)
  }, [
    activeElement,
    listUuid,
    pathname,
    push,
    scope,
    selected,
    isInputFocused,
    isModalOpen,
    isVcTable,
  ])
  useKeyShortcut(
    'b',
    () =>
      (selected.original?.betalistUrl &&
        openLinkNewTab(selected.original.betalistUrl)) ||
      (selected.original?.orgUuid?.betalistUrl &&
        openLinkNewTab(selected.original.orgUuid.betalistUrl)),
  )
  useKeyShortcut(
    'c',
    () =>
      (selected.original?.cbUrl && openLinkNewTab(selected.original.cbUrl)) ||
      (selected.original?.orgUuid?.cbUrl &&
        openLinkNewTab(selected.original.orgUuid.cbUrl)),
  )
  useKeyShortcut(
    'h',
    () =>
      (selected.original?.homepageUrl &&
        openLinkNewTab(selected.original.homepageUrl)) ||
      (selected.original?.orgUuid?.homepageUrl &&
        openLinkNewTab(selected.original.orgUuid.homepageUrl)),
  )
  useKeyShortcut(
    'l',
    () =>
      (selected.original?.linkedinUrl &&
        openLinkNewTab(selected.original.linkedinUrl)) ||
      (selected.original?.orgUuid?.linkedinUrl &&
        openLinkNewTab(selected.original.orgUuid.linkedinUrl)),
  )
  useKeyShortcut(
    'p',
    () => {
      if (
        (selected.original?.isOnPipedrive === 'Yes' &&
          selected.original?.pipedriveUrl &&
          !isVcTable) ||
        (selected.original?.orgUuid?.isOnPipedrive === 'Yes' &&
          selected.original?.orgUuid?.pipedriveUrl &&
          isVcTable)
      )
        openLinkNewTab(
          selected.original?.pipedriveUrl ||
            selected.original?.orgUuid?.pipedriveUrl,
        )
      if (
        selected.original?.isOnPipedrive === 'No' ||
        selected.original?.orgUuid?.isOnPipedrive === 'No'
      )
        setAddToPipedriveModal(!addToPipedriveModal)
    },
    true,
  )
  useKeyShortcut(
    't',
    () =>
      (selected.original?.twitterUrl &&
        openLinkNewTab(selected.original.twitterUrl)) ||
      (selected.original?.orgUuid?.twitterUrl &&
        openLinkNewTab(selected.original.orgUuid.twitterUrl)),
  )
  useKeyShortcut(
    'C',
    () =>
      (selected.original?.capterraUrl &&
        openLinkNewTab(selected.original.capterraUrl)) ||
      (selected?.original.orgUuid?.capterraUrl &&
        openLinkNewTab(selected.original.orgUuid.capterraUrl)),
  )
  useKeyShortcut(
    'L',
    () =>
      ((selected.original && !isVcTable) ||
        (isVcTable && selected.original?.orgUuid)) &&
      !isInvestorsPage &&
      setSaveRemoveListModal(!saveRemoveListModal),
    true,
  )
  useKeyShortcut(
    's',
    () =>
      ((selected.original && !isVcTable) ||
        (isVcTable && selected.original?.orgUuid)) &&
      !isInvestorsPage &&
      setShareModal(!shareModal),
    true,
  )
  useKeyShortcut(
    'r',
    () =>
      ((selected.original && !isVcTable) ||
        (isVcTable && selected.original?.orgUuid)) &&
      !isInvestorsPage &&
      setReviewModal(!reviewModal),
    true,
  )
  useKeyShortcut(
    '|',
    () =>
      ((selected.original && !isVcTable) ||
        (isVcTable && selected.original?.orgUuid)) &&
      !isInvestorsPage &&
      setShowColumnsModal(!showColumnsModal),
    true,
  )
  return {
    addToPipedriveModal,
    cursor,
    focusNextItem,
    reviewModal,
    saveRemoveListModal,
    selected,
    setAddToPipedriveModal,
    setCursor,
    setFocusNextItem,
    setReviewModal,
    setSaveRemoveListModal,
    setSelected,
    setShareModal,
    setShowColumnsModal,
    shareModal,
    showColumnsModal,
  }
}

export default useTableShortcuts
