import {useState} from 'react'
import {useMutation} from '@apollo/client'
import {useLocation} from 'react-router-dom'
import {GET_EVENT_ALL_EDITIONS, GET_EVENTS_REMOVED} from 'pages/Events/queries'
import REMOVE_EVENT from './mutations'

const useRemoveEventModal = ({editionUuid, eventUuid, listType, setModal}) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorReason, setErrorReason] = useState('')
  const {pathname} = useLocation()
  const isEventsTable = listType === 'events'
  const scrollTop = () => window.scrollTo({top: 0, behavior: 'smooth'})
  const [
    removeEvent,
    {loading: isRemoveEventLoading, error: isRemoveEventError},
  ] = useMutation(REMOVE_EVENT, {
    onCompleted: () => {
      setModal(false)
      setIsSuccess(true)
      if (pathname === '/events') scrollTop()
    },
    onError: error => {
      const errorMsgReason = error?.graphQLErrors[0]?.message
      setErrorReason(errorMsgReason)
      setModal(false)
      setIsError(true)
      console.error(error)
    },
  })
  const handleRemoveEvent = () =>
    removeEvent({
      variables: {editionUuid},
      refetchQueries:
        (pathname === '/events' && [
          {
            query: GET_EVENTS_REMOVED,
            variables: {page: 1, pageSize: 100, pastEvents: true},
          },
          {
            query: GET_EVENTS_REMOVED,
            variables: {page: 1, pageSize: 999999, pastEvents: true},
          },
          {
            query: GET_EVENTS_REMOVED,
            variables: {page: 1, pageSize: 999999, futureEvents: true},
          },
        ]) ||
        (isEventsTable &&
          pathname.startsWith('/events/') && [
            {
              query: GET_EVENT_ALL_EDITIONS,
              variables: {eventUuid},
            },
          ]),
    })
  return {
    errorReason,
    handleRemoveEvent,
    isError,
    isEventsTable,
    isRemoveEventError,
    isRemoveEventLoading,
    isSuccess,
    setIsError,
    setIsSuccess,
  }
}

export default useRemoveEventModal
