import {bool, number, string} from 'prop-types'
import {Loader, Text} from 'components'
import {formatNumber} from 'utils'
import useShowingResultsCounter from './hooks'

const ShowingResultsCounter = ({
  allDataLoading,
  isFiltered,
  listType,
  pageCount,
  pageIndex,
  pageSize,
  totalCount,
  totalFilteredCount,
}) => {
  const {
    isCompaniesOrPeopleOrInvestorsPage,
    isDashboardPage,
    resultsType,
    totalFiltered,
    totalResults,
  } = useShowingResultsCounter({listType, totalCount, totalFilteredCount})
  return (
    <Text
      css={{
        as: 'flex-start',
        mb: '$16',
        '@bp1': {as: 'center', mb: 0},
      }}
      data-testid="showing-results-counter"
      variant="pagination"
    >
      {isFiltered && totalFilteredCount ? (
        <>
          Showing{' '}
          {isCompaniesOrPeopleOrInvestorsPage
            ? 1
            : formatNumber(pageIndex * pageSize + 1)}{' '}
          to {isCompaniesOrPeopleOrInvestorsPage && totalFiltered}
          {!isCompaniesOrPeopleOrInvestorsPage &&
            pageIndex + 1 !== pageCount &&
            pageSize * (pageIndex + 1)}
          {!isCompaniesOrPeopleOrInvestorsPage &&
            pageIndex + 1 === pageCount &&
            totalFiltered}{' '}
          of {totalFiltered} filtered out of{' '}
          {isCompaniesOrPeopleOrInvestorsPage ? pageSize : totalResults}{' '}
          {resultsType}{' '}
          {isCompaniesOrPeopleOrInvestorsPage && (
            <>
              <Text variant="pagination-strong">from this page only</Text> (use
              the sidebar filters to filter the entire database)
            </>
          )}
        </>
      ) : null}
      {!isFiltered && (
        <>
          Showing {formatNumber(pageIndex * pageSize + 1)} to{' '}
          {pageIndex + 1 !== pageCount
            ? formatNumber(pageSize * (pageIndex + 1))
            : totalResults}{' '}
          of{' '}
          {allDataLoading && isDashboardPage ? (
            <Loader size="14" css={{d: 'inline-block'}} data-testid="loader" />
          ) : (
            totalResults
          )}{' '}
          {resultsType}
        </>
      )}
    </Text>
  )
}

ShowingResultsCounter.propTypes = {
  allDataLoading: bool.isRequired,
  isFiltered: bool.isRequired,
  listType: string.isRequired,
  pageCount: number.isRequired,
  pageIndex: number.isRequired,
  pageSize: number.isRequired,
  totalCount: number.isRequired,
  totalFilteredCount: number.isRequired,
}

export default ShowingResultsCounter
