import {Flex} from 'components'
import {styled} from 'styles'

const NoCompanyInMyListsContainer = styled(Flex, {
  ai: 'center',
  fd: 'column',
  jc: 'center',
  m: 'auto',
})

export default NoCompanyInMyListsContainer
